import {ROLES, SCOPES} from '../../constants/allConstants';
const ELEMENTS = {
  companyInfo: 'companyInfo',
  financeInfo: 'financeInfo',
  companySettings: 'companySettings',
  ownerInfo: 'ownerInfo',
  workersInfo: 'workersInfo',
  inviteButton: 'inviteButton',
  editButton: 'editButton',
  inviteCompany: 'inviteCompany',
};

const companyRules = Object.freeze({
  [ELEMENTS.companyInfo]: {
    [SCOPES.canView]: [ROLES.COMPANY_ADMIN, ROLES.CONTRACTOR, ROLES.IPERMIT_ADMIN, ROLES.IPERMIT_USER],
    [SCOPES.canEdit]: [ROLES.COMPANY_ADMIN, ROLES.IPERMIT_ADMIN, ROLES.IPERMIT_USER]
  },
  [ELEMENTS.ownerInfo]: {
    [SCOPES.canView]: [ROLES.COMPANY_ADMIN, ROLES.IPERMIT_ADMIN, ROLES.IPERMIT_USER],
    [SCOPES.canEdit]: [ROLES.COMPANY_ADMIN, ROLES.IPERMIT_ADMIN, ROLES.IPERMIT_USER]
  },
  [ELEMENTS.workersInfo]: {
    [SCOPES.canView]: [ROLES.COMPANY_ADMIN, ROLES.CONTRACTOR, ROLES.IPERMIT_ADMIN, ROLES.IPERMIT_USER],
    [SCOPES.canEdit]: [ROLES.COMPANY_ADMIN, ROLES.IPERMIT_ADMIN, ROLES.IPERMIT_USER]
  },
  [ELEMENTS.editButton]: {
    [SCOPES.canView]: [ROLES.COMPANY_ADMIN, ROLES.IPERMIT_ADMIN]
  },
  [ELEMENTS.financeInfo]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN],
    [SCOPES.canEdit]: [ROLES.IPERMIT_ADMIN],
    hideWhenImpersonating: [SCOPES.canView, SCOPES.canEdit]
  },
  [ELEMENTS.companySettings]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.IPERMIT_USER],
    [SCOPES.canEdit]: [ROLES.IPERMIT_ADMIN],
    hideWhenImpersonating: [SCOPES.canView, SCOPES.canEdit]
  },
  [ELEMENTS.inviteCompany]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.SYSTEM_ADMIN]
  }


});

export {companyRules, ELEMENTS};
