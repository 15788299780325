export const IPERMIT_MODULES = {
  INSPECTION: 'inspection',
  PERMIT: 'permit',
  HERS: 'hers',
  SCHEDULING: 'scheduling',
  MAILING: 'mailing',
  FINANCE: 'accounting',
  ALL_JOBS: 'all_jobs',
  CF1R: 'cf1r',
  COMPANIES: 'companies',
  ALL_COMPANIES: 'all_companies',
  CONTRACTOR_USERS: 'contractor_users',
};
