import {
  JOB_TYPES,
  JOB_FIELDS,
  MAILING_STATUSES,
  PERMIT_STATUSES,
  MAP_JOB_TYPE_VALUE_TO_API, ANSWER_OPTIONS, NOTE_TYPES, EQUIPMENT, USER_FIELDS
} from '../constants/allConstants';
import {toast} from 'react-toastify';
import * as allConstants from '../constants/allConstants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {EQUIPMENT_REQUIRES_MAP} from '../constants/equipment';
const lzjs = require('lzjs');
const _ = require('lodash');
const {DateTime} = require('luxon');

const Utils = {
  getInitials: (userName) => {
    const {firstName, lastName} = userName;
    const name = firstName + ' ' + lastName;
    const initialsRegex = new RegExp(/(\b\S)?/g);
    const initials = name.match(initialsRegex).join('').toUpperCase();
    return initials;
  },

  trimString: (str, length)=> {
    if (str.length > length) {
      return str.substring(0, length) + '...';
    } else {
      return str;
    }
  },

  getFormattedScheduledDate: ({startDate, startTime, endTime, timezone, duration, raw})=> {
    const date = Utils.formatIsoDateString(startDate, {date:true});

    if(startTime && endTime) {
      return `${date}, ${Utils.timeTo12HrFormat(startTime)} - ${Utils.timeTo12HrFormat(endTime)} ${timezone?.shortName || 'PST'}`;
    }
    let startT;
    let endT;

    if(startTime) {
      startT = Utils.timeTo12HrFormat(startTime);
      endT = Utils.addHoursToTime(startT, duration || 1);
    }

    if(raw) {
      return {
        date,
        startTime: Utils.timeTo12HrFormat(startT),
        endTime : Utils.timeTo12HrFormat(endT),
        timezone: timezone?.shortName || 'PST'
      };
    }

    if(startTime) {
      return `${date}, ${Utils.timeTo12HrFormat(startT)} - ${Utils.timeTo12HrFormat(endT)} ${timezone?.shortName || 'PST'}`.toUpperCase();
    } else {
      return `${date}`.toUpperCase();
    }
  },

  /**
   *
   * @param {string} time XX:XX
   */
  timeTo12HrFormat: (time)=> {
    if(!time) { return null;}
    time = time.toLowerCase();
    if(time.includes('am') || time.includes('pm')) { return time; }
    if(!time.includes(':')) return null;
    //return time is doesnt match regex am separated by space
    const [hours, minutes] = time.split(':');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${ampm}`;
  },

  addHoursToTime: (timeString, h)=> {
    if(!h) return timeString;
    try{
      const hours = Number(timeString.split(':')[0]);
      let added = hours + h;
      let ampm = timeString.split(' ')[1];
      if(added >= 12) {
        added = added === 12 ? 12 : added - 12;
        if(hours !== 12){
          if(ampm === 'AM') {
            ampm = 'PM';
          } else {
            ampm = 'AM';
          }
        }

      }

      return `${added}:${timeString.split(':')[1].split(' ')[0]} ${ampm}`;
    } catch (e) {
      return timeString;
    }



  },

  addHoursToISODate: (isodate,h)=> {
    if(!h || typeof h !== 'number') return isodate;
    const date = new Date(isodate);
    date.setHours(date.getHours() + h);
    return date.toISOString();

  },
  /**
   * Will trim long names after 16character, replacign the rest with ...
   * @param {string} name
   * @return {string}
   */
  trimUserName: (name)=> {
    if(!name || name.length<=16) return name;
    return `${name.slice(0,15)}...`;
  },
  dataURItoBlob: (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
  },
  /**
   *
   * @param {string} v1
   * @param {string} v2
   * @return {boolean}
   */
  equalsIgnoreCase: (v1, v2)=> {
    if(!v1 || !v2) return false;
    if(typeof v1 !== 'string' || typeof v2 !== 'string') return false;
    return v1.toLowerCase() === v2.toLowerCase();
  },
  /**
   *
   * @param {string} v1
   * @param {string} v2
   * @return {boolean}
   */
  notEqualsIgnoreCase: (v1, v2)=> {
    return v1.toLowerCase() !== v2.toLowerCase();
  },
  filterNotRelevantFields: (jobType, jobObject)=> {
    //console.log('filtering', jobObject);
    const fieldMap = {
      [JOB_TYPES.BUSINESS_LIC.value]: [
        JOB_FIELDS.JOB_TYPE,
        JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER,
        JOB_FIELDS.GENERAL.JOB_VALUE,
        JOB_FIELDS.GENERAL.JOB_JSON,
        JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL,
        JOB_FIELDS.GENERAL.START_DATE,
        JOB_FIELDS.GENERAL.NEED_BY_DATE,
        JOB_FIELDS.PERMIT_STATUS,
        JOB_FIELDS.ATTACHMENT.TEXT,
        JOB_FIELDS.NOTE.TEXT,
        JOB_FIELDS.ID
      ]
    };
    if(!fieldMap?.[jobType]) return jobObject;
    const allowedFields = new Set(fieldMap[jobType].map((f)=> f.api_name));
    Object.keys(jobObject).forEach((key)=> {
      if(!allowedFields.has(key)) {
        delete jobObject[key];
      }
    });
    return jobObject;
  },
  createJobObject: ({main, equipment, attachments})=> {
    const job_type = main[JOB_FIELDS.JOB_TYPE.api_name];
    const job_api_type = MAP_JOB_TYPE_VALUE_TO_API[job_type];
    const json = {};
    json[job_api_type] = _.cloneDeep(main[job_api_type]);
    if(equipment && equipment.length !==0) {
      json[JOB_FIELDS.SHARED.EQUIPMENT.api_name] = equipment;
    }

    if(Utils.equalsIgnoreCase(job_type, JOB_TYPES.BUSINESS_LIC.value)) {
      json[JOB_TYPES.BUSINESS_LIC.api_name] = {
        [JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name]: main[JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name]
      };
      delete main[JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name];
    }

    if(main?.[JOB_FIELDS.GENERAL.NEW_CONSTRUCTION.api_name]) {
      json[JOB_FIELDS.GENERAL.NEW_CONSTRUCTION.api_name] = main?.[JOB_FIELDS.GENERAL.NEW_CONSTRUCTION.api_name];
    }

    if(main[JOB_TYPES.COMMERCIAL.api_name]) {
      json[JOB_TYPES.COMMERCIAL.api_name]  = {...main[JOB_TYPES.COMMERCIAL.api_name]};
      delete main[JOB_TYPES.COMMERCIAL.api_name];
    }

    //attachments
    if(attachments && attachments.length !== 0) {
      main[JOB_FIELDS.ATTACHMENT.TEXT.api_name] = attachments;
      delete main[JOB_FIELDS.FILE.api_name];
    }

    //note
    if(!main?.[JOB_FIELDS.NOTE.TEXT.api_name] || main[JOB_FIELDS.NOTE.TEXT.api_name].length === 0) {
      delete  main[JOB_FIELDS.NOTE.TEXT.api_name];
    } else if(main?.[JOB_FIELDS.NOTE.TEXT.api_name] && main[JOB_FIELDS.NOTE.TEXT.api_name].length !== 0) {
      const text =  main[JOB_FIELDS.NOTE.TEXT.api_name];

      main[JOB_FIELDS.NOTE.TEXT.api_name] = {
        text: text,
        [JOB_FIELDS.NOTE.TYPE.api_name]: job_type === JOB_TYPES.TESTING_ONLY.value ? NOTE_TYPES.HERS.value : NOTE_TYPES.PERMIT.value
      };

    }

    delete main[job_api_type];

    //mechanical and testing only
    if(Utils.equalsIgnoreCase(job_type, JOB_TYPES.HVAC_RES.value) || Utils.equalsIgnoreCase(job_type, JOB_TYPES.TESTING_ONLY.value)) {
      if (json?.[job_api_type]?.[JOB_FIELDS.MECHANICAL.EL_INFO.api_name] && Utils.isEquipmentUnset(json[job_api_type][JOB_FIELDS.MECHANICAL.EL_INFO.api_name])) {
        delete json[job_api_type][JOB_FIELDS.MECHANICAL.EL_INFO.api_name];
      }
      if(main?.[JOB_TYPES.COMMERCIAL.api_name]) {
        json[JOB_TYPES.COMMERCIAL.api_name] = main[JOB_TYPES.COMMERCIAL.api_name];
        delete main[JOB_TYPES.COMMERCIAL.api_name];
      }
      if(json?.[job_api_type]?.[JOB_FIELDS.MECHANICAL.PL_INFO.api_name]) {
        if(!Utils.valueIsSpecified(json?.[job_api_type][JOB_FIELDS.MECHANICAL.PL_INFO.api_name]?.[JOB_FIELDS.MECHANICAL.GAS_LINE.api_name]) &&
        !json?.[job_api_type][JOB_FIELDS.MECHANICAL.PL_INFO.api_name][JOB_FIELDS.MECHANICAL.NEED_WH.api_name]
        ) {
          delete json[job_api_type][JOB_FIELDS.MECHANICAL.PL_INFO.api_name];
        }
      }
    }
    //mechanical only
    if(Utils.equalsIgnoreCase(job_type, JOB_TYPES.HVAC_RES.value)) {

      if(json[job_api_type]?.[JOB_FIELDS.MECHANICAL.PL_INFO.api_name]) {
        if (json[job_api_type]?.[JOB_FIELDS.MECHANICAL.PL_INFO.api_name][JOB_FIELDS.MECHANICAL.NEED_PLUMBING.api_name]) {
          delete json[job_api_type][JOB_FIELDS.MECHANICAL.PL_INFO.api_name][JOB_FIELDS.MECHANICAL.NEED_PLUMBING.api_name];
        }
      }
      if(json?.[job_api_type]?.[JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS.api_name] && json[job_api_type]?.[JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS.api_name].length === 0) {
        delete json[job_api_type][JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS.api_name];
      }
    }
    //testing only

    if(!Utils.equalsIgnoreCase(job_type, JOB_TYPES.TESTING_ONLY.value) && !Utils.equalsIgnoreCase(job_type, JOB_TYPES.HVAC_RES.value)) {
      delete main[JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name];
    }
    //solar
    if(Utils.equalsIgnoreCase(job_type, JOB_TYPES.SOLAR.value)){
      // console.log('Solar', main[JOB_TYPES.SOLAR.api_name]);
      //
      // json[JOB_TYPES.SOLAR.api_name] =  main[JOB_TYPES.SOLAR.api_name];
      // console.log(json, json);
      // delete main[JOB_TYPES.SOLAR.api_name]
    }

    const jobObj = Utils.filterNotRelevantFields(job_type, {...Utils.setJobAutoFields(main), ...{[JOB_FIELDS.GENERAL.JOB_JSON.api_name]: JSON.stringify(json)}});


    return jobObj;
  },
  getEquipmentThatRequiresMap: (subforms)=> {
    const equipmentRequiresMap = EQUIPMENT_REQUIRES_MAP;
    let equipment = [];
    const excludeKeys = new Set(['attachments']);
    if(subforms && Object.keys(subforms).length !== 0) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(subforms)) {
        if(!excludeKeys.has(key)) {
          equipment = [...equipment, ...Utils.removeEmptyEquipmentRows(value.getValues())];
        }
      }
    }
    return equipment.map((each)=> each.eq_name).filter((e)=> equipmentRequiresMap.includes(e));
  },

  jobShouldHaveMap: (job)=> {
    const json = job?.[JOB_FIELDS.GENERAL.JOB_JSON.api_name];
    let usedEquipment = [];
    if(json && typeof json === 'string') {
      try{
        const parsedJson = JSON.parse(json);
        usedEquipment = parsedJson?.equipment ? parsedJson.equipment : [];
      } catch(e) {
        console.error('Error on parsing:::', json);
      }
    }
    if(usedEquipment.length === 0) return false;
    return usedEquipment.map((piece)=>piece.eq_name).filter((e)=> EQUIPMENT_REQUIRES_MAP.includes(e)).length !== 0;
  },

  isServiceTitanJobWithMissingMap: (job)=> {
    if(!job) return false;
    if(job[JOB_FIELDS.SERVICE_TITAN_ID.api_name]) {
      return Utils.jobShouldHaveMap(job) && !job?.map;
    }
    return false;
  },

  getEquipmentString: (equipment)=> {
    if(!equipment || equipment.length === 0) return '';
    const counter = {
      [EQUIPMENT.FURNACE.display_name]: 0,
      [EQUIPMENT.GAS_PU.display_name]: 0,
      [EQUIPMENT.AIR_HANDLER.display_name]: 0,
      [EQUIPMENT.HEAT_PUMP_PU.display_name]: 0,
      [EQUIPMENT.CONDENSER.display_name]: 0,
      [EQUIPMENT.COIL.display_name]: 0,
      [EQUIPMENT.HEAT_PUMP_COND.display_name]: 0,
      [EQUIPMENT.MINI_SPLIT.display_name]:0,
      [EQUIPMENT.MINI_SPLIT_DUCTED.display_name]: 0,
      [EQUIPMENT.R6.display_name]:0,
      [EQUIPMENT.R8.display_name]:0,
      [EQUIPMENT.R10.display_name]:0,

    };
    equipment.forEach((piece)=> {
      const displayName = allConstants.EQ_API_TO_NAME_MAPPING[piece.eq_name];
      if(Utils.objectHasProperty(counter, displayName)) {
        counter[displayName] = counter[displayName] + 1;
      }
    });
    let string = '';
    for (const [key, value] of Object.entries(counter)) {
      if(value !== 0) {
        if(string.length !== 0) {
          string = string + ', ';
        }
        string = string+`${key}(${value})`;
      }
    }
    return string;
  },

  formatMapDistances: (dist)=> {
    if(dist && Array.isArray(dist)) {
      dist.forEach((m)=> {
        for (const [key, value] of Object.entries(m)) {
          for (let [k, v] of Object.entries(value)) {
            m[key][k] = v.length !== 0 ? v : '0';
          }
        }
      });
    }
    return dist;
  },

  getMapDistances: (json, jobType)=> {
    try{
      const obj = JSON.parse(json);
      const marks = obj?.[jobType]?.marks;
      return Utils.formatMapDistances(marks);
    } catch(e){
      return [];
    }

  },

  setJobAutoFields: (job)=> {
    job[JOB_FIELDS.MAILING_STATUS.api_name] = MAILING_STATUSES.NEW.value;
    if(job[JOB_FIELDS.JOB_TYPE.api_name] !== JOB_TYPES.TESTING_ONLY.value) {
      job[JOB_FIELDS.PERMIT_STATUS.api_name] = PERMIT_STATUSES.NEW.value;
    }
    return job;
  },

  getEquipmentFromSavedSubforms: (equipmentForm )=> {
    let equipment = [];

    if(equipmentForm && Object.keys(equipmentForm).length !== 0) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(equipmentForm)) {

        // Skip attachments
        if (key === 'attachments') {
          continue;
        }

        equipment = [...equipment, ...Utils.removeEmptyEquipmentRows(value.getValues())];
      }
    }
    return equipment;
  },

  removeEmptyEquipmentRows: (obj)=> {
    const newEqObject = [];
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(obj)) {
      const eq_values = value[0];

      if(eq_values) {
        const name = eq_values?.name;
        // eslint-disable-next-line no-unused-vars
        for(const [eq_key, eq_value] of Object.entries(eq_values.fields)) {
          if(!Utils.isEquipmentUnset(eq_value)) {
            newEqObject.push(
              {...eq_value, ...{eq_name: name}}
            );
          }
        }
      }

    }
    return newEqObject;
  },

  /**
   * Check if any of equipment properties is specified
   * @param eq_obj
   * @return {boolean}
   */
  isEquipmentUnset: (eq_obj)=> {
    const obj_values = Object.values(eq_obj);
    const filtered = obj_values.filter((v)=> v && true && v!== '');
    return filtered.length === 0;
  },

  testFunction: (a, b) => a + b,
  /**
   *
   * @param {Array} a
   * @param {Number}count
   * @return {*[]}
   */
  chunkify: (a, count) => {
    let n = Math.ceil(a.length / count);

    if (n === 1) {
      return [a];
    }

    const len = a.length;
    const out = [];
    let i = 0;
    let size;

    if (len % n === 0) {
      // size = Math.floor(len / n);
      size = count;
      while (i < len) {
        out.push(a.slice(i, i += size));
      }
    } else {
      n--;
      size = Math.floor(len / n);
      if (len % size === 0) size--;
      while (i < size * n) {
        out.push(a.slice(i, i += size));
      }
      out.push(a.slice(size * n));
    }
    return out;
  },
  /**
   *
   * @param {Array} arr
   * @param {Number} colCount
   */
  splitIntoColumns: (arr, colCount) => {
    if(!arr || !Array.isArray(arr) || !colCount) return null;
    const curr = [...arr];
    var arrays = [];
    while (curr.length > 0){
      arrays.push(curr.splice(0, Number(colCount)));
    }
    return arrays;

  },
  capitalizeAllObjectValues: (obj)=> {
    const newObj = Object.keys(obj).reduce((accumulator, key) => {
      return {...accumulator, [key]: obj[key].toUpperCase()};
    }, {});
    return newObj;
  },
  capitalizeFirstLetter: (string)=> {
    if(!string || string.length === 0) return '';
    return string[0].toUpperCase() + string.slice(1);
  },
  /**
   *
   * @param {Object|Array} opt_obj
   * @param{function|null} filterOptions
   * @return {any[]|*[]}
   */
  getOptions: (opt_obj, filterOptions=null)=> {
    const options = [];

    if(opt_obj && Array.isArray(opt_obj)) {
      opt_obj.forEach((v) => {
        options.push({name: v.display, value: v.value});
      });
    } else if(opt_obj && typeof opt_obj === 'object' && Object.keys(opt_obj).length !== 0){
      Object.values(opt_obj).forEach((v)=> {
        if(v.showOrder !== -1) {
          options[v.showOrder+1] = {name: v.display, value: v.value};
        }
      });
    }

    let filtered = options.filter((v)=> v !== null);
    if(filterOptions) {
      filtered = filtered.filter((opt)=> filterOptions(opt));
    }
    return filtered;
  },

  sleep: ms => new Promise(res => setTimeout(res, ms)),
  getAcronym: (str) => {
    if (!str) return '';
    return str.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
  },
  /**
   * @name  decodeFromBase64
   * @param str
   * @return {string|null}
   */
  decodeFromBase64: (str) => {
    try {
      const replaced_str = str.replace(/\s/g, '');
      return lzjs.decompressFromBase64(replaced_str);
    } catch (e) {
      console.log('Error on decodeFromBase64::', str);
      return null;
    }

  },
  urlContains: (name)=> {
    return window.location.href.indexOf(name) !== -1;
  },
  calculatePagesCount: (pageSize, totalCount) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  },
  /**
   *
   * @param {Object} location
   * @param {string} name
   * @param {string} value
   */
  createQueryParam: (location, params, exclude=[]) =>{
    const query = new URLSearchParams(location.search);
    Object.keys(params).forEach((p)=> {
      if(query.has(p)) {
        query.set(p, params[p]);
      } else {
        query.append(p, params[p]);
      }
    });

    exclude.forEach((p)=> {
      query.delete(p);
    });


    return query;
  },
  getUrlParameter: (name) =>{

    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

    var results = regex.exec(window.location.href);
    // console.log('getUrlParameter',  results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' ')));
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  /**
   * @name objectHasProperty
   * @memberOf Utils
   * @param {Object} obj
   * @param {string} property
   * @returns {boolean}
   */
  objectHasProperty: (obj, property) => {
    if(!obj) return false;
    return Object.prototype.hasOwnProperty.call(obj, property);
  },
  formstateContainErrorFor: (state, property) => {
    if(property === USER_FIELDS.PASSWORD.api_name) {
      return Utils.objectHasProperty(state, 'errors') && (_.get(state.errors, property)|| _.get(state.errors, USER_FIELDS.CONFIRM_PASSWORD.api_name));
    }
    return Utils.objectHasProperty(state, 'errors') && _.get(state.errors, property);
  },

  isAddressField: (key)=> {
    return key && key.toString().match(/address/gm);
  },

  getObjectDiff: (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!Utils.objectHasProperty(obj2, key)) {
        result.push(key);
      } else if (_.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  },


  dirtyValues: (dirtyFields, allValues) => {
    try{
      // NOTE: Recursive function.

      // If *any* item in an array was modified, the entire array must be submitted, because there's no
      // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
      const allKeys = Object.keys(allValues);

      //keep address fields always dirty
      allKeys.forEach((key)=> {
        if(Utils.isAddressField(key)){
          dirtyFields[key] = true;
        }
      });

      if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
      }
      //set dirty all nested address fields
      Object.values(dirtyFields).forEach((f)=> {
        if(typeof f === 'object') {
          const keys = Object.keys(f);
          if(keys && keys.includes(allConstants.COMPANY_FIELDS.ADDRESS_LINE_1.api_name)) {
            f[allConstants.COMPANY_FIELDS.ADDRESS_LINE_1.api_name] = true;
            f[allConstants.COMPANY_FIELDS.ADDRESS_LINE_2.api_name] = true;
            f[allConstants.COMPANY_FIELDS.ADDRESS_STATE.api_name] = true;
            f[allConstants.COMPANY_FIELDS.ADDRESS_CITY.api_name] = true;
            f[allConstants.COMPANY_FIELDS.ADDRESS_ZIPCODE.api_name] = true;
          }
        }
      });
      // Here, we have an object.
      return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [key, Utils.dirtyValues(dirtyFields[key], allValues[key])])
      );
    } catch(e) {
      return  allValues;
    }

  },
  checkAddressBeforeSubmit: (data)=> {

    if(data[allConstants.COMPANY_FIELDS.ADDRESS.api_name]){
      const address = data[allConstants.COMPANY_FIELDS.ADDRESS.api_name];
      const allValues = Object.values(address).map(v=> v.length > 0).filter((v)=> v);
      if(allValues && allValues.length !== 0) delete data[address];
    }

    return data;
  },
  isDevEnv: ()=> {
    return process.env.NODE_ENV === 'development';
  },
  isStagingEnv: ()=> {
    return process.env.NODE_ENV === 'staging';
  },
  isToday: (someDate)=> {
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear();
  },
  addMinutes(date, minutes) {

    if(!date || !minutes) return null;
    const timestamp = new Date(date).getTime();
    return new Date(timestamp + minutes * 60000);
  },
  createErrorObject: (message, code)=> {
    return {
      message,
      error: code,
    };
  },
  valueExist: (value) => {
    return (value === undefined || value === '') ? false : true;
  },
  /**
   * Will format iso string to XXX XX, XXXX, XX:XX XX
   * This converter will use America/Los_Angeles timezone
   * @param {string} isoDateString
   * @return {string}
   */
  formatIsoDateString: (isoDateString, options={}) => {
    if(!isoDateString) return options?.defaultReturn ? options.defaultReturn : '';
    const date = new Date(isoDateString);

    const defaultOptions = {
      timeZone: 'America/Los_Angeles'
    };

    const dateOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit'
    };

    const timeOptions = {hour: '2-digit', hour12: true, minute:'2-digit',};

    if(options?.date) {
      return date.toLocaleString('en-US', {...dateOptions, ...defaultOptions, ...options});
    } else if(options?.time) {
      return date.toLocaleString('en-US', {...timeOptions, ...defaultOptions, ...options});
    }

    const formattedString = date.toLocaleString('en-US', {...dateOptions, ...timeOptions, ...defaultOptions, ...options});
    return formattedString;
  },
  /**
   * Will format iso string to date string (no time) with format specified by options
   * This converter will use America/Los_Angeles timezone
   * https://www.w3schools.com/jsref/jsref_tolocalestring.asp
   * @param {string} isoDateString
   * @param {Object} options
   * @return {string}
   */
  formatIsoDateStringWithOptions: (isoDateString, options) => {
    if(!isoDateString || !options) return '';
    const date = new Date(isoDateString);
    const formattedString = date.toLocaleDateString(undefined, {...options, ...{timeZone: 'America/Los_Angeles'}});
    return formattedString;
  },
  /**
   * Will format iso string to time string (no date) with format specified by options
   * This converter will use America/Los_Angeles timezone
   * https://www.w3schools.com/jsref/jsref_tolocalestring.asp
   * @param {string} isoTimeString
   * @param {Object} options
   * @return {string}
   */
  formatIsoTimeStringWithOptions: (isoTimeString, options) => {
    if(!isoTimeString) return '';
    const opt = options? options : {};
    const date = new Date(isoTimeString);
    const formattedString = date.toLocaleTimeString(undefined, {...{timeZone: 'America/Los_Angeles'}, ...opt});
    return formattedString;
  },
  /**
   * Uses dayjs to format Local Date string to ISO date string in preferred timezone
   * KeepLocalTime if true would change the timezone of the date without changing the
   * local time. By defult rezones to 'America/Los_Angeles'
   * @param {string} dateString
   * @param {boolean} keepLocalTime
   * @param {string} tz
   * @returns
   */
  formatLocalTimeToIsoString: (dateString, keepLocalTime = false, tz = 'America/Los_Angeles') => {
    if(!dateString) return '';
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const isoDate = new Date(dateString).toISOString();
    const date = dayjs(isoDate).tz(tz, keepLocalTime);
    const rezonedIsoDate = date.toISOString();

    return rezonedIsoDate;
  },
  /**
   *
   * @param {any} val
   * @return {""|false|boolean}
   */
  valueIsSpecified: (val) => {
    if(!val) return false;

    if (val === '$0' || val === '$0.00') return false;
    if (typeof val === 'number') {
      return val !== 0;
    }
    if (typeof val === 'boolean') {
      // console.log('boolean')
      return val;
    }
    if (Array.isArray(val)) {
      return val.length !== 0;
    }
    if (typeof val === 'string') {
      if (!val || val.length === 0) {
        return false;
      }
      if(val.split(' ').filter((n)=> n!== 'null').length === 0 ){
        return false;
      }
    }
    if(val instanceof (Object)) {
      return false;
    }
    // console.log('val', val);
    return val && val?.toLowerCase() !== 'false' && val?.toLowerCase() !== 'no' && val?.toLowerCase() !== 'undefined';
  },
  reformatValue: (val) => {
    let transformed = val;
    if (val === null) {
      transformed = '-';
    }
    if (typeof val === 'boolean') {
      transformed = val ? 'yes' : 'no';
    }
    if (typeof val === 'number') {
      transformed = val.toString();
    }
    if (typeof val === 'string' && val.length === 0) {
      transformed = '-';
    }
    if (Array.isArray(val) && val.length !== 0) {
      transformed = val.join(', ');
    }
    return transformed;
  },
  /**
   *
   * @param {string} phone
   * @return {string} formatted (XXX) XXX-XXXX
   */
  // reformatPhoneNumberToDisplay: (phone)=> {
  //   if(!phone || phone.length === 0) return '';
  //   return new AsYouType('US').input(phone);
  // },
  /**
   * Will format phone numbers received from backend to (XXX) XXX-XXXX
   * @param {string} phone
   * @return {string} formatted (XXX) XXX-XXXX
   */
  formatPhoneNumber: (phone) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const countryExtensionMatch = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    const phoneNumberMatch = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (countryExtensionMatch) {
      let intlCode = (countryExtensionMatch[1] ? '+1 ' : '');
      return [intlCode, '(', countryExtensionMatch[2], ') ', countryExtensionMatch[3], '-', countryExtensionMatch[4]].join('');
    } else if (phoneNumberMatch) {
      return '(' + phoneNumberMatch[1] + ') ' + phoneNumberMatch[2] + '-' + phoneNumberMatch[3];
    } else {
      return null;
    }
  },
  /**
   * Will show error/success toast depending on response
   * @param {any} responseJson
   * @param {string} successMessage
   * @param {string} errorMessage
   * @param {string} toastId
   * @return
   */
  handleToastMessage: (responseJson, successMessage, errorMessage, toastId) => {
    if(responseJson.status === 'success') {
      toast.success(successMessage, {
        toastId: `success_${toastId}`
      });
    } else {
      toast.error(errorMessage, {
        toastId: `error_${toastId}`
      });
    }
  },
  errorToastMessage: (errorMessage, toastId) => {
    toast.error(errorMessage, {
      toastId: `error_${toastId}`
    });
  },
  textToBool: (text)=> {
    if(!text) return false;
    console.log('text', text);
    return Utils.equalsIgnoreCase(text, 'yes');
  },
  boolToText: (b)=> {
    if(b) return ANSWER_OPTIONS.YES.value;
    return ANSWER_OPTIONS.NO.value;
  },
  /**
   * Will format amount to USD currency and to the nearest 0.01 ("Penny Rounding")
   * @param {string} amount
   * @return {string} formatted $XX.XX
   */
  formatUsdCurrency: (amount) => {
    if (!amount) return null;
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      roundingIncrement: 1
    });
    const formattedCurrency = currencyFormatter.format(amount);

    return formattedCurrency;
  },
  createAddressString:(addressObj)=> {
    if (!addressObj || !(addressObj instanceof Object)) return null;
    return Object.values(addressObj).filter((l)=> l && l.length !== 0).join(', ');
  },
  /**
   * Will get name from response header's Content-Disposition
   * @param {string} fetchResponse
   * @return {string} fileName
   */
  getContentDispositionFileName: (fetchResponse) => {
    const contentDisposition = fetchResponse.headers['content-disposition'];
    const fileNameRegex = new RegExp(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/);
    const fileName = contentDisposition.match(fileNameRegex)[2];
    return fileName;
  },

  getEqupmentUsedString: (equipment)=> {
    if(!equipment || equipment.length ===0) return 0;
    const counter = {};
    equipment.forEach((piece)=> {
      if(!Utils.objectHasProperty(counter, piece.eq_name)) {
        counter[piece.eq_name] = 0;
      }
      counter[piece.eq_name] = counter[piece.eq_name] + 1;
    });
    let str = '';
    Object.keys(counter).forEach((key)=> {
      const amount = counter[key];
      if(amount && amount !== 0){
        if(str.length !== 0) {
          str = str + ', ';
        }
        str = `${str}${allConstants.EQ_API_TO_NAME_MAPPING[key]}(${amount})`;
      }
    });
    return str;
  },
  adjustTickCount: (maxValue) => {
    if(maxValue % 6 !== 0) {
      if(maxValue % 3 === 0) {
        return 4;
      } else if(maxValue % 5 === 0 ) {
        return 6;
      } else if(maxValue %2 === 0)
        return 3;
    }
    return 6;
  },
  /**
   * Will transform +XXXXXXXXXXX(+1 214 859 8758) to XXXXXXXXXX (214 859 8758)
   * @param {string} phoneNumber
   * @return {string|*}
   */
  reformatPhoneNumber: (phoneNumber)=> {
    //+1 214 859 8758
    if(!phoneNumber) return '';
    if(phoneNumber.indexOf('+') === 0 && phoneNumber.length === 12) {
      return phoneNumber.substring(2);
    }
    //1 214 859 8758
    if(phoneNumber.indexOf('1') === 0 && phoneNumber.length === 11) {
      return phoneNumber.substring(1);
    }

    return phoneNumber;
  },
  normalizeObjectKeys: (obj)=> {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      newObj[key.toLowerCase()] = value;
    }
    return newObj;
  },
  createSystemDataForRedux: (data, systemName)=> {
    const systems = {};
    for (const [key, value] of Object.entries(allConstants.SYSTEM_CONTENT)) {
      systems[key] = value.map((item)=> item.api_name);
    }

    if(!data || !systemName || !Object.keys(systems).includes(systemName)) return;

    const draft = data;

    if(!draft) return;

    const currentValues = {};
    const currentSystem = systems[systemName];
    draft.forEach((eqPiece)=> {
      if(currentSystem.includes(eqPiece.eq_name)) {
        if(!Utils.objectHasProperty(currentValues, eqPiece.eq_name)) {
          const name = eqPiece.eq_name;
          currentValues[eqPiece.eq_name] = [];
          currentValues[eqPiece.eq_name].push({
            name,
            fields:[]
          });

        }
        //delete eqPiece.eq_name;
        currentValues[eqPiece.eq_name][0].fields.push({...eqPiece});
      }
    });

    return currentValues;

  },
  canModifyEquipment: (jobData)=> {
    return true;

    //return !jobData?.[allConstants.JOB_FIELDS.SERVICE_TITAN_ID.api_name];
  },
  getAddressString: (address) => {
    if(!address) return '';
    const addressValues = Object.values(address).filter((v=> v.length !== 0));
    if(addressValues.length === 0) return '';
    const line2 = address?.line2 ? `, ${address.line2}` : '';
    return `${address.line1}${line2}, ${address.city}, ${address.state}, ${address.zipCode}`;
  },
  isObject: (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
  },

  mergeDeep: (target, source)=> {
    let output = Object.assign({}, target);
    if (Utils.isObject(target) && Utils.isObject(source)) {
      Object.keys(source).forEach(key => {
        if (Utils.isObject(source[key])) {
          if (!(key in target))
            Object.assign(output, {[key]: source[key]});
          else
            output[key] = Utils.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(output, {[key]: source[key]});
        }
      });
    }
    return output;
  },

  // will replace empty string values of field with key === 'fileUploadId` with undefined
  sanitizeFileUploadFields: (data)=> {
    const sanitizedData = {...data};
    //check all object keys and replace fileUploadIds with null if they are empty strings
    Object.keys(sanitizedData).forEach((key)=> {
      //ignore if array
      if(Array.isArray(sanitizedData[key])) return;
      if(typeof sanitizedData[key] === 'object') {
        sanitizedData[key] = Utils.sanitizeFileUploadFields(sanitizedData[key]);
      } else if(key === 'fileUploadId' && sanitizedData[key] === '') {
        sanitizedData[key] = undefined;
      }
    });
    return sanitizedData;

  },
  objectIsEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },
  openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  },

  /**
   * Converts a ISO datetime string to 12-hour format with AM/PM.
   *
   * @param {string} isoDatetimeStr - The datetime string in ISO format.
   * @param {object} options - The options object.
   * @param {boolean} options.includeSeconds - Whether to include seconds in the output. Defaults to true.
   * @returns {string} The datetime string in 12-hour format with AM/PM.
   */
  getTimeIn12hrFormat:  (isoDatetimeStr, options={
    includeSeconds: true
  }) => {
    const dt = new Date(isoDatetimeStr);
    const hours = dt.getHours() % 12 || 12;
    const minutes = dt.getMinutes().toString().padStart(2, '0');
    const seconds = dt.getSeconds().toString().padStart(2, '0');
    const amPm = dt.getHours() >= 12 ? 'PM' : 'AM';
    if(options.includeSeconds) {
      return `${hours}:${minutes}:${seconds} ${amPm}`;
    }

    return `${hours}:${minutes} ${amPm}`;
  },
  /**
   * Converts an ISO date in UTC to a date with a specific timezone.
   * @param {string} utcDateISO - The ISO date string in UTC.
   * @param {string} timezone - The target timezone (e.g., "America/Los_Angeles").
   * @returns {string} The formatted date string in the target timezone.
   */
  convertUtcToTimezone: (utcDateISO, timezone) => {
    try{
      const utcDateISOWithOffeset = DateTime.fromISO(utcDateISO, {zone: timezone}).toISO();
      //2017-05-15T13:36:00.000-04:00 exract offset
      const offset = utcDateISOWithOffeset.match(/([-+][0-9]{2}:[0-9]{2})/)[0];
      const offsetHours = parseInt(offset.split(':')[0]);
      const offsetMinutes = parseInt(offset.split(':')[1]);

      if(Number(offsetHours) < 0){
        const adjustedDate = DateTime.fromISO(utcDateISOWithOffeset).plus({hours: offsetHours, minutes: offsetMinutes});
        return adjustedDate.setZone(timezone).toISO().match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/)[0];
      } else {
        return utcDateISOWithOffeset.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/)[0];
      }

    } catch (error) {
      console.log('error', error);
      return utcDateISO;
    }
  },

  getTimezoneOffset: (timezone) => {
    try {
      const date = new Date();
      const offsetInMillis = date.getTimezoneOffset() * 60 * 1000;
      console.log('offsetInMillis:::', offsetInMillis);
      const targetOffsetInMillis = new Date().toLocaleString('en', {timeZone: timezone, timeZoneName: 'short'}).match(/([-+][0-9]{2}:[0-9]{2})/)[0];
      return -(targetOffsetInMillis + offsetInMillis) / (60 * 1000);
    } catch (error) {
      console.error('Error getting timezone offset:', error);
      return 0; // Fallback to UTC if there's an error
    }
  },


  /**
   * Groups timeline events by date.
   *
   * @param {Array} timelineEvents - The timeline events array.
   * @returns {Object} - An object containing the grouped timeline events, where each key represents a date and the corresponding value is an array of events.
   */
  groupTimelineEvents: (timelineEvents) => {
    const groupedItems = {};

    for (const item of timelineEvents) {
      const date = new Date(item.adjustedCreatedAt);
      const options = {year: 'numeric', month: 'short', day: 'numeric'};
      const formattedDate =  date.toLocaleDateString(undefined, options);

      if (!groupedItems[formattedDate]) {
        groupedItems[formattedDate] = []; // Initialize an empty array for the date
      }

      groupedItems[formattedDate].push(item); // Add the item to the corresponding date array
    }
    return groupedItems;
  },
};

export default Utils;
if(process.env.NODE_ENV === 'development') {
  window.Utils = Utils;
}

