import React, {useState} from 'react';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as allConstants from '../../constants/allConstants';
import UserModal from '../../pieces/modals/userModal';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import AdminPageGrid from './adminPageGrid';
import usePagination from '../../hooks/usePagination';
import ListPageHeader from '../../pieces/listPageHeader';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import {CheersConnection} from './cheersConnection';
import ApiUtils from '../../utils/apiUtils';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

const AdminPage = () => {
  const viewController = useGridViewState();

  const pagination = usePagination();
  const classes = globalUseClasses();
  const navigate = useNavigate();


  const [showConnectToCheersPopup, setConnectToCheersPopup] = useState(false);
  const handleConnectToCheersClose = () => setConnectToCheersPopup(false);

  const [selectedUser, setSelectedUser] = useState(undefined);


  const handleAddUserOpen = ()=>{
    navigate(`${allConstants.PATH.ADMIN_PAGE_USER}${location.search}`);
  };


  const headerComponent = <ListPageHeader header={'iPermit Users'}
    addHandler={handleAddUserOpen}
    showAddButton = {true}
    totalItems={pagination.totalItemsCount}
  >
    {pagination.ItemsController}
  </ListPageHeader>;


  const connectToCheersHandler = (data)=> {
    setSelectedUser(data);
    setConnectToCheersPopup(true);
  };

  const disconnectFromCheersHandler = async (data)=> {
    const result = await ApiUtils.makeApiCall('POST', 'users/disconnect-cheers-account', JSON.stringify({
      userId: data.id
    }));
    if(result.status === 'error'){
      toast(result.message, {type: 'error'});
    } else {
      toast('Cheers account disconnected', {type: 'success'});
    }
  };

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>
            {selectedUser && showConnectToCheersPopup && <UserModal
              open={showConnectToCheersPopup}
              handleClose={handleConnectToCheersClose}
              header={'Connect account to cheers'}>
              <CheersConnection
                selectedUser={selectedUser}
                closeModal={handleConnectToCheersClose}/>

            </UserModal>}

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AdminPageGrid
                viewController={viewController}
                pagination={pagination}
                cheersHandler={connectToCheersHandler}
                disconnectCheersHandler={disconnectFromCheersHandler}
              />
              {pagination.PageController}
            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};
export default AdminPage;
