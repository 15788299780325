import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../constants/allConstants';
import {globalUseClasses} from '../../styles/globalClasses';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import validator from '../../utils/validators/validator';
import {FormProvider, useForm} from 'react-hook-form';
import ApiUtils from '../../utils/apiUtils';
import {toast} from 'react-toastify';
import Utils from '../../utils/utils';
import {Box, Card, CardContent, Grid} from '@mui/material';
import clsx from 'clsx';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import {ERROR_MSG} from '../../constants/allConstants';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import {inputTypes} from '../../pieces/inputs/formInput';

export const RaterInfoPanel = (props) => {
  const {RATER_FIELDS} = allConstants;
  const [raterData, setRaterData] = useState(props?.raterData || {});
  const classes = {...globalUseClasses()};

  const options = {
    markets: useAutocompleteOptions({field: 'markets'}).options,
    raterDepartments: useAutocompleteOptions({field: 'raterDepartments'}).options
  };

  const getDefaultValue = (field, altValue='')=> {
    return raterData?.[field?.api_name]||altValue;
  };

  const getFormDefaultValues = ()=> {
    return {
      [RATER_FIELDS.NAME.api_name]: getDefaultValue(RATER_FIELDS.NAME),
      [RATER_FIELDS.STATUS.api_name]: getDefaultValue(RATER_FIELDS.STATUS),
      [RATER_FIELDS.PHONE_NUMBER.api_name]: getDefaultValue(RATER_FIELDS.PHONE_NUMBER),
      [RATER_FIELDS.ALT_PHONE_NUMBER.api_name]: getDefaultValue(RATER_FIELDS.ALT_PHONE_NUMBER),
      [RATER_FIELDS.EMAIL.api_name]: getDefaultValue(RATER_FIELDS.EMAIL),
      [RATER_FIELDS.ALT_EMAIL.api_name]: getDefaultValue(RATER_FIELDS.ALT_EMAIL),
      [RATER_FIELDS.DEPARTMENTS.api_name]: getDefaultValue(RATER_FIELDS.DEPARTMENTS, []),
      [RATER_FIELDS.MARKETS.api_name]: getDefaultValue(RATER_FIELDS.MARKETS, []),
      [RATER_FIELDS.CITIES.api_name]: getDefaultValue(RATER_FIELDS.CITIES, []),
      [RATER_FIELDS.ONYX_ENVOY_EMAIL.api_name]: getDefaultValue(RATER_FIELDS.ONYX_ENVOY_EMAIL),
    };
  };

  Yup.addMethod(Yup.string, 'checkIfEmailExists', validator.checkIfEmailExists);
  Yup.addMethod(Yup.string, 'validatePhoneNumber', function (errorMessage) {
    return this.test('phone_number', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidPhoneNumber(value) ||
        createError({path, message: errorMessage})
      );
    });
  });
  Yup.addMethod(Yup.string, 'validateEmail', function (errorMessage) {
    return this.test('email', errorMessage, function (value, nullable) {
      const {path, createError} = this;
      return (
        validator.isValidEmail(value, nullable) ||
        createError({path, message: errorMessage})
      );
    });
  });

  Yup.addMethod(Yup.string, 'validateRaterEmail', async function (email, currentEmail) {
    if(!email) return true;
    if(currentEmail && currentEmail === email) return true;
    let exists = false;
    try {
      let endpoint = `ipermit/raters/is-unique?email=${email}`;
      if(raterData?.[RATER_FIELDS.ID.api_name]) endpoint += `&id=${raterData[RATER_FIELDS.ID.api_name]}`;
      const response = await ApiUtils.makeApiCall('GET', endpoint, undefined);
      exists = !!response.data?.isUnique;
    } catch (e) {
    }
    return exists;
  });

  Yup.addMethod(Yup.string, 'validateOnyxUserEmail', async function (email, check) {
    let valid = false;
    if(!email) return true;
    try {
      let endpoint = `ipermit/raters/validate-rater-onyx-email?email=${email}`;
      if(raterData?.[RATER_FIELDS.ID.api_name]) endpoint += `&id=${raterData[RATER_FIELDS.ID.api_name]}`;
      const response = await ApiUtils.makeApiCall('GET', endpoint, undefined);
      valid = response.data?.[check];
    } catch (e) {}
    return valid;

  });

  const getValidationSchema = ()=> {
    const validationOptions = {
      [RATER_FIELDS.NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
      [RATER_FIELDS.DEPARTMENTS.api_name]: Yup.array().required(allConstants.ERROR_MSG.REQUIRED_FIELD).min(1, ERROR_MSG.REQUIRED_FIELD),
      [RATER_FIELDS.MARKETS.api_name]: Yup.array().required(allConstants.ERROR_MSG.REQUIRED_FIELD).min(1, ERROR_MSG.REQUIRED_FIELD),
      [RATER_FIELDS.EMAIL.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD)

        .test(
          {
            test: value => {
              return Yup.string().validateRaterEmail(value, raterData[RATER_FIELDS.EMAIL.api_name]);
            },
            message: allConstants.ERROR_MSG.RATER_ALREADY_EXISTS,
          },

        ).validateEmail(allConstants.ERROR_MSG.EMAIL_INVALID),
      [RATER_FIELDS.ALT_EMAIL.api_name]: Yup.string().validateEmail(allConstants.ERROR_MSG.EMAIL_INVALID, true).nullable()  ,
      [RATER_FIELDS.PHONE_NUMBER.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE).nullable(),
      [RATER_FIELDS.ALT_PHONE_NUMBER.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE).nullable(),
      [RATER_FIELDS.ONYX_ENVOY_EMAIL.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).validateEmail(allConstants.ERROR_MSG.EMAIL_INVALID)
        .test(
          {
            test: value => {
              return Yup.string().validateOnyxUserEmail(value, 'isValid');
            },
            message: allConstants.ERROR_MSG.RATER_NOT_FOUND_IN_ONYX
          },
        )
        .test(
          {
            test: value => {
              return Yup.string().validateOnyxUserEmail(value, 'isUnique');
            },
            message: allConstants.ERROR_MSG.ONYX_EMAIL_USED_BY_OTHER_RATER
          },
        ),
    };
    return Yup.object().shape(validationOptions);
  };

  const formOptions = {
    mode: 'onBlur',
    criteriaMode:'all',
    reValidateMode: 'onChange',
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(getValidationSchema()),
  };

  const formMethods = useForm(formOptions);

  useEffect(()=> {
    setRaterData(props.raterData);
  }, [props.raterData]);

  useEffect(()=> {
    formMethods.reset(getFormDefaultValues());
  }, [raterData]);

  if(ApiUtils.isDevelopment()) {
    window.raterForm = formMethods;
  }

  const submitInvite = async (data) => {
    props.setSubmitting(true);
    const newData = {...data};
    const addResult = await ApiUtils.makeApiCall('POST', 'ipermit/raters/create', JSON.stringify(newData));
    let successMessage = 'Rater' + data[RATER_FIELDS.NAME.api_name] + ' successfully added.';
    if(addResult) {
      if(addResult.status === 'error'){
        toast(addResult.message, {type: 'error'});
      } else {
        toast(successMessage, {type: 'success'});
      }

      if(addResult?.data?.rater) {
        props.setRaterData(addResult?.data?.rater);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }
  };

  const onSubmit = async (data)=> {
    if(props.isAdding) {
      await submitInvite(data);
      return;
    } else {
      props.setSubmitting(true);
      const newRaterData = {...data};
      newRaterData[RATER_FIELDS.ID.api_name] = raterData[RATER_FIELDS.ID.api_name];

      try{
        let successMessage = 'Rater' + data[RATER_FIELDS.NAME.api_name] + ' successfully updated';
        const editResponse = await ApiUtils.makeApiCall('POST', 'ipermit/raters/edit', JSON.stringify(newRaterData));

        if(editResponse.status === 'error'){
          toast(editResponse.message, {type: 'error'});
        } else {
          toast(successMessage, {type: 'success'});
        }

        if(editResponse?.data?.rater) {
          props.setRaterData(editResponse?.data?.rater);
        }

      } catch (e) {
        console.error(e);
        Utils.errorToastMessage(`Error editing rater. ${e?.message}`);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }
  };

  const onCancel = ()=> {
    props.setViewMode(true);
    formMethods.reset(getFormDefaultValues());
  };

  const inputDefaultProps = {
    viewMode: props.viewMode,
    renderLabel: true,
    isEditable: true,
    fullWidth: true,
  };


  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} method={'POST'}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={classes.card_block} sx={{height: '100%'}}>
                <CardContent sx={{
                  '& .two_col_container': {
                    maxWidth: 'unset',
                    border: '1px solid red',
                  }
                }}>
                  <Box className={clsx(classes.two_col_container)}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={RATER_FIELDS.NAME}
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={RATER_FIELDS.EMAIL}
                    />
                  </Box>
                  <Box className={clsx(classes.two_col_container)}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={RATER_FIELDS.PHONE_NUMBER}
                      inputType={inputTypes.phone}
                      defaultValue={getDefaultValue(RATER_FIELDS.PHONE_NUMBER)}
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={RATER_FIELDS.ONYX_ENVOY_EMAIL}
                    />
                  </Box>
                  <Box className={clsx(classes.two_col_container)}>


                  </Box>
                  <Box className={clsx(classes.two_col_container)}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={RATER_FIELDS.ALT_PHONE_NUMBER}
                      inputType={inputTypes.phone}
                      defaultValue={getDefaultValue(RATER_FIELDS.ALT_PHONE_NUMBER)}
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={RATER_FIELDS.ALT_EMAIL}
                    />

                  </Box>

                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={classes.card_block} sx={{height: '100%'}}>
                <CardContent sx={{
                  '& .two_col_container': {
                    maxWidth: 'unset',
                  }
                }}>
                  <Box className={clsx(classes.two_col_container)}>
                    <CustomFormBlock key={getDefaultValue(RATER_FIELDS.DEPARTMENTS)?.length}
                      {...inputDefaultProps}
                      field={RATER_FIELDS.DEPARTMENTS}
                      inputType={inputTypes.multiSelect}
                      options={Utils.getOptions(options.raterDepartments)}
                      defaultValue={getDefaultValue(RATER_FIELDS.DEPARTMENTS)}
                    />
                    <CustomFormBlock key={JSON.stringify(getDefaultValue(RATER_FIELDS.CAN_DO_EUC))}
                      {...inputDefaultProps}
                      field={RATER_FIELDS.CAN_DO_EUC}
                      inputType={inputTypes.checkbox}
                      renderLabel={false}
                      labelLeft={true}
                      defaultValue={getDefaultValue(RATER_FIELDS.CAN_DO_EUC)}
                    />
                  </Box>
                  <Box className={clsx(classes.two_col_container)}>

                    <CustomFormBlock key={JSON.stringify(getDefaultValue(RATER_FIELDS.MARKETS))}
                      {...inputDefaultProps}
                      field={RATER_FIELDS.MARKETS}
                      inputType={inputTypes.multiSelect}
                      options={Utils.getOptions(options.markets)}
                      defaultValue={getDefaultValue(RATER_FIELDS.MARKETS)}
                    />
                  </Box>

                </CardContent>
              </Card>
            </Grid>
            {!props.viewMode && <Grid xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancel} cancelText={'Cancel'}/>
              </Box>
            </Grid>}
          </Grid>

        </form>
      </FormProvider>
    </>
  );
};

RaterInfoPanel.propTypes = {
  raterData: PropTypes.instanceOf(Object),
  setRaterData: PropTypes.func,
  viewMode: PropTypes.bool,
  setViewMode: PropTypes.func,
  isAdding: PropTypes.bool,
  setSubmitting: PropTypes.func,
};

RaterInfoPanel.defaultProps = {
  raterData: {},
  setRaterData: ()=>{},
  viewMode: true,
  setViewMode: ()=>{},
  isAdding: false,
  setSubmitting: ()=>{},
};

