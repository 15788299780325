import React, {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import {Box, Stack, Typography} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Utils from '../utils/utils';
import {urlSearchParams} from '../baseUrls';

const PageCountSelector = (props)=> {
  const handleSelect = (e)=> {
    props.handleChange(e.target.value);
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'} >
        <Box mr={3}>
          <Typography variant={'subtitle2'} fontSize={'0.9rem'}>{`Showing ${'1'} to ${props.itemsPerPage}`}</Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} mr={2}>
          <Typography variant={'subtitle2'} fontSize={'0.9rem'}>Show</Typography>
          <FormControl sx={{m: 1, minWidth: 80}}>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="items-per-page-selec"
              value={props.itemsPerPage}
              onChange={(e)=>handleSelect(e)}
              autoWidth
              sx={{
                '& .MuiSelect-outlined': {
                  paddingTop: '5px',
                  paddingBottom:'5px',
                  backgroundColor: STYLE_CONSTANTS.COLORS.white
                }
              }}
            >
              <MenuItem value={'10'}>10</MenuItem>
              <MenuItem value={'50'}>50</MenuItem>
              <MenuItem value={'100'}>100</MenuItem>
            </Select>
          </FormControl>
          <Typography variant={'subtitle2'} fontSize={'0.9rem'}> per page</Typography>
        </Box>

      </Box>

    </>
  );
};

PageCountSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

const Paginator= (props)=> {
  const handleChange = (e, value)=> {
    props.handleChange(value);
  };
  return (
    <Box  sx={{height: STYLE_CONSTANTS.HEIGHT.PAGINATOR(),
      position: 'static',
      display: 'flex',
      alignItems: 'center'
    }}>
      <Stack spacing={2}>
        <Pagination
          count={props.count}
          page={props.currentPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          sx={{
            '& .MuiButtonBase-root': {
              width: 41,
              heigh: 41,
              color: STYLE_CONSTANTS.COLORS.globalBlue,
              borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
              '&.Mui-selected, &:hover': {
                color: STYLE_CONSTANTS.COLORS.white,
                borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
                backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
                '&:hover': {
                  color: STYLE_CONSTANTS.COLORS.white,
                  borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
                  backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue
                }
              }
            }

          }}
        />
      </Stack>
    </Box>

  );
};

Paginator.propTypes = {
  handleChange: PropTypes.func.isRequired,
  count: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
};

Paginator.defaultProps = {
  count: undefined
};

/**
 *
 * @return {{PageController: JSX.Element,
 * itemsPerPage: number | undefined,
 * totalPageCount: number | undefined,
 * setTotalPageCount: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void},
 * currentPage: number | undefined,
 * setTotalItemsCount: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void},
 * ItemsController: JSX.Element}}
 */
const usePagination = () => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  const items = parseInt(query.get('items') || '100', 10);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalItemsCount, setTotalItemsCount] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(items);

  const handleItemsPerPageSelect = (items)=> {
    setItemsPerPage(items);
  };

  const excludeParams = [urlSearchParams.openTab];
  useEffect(()=> {
    const query = new URLSearchParams(location.search);

    const page = parseInt(query.get('page'), 10);
    setSearchParams(Utils.createQueryParam(location, {items: itemsPerPage ? itemsPerPage.toString() : '100', page: page || '1'}, excludeParams));
    //setSearchParams({items: itemsPerPage ? itemsPerPage.toString() : '10', page: page || '1',});
  }, [itemsPerPage]);

  useEffect(()=> {
    const query = new URLSearchParams(location.search);
    const items = parseInt(query.get('items'), 10);
    setSearchParams(Utils.createQueryParam(location, {items: items || '10', page: currentPage ? currentPage.toString() : '1'}, excludeParams));
    // setSearchParams({items: items || '10', page: currentPage ? currentPage.toString() : '1'});
  }, [currentPage]);

  const PageController = <Paginator currentPage={currentPage}
    count={totalPageCount}
    handleChange={setCurrentPage}
  />;

  const ItemsController = <PageCountSelector
    itemsPerPage={itemsPerPage}
    totalItems={totalItemsCount}
    currentPage={currentPage}
    handleChange={handleItemsPerPageSelect}/>;

  const state = {
    currentPage,
    itemsPerPage,
    totalItemsCount,
  };

  const actions = {
    setTotalPageCount: setTotalPageCount,
    setTotalItemsCount: setTotalItemsCount,
    setCurrentPage: setCurrentPage,
  };

  return {...state, ...actions, PageController, ItemsController};
};

export default usePagination;
