import React, {useEffect, useState, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import clsx from 'clsx';
import Utils from '../../utils/utils';
import ApiUtils from '../../utils/apiUtils';
import {useForm, FormProvider} from 'react-hook-form';
import {Box, Button, IconButton} from '@mui/material';
import {globalUseClasses} from '../../styles/globalClasses';
import NavBar from '../../pieces/navbar/navbar';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import * as allConstants from '../../constants/allConstants';
import {RecordHeader} from '../../pieces/recordHeader';
import {Text, TextWithIconWrapper} from '../../pieces/text';
import {ReactComponent as OpenIconSvg} from '../../static/images/open-sign.svg';
import {ReactComponent as LocationIconSvg} from '../../static/images/locationPin.svg';
import {ReactComponent as FolderIconSvg} from '../../static/images/folderIcon.svg';
import {ReactComponent as NotesIconSvg} from '../../static/images/noteIcon.svg';
import {ReactComponent as SearchIconSvg} from '../../static/images/searchIcon.svg';
import {ReactComponent as InfoIconSvg} from '../../static/images/infoIcon.svg';
import Spacer from '../../pieces/spacer';
import {Card} from '../../pieces/card';
import CustomFormBlock from '../../pieces/inputs/formBlock';
import {inputTypes} from '../../pieces/inputs/formInput';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import LadderIcon from '../../static/images/ladder.png';
import {CityNotesContent} from './cityNotesContent';
import PropTypes from 'prop-types';
import Loader from '../misc/loader';
import {NoDataStringWrapper} from '../../pieces/noDataStringWrapper';
import {toast} from 'react-toastify';
import {CityDocumentsByJobType} from './cityDocumentsByJobType';
import {CityDocumentsForm} from './cityDocumentsForm';
import {useTabs} from '../../hooks/useTabs';
import {Accordion, AccordionDetails, AccordionSummary} from '../../pieces/accordion/accordionTab';
import {HowToScheduleInspectionFrom} from './howToScheduleInspectionFrom';
import {FloatingMenu} from '../../pieces/floatingMenu';
import {timelineTypes, useTimeline} from '../../hooks/useTimeline';
import {urlSearchParams} from '../../baseUrls';


export const CityView = (props) => {
  const classes = {...globalUseClasses()};
  const navigate = useNavigate();
  const {CITY_FIELDS} = allConstants;

  const [cityData, setCityData] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [editMode, setEditMode] = useState(props.add);
  const [isAdding, setIsAdding] = useState(props.add);
  const [noCityFound, setNoCityFound] = useState(false);
  const waysToScheduleInspection = useRef();
  const [isAddingDocument, setIsAddingDocument] = useState(false);

  const timeline = useTimeline({
    recordId: cityData?.[CITY_FIELDS.ID.api_name],
    moduleName: timelineTypes.city,
  });

  useEffect(()=> {
    if(timeline.showTimeline) {
      setEditMode(false);
      setIsAddingDocument(false);
    }
  }, [timeline.showTimeline]);

  const documentTabValues = {
    documents: 'documents',
    sortedByJob: 'sortedByJob',
  };

  // useEffect(()=> {
  //   return ()=> {
  //     urlParams.delete(urlSearchParams.timeline);
  //     window.history.replaceState({}, '', `${location.pathname}?${urlParams.toString()}`);
  //   };
  // },[]);


  const options = {
    markets: useAutocompleteOptions({field: CITY_FIELDS.LOCATION.api_name}).options,
    cityOptions: useAutocompleteOptions({field: 'cityOptions'}).options,
  };

  const documentTabs = [
    {label: 'Documents', value: documentTabValues.documents},
    {label: 'Sorted By job', value: documentTabValues.sortedByJob},
  ];

  const documentTabsController = useTabs({
    tabs: documentTabs,
    color: STYLE_CONSTANTS.COLORS.globalGreen,
  });

  const route = useParams();
  const cityId = route?.id;

  const goBack = ()=> {
    const urlParams = new URLSearchParams(location.search);
    if(urlParams.has(urlSearchParams.timeline)) {
      urlParams.delete(urlSearchParams.timeline);
      window.history.replaceState({}, '', `${location.pathname}?${urlParams.toString()}`);
    }
    navigate(`${allConstants.PATH.CITIES}${location.search}`);
  };

  const validationSchema = Yup.object().shape({
    [CITY_FIELDS.NAME.api_name]: Yup.string().required('Required'),
    [CITY_FIELDS.LOCATION.api_name]: Yup.string().required('Required'),
  });

  const editCityForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    resolver: yupResolver(validationSchema),

  });

  if(process.env.NODE_ENV === 'development') {
    window.cityForm = editCityForm;
  }

  const handleResponse = (result, message)=> {
    const toastOptions = {
      toastId: 'cityEditSuccess',
      autoClose: 1000,
    };

    if(result.status === 'success') {
      const cityData = result?.data?.city;
      if(!cityData) {
        setNoCityFound(true);
      } else {
        setCityData(cityData);
      }
      setIsFetching(false);
      setEditMode(false);
      setIsAdding(false);
      toast.success(message ? message : 'City updated', toastOptions);
      if(isAdding) {
        navigate(`/cities/${cityData.id}`);
      }
    } else {
      setIsFetching(false);
      toast.error( `Error in updating city: ${result?.message}`, toastOptions);
    }

  };

  useEffect(async ()=> {
    if(isAdding) return;
    try{
      if(cityId) {
        setIsFetching(true);
        const cityResponse = await ApiUtils.makeApiCall('GET', `/cities/${cityId}`);

        if(cityResponse?.status === 'success') {
          const city = cityResponse?.data?.city;
          if(!city) {
            setNoCityFound(true);
          } else {
            setCityData(city);
          }
        } else {
          setNoCityFound(true);
        }
        setIsFetching(false);

      }
    } catch (e) {
      console.log('[Error] in getting city date', e);
    }
  }, []);

  useEffect(()=> {
    if(cityData) {
      editCityForm.reset(cityData);
    }
  }, [cityData]);

  const addCity = async (data)=> {
    const payload = {
      ...data
    };
    const response = await ApiUtils.makeApiCall('POST', 'cities/create', JSON.stringify(payload));
    handleResponse(response, 'City added');
  };

  const editCity = async (data)=> {
    const payload = {
      id: cityData[CITY_FIELDS.ID.api_name],
      ...data
    };

    const response = await ApiUtils.makeApiCall('POST', 'cities/edit', JSON.stringify(payload));
    handleResponse(response);
  };

  const onSubmit = async (data)=> {
    //get ways to schedule inspection data
    const currentWaysToScheduleInspectionData = cityData?.[CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name]??{};
    const waysToScheduleInspectionData = waysToScheduleInspection.current?.getValues();

    const ways = {};
    waysToScheduleInspectionData?.waysToScheduleInspection?.forEach((entry)=> {
      ways[entry[CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name]] = entry.note;
    });

    for (const [key] of Object.entries(currentWaysToScheduleInspectionData)) {
      if(!ways[key]) {
        ways[key] =null;
      }
    }



    const requestPayload = {
      ...data,
      [CITY_FIELDS.SCHEDULE_INSPECTION_VIA.api_name]: ways,
      [CITY_FIELDS.FT.api_name]: data[CITY_FIELDS.FT.api_name] && data[CITY_FIELDS.FT.api_name].length > 0 ? data[CITY_FIELDS.FT.api_name] : null,
    };


    if(isAdding) {
      await addCity(requestPayload);
    } else {
      await editCity(requestPayload);
    }
  };

  const onCancel = ()=> {
    if(isAdding) {
      goBack();
    } else {
      editCityForm.reset(cityData);
      setEditMode(false);
    }
  };

  const cityFormCommonProps = {
    fullWidth: true,
    maxWidth: '100%',
    isEditable: true,
    renderLabel: true,
    viewMode: !editMode,
    allowNone: true,
  };

  const getDefaultValue = (field, returnBoolean)=> {
    if(cityData?.[field]) {
      return cityData?.[field];
    }
    return returnBoolean ? false : '';
  };

  const saveWaysSubform = (subform)=> {
    waysToScheduleInspection.current = subform;
  };

  const cancelDocumentsEditing = ()=> {
    setIsAddingDocument(false);
  };

  const successDocumentsEditing = (newDocuments)=> {
    setCityData({...cityData, documents: newDocuments});
    setIsAddingDocument(false);
  };

  const getDocumentsView = ()=> {
    const currentTab = documentTabs[documentTabsController.activeIndex];
    if(editMode || currentTab.value === documentTabValues.documents) {
      return <CityDocumentsForm documents={cityData?.documents}
        editMode={isAddingDocument}
        onCancel={cancelDocumentsEditing}
        onSuccess={successDocumentsEditing}
        cityId={cityData[CITY_FIELDS.ID.api_name]}
        setCityData={setCityData}

      />;
    }
    return <CityDocumentsByJobType documents={cityData?.documents}/>;
  };

  useEffect(()=> {
    if(editMode) {
      documentTabsController.setActiveIndex(0);
      documentTabsController.disable();
    } else {
      documentTabsController.enable();
    }
  }, [editMode]);

  const headerHeight = '50px';

  const floatingMenuItems = [
    timeline.timelineButton(),
  ];

  return (
    <Box className={clsx(classes.page)}>
      <NavBar/>
      <FloatingMenu items={floatingMenuItems}/>
      {isFetching && <Loader timeout={50}/>}
      {!isFetching && noCityFound && <NoDataStringWrapper text={'No city data found'}/>}

      {(cityData || isAdding) && (
        <>
          <Box sx={{
            zIndex: '1000',
            position: 'fixed',
            height: headerHeight,
            width: '100%',
            textTransform: 'uppercase',
            backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
            borderBottom: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',

          }}>
            <Box sx={{
              paddingX: {xs: '8px', xl: '12px'},
              paddingY: '2px',
              display: 'grid',
              height: '100%',
              gridTemplateColumns: '200px 1fr 200px!important',
              backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
              '.MuiTypography-root': {
                color: STYLE_CONSTANTS.COLORS.white
              }
            }}>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton onClick={goBack} sx={{
                  height: '40px',
                  paddingX: '0px',
                  justifyContent: 'flex-start',
                  color: STYLE_CONSTANTS.COLORS.white,
                  '&:hover': {
                    transform: 'scale(1.1)'
                  }
                }}>
                  <KeyboardBackspaceIcon/>

                </IconButton>
              </Box>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}
                flexDirection={'column'}
                sx={{
                  '.MuiTypography-root': {
                    color: STYLE_CONSTANTS.COLORS.white
                  }
                }}>
                <RecordHeader text={cityData?.[CITY_FIELDS.NAME.api_name]}/>
                <Box display={'flex'} alignItems={'center'}>
                  {cityData?.[CITY_FIELDS.HOURS.api_name] && <Box sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: STYLE_CONSTANTS.COLORS.white,
                    '& svg': {
                      transform: 'translateY(-3px)'
                    }
                  }}>
                    <TextWithIconWrapper icon={<OpenIconSvg/>}>
                      <Text text={cityData?.[CITY_FIELDS.HOURS.api_name]} color={STYLE_CONSTANTS.COLORS.white} sizePx={12}/>
                    </TextWithIconWrapper>

                  </Box>}
                  {cityData?.[CITY_FIELDS.CLOSURE_CODE.api_name] && <>
                    {cityData?.[CITY_FIELDS.HOURS.api_name] && <Spacer x={2}/>}
                    <Box sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: STYLE_CONSTANTS.COLORS.white,
                      '& svg': {
                        transform: 'translateY(-3px)'
                      }
                    }}>
                      <Text text={`Closure Code: ${cityData?.[CITY_FIELDS.CLOSURE_CODE.api_name]}`} color={STYLE_CONSTANTS.COLORS.white} sizePx={12}/>
                    </Box>
                  </>}
                </Box>
              </Box>
              {!timeline.showTimeline && <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '& .MuiButton-root': {
                  backgroundColor: STYLE_CONSTANTS.COLORS.white,
                  color: STYLE_CONSTANTS.COLORS.globalBlue,
                }
              }}>
                {!editMode &&  <Button variant="contained"
                  color={'primary'}
                  sx={{...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                    '&.Mui-disabled': {
                      backgroundColor: STYLE_CONSTANTS.COLORS.lightGrayHover,
                      color: STYLE_CONSTANTS.COLORS.fontPale,
                    },
                  }}
                  onClick={()=>setEditMode(true)}
                  disabled={isAddingDocument}
                >Edit</Button>}
                {editMode && <>
                  <Button variant="contained"
                    color={'secondary'}
                    sx={{...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                      '&.MuiButton-containedSecondary': {
                        backgroundColor: STYLE_CONSTANTS.COLORS.globalRed,
                      },
                      '&:hover': {
                        color: STYLE_CONSTANTS.COLORS.white,
                      }
                    }}
                    onClick={onCancel}
                  >Cancel</Button>
                  <Spacer x={2}/>
                  <Button variant="contained"
                    color={'primary'}
                    sx={{...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                      '&.MuiButton-containedPrimary': {
                        backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen,
                      },
                      '&:hover': {
                        color: STYLE_CONSTANTS.COLORS.white,
                      }
                    }}
                    onClick={editCityForm.handleSubmit(onSubmit)}
                  >Save</Button>
                </>}
              </Box>}
            </Box>

          </Box>


          <Box sx={{
            width: '100%',
            paddingTop: headerHeight,
            paddingX: {xs: '16px', md: '24px', xl: '32px'},
            display: 'flex',
            justifyContent: timeline.showTimeline ? 'start':'center',
            paddingBottom: theme=>theme.spacing(2)
          }}>
            {timeline.showTimeline && timeline.timeline()}
            {!timeline.showTimeline && <>
              <Box sx={{
                display:'grid',
                gridTemplateColumns: '1fr',
              }}
              >
                <FormProvider {...editCityForm}>
                  <form onSubmit={editCityForm.handleSubmit(onSubmit)} method={'POST'}>
                    <Box sx={{maxWidth: STYLE_CONSTANTS.DEFAULT.MAX_WIDTH_JOB_VIEW,
                      minWidth: {xs: '100%', md: '60%'},
                      width: '100%'}}>
                      <Spacer y={2}/>

                      {/*Overview*/}
                      <Box
                        sx={STYLE_CONSTANTS.STYLES.SHADOW_BOX}
                      >
                        <Card header={
                          <TextWithIconWrapper icon={<LocationIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                            <Text text={'Overview'} weight={'bold'}/>
                          </TextWithIconWrapper>
                        }>
                          <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gridGap: '16px',
                          }}>
                            <Box>
                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.NAME}
                              />
                              <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                gridGap: '16px',
                              }}>
                                <CustomFormBlock
                                  {...cityFormCommonProps}
                                  field={{...CITY_FIELDS.LOCATION, label: 'Market'}}
                                  inputType={inputTypes.singleSelect}
                                  options={Utils.getOptions(options.markets)}
                                  defaultValue={getDefaultValue(CITY_FIELDS.LOCATION.api_name)}
                                  allowNone={false}
                                />
                                <CustomFormBlock
                                  {...cityFormCommonProps}
                                  field={CITY_FIELDS.COUNTY}
                                />
                              </Box>
                              <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridGap: '16px',
                              }}>
                                <CustomFormBlock
                                  {...cityFormCommonProps}
                                  field={CITY_FIELDS.PHONE_NUMBER}
                                  inputType={inputTypes.phone}
                                  defaultValue={getDefaultValue(CITY_FIELDS.PHONE_NUMBER.api_name)}
                                />
                                <CustomFormBlock
                                  {...cityFormCommonProps}
                                  field={CITY_FIELDS.ALT_PHONE_NUMBER}
                                  inputType={inputTypes.phone}
                                  defaultValue={getDefaultValue(CITY_FIELDS.ALT_PHONE_NUMBER.api_name)}
                                />
                              </Box>
                            </Box>
                            <Box>
                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.HOURS}
                              />
                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.ADDRESS}
                                inputType={inputTypes.address}
                                renderLabel={false}
                                defaultValue={getDefaultValue(CITY_FIELDS.ADDRESS.api_name)}
                                maxWidth={'100%'}

                              />
                            </Box>
                          </Box>
                        </Card>
                      </Box>

                      <Spacer y={2}/>

                      {/*Additional information*/}
                      <Box display={'flex'} width={'100%'}>
                        <Box
                          sx={
                            {...STYLE_CONSTANTS.STYLES.SHADOW_BOX,
                              width: '100%'
                            }}
                        >
                          <Card header={
                            <TextWithIconWrapper icon={<InfoIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                              <Text text={'Additional information'} weight={'bold'}/>
                            </TextWithIconWrapper>
                          }>
                            <Box sx={{
                              display: 'grid',
                              gridTemplateColumns: {xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr', lg:'100px 150px 150px 150px 1fr'},
                              gridGap: '16px',
                            }}>
                              {/*<Box>*/}
                              {/*  <Box className={classes.two_col_container}>*/}
                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.FT}
                              />
                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.SET_BACKS}
                                inputType={inputTypes.singleSelect}
                                options={Utils.getOptions(options.cityOptions?.setBacks)}
                                defaultValue={getDefaultValue(CITY_FIELDS.SET_BACKS.api_name)}

                              />


                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.ON_ROOF}
                                inputType={inputTypes.singleSelect}
                                options={Utils.getOptions(options.cityOptions?.onRoof)}
                                defaultValue={getDefaultValue(CITY_FIELDS.ON_ROOF.api_name)}
                              />
                              {/*<Box className={classes.two_col_container}>*/}
                              {/*<CustomFormBlock*/}
                              {/*  {...cityFormCommonProps}*/}
                              {/*  field={CITY_FIELDS.DOCUMENTS}*/}
                              {/*  inputType={inputTypes.singleSelect}*/}
                              {/*  options={Utils.getOptions(allConstants.ANSWER_OPTIONS)}*/}
                              {/*  defaultValue={getDefaultValue(CITY_FIELDS.DOCUMENTS.api_name)}*/}
                              {/*/>*/}
                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.CLOSURE_CODE}
                                inputType={inputTypes.singleSelect}
                                options={Utils.getOptions(options.cityOptions?.closureCodes)}
                                defaultValue={getDefaultValue(CITY_FIELDS.CLOSURE_CODE.api_name)}
                              />

                              {/*</Box>*/}
                              <CustomFormBlock
                                {...cityFormCommonProps}
                                field={CITY_FIELDS.CITY_TYPE}
                                inputType={inputTypes.singleSelect}
                                options={Utils.getOptions(options.cityOptions?.types)}
                                defaultValue={getDefaultValue(CITY_FIELDS.CITY_TYPE.api_name)}
                              />
                              {/*</Box>*/}

                            </Box>
                          </Card>
                        </Box>


                      </Box>
                      <Spacer y={2}/>
                      {/*Inspection information*/}
                      <Box display={'flex'} width={'100%'}>
                        <Box
                          sx={
                            {...STYLE_CONSTANTS.STYLES.SHADOW_BOX,
                              width: '100%'
                            }}
                        >
                          <Card header={
                            <TextWithIconWrapper icon={<SearchIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                              <Text text={'City Inspection information'} weight={'bold'}/>
                            </TextWithIconWrapper>
                          }>
                            <Box sx={{
                              display: 'grid',
                              gridTemplateColumns: '250px 1fr',
                              gridGap: '16px',
                            }}>
                              <CustomFormBlock {...cityFormCommonProps}
                                inputType={inputTypes.singleSelect}
                                options={Utils.getOptions(options.cityOptions?.cityInspectionRequirements)}
                                field={allConstants.INSPECTION_FIELDS.REQUIREMENTS}
                                defaultValue={getDefaultValue(allConstants.INSPECTION_FIELDS.REQUIREMENTS.api_name)}
                              />
                              <Box sx={{
                                display: 'flex',
                              }}>
                                <Box display={'flex'} sx={{
                                  '& img': {
                                    height: '100%',
                                    transform: {xs: 'scale(0.5) translateX(-46px) translateY(-14px)', xl: 'scale(0.7) translateX(-24px) translateY(-10px)'},
                                  }
                                }}>
                                  <CustomFormBlock {...cityFormCommonProps}
                                    inputType={inputTypes.checkbox}
                                    renderLabel={false}
                                    field={CITY_FIELDS.LADDER}
                                    defaultValue={getDefaultValue(CITY_FIELDS.LADDER.api_name, true)}
                                  />
                                  <img src={LadderIcon} alt={'need ladder'}/>
                                </Box>
                              </Box>

                            </Box>
                            <HowToScheduleInspectionFrom saveSubform={saveWaysSubform}
                              editable={editMode} cityData={cityData}/>
                          </Card>
                        </Box>
                      </Box>
                      <Spacer y={2}/>
                    </Box>
                  </form>
                </FormProvider>

                <Box sx={{maxWidth: STYLE_CONSTANTS.DEFAULT.MAX_WIDTH_JOB_VIEW,
                  minWidth: {xs: '100%', md: '60%'},
                  width: '100%'}}>
                  {isAdding && <Text text={'You\'ll be able to add notes and documents after city created'} weight={'bold'}/>}
                  {!isAdding && <>
                    <Accordion defaultExpanded={true} sx={{
                      '& .MuiAccordionDetails-root': {
                        paddingTop: 0
                      }
                    }}>
                      <AccordionSummary
                        aria-controls={'notes-header'}
                        id={'notes-content'}

                      >
                        <TextWithIconWrapper icon={<FolderIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                          <Text text={'City Documents'} weight={'bold'}/>
                          <Spacer x={4}/>
                          {documentTabsController.Stepper}
                        </TextWithIconWrapper>
                        <Button variant={'contained'} color={isAddingDocument ? 'secondary' : 'primary' }
                          onClick={(e)=> {
                            e.stopPropagation();
                            e.preventDefault();
                            documentTabsController.setActiveIndex(0);
                            setIsAddingDocument(!isAddingDocument);
                          }}
                          disabled={editMode}
                          sx={{
                            ...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                            textTransform: 'uppercase'
                          }}
                        >{isAddingDocument ?  'Cancel' : 'Edit Documents'}</Button>

                      </AccordionSummary>
                      <AccordionDetails>
                        {getDocumentsView()}
                      </AccordionDetails>
                    </Accordion>

                    <Spacer y={2}/>
                    <Box
                      sx={STYLE_CONSTANTS.STYLES.SHADOW_BOX}
                    >
                      <Card header={
                        <TextWithIconWrapper icon={<NotesIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                          <Text text={'Notes'} weight={'bold'}/>
                        </TextWithIconWrapper>
                      }>
                        <CityNotesContent notes={cityData?.notes}
                          cityId={cityData[CITY_FIELDS.ID.api_name]}
                          successCallback={(updatedNotes)=> {
                            setCityData({...cityData, notes: updatedNotes});
                          }}
                        />
                      </Card>
                    </Box>
                  </>}
                </Box>
              </Box>
            </>}
          </Box>
        </>)}

    </Box>
  );
};

CityView.propTypes = {
  add: PropTypes.bool,
};

CityView.defaultProps = {
  add: false,
};
