import React, {useEffect, useState} from 'react';
import * as allConstants from '../../constants/allConstants';
import Utils from '../../utils/utils';
import {urlSearchParams} from '../../baseUrls';
import {RecordController} from '../../hooks/recordController';
import {RecordBodyWrapper} from '../wrappers/recordBodyWrapper';
import {RecordWrapper} from '../wrappers/recordWrapper';
import {RecordHeader} from '../../pieces/recordHeader';
import {Box, Button, Typography} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {COLORS} from '../../styles/styleConstants';
import EditFormButton from '../../pieces/editFormButton';
import {UserInfoPanel} from './panels/userInfo';
import {userPageRules} from './usersPageAccessControl';
import {UserNotificationsPanel} from './panels/userNotifications';
import ApiUtils from '../../utils/apiUtils';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import UserModal from '../../pieces/modals/userModal';
import SetCompanyAdmin, {canBeAdmin} from './setCompanyAdmin';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import Spacer from '../../pieces/spacer';
import {roleTranslator} from '../../utils/translator';
import PermissionGate from '../../permissions/permissionGate';
import {useGridViewState} from '../../hooks/useGridViewState';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Text} from '../../pieces/text';
import {GotItPopup} from '../../pieces/modals/gotItPopup';


export const UserView = () => {

  const {userRole, companyId} = useGridViewState();
  const currentCompany = useSelector((state=> state.company));
  const userId = Utils.getUrlParameter(urlSearchParams.userId);

  const isInvite = !userId;

  const [viewMode, setViewMode] = useState(!isInvite);
  const [userData, setUserData] = useState({});
  const {USER_FIELDS} = allConstants;
  const [submitting, setSubmitting] = useState(false);
  const [changeAdmin, setChangeAdmin] = useState(false);
  const [switchTabsWarning, setSwitchTabsWarning] = useState(false);
  const navigate = useNavigate();


  useEffect(async ()=> {
    if(!userId) return;
    const usedData = await ApiUtils.makeApiCall('GET', `users/${userId}`);
    setUserData(usedData?.data.user);
  }, []);

  useEffect(async ()=> {
    if(viewMode && userId) {
      const usedData = await ApiUtils.makeApiCall('GET', `users/${userId}`);
      setUserData(usedData?.data.user);
      return;
    }
    if(viewMode && isInvite) {
      setViewMode(true);
      navigate(getBackPath());
    }
  }, [viewMode]);

  useEffect(()=> {
    if(isInvite && userData?.id){
      navigate(`${allConstants.PATH.USER}${location.search}&${urlSearchParams.userId}=${userData.id}`);
    }
  }, [userData]);

  const getValue = (field)=> {
    return userData?.[field.api_name]||'';
  };

  /**
   * Switch to edit mode
   */
  const switchToEdit = ()=> {
    setViewMode(false);
  };

  const onCancel = async (e)=> {
    e.preventDefault();
    setViewMode(true);
    setViewMode(true);
  };

  const isCompanyAdmin = ()=> {
    return getValue(USER_FIELDS.ROLE) === allConstants.ROLES.COMPANY_ADMIN;
  };

  const tabNames = {
    userInfo: 'User Info',
    userNotifications: 'Notifications',
    userPermissions: 'Permissions',
  };

  const panelProps = {
    viewMode: viewMode,
    setViewMode: setViewMode,
    userData: userData,
    setUserData: setUserData,
    setSubmitting: setSubmitting,
    currentCompany: currentCompany,
    isInvite: isInvite,
  };

  const getTabs = ()=> {
    return {
      userInfo: {
        name: tabNames.userInfo,
        api_name: 'user_info',
        order: 0,
        panel: <UserInfoPanel {...panelProps}/>,
        fieldsToValidate: [],
      },
      userNotifications: {
        name: tabNames.userNotifications,
        api_name: 'user_notifications',
        order: 1,
        panel: <UserNotificationsPanel {...panelProps}/>,
        fieldsToValidate: [],
      }
    };
  };

  const getCurrentTabs = ()=> {
    const tabs = getTabs();
    const currentTabs = {};
    for (const [key, value] of Object.entries(tabs)) {
      currentTabs[key] = value;
    }
    return currentTabs;
  };

  const headerHeight = '75px';

  const getBackPath = ()=> {
    const items = Utils.getUrlParameter('items');
    const page = Utils.getUrlParameter('page');
    let path = `${allConstants.PATH.USERS}?items=${items||100}&page=${page||1}`;
    return path;
  };

  const getWarningMessage = ()=> {
    if(isInvite) {
      return (
        <>
          <Text text={'Please invite user first'} sizePx={14}/>
        </>
      );
    }
    return (
      <>
        <Text text={'You have unsaved changes'} sizePx={14}/>
        <Text text={'Please save or cancel editing first'} sizePx={14}/>
      </>
    );
  };

  return(
    <>
      {changeAdmin && <UserModal
        open={true}
        handleClose={()=> setChangeAdmin(false)}
        header={getValue(USER_FIELDS.COMPANY_NAME)}

      >
        <SetCompanyAdmin selectedUser={userData} closeModal={()=> setChangeAdmin(false)}/>
      </UserModal>}
      {switchTabsWarning &&  <GotItPopup handleClose={()=>setSwitchTabsWarning(false)}>
        {getWarningMessage()}
      </GotItPopup>}

      <RecordController
        recordId={userId || null}
        module={'users'}
        responseTarget={'user'}
        saveRecord={(data)=>setUserData(data)}
        viewMode={viewMode}
      >
      </RecordController>
      <RecordWrapper
        hideNavbar={false}
        headerHeight={headerHeight}

        header={
          <Box display={'flex'} flexDirection={'row'} id={'headercontainer'} justifyContent={'center'} sx={{
            width: '100%'

          }}>
            <Box display={'flex'} flexDirection={'row'} width={'100%'} height={'75px'}>
              <Box display={'flex'}
                width={'calc(100% - 110px)'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={0.5}
              >
                <RecordHeader
                  text={isInvite ? 'Invite user' :`${getValue(USER_FIELDS.FIRST_NAME)} ${getValue(USER_FIELDS.LAST_NAME)}`}
                  headerHeight={headerHeight}
                >
                  <StatusRenderer value={getValue(USER_FIELDS.STATUS)}/>
                </RecordHeader>
                {isInvite && <Typography component={'span'} fontWeight={'bold'}>
                  {currentCompany?.[allConstants.COMPANY_FIELDS.NAME.api_name]}
                </Typography>}
                {getValue(USER_FIELDS.EMAIL) &&
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <EmailOutlinedIcon sx={{color: COLORS.globalBlue, marginRight: '8px'}}/>
                  <Typography component={'span'} sx={{
                    fontStyle: 'italic'
                  }}>{getValue(USER_FIELDS.EMAIL)}</Typography>
                  <Spacer x={2}/>
                  <Typography component={'span'} sx={{
                    fontWeight: 'bold',
                    color: COLORS.globalBlue
                  }}>Role:</Typography>
                  <Spacer x={1}/>
                  <Typography component={'span'}>
                    {roleTranslator.translate(getValue(USER_FIELDS.ROLE))}
                  </Typography>
                </Box>}
                {!isInvite && <Typography component={'span'} fontWeight={'bold'}>
                  {getValue(USER_FIELDS.COMPANY_NAME)}
                </Typography>}
              </Box>
              <Box display={'flex'} sx={{width:'350px', height: 'fit-content'}} alignItems={'center'} justifyContent={'flex-end'}>
                {!isCompanyAdmin() && !isInvite && canBeAdmin(getValue(USER_FIELDS.STATUS, USER_FIELDS.ROLE)) &&
                <PermissionGate
                  modulePermissions={userPageRules.changeCompanyAdmin}
                  scope={allConstants.SCOPES.canView}
                  role={userRole}
                  companyId={companyId}
                >
                  <Box>
                    <Button variant={viewMode ? 'contained' : 'outlined'}
                      color={'primary'}
                      sx={{...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                        '&.MuiButton-root': {
                          textTransform: 'uppercase'
                        },
                        '&.MuiButton-containedPrimary': {
                          backgroundColor: STYLE_CONSTANTS.COLORS.globalGreen,
                        },
                        '&:hover': {
                          color: STYLE_CONSTANTS.COLORS.white,
                        },
                      }}
                      onClick={()=> setChangeAdmin(true)}
                      disabled={!viewMode}
                    >Set Company Admin</Button>
                  </Box>
                </PermissionGate>
                }

                <EditFormButton onClick={viewMode ? switchToEdit : onCancel}
                  onCancel={onCancel}
                  edit={viewMode}

                />

              </Box>
            </Box>
          </Box>
        }
      >
        <RecordBodyWrapper
          tabs={getCurrentTabs()}
          headerHeight={headerHeight}
          goBack={{
            show: true,
            path: getBackPath()
          }}
          showLoader={submitting}
          preventSwitchTabsOnEdit={!viewMode}
          setSwitchTabsWarning={setSwitchTabsWarning}
        />

      </RecordWrapper>

    </>

  );
};
