import PropTypes from 'prop-types';
import {ROLES, COMPANY_STATUSES, IPERMIT_ROLES} from '../constants/allConstants';
import ApiUtils from '../utils/apiUtils';
/**
 *
 * @param {Object} modulePermissions
 * @param {string} scope
 * @param {boolean} blockForDeactivatedCompany
 * @param {string} role
 * @param children
 * @param {boolean} hideWhenImpersonating
 * @param {string} companyId
 * @param {string} companyStatus
 * @return {null|boolean|*}
 * @constructor
 */

export default function PermissionGate({modulePermissions={}, element, scope, blockForDeactivatedCompany, role, children, companyId, companyStatus}) {
  if(ApiUtils.isMockMode()) {
    if(children) return children;
    return true;
  }
  if(!scope || !role || !modulePermissions || Object.keys(modulePermissions).length === 0) return false;
  const permissions = modulePermissions[scope];

  let permissionGranted;
  if(element) {
    console.log('module permission',element, modulePermissions, role);
  }

  const hideWhenImpersonating = modulePermissions.hideWhenImpersonating && modulePermissions.hideWhenImpersonating.includes(scope);
  const hideFromIpermit = modulePermissions.hideFromIpermit && modulePermissions.hideFromIpermit.includes(scope);
  if(role === ROLES.SYSTEM_ADMIN) {
    permissionGranted = modulePermissions?.hideFromSystemAdmin !== true;
  } else if(blockForDeactivatedCompany && companyStatus === COMPANY_STATUSES.NOT_ACTIVE.value) {
    permissionGranted = false;
  }

  else {
    permissionGranted = permissions.includes(role);
    if(Object.values(IPERMIT_ROLES).includes(role)) {

      if(hideWhenImpersonating) {
        permissionGranted = !companyId && permissionGranted;
      }
      if(hideFromIpermit) {
        permissionGranted = companyId && permissionGranted;
      }
    }

  }

  if (!permissionGranted) {
    if(children) {
      return null;
    }
    return false;
  }

  if(children) return children;
  return true;
}
PermissionGate.propTypes = {
  modulePermissions: PropTypes.instanceOf(Object).isRequired,
  scope: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  children: PropTypes.element
};
PermissionGate.defaultProps = {
  children: null,
  companyId: null,
};
