/* eslint  no-unused-vars: 0 */
import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import actions from '../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import base_url, {urlSearchParams} from '../../../baseUrls';
import * as allConstants from '../../../constants/allConstants';
import StatusRenderer from '../../../pieces/grids/statusRenderer';
import InspectionRequirementsRenderer from '../../../pieces/grids/inspectionRequirementsRenderer';
import {getJobColumns, jobApiTypes} from '../helpers/jobGridOptions';
import MyPaginatedGrid from '../../../pieces/grids/paginatedGridWrapper';
import PermissionGate from '../../../permissions/permissionGate';
import {generalRules} from '../../../generalAccessControl';
import {MaximizableCellRenderer} from '../../../pieces/grids/MaximizableCellRenderer';
import ApiUtils from '../../../utils/apiUtils';
import axios from 'axios';
import {printJobDetails} from '../../../utils/print';
import {useNavigate} from 'react-router-dom';
import {companyAndUserUtils} from '../../../utils/companyAndUserUtils';
import Utils from '../../../utils/utils';
import {isContractor, isImpersonating} from '../../../permissions/utils';
import {getInspectionColumns} from '../ipermitJobPages/listViews/inspections/inspectionsGridOptions';
import {jobRules} from '../jobsAccessControl';
import {orderInspection, jobHasInspection, canDoInspection} from '../helpers/requestCityInspection';
import {useAutocompleteOptions} from '../../../hooks/useAutompleteOptions';
const _ = require('lodash');


const AllJobsGrid = (props) => {
  const navigate = useNavigate();
  const fetchJob = async (jobId) => {
    let url = `${base_url.api}/jobs/${jobId}`;
    const authToken = await ApiUtils.getAccessToken();
    const jobResponse = await axios.get(url, {headers: {Authorization: authToken}});
    return jobResponse;
  };

  const jobStatusOptions = useAutocompleteOptions({field: 'jobStatuses'}).options;

  const gridType = props.gridType;
  const dispatch = useDispatch();
  const viewController = props.viewController;
  const [components] = useState({
    'statusRenderer': StatusRenderer,
    'MaximizableCellRenderer': MaximizableCellRenderer,
    'InspectionRequirementsRenderer': InspectionRequirementsRenderer
  });
  const company = useSelector(state => state.company);
  const companyAllowedTests = company?.allowedTests || [];
  const companyAllowedPermits = company?.allowedPermits || [];

  const handleDisplayJobDetails = (params) => {
    if(!isContractor(viewController.userRole)) {
      //ipermit users
      if(isImpersonating(viewController.userRole, viewController.companyId) && params?.node?.data?.[allConstants.JOB_FIELDS.IS_DRAFT.api_name]) {
        openDraft(params?.node?.data.id);
      } else {
        props.viewJobHandler(params?.node?.data.id, true);
      }

    } else {
      //contractor users
      if(params?.node?.data?.[allConstants.JOB_FIELDS.IS_DRAFT.api_name]) {
        openDraft(params?.node?.data.id);
      } else {
        props.viewJobHandler(params?.node?.data.id, true);
      }
    }
  };

  const columnDefs = useMemo(()=> {
    const columns = getJobColumns(handleDisplayJobDetails, 'MaximizableCellRenderer', {
      ...jobStatusOptions
    });
    const inspectionColumns = getInspectionColumns(handleDisplayJobDetails, 'MaximizableCellRenderer');

    const head = [
      columns.id,
      columns.company,
      columns.jobNumber,
    ];
    switch (gridType) {
    case (jobApiTypes.cf1r):{
      return [...head, ...[
        columns.jobType,
        columns.neededByDate,
        // columns.zone,
        columns.companyName,
        columns.hoName,
        columns.assignedCity,
        columns.permitStatus,
        columns.permitStatusChangeDate,
        columns.createdTime
      ]];
    }
    default: {
      head.push(columns.hoName);
      if (process.env.NODE_ENV === 'development' || PermissionGate({
        modulePermissions: generalRules.forceAddToCRM, scope: allConstants.SCOPES.actionAllowed,
        role: viewController.userRole
      })) {
        head.push(columns.notSyncedToCRM);
      }
      if (viewController.userRole === allConstants.ROLES.IPERMIT_USER ||
        viewController.userRole === allConstants.ROLES.IPERMIT_ADMIN) {
        head.push(columns.companyName);
      }

      if (gridType === jobApiTypes.all && (!companyAllowedTests || !Array.isArray(companyAllowedTests) || companyAllowedTests.includes(allConstants.TEST_TYPES.HERS.value))) {
        if (!_.isEqual(companyAllowedPermits, [allConstants.JOB_TYPES.TESTING_ONLY.value])) {
          head.push(columns.permitStatus);
          head.push(columns.assignedCity);
        }
        return [...head, ...[
          columns.hersStatus,
          columns.hersScheduledDate,
          columns.createdTime,
          columns.orderedBy,
        ]];
      } else if (gridType === jobApiTypes.all) {
        if (!_.isEqual(companyAllowedPermits, [allConstants.JOB_TYPES.TESTING_ONLY.value])) {
          head.push(columns.permitStatus);
          head.push(columns.assignedCity);
        }
        return [...head, ...[
          columns.createdTime,
          columns.orderedBy,
        ]];
      }

      if (gridType === jobApiTypes.hers) {
        return [...head, ...[
          columns.hersStatus,
          columns.assignedCity,
          columns.hersScheduledDate,
          columns.hoPhone,
          columns.createdTime,
          columns.orderedBy,
        ]];
      }

      if (gridType === jobApiTypes.euc) {
        return [...head, ...[
          columns.hersStatus,
          columns.assignedCity,
          columns.hersScheduledDate,
          columns.hoPhone,
          columns.createdTime,
          columns.orderedBy,
        ]];
      }

      if (gridType === jobApiTypes.inspections) {
        return ([columns.id,
          inspectionColumns.jobNumber,
          columns.company,
          inspectionColumns.companyName,
          inspectionColumns.jobType,
          inspectionColumns.assignedCity,
          inspectionColumns.requirements,
          columns.permitStatus,
          columns.hersStatus,
          columns.mailingStatus,
          inspectionColumns.inspectionStatus,
          inspectionColumns.callsCount,
          inspectionColumns.inspectionScheduledDate,
          inspectionColumns.inspectionResult
        ]);
      }

      if (companyAllowedTests.includes(allConstants.TEST_TYPES.HERS.value)) {
        return [...head, ...[
          columns.permitStatus,
          columns.assignedCity,
          columns.hersStatus,
          columns.createdTime,
          columns.orderedBy,
        ]];
      } else {
        return [...head, ...[
          columns.permitStatus,
          columns.assignedCity,
          columns.createdTime,
          columns.orderedBy,
        ]];
      }
    }}


  }, [gridType, jobStatusOptions]);

  const isGeneralGrid = ()=> {
    const generalGridTypes = [
      jobApiTypes.all,
      jobApiTypes.permits,
      jobApiTypes.hers,
      jobApiTypes.euc,
    ];
    return generalGridTypes.includes(gridType);
  };

  const getFetchParams = (gridType)=> {
    const baseJobsUrl = `${base_url.api}jobs`;
    const baseIpermitJobsUrl = `${base_url.api}ipermit/jobs`;
    let url = baseJobsUrl;
    let responseKey = 'jobs';
    if(gridType ===jobApiTypes.permits){
      url = `${baseJobsUrl}/get-permit-jobs`;
    } else if(gridType ===jobApiTypes.hers){
      url = `${baseJobsUrl}/get-hers-jobs`;
    } else if(gridType ===jobApiTypes.euc){
      url = `${baseJobsUrl}/get-euc-jobs`;
    } else if(gridType === jobApiTypes.drafts) {
      url = `${baseJobsUrl}/get-drafts`;
    } else if(gridType === jobApiTypes.inspections) {
      const searchParams = new URLSearchParams(location.search);
      const isFailedOnly = searchParams.get(urlSearchParams.failedInspections);
      if(isFailedOnly) {
        url = `${baseJobsUrl}/get-failed-inspections`;
      } else {
        url = `${baseJobsUrl}/get-inspection-jobs`;
      }
      responseKey = 'inspections';
    } else if(gridType === jobApiTypes.cf1r) {
      url = `${baseIpermitJobsUrl}/cf1r`;
    }

    return {url: url, respKey: responseKey};
  };

  const impersonate = async (companyId)=> {
    Object.values(jobApiTypes).forEach((gridType)=> [
      localStorage.removeItem(`jobs-${gridType}`)
    ]);
    const result = await dispatch(actions.impersonate(companyId));
    if(result?.payload?.company?.[allConstants.COMPANY_FIELDS.ID.api_name]){
      navigate(`${allConstants.PATH.JOBS}?${urlSearchParams.view}=${jobApiTypes.all}`);
    }

  };

  const duplicateJob = (jobId)=> {
    navigate(`${allConstants.PATH.CREATE_JOB}?${urlSearchParams.duplicateOfId}=${jobId}`);

  };

  const openDraft = (jobId)=> {
    navigate(`${allConstants.PATH.CREATE_JOB}?${urlSearchParams.draftId}=${jobId}`);
  };


  const printJob = (jobId)=> {
    //  props.parentFetching(true);
    fetchJob(jobId)
      .then((data) => {
        console.log('got job', data.data.data.job);
        printJobDetails(data.data.data.job);
      })
      .catch((error) => {
        // props.parentFetching(false);
        console.log(error);
      });
  };

  const forceAddToCRM = async (jobId, jobNumber)=> {
    try{
      const authToken = await ApiUtils.getAccessToken();
      let url = `${base_url.api}jobs/${jobId}`;
      const jobResponse = await axios.get(url, {headers: {Authorization: authToken}});
      const job = jobResponse?.data?.data?.job;
      if(job && job?.[allConstants.JOB_FIELDS.ZOHO_ID.api_name]) {
        Utils.handleToastMessage({status: 'success'}, `${jobNumber} Already exists on CRM`, undefined, 'crm_force_add_success' );
        return;
      }
      const response = await fetch(

        `${base_url.api}jobs/sync-with-zoho`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify({
            jobsIds: [jobId]
          }),
        }
      );
      let data = await response.json();
      Utils.handleToastMessage(data, `${jobNumber} added to CRM`, undefined, 'crm_force_add_success' );
    } catch(e){
      Utils.errorToastMessage(`Error on force adding to CRM::: ${JSON.stringify(e)}`, 'crm_force_add_error');

    }
    ///sync-with-zoho

  };

  const setCF1RPending = async (job)=> {
    try{
      const isPending = isCf1rPending(job);
      const payload = {
        jobId: job[allConstants.JOB_FIELDS.ID.api_name],
        isCf1rPending: !isPending,
      };
      const message = isPending ? 'CF1R completed' : 'CF1R set to PENDING';
      const response = await ApiUtils.makeApiCall('POST', 'ipermit/jobs/cf1r/update-pending-state', JSON.stringify(payload));
      Utils.handleToastMessage(response, message,  undefined, 'cf1r_pending_success');
    } catch (e) {
      console.log('error on changing CF1R status', e);
    }

  };

  const jobNeedsCF1R = (job)=> {
    if(!job) return false;
    const jobType = job[allConstants.JOB_FIELDS.JOB_TYPE.api_name];
    if(jobType !== allConstants.JOB_TYPES.HVAC_RES.value && jobType !== allConstants.JOB_TYPES.TESTING_ONLY.value) return false;
    const propertyType = job[allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name];
    return propertyType !== allConstants.PROPERTY_TYPES.COMMERCIAL.value;
  };

  const isCf1rPending = (job)=> {
    if(!job) return false;
    return job[allConstants.PERMIT_MODULE_FIELDS.CF1R_IS_PENDING.api_name];
  };

  const getContextMenuItems = (params)=> {

    let standard = [
      {
        name:  'Show Job Details',
        cssClasses: ['blueFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {handleDisplayJobDetails(params);},
      },

      {
        name: 'Print job',

        action: ()=> printJob(params?.node?.data.id)
      },
      {
        name: 'Duplicate',
        disabled: !companyAndUserUtils.canAddNewEntityToCompany(company) ||
          viewController.selectedMultipleRows() ||
          params?.node?.data?.isDraft||
          (!isContractor(viewController.userRole) && !isImpersonating(viewController.userRole, viewController.companyId)),
        action: ()=> duplicateJob(params?.node?.data.id)
      },

      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];
    if(params?.node?.data?.[allConstants.JOB_FIELDS.IS_DRAFT.api_name]) {
      const deleteCount = isImpersonating(viewController.userRole, viewController.companyId) ? 1 : 0;
      standard.splice(0, deleteCount, {
        name: 'Edit Draft & Submit Job',
        cssClasses: ['redFont', 'bold'],
        disabled: viewController.selectedMultipleRows() ||
            (!isContractor(viewController.userRole) && !isImpersonating(viewController.userRole, viewController.companyId)),
        action: () => {
          openDraft(params?.node?.data.id);
        },
      });
    }

    const separator=[
      'separator'
    ];
    const ipermitOptions = [
      {
        name: 'Impersonate',
        disabled: viewController.selectedMultipleRows(),
        action: () => {
          impersonate(params?.node?.data.company.id);
        },
        cssClasses: ['redFont', 'bold'],
      }
    ];
    if(PermissionGate({
      modulePermissions: jobRules.requestInspection,
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole, companyId: viewController.companyId}) &&
      (isGeneralGrid(props.gridType) || props.gridType === jobApiTypes.inspections)
    ) {
      ipermitOptions.push({
        name: 'Order Inspection',
        disabled: viewController.selectedMultipleRows() || jobHasInspection(params?.node?.data) || !canDoInspection(params?.node?.data),
        action: () => orderInspection(params?.node?.data.id),
      });
    }
    if(PermissionGate({
      modulePermissions: jobRules.setCf1rPending,
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole, companyId: viewController.companyId}) &&
      (isGeneralGrid(props.gridType) || props.gridType === jobApiTypes.cf1r)) {
      ipermitOptions.push({
        name: isCf1rPending(params?.node?.data) ? 'CF1R Completed' : 'Set CF1R Needed',
        disabled: viewController.selectedMultipleRows() || !jobNeedsCF1R(params?.node?.data),
        action: () => setCF1RPending(params?.node?.data)
      });
    }

    const dangerous = [
      {
        name:  'Job JSON',
        action: () => {props.devViewJobHandler(params?.node?.data.id, true);},
      },
      {
        name: 'Force Add to CRM',
        disabled: viewController.selectedMultipleRows() || params?.node?.data.isDraft,
        action: ()=>{forceAddToCRM(params?.node?.data.id, params?.node?.data[allConstants.JOB_FIELDS.JOB_NUMBER.api_name]).then(r => r);}
      },

      {
        name: 'Sync Notes to CRM',
        disabled: viewController.selectedMultipleRows() || params?.node?.data.isDraft,
        action: () => {props.crmAlertHandler(params?.node?.data.id, true);}
      }
    ];

    let listOfActions = [];
    if(PermissionGate({
      modulePermissions: generalRules.impersonate, scope: allConstants.SCOPES.canView,
      role: viewController.userRole, companyId: viewController.companyId})) {
      listOfActions = [...ipermitOptions, ...separator, ...standard];
    } else {
      listOfActions = [...standard];
    }
    if(process.env.NODE_ENV === 'development' || PermissionGate({
      modulePermissions: generalRules.forceAddToCRM, scope: allConstants.SCOPES.actionAllowed,
      role: viewController.userRole
    })) {
      listOfActions = [...listOfActions, 'separator', ...dangerous];
    }

    return listOfActions;

  };

  const fetchParams = getFetchParams(props.gridType);
  if(!jobStatusOptions) return null;

  return (
    <>
      <MyPaginatedGrid
        columnDefs={columnDefs}
        components={components}
        contextMenu={getContextMenuItems}
        onCellDoubleClicked={handleDisplayJobDetails}
        registerGrid={viewController.setGridToParent}
        fetchParams={fetchParams}
        pagination={props.pagination}
        onFetchFail={viewController.onFetchFail}
        ls_name={`jobs-${gridType}`}
        defFilterParams={props.defFilterParams}
        draftAction={openDraft}
        additionalFilters={props.additionalFilters}
      />
    </>
  );
};
AllJobsGrid.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  devViewJobHandler: PropTypes.func.isRequired,
  crmAlertHandler: PropTypes.func.isRequired,
  viewJobHandler: PropTypes.func.isRequired,
  gridType: PropTypes.oneOf(['hers', 'permits', 'all', 'cf1r']),
  viewController: PropTypes.instanceOf(Object).isRequired,
  defFilterParams: PropTypes.instanceOf(Array),
  additionalFilters: PropTypes.instanceOf(Object),
};

AllJobsGrid.defaultProps = {
  gridType: 'all',
  defFilterParams: [],
  additionalFilters: {}
};
export default AllJobsGrid;
