const NOTE_TYPES = {
  PERMIT: {api_name: 'Permit', display: 'Permit', value: 'Permit', showOrder: 0, def: false},
  HERS: {api_name: 'HERS', display: 'HERS', value: 'HERS', showOrder: 1, def: false},
  INSPECTION: {api_name: 'Inspection', display: 'Inspection', value: 'Inspection', showOrder: 2, def: false},
};

const ATTACHMENT_TYPES = {
  PERMIT: {api_name: 'Permit', display: 'Permit', value: 'Permit', showOrder: 0, def: false},
  HERS: {api_name: 'HERS', display: 'HERS', value: 'HERS', showOrder: 1, def: false},
  INSPECTION: {api_name: 'Inspection', display: 'Inspection', value: 'Inspection', showOrder: 2, def: false},
  CF1R: {api_name: 'CF1R', display: 'CF1R', value: 'CF1R', showOrder: -1, def: false},
};

export {NOTE_TYPES, ATTACHMENT_TYPES};
