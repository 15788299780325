import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useJob} from '../../../contextProviders/jobContext';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {Box} from '@mui/material';
import Utils from '../../../utils/utils';
import {JobDisplayNotes} from '../../../pieces/jobDisplayNotes';
import {scrollStyles} from '../allJobs/allJobsViewStyles';
import {useForm, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useGetRole} from '../../../hooks/useGetRole';
import Loader from '../../misc/loader';
import {toast} from 'react-toastify';
import ApiUtils from '../../../utils/apiUtils';
import {AddNoteForm} from '../../../pieces/noteElements/addNoteForm';

import PermissionGate from '../../../permissions/permissionGate';
import ELEMENTS, {jobRules} from '../../../pages/jobs/jobsAccessControl';


export const InternalNotesContent = (props) => {
  const {job, setJob} = useJob();
  const company = useSelector(state => state.company);

  const role = useGetRole();

  const [submitting, setSubmitting] = useState(false);

  const {JOB_FIELDS} = allConstants;
  const notes = job?.internalNotes || [];

  const checkAndReturnNoContent = ()=> {
    return notes.length === 0;
  };

  const formOptions = {
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const formMethods = useForm(formOptions);

  const watchNote = useWatch({
    control: formMethods.control,
    name: allConstants.JOB_FIELDS.NOTE.TEXT.api_name,
  });

  if(Utils.isDevEnv()) {
    window.noteForm = formMethods;
  }

  const handleSubmitResponse = (result)=> {
    const toastOptions = {
      toastId: 'add_note_response',
      autoClose: 1000,
    };

    if(result.status === 'success') {
      const updatedNotes = result?.data;

      setJob({...job, ...updatedNotes});
      formMethods.reset();
      toast.success('Internal note added', toastOptions);
    } else{
      toast.error(`Error on adding note: ${result?.message}`, toastOptions);
    }
    Utils.sleep(1000).then(()=> {
      setSubmitting(false);
    });
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    const dataToSubmit = {
      jobId: job?.[JOB_FIELDS.ID.api_name],
      note: data[allConstants.JOB_FIELDS.NOTE.TEXT.api_name],
      [allConstants.JOB_FIELDS.NOTE.NOTIFY_EMAIL.api_name]: data[allConstants.JOB_FIELDS.NOTE.NOTIFY_EMAIL.api_name],
    };
    const result = await ApiUtils.makeApiCall('POST', 'jobs/internal-note', JSON.stringify(dataToSubmit));
    handleSubmitResponse(result);
  };

  return (
    <>
      <Box sx={{
        position: 'relative',
        // border: `1px solid ${STYLE_CONSTANTS.COLORS.lightGray}`,
        paddingTop: '0px',
        padding: submitting ? '0' : {xs: '4px', md: '8px', xl: '16px'},
        maxHeight: '400px',
        overflowY: 'scroll',
        ...scrollStyles,
      }}>
        {checkAndReturnNoContent()}
        <Box sx={{
          position: 'absolute',
          top:0,
          left:0,
          right:0,
          bottom:0,
          display: submitting? 'initial' : 'none',
          zIndex: 100,
          padding: 0,
          backgroundColor: STYLE_CONSTANTS.COLORS.white
        }}>
          <Loader useOwnWrapper/>
        </Box>
        <JobDisplayNotes jobNotes={notes} defaultReturn={null}/>
        <AddNoteForm formMethods={formMethods} color={STYLE_CONSTANTS.COLORS.globalBlue}
          onSubmitAction={onSubmit}
          submitWithNotify={true}
          userAllowedToAddNote={PermissionGate({
            modulePermissions: jobRules[ELEMENTS.addInspectionNote],
            scope: allConstants.SCOPES.canView,
            companyId: company?.[allConstants.COMPANY_FIELDS.ID.api_name],
            role: role
          })}
          company={company}
          role={role}
          ipermitViewNote={'Internal'}
          submitDisabled={!watchNote || watchNote.length === 0}/>
      </Box>
    </>
  );
};

InternalNotesContent.propTypes = {
  defaultNoteType: PropTypes.shape({
    display: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,

  }).isRequired,
};
