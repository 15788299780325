import React from 'react';
import PropTypes from 'prop-types';
import {NavbarLink} from './navbarLink';
import {generalRules, ELEMENTS} from '../../generalAccessControl';
import JOB_ELEMENTS, {jobRules} from '../../pages/jobs/jobsAccessControl';
import PermissionGate from '../../permissions/permissionGate';

import * as allConstants from '../../constants/allConstants';
import {SCOPES} from '../../constants/allConstants';
import {urlSearchParams} from '../../baseUrls';
import {LinkWrapper} from './linkWrapper';
import {NavbarMenuButton} from './navbarMenuButton';


const AllLinks = (props)=> {

  if(props.showIpermitLinks){
    return (
      <>

        <PermissionGate scope={allConstants.SCOPES.canView}
          role={props.userRole}
          companyId={props.companyId}
          modulePermissions={generalRules.permitsModule}>
          <LinkWrapper>
            <NavbarMenuButton buttonContent={'Permits'}
              startIcon={{
                alt: 'permits',
                icon: 'two_pages'
              }}
              module={allConstants.IPERMIT_MODULES.PERMIT}
              links={[
                {
                  alt: 'permits',
                  text: 'All Jobs',
                  submodule: allConstants.IPERMIT_MODULES.ALL_JOBS,
                },
                {
                  alt: 'permits',
                  text: 'CF1R',
                  submodule: allConstants.IPERMIT_MODULES.CF1R,
                }
              ]}
            >
            </NavbarMenuButton>
          </LinkWrapper>
        </PermissionGate>
        <PermissionGate scope={allConstants.SCOPES.canView}
          role={props.userRole}
          companyId={props.companyId}
          modulePermissions={generalRules.inspectionsModule}>
          <LinkWrapper>
            <NavbarLink path={`${allConstants.PATH.MODULE}?${urlSearchParams.moduleName}=${allConstants.IPERMIT_MODULES.INSPECTION}`} alt={'inspections'} icon={'list'} text={'Inspections'}/>
          </LinkWrapper>
        </PermissionGate>

        <PermissionGate modulePermissions={generalRules.allCompaniesLink}
          scope={SCOPES.canView}
          role={props.userRole}
          companyId={props.companyId}>
          <LinkWrapper>

            <NavbarMenuButton buttonContent={'Companies'}
              drowdownWidth={'200px'}
              startIcon={{
                alt: 'companies',
                icon: 'briefcase'
              }}
              module={allConstants.IPERMIT_MODULES.COMPANIES}
              links={[
                {
                  alt: 'companies',
                  text: 'All Companies',
                  path: allConstants.PATH.ALL_COMPANIES,
                },
                {
                  alt: 'users',
                  text: 'Contractor Users',
                  path: allConstants.PATH.USERS,
                }
              ]}
            >
            </NavbarMenuButton>

            {/*<NavbarLink alt={'all companies'} icon={'briefcase'}  text={'All Companies'} path={allConstants.PATH.ALL_COMPANIES}/>*/}
          </LinkWrapper>
        </PermissionGate>

        {/*<PermissionGate scope={allConstants.SCOPES.canView} role={props.userRole}*/}
        {/*  modulePermissions={generalRules.usersLink}>*/}
        {/*  <LinkWrapper>*/}
        {/*    <NavbarLink alt={'users'} icon={'users'} text={'Users'} path={allConstants.PATH.USERS}/>*/}
        {/*  </LinkWrapper>*/}
        {/*</PermissionGate>*/}

        <PermissionGate modulePermissions={generalRules[ELEMENTS.cities]} scope={SCOPES.canView} role={props.userRole} companyId={props.companyId}>
          <LinkWrapper>
            <NavbarLink alt={'cities'}
              icon={'city'}
              text={'Cities'}
              path={allConstants.PATH.CITIES}/>
          </LinkWrapper>
        </PermissionGate>

        <PermissionGate scope={allConstants.SCOPES.canView}
          role={props.userRole}
          companyId={props.companyId}
          modulePermissions={generalRules.products}>
          <LinkWrapper>
            <NavbarLink alt={'products'} icon={'products'} text={'Products'} path={allConstants.PATH.PRODUCTS}/>
          </LinkWrapper>
        </PermissionGate>
        <PermissionGate scope={allConstants.SCOPES.canView}
          role={props.userRole}
          companyId={props.companyId}
          modulePermissions={generalRules.raters}>
          <LinkWrapper>
            <NavbarLink alt={'Raters'} icon={'users'} text={'Raters'} path={allConstants.PATH.RATERS}/>
          </LinkWrapper>
        </PermissionGate>
        <PermissionGate modulePermissions={generalRules[ELEMENTS.analytics]} scope={SCOPES.canView} role={props.userRole} companyId={props.companyId}>
          <LinkWrapper>
            <NavbarLink alt={'analytics'}
              icon={'piechart'}
              text={'Analytics'}
              path={allConstants.PATH.ANALYTICS}/>
          </LinkWrapper>
        </PermissionGate>


      </>
    );
  }

  return (
    <>
      <PermissionGate scope={allConstants.SCOPES.canView}
        role={props.userRole}
        companyId={props.companyId}
        modulePermissions={jobRules[JOB_ELEMENTS.addJobButton]}>
        <LinkWrapper>
          <NavbarLink alt={'create_job'} icon={'plus'} text={'Create job'} path={allConstants.PATH.CREATE_JOB}/>
        </LinkWrapper>
      </PermissionGate>
      <LinkWrapper>
        <NavbarLink alt={'job'} icon={'list'} text={'Jobs'} path={allConstants.PATH.JOBS}/>
      </LinkWrapper>
      <PermissionGate scope={allConstants.SCOPES.canView}
        role={props.userRole}
        companyId={props.companyId}
        modulePermissions={generalRules.jobsViewForContractor}>
        <LinkWrapper>
          <NavbarLink alt={'job'} icon={'list'} text={'Jobs'} path={allConstants.PATH.JOBS}/>
        </LinkWrapper>
      </PermissionGate>

      <PermissionGate modulePermissions={generalRules.companyLink} scope={SCOPES.canView} role={props.userRole} companyId={props.companyId}>
        <LinkWrapper>
          <NavbarLink alt={'company'} icon={'briefcase'}  text={'Company'}
            path={props.companyId ? `${allConstants.PATH.COMPANY}?${urlSearchParams.companyId}=${props.companyId}`: allConstants.PATH.COMPANY}
          />
        </LinkWrapper>
      </PermissionGate>

      <PermissionGate scope={allConstants.SCOPES.canView} role={props.userRole}
        modulePermissions={generalRules.usersLink}>
        <LinkWrapper>
          <NavbarLink alt={'users'} icon={'users'} text={'Users'} path={allConstants.PATH.USERS}/>
        </LinkWrapper>
      </PermissionGate>

      <PermissionGate modulePermissions={generalRules[ELEMENTS.analytics]} scope={SCOPES.canView} role={props.userRole} companyId={props.companyId}>
        <LinkWrapper>
          <NavbarLink alt={'analytics'}
            icon={'piechart'}
            text={'Analytics'}
            path={allConstants.PATH.ANALYTICS}/>
        </LinkWrapper>
      </PermissionGate>


    </>
  );
};

AllLinks.propTypes = {
  userRole: PropTypes.string,
  companyId: PropTypes.string,
  showIpermitLinks: PropTypes.bool,
};
AllLinks.defaultProps = {
  userRole: allConstants.ROLES.CONTRACTOR,
  companyId: null,
  showIpermitLinks: false,
};

export {AllLinks};
