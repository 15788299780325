import React, {useEffect, useState} from 'react';
import {Button, Typography, IconButton} from '@mui/material';
import {Box} from '@mui/system';
import PropTypes from 'prop-types';
import TypeSwitch from '../../../../pieces/typeSwitch';
import ModalWrapper from '../../../../pieces/modals/modalWrapper';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import CloseIcon from '@mui/icons-material/Close';

function NoteType(props) {
  const [toggle, setToggle] = useState(props.toggle);

  useEffect(()=> {
    setToggle(props.toggle);
  }, [props.toggle]);
  return (
    <Box>
      <ModalWrapper
        open={props.open}
        handleClose={props.onClose}
        width={'clamp(240px, 90vw, 640px)'}
        sx={{
          borderRadius: '10px',
          justifyContent: 'center'
        }}
      >
        <Box justifyContent={'center'} textAlign={'center'} sx={{backgroundColor: 'white', borderRadius: '10px'}}>
          <Box ml={{xs: '16px', sm: '24px'}} textAlign={'end'}>
            <IconButton
              sx={{
                backgroundColor:STYLE_CONSTANTS.COLORS.backgroundMain,
                width: '34px',
                height: '34px'
              }}
              onClick={props.onClose}>
              <CloseIcon/>
            </IconButton>
          </Box>
          <Typography pb={4} variant="h1">Note related to:</Typography>
          <Box pb={6} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <TypeSwitch trueLabel={'HERS'} falseLabel={'PERMIT'} onToggle={props.onToggle} toggle={toggle}/>
          </Box>
          <Box pb={12}>
            <Button
              onClick={props.onSubmit}
              variant={'contained'}
              color={'primary'}
              sx={{paddingX: 4, paddingY: 1}}
            >
              Send Note
            </Button>
          </Box>
        </Box>
      </ModalWrapper>
    </Box>
  );
}

NoteType.propTypes = {
  open: PropTypes.func,
  onClose: PropTypes.func,
  toggle: PropTypes.bool,
  onToggle: PropTypes.func,
  onSubmit: PropTypes.any,
};

export default NoteType;
