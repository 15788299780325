import React from 'react';
// import PropTypes from 'prop-types';
// import {Box} from '@mui/material';
// import {Text} from '../../../pieces/text';
// import Spacer from '../../../pieces/spacer';

export const UserNotificationsPanel = (props) => {
  return (
    <>
      <div>User Notifications Panel</div>
    </>
  );
};

