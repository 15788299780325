import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm, FormProvider} from 'react-hook-form';
import ApiUtils from '../../../utils/apiUtils';
import * as allConstants from '../../../constants/allConstants';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {ERROR_MSG} from '../../../constants/allConstants';
import validator from '../../../utils/validators/validator';
import {Box, CardContent, Grid, Card} from '@mui/material';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import FormSubmitButtons from '../../../pieces/formSubmitButtons';
import {globalUseClasses} from '../../../styles/globalClasses';
import {unwrapRequestResult} from '../../../utils/unwrapRequestResult';
import actions from '../../../redux/actions';
import {errorHandler} from '../../../utils/errorHandler';
import {useDispatch} from 'react-redux';
import {urlSearchParams} from '../../../baseUrls';
import {useNavigate} from 'react-router-dom';

export const UserInfoPanel = (props) => {
  const {USER_FIELDS} = allConstants;
  const [userData, setUserData] = useState(props?.userData || {});
  const classes = {...globalUseClasses()};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDefaultValue = (field)=> {
    return userData?.[field.api_name]||'';
  };

  const getFormDefaultValues = ()=> {
    return {
      [USER_FIELDS.FIRST_NAME.api_name]: getDefaultValue(USER_FIELDS.FIRST_NAME),
      [USER_FIELDS.LAST_NAME.api_name]: getDefaultValue(USER_FIELDS.LAST_NAME),
      [USER_FIELDS.EMAIL.api_name]: getDefaultValue(USER_FIELDS.EMAIL),
      [USER_FIELDS.PHONE_NUMBER.api_name]: getDefaultValue(USER_FIELDS.PHONE_NUMBER),
      [USER_FIELDS.TITLE.api_name]: getDefaultValue(USER_FIELDS.TITLE),
    };
  };

  Yup.addMethod(Yup.string, 'checkIfEmailExists', validator.checkIfEmailExists);
  Yup.addMethod(Yup.string, 'validatePhoneNumber', function (errorMessage) {
    return this.test('phone_number', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidPhoneNumber(value) ||
        createError({path, message: errorMessage})
      );
    });
  });
  Yup.addMethod(Yup.string, 'validateEmail', function (errorMessage) {
    return this.test('email', errorMessage, function (value) {
      const {path, createError} = this;
      return (
        validator.isValidEmail(value) ||
        createError({path, message: errorMessage})
      );
    });
  });

  const formOptions = {
    mode: 'onBlur',
    criteriaMode:'all',
    reValidateMode: 'onChange',
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(Yup.object().shape({
      [USER_FIELDS.FIRST_NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
      [USER_FIELDS.LAST_NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
      [USER_FIELDS.EMAIL.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD)
        .validateEmail(ERROR_MSG.EMAIL_INVALID)
        .test(
          {
            test: value => {
              return Yup.string().checkIfEmailExists(value, userData[USER_FIELDS.EMAIL.api_name]);
            },
            message: allConstants.ERROR_MSG.USER_ALREADY_EXISTS
          }
        ),
      [USER_FIELDS.PHONE_NUMBER.api_name]: Yup.string().validatePhoneNumber(ERROR_MSG.INVALID_PHONE).nullable(),
    })),
  };

  const formMethods = useForm(formOptions);

  useEffect(()=> {
    formMethods.reset(getFormDefaultValues());
  }, [userData]);

  useEffect(()=> {
    setUserData(props.userData);
  },[props.userData]);


  if(ApiUtils.isDevelopment()) {
    window.userForm = formMethods;
  }

  const submitInvite = async (data) => {
    const companyId = props.currentCompany[allConstants.COMPANY_FIELDS.ID.api_name];
    if(!companyId) {
      Utils.errorToastMessage('Company is not selected');
      return;
    }
    props.setSubmitting(true);
    const newData = {...data, ...{
      [allConstants.USER_FIELDS.COMPANY_ID.api_name]: companyId},
    [allConstants.USER_FIELDS.ROLE.api_name]: allConstants.ROLES.CONTRACTOR,
    };
    const inviteResult = await dispatch(actions.inviteUser(newData));
    if(inviteResult) {
      unwrapRequestResult({
        showSuccess: true,
        result: inviteResult,
        successMessage: 'New contractor has been invited.',
        id: 'invite_contractor',
        errorMessage: errorHandler.getErrorMessage(inviteResult?.payload?.errorCode, 'Failed to invite new user')
      });

      const user = inviteResult?.payload?.user;
      if(user) {
        navigate(`${allConstants.PATH.USER}${location.search}&${urlSearchParams.userId}=${user.id}`);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }
  };

  const onSubmit = async (data)=> {
    if(props.isInvite) {
      submitInvite(data);
    } else {
      props.setSubmitting(true);

      try{
        let successMessage = 'User ' + data[USER_FIELDS.FIRST_NAME.api_name] + ' ' + data[USER_FIELDS.LAST_NAME.api_name] + ' successfully edited.';
        const editResponse = await ApiUtils.makeApiCall('POST', `users/update-user-data/${userData.id}`, JSON.stringify(data));

        if(editResponse) {
          unwrapRequestResult({
            showSuccess: true,
            result: editResponse ,
            successMessage: successMessage,
            id: 'edit_user_success',
          });

        }
        const user = editResponse?.payload?.user;
        if(user) {
          props.setUserData(user);
        }

      } catch (e) {
        console.error(e);
        Utils.errorToastMessage(`Error editing user. ${e?.message}`);
      }
      props.setViewMode(true);
      props.setSubmitting(false);
    }


  };

  const onCancel = ()=> {
    props.setViewMode(true);
  };

  const inputDefaultProps = {
    viewMode: props.viewMode,
    renderLabel: true,
    isEditable: true,
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} method={'POST'}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={classes.card_block} sx={{height: '100%'}} >
                <CardContent className={classes.half_width} >
                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.FIRST_NAME}
                      fullWidth
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.LAST_NAME}
                      fullWidth
                    />
                  </Box>
                  <CustomFormBlock
                    {...inputDefaultProps}
                    field={{...USER_FIELDS.EMAIL, ...{required: true}}}
                    isEditable={props.isInvite}
                    fullWidth
                    preventCapitalize={true}
                  />
                  <Box className={classes.two_col_container}>
                    <CustomFormBlock
                      {...inputDefaultProps}
                      inputType={inputTypes.phone}
                      field={USER_FIELDS.PHONE_NUMBER}
                      defaultValue={Utils.reformatPhoneNumber(getDefaultValue(USER_FIELDS.PHONE_NUMBER))}
                      fullWidth
                    />
                    <CustomFormBlock
                      {...inputDefaultProps}
                      field={USER_FIELDS.TITLE}
                      fullWidth
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {!props.viewMode && <Grid xs={12}>
              <Box display={'flex'} justifyContent={'center'}>
                <FormSubmitButtons cancelAction={onCancel} submitText={props.isInvite ? 'Invite' : 'Update'} cancelText={'Cancel'}/>
              </Box>
            </Grid>}
          </Grid>

        </form>
      </FormProvider>
    </>
  );
};

UserInfoPanel.propTypes = {
  userData: PropTypes.instanceOf(Object),
  viewMode: PropTypes.bool,
  setViewMode: PropTypes.func,
  setSubmitting: PropTypes.func,
  isInvite: PropTypes.bool,
  currentCompany: PropTypes.instanceOf(Object),
  setUserData: PropTypes.func,

};

UserInfoPanel.defaultProps = {
  userData: {},
  viewMode: true,
  setViewMode: ()=> {},
  setSubmitting: ()=> {},
  isInvite: false,
  currentCompany: {},
  setUserData: ()=> {},

};
