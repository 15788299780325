import * as React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel} from '@mui/material';
import SimpleSwitch from '../simpleSwitch';

export default function CustomizedSwitch(props) {
  const onChange=(e, flag)=> {
    props.action(flag);
  };
  return (
    <>
      <FormControlLabel control={<SimpleSwitch onChange={onChange} defaultChecked={props.defaultChecked}/>}
        label={props.label}
        sx={{marginY: theme=>theme.spacing(1),}}

      />
    </>
  );
}

CustomizedSwitch.propTypes = {
  action: PropTypes.func,
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
};

CustomizedSwitch.defaultProps = {
  action: ()=>{},
  defaultChecked: false,
};