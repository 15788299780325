import {ROLES, SCOPES, IPERMIT_ROLES} from '../../constants/allConstants';
const ELEMENTS = {
  inviteCompanyButton: 'inviteCompanyButton',
  impersonate: 'impersonate',
  activate: 'activate',
  block: 'block',
  editFinancialInfo: 'editFinancialInfo',
};


const allCompaniesPageRules = Object.freeze({
  [ELEMENTS.impersonate]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideFromSystemAdmin: true,
  },
  [ELEMENTS.activate]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN],
  },
  [ELEMENTS.block]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN],
  },
  [ELEMENTS.inviteCompanyButton]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN]
  },
  [ELEMENTS.editFinancialInfo]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.SYSTEM_ADMIN]
  },
});

export {allCompaniesPageRules, ELEMENTS};
