import {createAsyncThunk} from '@reduxjs/toolkit';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import {USER_FIELDS} from '../../../constants/allConstants';

export const getCurrentUserData = createAsyncThunk(
  'users/getCurrentUserData',
  async ( thunkAPI) => {
    try {
      console.log('getting user data');
      const authToken = await ApiUtils.getAccessToken();
      if(!authToken) {
        return thunkAPI.rejectWithValue('AUTH_ERROR');
      }

      const response = await fetch(
        `${base_url.api}users/current-user-data`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      let data = await response.json();
      // console.log('getCurrentUserData response',response, response.status === 200);
      if (response.status === 200) {
        return {...data.data};
      } else {
        console.log('Rejected with', data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCurrentUserCompanyReducer = {
  [getCurrentUserData.fulfilled]: (state, {payload}) => {
    const newState = {...state, ...payload.company};
    return newState;
  },
};

export const getCurrentUserDataReducer = {
  [getCurrentUserData.fulfilled]: (state, {payload}) => {

    state.isFetching = false;
    state.isSuccess = true;
    state.id = payload?.user?.id;
    state.email =  payload?.user?.email;
    state.role =  payload?.user?.role;
    state.userName = {lastName:  payload?.user?.lastName, firstName:  payload?.user?.firstName};
    state.status =  payload?.user?.status;
    state.isAuthenticated = true;
    state[USER_FIELDS.HAS_CONNECTED_TO_CHEERS.api_name] = payload?.user?.[USER_FIELDS.HAS_CONNECTED_TO_CHEERS.api_name];
    state[USER_FIELDS.IS_CONNECT_TO_CHEERS_REQUESTED.api_name] = payload?.user?.[USER_FIELDS.IS_CONNECT_TO_CHEERS_REQUESTED.api_name];
    if(payload.user.companyId){
      state.companyId = payload.user.companyId;
    }

  },
  [getCurrentUserData.pending]: (state) => {
    state.isFetching = true;
    state.authPending = true;
  },
  [getCurrentUserData.rejected]: (state, {payload}) => {
    state.isFetching = false;
    state.isAuthenticated = false;
    state.authPending = false;
  },
};
