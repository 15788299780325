import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Grid} from '@mui/material';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import * as allConstants from '../../../constants/allConstants';
import {useJob} from '../../../contextProviders/jobContext';
import {CustomMediaQuery} from '../../../hooks/customMediaQuery';
import {Accordion, AccordionDetails, AccordionSummary} from '../../../pieces/accordion/accordionTab';
import {Card} from '../../../pieces/card';
import {FormValue} from '../../../pieces/displayJob/formLine';
import Spacer from '../../../pieces/spacer';
import {Text, TextWithIconWrapper} from '../../../pieces/text';
import {ReactComponent as AttachmentIconSvg} from '../../../static/images/attachmentIcon.svg';
import {ReactComponent as BulletListIconSvg} from '../../../static/images/bulletListIcon.svg';
import {ReactComponent as HomeIconSvg} from '../../../static/images/homeIcon.svg';
import {ReactComponent as NoteIconSvg} from '../../../static/images/noteIcon.svg';
import Utils from '../../../utils/utils';
import {ScheduledDatesWrapper} from '../../wrappers/scheduledDatesWrapper';
import {AttachmentsContent} from '../ipermitJobPages/attachmentsContent';
import {ExternalNotesContent} from '../ipermitJobPages/externalNotesContent';
import {useTabs} from '../../../hooks/useTabs';
import {JobDetailsComponent} from '../jobDetails/tabs/jobDetailsTab';
import {HomeownerInfoBlock} from './homeownerInfoBlock';
import {IpermitRecordHeader} from './ipermiRecordHeader';
import {InternalNotesContent} from '../ipermitJobPages/internalNotesContent';
import downloadFile from '../../../utils/downloadFileUtil';
import {FloatingMenu} from '../../../pieces/floatingMenu';

export const showChildrenPosition = {
  afterGeneral: 'afterGeneral',
  last: 'last',
};


export const IpermitJobWrapper = (props)=> {
  const jobContext = useJob();
  const {job} = jobContext;
  const {JOB_FIELDS, INSPECTION_FIELDS, SCHEDULING_FIELDS} = allConstants;
  const queries =CustomMediaQuery();
  const [addingAttachment, setAddingAttachment] = useState(false);
  const [attachmentsToDownload, setAttachmentsToDownload] = useState([]);

  const isHERSScheduled = Utils.valueIsSpecified(job?.[JOB_FIELDS.HERS_SCHEDULED_DATE.api_name]);
  const isInspectionScheduled = Utils.valueIsSpecified(job?.[INSPECTION_FIELDS.INSPECTION.api_name]?.[SCHEDULING_FIELDS.START_DATE.api_name]);

  const headerHeight = STYLE_CONSTANTS.HEIGHT.JOB_HEADER_HEIGHT(queries);
  const homeOwnerRef = useRef();
  const notesRef = useRef();
  const internalNotesRef = useRef();
  const attachmentsRef = useRef();
  const jobDetailsRef = useRef();

  useEffect(()=>{
    return ()=>{

      jobContext.timeline.setShowTimeline(false);
    };
  }, []);

  const getDefaultNoteTypeForModule = () => {
    const noteTypeMap = {
      [allConstants.IPERMIT_MODULES.PERMIT]: allConstants.NOTE_TYPES.PERMIT,
      [allConstants.IPERMIT_MODULES.INSPECTION]: allConstants.NOTE_TYPES.INSPECTION,
      [allConstants.IPERMIT_MODULES.HERS]: allConstants.NOTE_TYPES.HERS,

    };
    return noteTypeMap?.[props.module] || allConstants.NOTE_TYPES.PERMIT;
  };

  const generalBlockTemplate = ()=> {
    const mapping = {
      [allConstants.IPERMIT_MODULES.INSPECTION]: [{xs: '140px 1fr', xl: '160px 1fr'}, '130px 1fr'],
      [allConstants.IPERMIT_MODULES.CF1R]: [{xs: '140px 1fr', xl: '160px 1fr'}, {xs: '140px 1fr', xl: '160px 1fr'}],
      [allConstants.IPERMIT_MODULES.ALL_JOBS]: [{xs: '140px 1fr', xl: '160px 1fr'}, {xs: '140px 1fr', xl: '160px 1fr'}]
    };
    if(mapping[props.module]){
      return mapping[props.module];
    }
    return [['1fr', '1fr'], ['1fr', '1fr']];
  };

  const getAttachemntsCount = (type)=>{
    const attachments = job?.attachments;
    if(!attachments) return 0;
    const count = attachments.filter(attachment=>attachment.type === type).length;
    if(type === allConstants.ATTACHMENT_TYPES.PERMIT.value){
      const Cf1rAttachments = attachments.filter(attachment=>attachment.type === allConstants.ATTACHMENT_TYPES.CF1R.value).length;
      return count + Cf1rAttachments;
    }
    return count;
  };

  const getNotesCount = (type)=>{
    const notes = job?.notes;
    if(!notes) return 0;
    return notes.filter(note=>note.type === type).length;
  };

  const getJobTabs = ()=> {
    let jobAtachmentTabs = [allConstants.ATTACHMENT_TYPES.PERMIT, allConstants.ATTACHMENT_TYPES.HERS, allConstants.ATTACHMENT_TYPES.INSPECTION];
    let jobNoteTabs = [allConstants.NOTE_TYPES.PERMIT, allConstants.NOTE_TYPES.HERS, allConstants.NOTE_TYPES.INSPECTION];

    return {
      jobAttachmentTabs: jobAtachmentTabs.map(tab=>({label: `${tab.display}`, type: tab.value, count: getAttachemntsCount(tab.api_name)})),
      jobNoteTabs: jobNoteTabs.map(tab=>({label: `${tab.display}`, type: tab.value, count: getNotesCount(tab.api_name)})),
      color: STYLE_CONSTANTS.COLORS.brightBlue
    };
  };

  const {jobAttachmentTabs, jobNoteTabs, color} = getJobTabs();
  const attachmentTabsController = useTabs({
    tabs: jobAttachmentTabs,
    color: color,
  });

  const notesTabsController = useTabs({
    tabs: jobNoteTabs,
    color: color,
  });

  const handleAttachmentCheckboxChange = (checked, attachment)=>{
    if(checked){
      setAttachmentsToDownload([...attachmentsToDownload, ...[attachment.fileId]]);
    }else{
      setAttachmentsToDownload(attachmentsToDownload.filter(id=>id !== attachment.fileId));
    }
  };

  const downloadSelected = () => {
    if(!attachmentsToDownload.length) return;
    attachmentsToDownload.forEach(fileId=>{
      downloadFile({id: fileId});
    });
  };

  const floatingMenuItems = [
    {name: 'homeOwner', ref: homeOwnerRef, icon:  <HomeIconSvg/>},
    {name: 'notes', ref: notesRef, icon:  <NoteIconSvg/>},
    {name: 'internalNotes', ref: internalNotesRef, icon:  <NoteIconSvg/>},
    {name: 'jobDetails', ref: jobDetailsRef, icon:  <BulletListIconSvg/>},
    {name: 'attachments', ref: attachmentsRef, icon: <AttachmentIconSvg/>},
    jobContext.timeline.timelineButton(),

  ];


  return(
    <>
      <FloatingMenu items={floatingMenuItems}/>

      <Box sx={{
        zIndex: '1000',
        position: 'fixed',
        height: headerHeight,
        width: '100%',
        textTransform: 'uppercase',
        backgroundColor: STYLE_CONSTANTS.COLORS.white,
        borderBottom: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}>
        <IpermitRecordHeader module={props.module} backPath={props.backPath}/>
      </Box>
      <Spacer y={3}/>

      <Box sx={{
        width: '100%',
        paddingTop: headerHeight,
        paddingX: {xs: '16px', md: '24px', xl: '32px'},
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: theme=>theme.spacing(2),

      }}>
        {jobContext.timeline.showTimeline && jobContext.timeline.timeline()}
        {!jobContext.timeline.showTimeline && <>
          <Box sx={{maxWidth: STYLE_CONSTANTS.DEFAULT.MAX_WIDTH_JOB_VIEW,
            minWidth: {xs: '100%', md: '60%'},
            width: '100%'}}>


            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'100%'}>

              {/*scheduled dates block*/}
              {(isInspectionScheduled || isHERSScheduled) &&
              <>
                <ScheduledDatesWrapper HERSDate={job?.[JOB_FIELDS.HERS_SCHEDULED_DATE.api_name]}
                  inspectionData={job?.[INSPECTION_FIELDS.INSPECTION.api_name]}
                  timezone={job?.timezone}/>
                <Spacer y={2}/>
              </>

              }

              {/*home owenr + general info*/}
              <Box width={'100%'} ref={homeOwnerRef}>
                <Box display={'grid'} sx={{
                  width: '100%',
                  gridTemplateColumns: {xs: '1fr', md: '400px 1fr'},
                  columnGap: {xs:'32px'}
                }}>
                  <Box
                    sx={{
                      border: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
                      borderRadius: '8px',
                    }}
                  >
                    <Card header={
                      <TextWithIconWrapper icon={<HomeIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                        <Text text={'homeowner information'} weight={'bold'}/>
                      </TextWithIconWrapper>
                    }>
                      <HomeownerInfoBlock/>

                    </Card>
                  </Box>
                  {queries.isXS && <Spacer y={1}/>}
                  <Box
                    width={'100%'}
                    sx={{
                      border: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
                      borderRadius: '8px',
                    }}>
                    <Card
                      header={
                        <TextWithIconWrapper icon={<BulletListIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                          <Text text={'job information'} weight={'bold'}/>
                        </TextWithIconWrapper>
                      }
                    >
                      <Box display={'grid'} sx={{
                        gridTemplateColumns: {xs: '1fr', md:'1fr 1fr'},
                        rowGap: '4px',
                        textTransform: 'capitalize',

                      }}>
                        <Box display={'flex'} rowGap={1} flexDirection={'column'}>
                          {props.generalInfo[0].map((row, index)=> {
                            return (
                              <Box key={row.label + index} display={'grid'} gridTemplateColumns={generalBlockTemplate()[0]}>
                                <Text text={row.label} uppercase={false} weight={'bold'} />
                                <FormValue value={row.value}/>
                              </Box>
                            );
                          }
                          )}

                        </Box>
                        <Box display={'flex'} rowGap={1} flexDirection={'column'}>
                          {props.generalInfo[1].map((row, index)=> {
                            return (
                              <Box key={row.label + index} display={'grid'} gridTemplateColumns={generalBlockTemplate()[1]}>
                                <Text text={row.label} uppercase={false} weight={'bold'} />
                                <FormValue value={row.value}/>
                              </Box>
                            );
                          }
                          )}

                        </Box>
                      </Box>

                    </Card>
                  </Box>

                </Box>
              </Box>
            </Box>
            {props.showChildrenPosition === showChildrenPosition.afterGeneral && props.children}
            <Spacer y={3}/>
            {/*Notes*/}
            <Box width={'100%'} display={'flex'} ref={notesRef}
              sx={{
                border: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
                borderRadius: '8px',
              }}
            >
              <Accordion defaultExpanded={true} sx={{
                '& .MuiAccordionDetails-root': {
                  paddingTop: 0
                }
              }}>
                <AccordionSummary
                  aria-controls={'notes-header'}
                  id={'notes-content'}

                >
                  <TextWithIconWrapper icon={<NoteIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                    <Text text={'External Notes'} weight={'bold'}/>
                    <Spacer x={4}/>
                    {notesTabsController.Stepper}
                  </TextWithIconWrapper>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} >
                      <ExternalNotesContent
                        defaultNoteType={getDefaultNoteTypeForModule()}
                        moduleName={props.module}
                        activeTabIndex={notesTabsController.activeIndex}
                        tabs={jobNoteTabs}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
                {/*<ExternalNotesContent/>*/}
              </Accordion>

            </Box>
            <Spacer y={3}/>
            {/* internal Notes*/}
            <Box width={'100%'} display={'flex'} ref={internalNotesRef}
              sx={{
                border: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
                borderRadius: '8px',
              }}
            >
              <Accordion defaultExpanded={true} sx={{
                '& .MuiAccordionDetails-root': {
                  paddingTop: 0
                }
              }}>
                <AccordionSummary
                  aria-controls={'notes-header'}
                  id={'notes-content'}

                >
                  <TextWithIconWrapper icon={<NoteIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                    <Text text={'Internal Notes'} weight={'bold'}/>
                  </TextWithIconWrapper>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} >
                      <InternalNotesContent
                        defaultNoteType={getDefaultNoteTypeForModule()}
                        module={props.module}
                        activeTabIndex={notesTabsController.activeIndex}
                        tabs={jobNoteTabs}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
                {/*<ExternalNotesContent/>*/}
              </Accordion>

            </Box>
            <Spacer y={3}/>
            {/*/!*job details*!/*/}
            <Box display={'flex'}  ref={jobDetailsRef}
              width={'100%'}
              sx={{textTransform: 'uppercase', border: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`, borderRadius: '8px'}}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  aria-controls={'job-details-header'}
                  id={'job-details-content'}
                >
                  <Box display={'grid'} gridTemplateColumns={'150px 1fr'} columnGap={2} py={'4px'}>
                    <Box display={'flex'} alignItems={'center'}>
                      <TextWithIconWrapper icon={<BulletListIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                        <Text text={'job details'} weight={'bold'}/>
                      </TextWithIconWrapper>
                    </Box>
                    {job?.[JOB_FIELDS.GENERAL.EQUIPMENT_USED.api_name] &&
                  job?.[JOB_FIELDS.GENERAL.EQUIPMENT_USED.api_name].length !== 0 &&
                  <Box display={'flex'}>
                    <Text text={'Summary:'}/>
                    <Spacer x={1}/>
                    <Text text={job?.[JOB_FIELDS.GENERAL.EQUIPMENT_USED.api_name]}/>
                  </Box>
                    }
                  </Box>

                </AccordionSummary>
                <AccordionDetails>
                  <JobDetailsComponent job={job} preventScroll={true}/>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Spacer y={3}/>

            {/*attachments*/}
            <Box display={'flex'}  width={'100%'}
              ref={attachmentsRef}
              sx={{
                textTransform: 'uppercase',
                border: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
                borderRadius: '8px'
              }}>
              <Accordion defaultExpanded={true} sx={{
                '& .MuiAccordionDetails-root': {
                  paddingTop: 0
                }
              }}>
                <AccordionSummary
                  aria-controls={'attachments-header'}
                  id={'attachments-content'}

                >
                  <TextWithIconWrapper icon={<AttachmentIconSvg/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                    <Text text={'Attachments'} weight={'bold'}/>
                    <Spacer x={4}/>
                    {attachmentTabsController.Stepper}
                  </TextWithIconWrapper>
                  <Box display={'flex'}>
                    {attachmentsToDownload.length > 0 && <Button variant={'outlined'} color={'primary' }
                      onClick={(e)=> {
                        e.stopPropagation();
                        e.preventDefault();
                        downloadSelected();
                      }}
                      sx={{
                        ...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                        textTransform: 'uppercase'
                      }}
                    >{'Download selected'}</Button>}
                    <Spacer x={2}/>
                    <Button variant={'contained'} color={addingAttachment ? 'secondary' : 'primary' }
                      onClick={(e)=> {
                        e.stopPropagation();
                        e.preventDefault();
                        setAddingAttachment(!addingAttachment);
                      }}
                      sx={{
                        ...STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON,
                        textTransform: 'uppercase'
                      }}
                    >{addingAttachment ?  'Cancel' : 'Add Attachment'}</Button>
                  </Box>


                </AccordionSummary>
                <AccordionDetails>
                  <AttachmentsContent addAttachment={addingAttachment}
                    moduleName={props.module}
                    cancelAction={()=> setAddingAttachment(false)}
                    activeTabIndex={attachmentTabsController.activeIndex}
                    tabs={jobAttachmentTabs}
                    handleAttachmentCheckboxChange={handleAttachmentCheckboxChange}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Spacer y={3}/>

          </Box>
        </>}

      </Box>

      {!jobContext.timeline.showTimeline && <Box sx={{
        borderTopLeftRadius: '18px',
        borderTopRightRadius: '18px',
        backgroundColor: STYLE_CONSTANTS.COLORS.white,
        marginTop: '-16px',
        padding: {xs: '16px', md: '24', xl: '32px'},
        minHeight: '80vh',

      }}>
        <Box sx={{
          maxWidth: STYLE_CONSTANTS.DEFAULT.MAX_WIDTH_JOB_VIEW,
          margin: 'auto'
        }}>
          {props.showChildrenPosition === showChildrenPosition.last && props.children}

        </Box>
      </Box>}

    </>
  );
};

IpermitJobWrapper.propTypes = {
  module: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired,
  generalInfo: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.string.isRequired
  }))),
  showChildrenPosition: PropTypes.string,
};

IpermitJobWrapper.defaultProps = {
  showChildrenPosition: showChildrenPosition.last
};
