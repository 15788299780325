import React, {useEffect} from 'react';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import * as allConstants from '../../../constants/allConstants';
import Grid from '@mui/material/Grid';
import {Box, Card, CardContent} from '@mui/material';
import {useCompanyPageStyles} from '../companyPageStyles';
import {globalUseClasses} from '../../../styles/globalClasses';
import {inputTypes} from '../../../pieces/inputs/formInput';
import clsx from 'clsx';
import {companyDefaultProps, companyPropTypes} from './panelProps';
import {useFormContext} from 'react-hook-form';
import Utils from '../../../utils/utils';

export const WorkersComp = (props)=> {
  const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;
  const formMethods = useFormContext();
  const [isRequired, setIsRequired] = React.useState(true);

  useEffect(()=> {
    formMethods.setValue(`${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name}`, allConstants.WORKERS_COMP_TYPES.REQUIRED.value);
  },[]);

  const watchType = formMethods.watch(`${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name}`);

  useEffect(()=> {
    if(!watchType) {return;}

    if(watchType === allConstants.WORKERS_COMP_TYPES.EXEMPT.value) {
      formMethods.clearErrors([
        `${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_CERT.api_name}`,
        `${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_CERT_EXP_DATE.api_name}`,
        `${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_CERT_NUMBER.api_name}`,
      ]);
    }

    setIsRequired(!props.tabProps.isIpermitInvite && watchType === allConstants.WORKERS_COMP_TYPES.REQUIRED.value);
  }, [watchType]);


  const getCompanyData = ()=> {
    const initialData = props.tabProps?.companyData || {};
    return {...initialData, ...formMethods.getValues()};
  };

  const companyData = getCompanyData();

  const getDefaultValue = (field, parent = null) => {
    if (parent) {
      return companyData?.[parent]?.[field];
    }
    return companyData?.[field];
  };

  const getFileDefault = (field, parent)=> {
    const savedFile = sessionStorage.getItem(`${parent}.${field}`);
    const savedName = sessionStorage.getItem(`${parent}.${field}_name`);
    if(savedFile) {
      return {id: 'file ready to upload', name: savedName};
    } else {
      const formData =  getDefaultValue(parent);
      return {
        id: formData?.[field] || formData?.fileId,
        name: formData?.fileName
      };
    }
  };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%',
    nested: true,
    parent: COMPANY_FIELDS.WORKERS_COMP.api_name
  };

  return (
    <>
      <Grid container maxWidth={'560px'} spacing={3}>
        <Grid item sm={12}>
          <Card className={classes.card_block}>
            <CardContent className={classes.half_width}>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.WORKERS_COMP_COMPANY}
                defaultValue={getDefaultValue(COMPANY_FIELDS.WORKERS_COMP_COMPANY.api_name, commonFieldProps.parent)}
                isEditable={true}

              />
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={{...COMPANY_FIELDS.WORKERS_COMP_CERT_NUMBER, ...{required: isRequired}}}
                defaultValue={getDefaultValue(COMPANY_FIELDS.WORKERS_COMP_CERT_NUMBER.api_name, commonFieldProps.parent)}
                isEditable={true}

              />
              <Box className={classes.two_col_container}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  field={COMPANY_FIELDS.WORKERS_COMP_AGENT_NAME}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.WORKERS_COMP_AGENT_NAME.api_name, commonFieldProps.parent)}
                  isEditable={true}

                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  inputType={inputTypes.phone}
                  field={COMPANY_FIELDS.WORKERS_COMP_AGENT_PHONE}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.WORKERS_COMP_AGENT_PHONE.api_name, commonFieldProps.parent)}
                  isEditable={true}

                />
              </Box>
              <CustomFormBlock
                {...props.inputDefaultProps}
                {...commonFieldProps}
                field={COMPANY_FIELDS.WORKERS_COMP_TYPE}
                inputType={inputTypes.singleSelect}
                options={Utils.getOptions(allConstants.WORKERS_COMP_TYPES)}
                defaultValue={getDefaultValue(COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name, commonFieldProps.parent) || allConstants.WORKERS_COMP_TYPES.REQUIRED.value}
                isEditable={true}

              />
              <Box className={clsx(classes.two_col_container, classes.file_and_date_row)}>
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  inputType={inputTypes.file}
                  field={{...COMPANY_FIELDS.WORKERS_COMP_CERT, ...{required: isRequired}}}
                  defaultValue={getFileDefault(COMPANY_FIELDS.WORKERS_COMP_CERT.api_name, commonFieldProps.parent)}
                  isEditable={true}
                  dontUploadImmediately={props.tabProps.dontUploadImmediately}

                />
                <CustomFormBlock
                  {...props.inputDefaultProps}
                  {...commonFieldProps}
                  inputType={inputTypes.date}
                  field={{...COMPANY_FIELDS.WORKERS_COMP_CERT_EXP_DATE, ...{required: isRequired}}}
                  defaultValue={getDefaultValue(COMPANY_FIELDS.WORKERS_COMP_CERT_EXP_DATE.api_name, commonFieldProps.parent)}
                  isEditable={true}


                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

WorkersComp.propTypes = companyPropTypes;
WorkersComp.defaultProps = companyDefaultProps;
