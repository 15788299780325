import {makeStyles} from '@mui/styles';
import * as STYLE_CONSTANTS from './styleConstants';
import myTheme from './myStyles';



const globalUseClasses = makeStyles((theme) => {
  theme = myTheme;
  return {
    label_bold: {
      fontWeight: 600,
    },
    eq_name: {
      fontWeight: 600,
      fontSize: '16px',
      [`${theme.breakpoints.only('xs')}`]: {
        fontSize: '14px',
      },
      marginBottom: '4px',
    },
    paper_style: {
      boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.12)',
      borderRadius: '10px',
      height: '100%'
    },
    pseudoLabel: {
      fontSize: '16px',
      fontWeight: 600,
      display: 'block',
      // marginBottom: theme.spacing(2),
      color: STYLE_CONSTANTS.COLORS.fontMain,
      position: 'relative',
      width: 'fit-content',
    },
    required: {
      '&::after': {
        content: '" *"',
        position: 'absolute',
        right: '-10px',
        color: 'red'
      }
    },
    nav_bar: {
      height: '55px',
      [`${theme.breakpoints.only('xs')} and (orientation: landscape)`]: {
        height: '60px'
      },
    },
    page: {
      paddingTop: `${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false, number: true})+3}px !important`,
      height: '100%',
      // [`${theme.breakpoints.only('xs')} and (orientation: landscape)`]: {
      //   height: 'calc(100vh - 60px)',
      //   paddingTop: '60px',
      // },
    },
    // customDenseFromControl: {
    //   marginBottom: '5px'
    // },
    input_label:{
      color:`${STYLE_CONSTANTS.COLORS.fontMain}`,
      fontWeight: 600,
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px'
      }
      //fontSize: isXS ? '14px' : 'initial',
    },
    border_rad_default: {
      borderRadius: '6px',
    },
    home_content_container: {
      height: '100%',
      [`${theme.breakpoints.only('md')} and (orientation: landscape)`]: {
        //height: 'calc(100vh - 76px)'
      },
      padding: `${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false, number: true})+10}px ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)}`,
      [theme.breakpoints.only('xs')]: {
        paddingTop: `${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false, number: true})+10}px`,
        padding: `${theme.spacing(2)}`
      },
      [theme.breakpoints.only('sm')]: {
        padding: `${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false, number: true})+10}px ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(4)}`,
      },
      [theme.breakpoints.up('lg')]: {
        //height: 'calc(100% - 76px)'
      },
      // paddingTop: isXS ? theme.spacing(2) : isSM?theme.spacing(2):theme.spacing(3),
      /// paddingRight: isXS ? theme.spacing(2) : isSM?theme.spacing(2):theme.spacing(3),
      // paddingBottom: isXS ? theme.spacing(2) : isSM?theme.spacing(2):theme.spacing(3),
      // paddingLeft: isXS ? theme.spacing(2) : isSM?theme.spacing(4):theme.spacing(6),

      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,

      // height: `calc(100% - ${STYLE_CONSTANTS.HEIGHT.NAVBAR(props)})`
    },
    content_container: {
      //paddingTop: theme.spacing(1),
      backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain,
      // paddingLeft: isXS ? theme.spacing(2) : isSM?theme.spacing(4):theme.spacing(6),
      //height: `calc(100vh - ${STYLE_CONSTANTS.HEIGHT.NAVBAR(props)})`,
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column'
    },
    grid_view: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      height: '100%'
    },
    two_col_container: {
      display: 'grid',
      maxWidth: '500px',
      gridAutoFlow: 'column',
      columnGap: theme.spacing(1),
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

      [theme.breakpoints.down('md')]: {
        gridAutoFlow: 'row',
      },
      [theme.breakpoints.only('md')]: {
        columnGap: theme.spacing(2),
      },
      [theme.breakpoints.only('lg')]: {
        columnGap: theme.spacing(3),
      },
      [theme.breakpoints.only('xl')]: {
        columnGap: theme.spacing(4),
      }
      // columnGap: `${(isXS || isSM)  ? theme.spacing(2) : (isMD|| isLG) ? theme.spacing(3):theme.spacing(4)}`,
    },
    file_and_date_row: {
      gridTemplateColumns: '2fr 1fr !important'
    },
    three_col_container: {
      display: 'grid',
      maxWidth: '500px',
      gridAutoFlow: 'column',
      columnGap: theme.spacing(1),
      gridTemplateColumns: '4fr 2fr 2fr',

      [theme.breakpoints.down('md')]: {
        gridAutoFlow: 'row',
      },
      [theme.breakpoints.only('md')]: {
        columnGap: theme.spacing(2),
      },
      [theme.breakpoints.only('lg')]: {
        columnGap: theme.spacing(3),
      },
      [theme.breakpoints.only('xl')]: {
        columnGap: theme.spacing(4),
      }
      // columnGap: `${(isXS || isSM)  ? theme.spacing(2) : (isMD|| isLG) ? theme.spacing(3):theme.spacing(4)}`,
    },

    info_paper: {
      [theme.breakpoints.up('xs')]: {
        padding:  theme.spacing(2)
      },
      [theme.breakpoints.between('md', 'lg')]: {
        padding:  theme.spacing(3)
      },
      [theme.breakpoints.only('xl')]: {
        padding:  theme.spacing(4)
      },
      // padding: (isXS || isSM)  ? theme.spacing(2) : (isMD|| isLG) ? theme.spacing(3):theme.spacing(4),
      paddingBottom: '0',
      height: '100%'
    },
    create_job_paper: {
      padding:  theme.spacing(2),

      [theme.breakpoints.only('md')]: {
        paddingLeft:  theme.spacing(6),
        paddingRight:  theme.spacing(6),
      },
      [theme.breakpoints.only('lg')]: {
        paddingLeft:  theme.spacing(8),
        paddingRight:  theme.spacing(8),
      },
      [theme.breakpoints.only('xl')]: {
        paddingLeft:  theme.spacing(10),
        paddingRight:  theme.spacing(10),
      },
      // padding: (isXS || isSM)  ? theme.spacing(2) : (isMD|| isLG) ? theme.spacing(3):theme.spacing(4),
      // paddingBottom: '0',
      height: '100%',
      marginTop: '10px'
    },
    paper_card: {
      padding:  theme.spacing(2),

      [theme.breakpoints.only('md')]: {
        paddingLeft:  theme.spacing(6),
        paddingRight:  theme.spacing(6),
      },
      [theme.breakpoints.only('lg')]: {
        paddingLeft:  theme.spacing(8),
        paddingRight:  theme.spacing(8),
      },
      [theme.breakpoints.only('xl')]: {
        paddingLeft:  theme.spacing(10),
        paddingRight:  theme.spacing(10),
      },
      // padding: (isXS || isSM)  ? theme.spacing(2) : (isMD|| isLG) ? theme.spacing(3):theme.spacing(4),
      // paddingBottom: '0',

    },
    form_group: {
      marginBottom: theme.spacing(2)
    },
    no_capitalize: {
      textTransform: 'none !important',
      '& .MuiInputBase-input': {
        textTransform: 'none !important'
      }
    },
    error_helper_text: {
      color: 'red',
      margin: '0 !important',
      backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain
    },
    logo: {
      width:  '110px',
      [theme.breakpoints.only('xs')]: {
        width: '100px',
      },
    },
    card_block: {
      boxShadow: '0px 2px 7px rgba(140, 164, 226, 0.25)',
      borderRadius: '12px'
    },
    half_width: {
      maxWidth: '500px',
      margin: '0 auto'
    },
    card_section_header: {
      fontSize: '12px',
      fontWeight: '900',
      marginBottom: theme.spacing(1.3)
    },
    uppercase: {
      textTransform: 'uppercase'
    },
    blue_text: {
      color: STYLE_CONSTANTS.COLORS.globalBlue
    },
    record_block: {
      border: `1px solid ${STYLE_CONSTANTS.COLORS.globalGreen}`,
      borderRadius: '8px',
    }

  };});

export {globalUseClasses};
