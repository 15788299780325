import {Button, Box, Grid, Typography} from '@mui/material';
import React, {useState} from 'react';
import AddButton from '../../../../pieces/addButton';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import AddAttachmentModal from '../addAttachmentModal';
import PropTypes, {any} from 'prop-types';
import MessageView from '../views/messageView';
import {JOB_FIELDS} from '../../../../constants/allConstants';
import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import PermissionGate from '../../../../permissions/permissionGate';
import ELEMENTS, {jobRules} from '../../jobsAccessControl';
import * as allConstants from '../../../../constants/allConstants';
import downloadFile from '../../../../utils/downloadFileUtil';

function Attachments(props) {

  const [showAddAttachment, setShowAddAttachment] = useState(false);
  const [attachments, setAttachments] = useState(props.attachments);
  const user = useSelector(state => state.user);
  const company = useSelector(state => state.company);

  const openAddAttachment = () => {
    setShowAddAttachment(true);
  };

  const closeAddAttachment = () => {
    setShowAddAttachment(false);
  };

  const downloadAllFiles = () => {
    for (const idx in attachments) {
      downloadFile({id: attachments[idx].fileId});
    }
  };

  const showNewAttachments = (data) => {
    const newAttachments = makeAttachmentObjects(data);
    setAttachments([...attachments, ...newAttachments]);
    getContractorAttachments();
  };

  const makeAttachmentObjects = (data) => {
    const name = user.userName.firstName + ' ' + user.userName.lastName;
    const attachmentObjects = data.attachments.map((attachment) => {
      const timeCreate = new Date(Date.now()).toISOString();
      const object = {
        id: uuidv4(),
        fileId: attachment.fileUploadId,
        fileName: attachment.fileName,
        type: attachment.type,
        createdAt: timeCreate,
        uploadedBy: {
          name: name,
          addedFrom: JOB_FIELDS.ATTACHMENT.ADDED_FROM.CONTRACTOR.label,
        }
      };
      return object;
    });

    return attachmentObjects;
  };

  const newMessageView = (attachment, index, count) => {
    let name = attachment.uploadedBy.name.split(' ');
    return <MessageView
      key={attachment.id}
      fileId={attachment.fileId}
      firstName={name[0]}
      lastName={name.slice(1).join(' ')}
      isLastChild={index + 1 === count}
      date={attachment.createdAt}
      message={attachment.fileName}
      role={attachment.uploadedBy.addedFrom}
      onClick={(id)=>downloadFile({id: id})}
    />;
  };

  const getIpermitAttachments = () => {
    let currentAttachments = attachments;
    const ipermitAttachments = currentAttachments.filter((attachment) => {
      return attachment.uploadedBy.addedFrom === JOB_FIELDS.ATTACHMENT.ADDED_FROM.IPERMIT.label;
    });

    if(ipermitAttachments && ipermitAttachments.length !== 0) {
      const attachmentCount = ipermitAttachments.length;
      currentAttachments = ipermitAttachments.map((attachment, index) => {
        return newMessageView(attachment, index, attachmentCount);
      });
    } else {
      currentAttachments = <Typography variant={'p'} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 'inherit'}}>No iPermit attachments added</Typography>;
    }
    if(!currentAttachments || currentAttachments.length === 0) return null;
    return (
      <>
        <Box>
          {currentAttachments}
        </Box>
      </>
    );
  };

  const getContractorAttachments = () => {
    let currentAttachments = attachments;
    const contractorAttachments = currentAttachments.filter((attachment) => {
      return attachment.uploadedBy.addedFrom === JOB_FIELDS.ATTACHMENT.ADDED_FROM.CONTRACTOR.label;
    });

    if(contractorAttachments && contractorAttachments.length !== 0) {
      const attachmentCount = contractorAttachments.length;
      currentAttachments = contractorAttachments.map((attachment, index) => {
        return newMessageView(attachment, index, attachmentCount);
      });
    } else {
      currentAttachments = <Typography variant={'p'} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 'inherit'}}>No Contractor attachments added</Typography>;
    }
    if(!currentAttachments || currentAttachments.length === 0) return null;
    return (
      <>
        <Box>
          {currentAttachments}
        </Box>
      </>
    );
  };

  const contractorAttachments = getContractorAttachments();
  const ipermitAttachments = getIpermitAttachments();

  return (
    <Box
      flexDirection={'column'}
      width={'100%'}
    >
      <AddAttachmentModal
        open={showAddAttachment}
        onClose={closeAddAttachment}
        addAttachments={showNewAttachments}
        jobId={props.id}
        jobType={props[JOB_FIELDS.JOB_TYPE.api_name]}
      />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Button
          variant='outlined'
          color={'primary'}
          onClick={downloadAllFiles}
        >PRINT ALL</Button>
        <PermissionGate
          modulePermissions={jobRules[ELEMENTS.addAttachment]}
          scope={allConstants.SCOPES.canView}
          blockForDeactivatedCompany={true}
          companyStatus={company[allConstants.COMPANY_FIELDS.STATUS.api_name]}
          role={user[allConstants.USER_FIELDS.ROLE.api_name]}>
          <AddButton
            action={openAddAttachment}
          />
        </PermissionGate>
      </Box>
      <Box backgroundColor={STYLE_CONSTANTS.COLORS.lightGrayHover} height={'1px'} my={1}/>
      <Grid container>
        <Grid item xs={12} sm={4}>
          {ipermitAttachments}
        </Grid>
        <Grid item xs={12} sm={8} sx={{
          '&::before': {
            background: `${STYLE_CONSTANTS.COLORS.lightGrayHover}`,
            width: '1px',
            height: '100%',
            // maxHeight: '100%',
            content: '""',
            position: 'absolute',
            transform: 'translateX(-1px)',
          }
        }}>
          {contractorAttachments}
        </Grid>
      </Grid>
    </Box>
  );
}

Attachments.propTypes = {
  id: PropTypes.string,
  attachments: PropTypes.arrayOf(any),
};

export default Attachments;
