import SendIcon from '@mui/icons-material/Send';
import {Button, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/system';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import base_url from '../../../../baseUrls';
import * as allConstants from '../../../../constants/allConstants';
import {useGetRole} from '../../../../hooks/useGetRole';
import PermissionGate from '../../../../permissions/permissionGate';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {NoDataStringWrapper} from '../../../../pieces/noDataStringWrapper';
import ApiUtils from '../../../../utils/apiUtils';
import Utils from '../../../../utils/utils';
import Loader from '../../../misc/loader';
import ELEMENTS, {jobRules} from '../../jobsAccessControl';
import {useJobDetailViewStyles} from '../views/jobDetailViewStyles';
import MessageView from '../views/messageView';
import NoteType from '../views/selectNoteTypeView';

const useStyles = makeStyles((theme) => ({
  note_input: {
    '& .MuiInputBase-inputMultiline': {
      width: '100% !important',
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
      '& textarea': {
        margin: 0,
      }
    }
  },
}));

function Notes(props) {

  const formOptions = {
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  };

  const jobType = props[allConstants.JOB_FIELDS.JOB_TYPE.api_name];

  let defaultNoteType = false;

  if(jobType === allConstants.JOB_TYPES.TESTING_ONLY.value) {
    defaultNoteType = true;
  }

  const companyAllowedTests = useSelector(state => state.company.allowedTests);
  const canSelectNoteType = (jobType === allConstants.JOB_TYPES.HVAC_RES.value && props.isHERSRequired === true && companyAllowedTests && Array.isArray(companyAllowedTests)
    && companyAllowedTests.includes(allConstants.TEST_TYPES.HERS.value));

  const messagesEndRef = useRef(null);
  const formMethods = useForm(formOptions);

  const watchNote = useWatch({
    control: formMethods.control,
    name: allConstants.JOB_FIELDS.NOTE.TEXT.api_name,
  });

  if(Utils.isDevEnv()) {
    window.noteForm = formMethods;
  }
  const classes = {...useStyles(), ...useJobDetailViewStyles()};
  const [showNoteSwitch, setShowNoteSwitch] = useState(false);

  const [noteType, setNoteType] = useState(defaultNoteType);
  const [fetching, setFetching] = useState(false);
  const user = useSelector(state => state.user);
  const company = useSelector(state => state.company);
  const role = useGetRole();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const messages = props.jobNotes;
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const openNoteSwitch = () => {
    setShowNoteSwitch(true);
  };

  const closeNoteSwitch = () => {
    setShowNoteSwitch(false);
  };

  const changeNoteTypeToggle = (event) => {
    setNoteType(event.target.checked);
  };

  const notesInputDefaultProps = {
    viewMode: false,
    renderLabel: true,
  };

  const makeNoteObject = (noteData) => {
    const timeCreate = new Date(Date.now()).toISOString();
    const noteObject = {
      id: noteData.jobId,
      note: noteData.note,
      type: noteData.type,
      createdAt: timeCreate,
      author: {
        firstName: user.userName.firstName,
        lastName: user.userName.lastName,
        role: allConstants.CONTRACTOR_DISPLAY_ROLES.CONTRACTOR.value,
      },
    };

    return noteObject;
  };

  const onSubmit = async (data) => {
    closeNoteSwitch();
    setNoteType(defaultNoteType);
    setFetching(true);

    const touchedFields = formMethods.formState.touchedFields;
    const cleanData = Utils.dirtyValues(touchedFields, data);
    const type = noteType ? allConstants.NOTE_TYPES.HERS.value : allConstants.NOTE_TYPES.PERMIT.value;
    const authToken = await ApiUtils.getAccessToken();

    if(!authToken) {
      return;
    }

    const noteData = {
      jobId: props.id,
      note: cleanData.note,
      type: type,
    };

    const response = await fetch(`${base_url.api}jobs/add-note`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(
          noteData
        ),
      }).catch((error) => {
      setFetching(false);
      console.log(error);
    });

    const responseJson = await response.json();

    Utils.handleToastMessage(responseJson, 'Note added', 'Please try adding note again', 'add_note');

    if (responseJson.status === 'success') {
      formMethods.reset();
      const note = makeNoteObject(noteData);
      props.addNote(note);
    }

    setFetching(false);
  };

  const getNotes = ()=> {
    let notes;

    if(props.jobNotes && props.jobNotes.length !== 0) {
      const notesCount = props.jobNotes.length;
      const jobNotes = props.jobNotes;
      notes = jobNotes.map((note, idx) => {
        return <MessageView key={note.id}
          firstName={note.author[allConstants.USER_FIELDS.FIRST_NAME.api_name]}
          isLastChild={idx+1=== notesCount}
          lastName={note.author[allConstants.USER_FIELDS.LAST_NAME.api_name]}
          date={note.createdAt}
          message={note.note}
          role={note.author.role}/>;
      });
    } else {
      notes =  <Box sx={{height: 'calc(48vh - 16px)'}}><NoDataStringWrapper text={'No notes added'}/></Box>;
    }
    if(!notes || notes.length === 0) return  null;
    return (
      <>
        <Box>
          {notes}
        </Box>
      </>
    );
  };

  const notes = getNotes();
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Box className={classes.scrollable_tab_content} display={'flex'} sx={{width: '100%'}}>
            <Box sx={{
              width: notes ? '95%' : '100%',
              // height: 'inherit',
            }}>
              {
                notes && <>
                  {notes}
                  <Box ref={messagesEndRef}/>
                </>

              }
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        { fetching && <Loader/> }
        <Grid item xs={12}>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)} method={'POST'}>
              <NoteType open={showNoteSwitch}
                onClose={closeNoteSwitch}
                toggle={noteType}
                onToggle={changeNoteTypeToggle}
                onSubmit={formMethods.handleSubmit(onSubmit)}/>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                sx={{
                  paddingBottom: 0,
                  '& .scrollable_tab_content': {
                    height: '40vh'
                  }
                }}
              >
                <PermissionGate
                  modulePermissions={jobRules[ELEMENTS.addNote]}
                  scope={allConstants.SCOPES.canView}
                  blockForDeactivatedCompany={true}
                  companyStatus={company[allConstants.COMPANY_FIELDS.STATUS.api_name]}
                  role={role}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={3}
                    sx={{
                      // width: 'calc(100% - 60px)',
                      // paddingBottom: '24px',
                      backgroundColor: '#fff'
                    }}
                    className={classes.note_input}
                  >
                    <CustomFormBlock
                      {...notesInputDefaultProps}
                      multiline
                      fullWidth
                      rowCount={1}
                      isEditable={!fetching}
                      field={allConstants.JOB_FIELDS.NOTE.TEXT}
                      renderLabel={false}
                      hideHelper={true}
                      maxWidth={'100%'}
                      placeholder={'Enter your message here'}
                    />
                    <Button
                      variant='contained'
                      disabled={fetching || (!watchNote || watchNote.length === 0)}
                      // onClick={()=> {console.log('clikc')}}
                      type={canSelectNoteType ? 'button': 'submit'}
                      onClick={canSelectNoteType ? openNoteSwitch: null}
                    >
                      <SendIcon/>
                    </Button>
                  </Box>
                </PermissionGate>
              </Box>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Box>
  );
}

Notes.propTypes = {
  id: PropTypes.string,
  jobNotes: PropTypes.instanceOf(Object).isRequired,
  addNote: PropTypes.func,
  isHERSRequired: PropTypes.bool,
};

export default Notes;
