/* eslint-disable react/prop-types */
import React from 'react';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import * as allConstants from '../../../constants/allConstants';
import Grid from '@mui/material/Grid';
import {Box, Typography, Card, CardContent} from '@mui/material';
import {useCompanyPageStyles} from '../companyPageStyles';
import {globalUseClasses} from '../../../styles/globalClasses';
import {inputTypes} from '../../../pieces/inputs/formInput';
import Utils from '../../../utils/utils';
import {companyDefaultProps, companyPropTypes} from './panelProps';
import {companyViewModes} from '../companyViewModes';
import {useFormContext} from 'react-hook-form';

export const CompanyInfo = (props)=> {
  const classes = {...useCompanyPageStyles(), ...globalUseClasses()};
  const COMPANY_FIELDS = allConstants.COMPANY_FIELDS;
  const formMethods = useFormContext();


  const isIpermitInvite = props.tabProps.isIpermitInvite;

  const getCompanyData = ()=> {
    const initialData = props.tabProps?.companyData || {};
    return {...initialData, ...formMethods.getValues()};
  };

  const companyData = getCompanyData();

  const getFileDefault = (field, parent)=> {
    const savedFile = sessionStorage.getItem(`${parent}.${field}`);
    const savedName = sessionStorage.getItem(`${parent}.${field}_name`);
    if(savedFile) {
      return {id: 'file ready to upload', name: savedName};
    } else {
      const formData =  getDefaultValue(parent);
      return {
        id: formData?.[field] || formData?.fileId,
        name: formData?.fileName
      };
    }
  };

  const getDefaultValue = (field, parent=null)=> {
    if(!field) return;
    if(parent) {
      return companyData?.[parent]?.[field];
    }
    return companyData?.[field];
  };

  const ipermiViewAdminBlock = () => {
    return (
      <>
        <Typography className={classes.card_section_header} >Administrator Information</Typography>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props.inputDefaultProps}
            field={COMPANY_FIELDS.ADMIN_NAME_FIRST}
            nested={true}
            parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
            fullWidth={true}
            isEditable={false}
            defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
          />

          <CustomFormBlock
            {...props.inputDefaultProps}
            field={COMPANY_FIELDS.ADMIN_NAME_LAST}
            nested={true}
            parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
            fullWidth={true}
            isEditable={false}
            defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_NAME_LAST.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
          />
        </Box>
        <CustomFormBlock
          {...props.inputDefaultProps}
          inputType={inputTypes.phone}
          field={COMPANY_FIELDS.ADMIN_PHONE}
          fullWidth={true}
          isEditable={false}
          nested={true}
          parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
          defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_PHONE.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
        />
        <CustomFormBlock
          {...props.inputDefaultProps}
          field={COMPANY_FIELDS.ADMIN_EMAIL}
          fullWidth={true}
          isEditable={false}
          nested={true}
          parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
          defaultValue={getDefaultValue(COMPANY_FIELDS.EMAIL.api_name,  COMPANY_FIELDS.ADMIN_INFO.api_name)}
        />
      </>
    );
  };

  const AdminInfoBlock = (header, isEditable) => {
    return (
      <>
        <Typography className={classes.card_section_header} >{header}</Typography>
        <Box className={classes.two_col_container}>
          <CustomFormBlock
            {...props.inputDefaultProps}
            field={COMPANY_FIELDS.ADMIN_NAME_FIRST}
            nested={true}
            parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
            fullWidth={true}
            isEditable={isEditable}
            defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
          />

          <CustomFormBlock
            {...props.inputDefaultProps}
            field={COMPANY_FIELDS.ADMIN_NAME_LAST}
            nested={true}
            parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
            fullWidth={true}
            isEditable={isEditable}
            defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_NAME_LAST.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
          />
        </Box>
      </>
    );
  };

  const stateLicenseCard = <Card className={classes.card_block} sx={{height: '100%'}}>
    <CardContent className={classes.half_width}>
      <CustomFormBlock
        {...props.inputDefaultProps}
        inputType={inputTypes.file}
        field={{...COMPANY_FIELDS.STATE_LICENSE_FILE, ...{required: !isIpermitInvite}}}
        nested={true}
        renderLabel={true}
        parent={COMPANY_FIELDS.STATE_LICENSE.api_name}
        fullWidth={true}
        defaultValue= {getFileDefault(COMPANY_FIELDS.STATE_LICENSE_FILE.api_name, COMPANY_FIELDS.STATE_LICENSE.api_name)}
        dontUploadImmediately={props.tabProps.dontUploadImmediately}

      />
      <Box className={classes.two_col_container}>
        <CustomFormBlock
          {...props.inputDefaultProps}
          field={{...COMPANY_FIELDS.STATE_LICENSE_NUMBER, ...{required: !isIpermitInvite}}}
          nested={true}
          parent={COMPANY_FIELDS.STATE_LICENSE.api_name}
          fullWidth={true}
          isEditable={props.tabProps.companyViewMode !== companyViewModes.selfRegistering && !isIpermitInvite}
          defaultValue={getDefaultValue(COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name, COMPANY_FIELDS.STATE_LICENSE.api_name)}

        />

        <CustomFormBlock
          {...props.inputDefaultProps}
          inputType={inputTypes.date}
          field={{...COMPANY_FIELDS.STATE_LICENSE_EXPIRES_AT, ...{required: !isIpermitInvite}}}
          nested={true}
          parent={COMPANY_FIELDS.STATE_LICENSE.api_name}
          fullWidth={true}
          defaultValue={getDefaultValue(COMPANY_FIELDS.STATE_LICENSE_EXPIRES_AT.api_name, COMPANY_FIELDS.STATE_LICENSE.api_name)}

        />
      </Box>
      <Box className={classes.two_col_container}>
        <CustomFormBlock
          {...props.inputDefaultProps}
          field={COMPANY_FIELDS.FEDERAL_ID}
          fullWidth={true}
          defaultValue={getDefaultValue(COMPANY_FIELDS.FEDERAL_ID.api_name)}

        />
        <CustomFormBlock
          {...props.inputDefaultProps}
          field={COMPANY_FIELDS.CHEERS_USERNAME}
          fullWidth={true}
          defaultValue={getDefaultValue(COMPANY_FIELDS.CHEERS_USERNAME.api_name)}

        />
      </Box>
    </CardContent>
  </Card>;

  const authLetterCard = <Card className={classes.card_block} sx={{height: '100%'}}>
    <CardContent className={classes.half_width}>
      <CustomFormBlock
        {...props.inputDefaultProps}
        field={COMPANY_FIELDS.MAIN_CONTACT}
        renderLabel={true}
        fullWidth={true}
        defaultValue={getDefaultValue(COMPANY_FIELDS.MAIN_CONTACT)}

      />
      <Box className={classes.two_col_container}>
        <CustomFormBlock
          {...props.inputDefaultProps}
          inputType={inputTypes.file}
          field={COMPANY_FIELDS.AUTH_LETTER_FILE}
          nested={true}
          renderLabel={true}
          parent={COMPANY_FIELDS.AUTH_LETTER.api_name}
          fullWidth={true}
          defaultValue={getFileDefault(COMPANY_FIELDS.AUTH_LETTER_FILE.api_name, COMPANY_FIELDS.AUTH_LETTER.api_name)}
          dontUploadImmediately={props.tabProps.dontUploadImmediately}

        />

        <CustomFormBlock
          {...props.inputDefaultProps}
          inputType={inputTypes.date}
          field={COMPANY_FIELDS.AUTH_LETTER_EXPIRES_AT}
          nested={true}
          renderLabel={true}
          parent={COMPANY_FIELDS.AUTH_LETTER.api_name}
          fullWidth={true}
          defaultValue={getDefaultValue(COMPANY_FIELDS.AUTH_LETTER_EXPIRES_AT.api_name, COMPANY_FIELDS.AUTH_LETTER.api_name)}

        />
      </Box>
      <Box className={classes.two_col_container}>
        <CustomFormBlock
          {...props.inputDefaultProps}
          field={COMPANY_FIELDS.MAIL_PACKET_TO}
          fullWidth={true}
          renderLabel={true}
          defaultValue={getDefaultValue(COMPANY_FIELDS.MAIL_PACKET_TO.api_name)}

        />
        <CustomFormBlock
          inputType={inputTypes.singleSelect}
          options={Utils.getOptions(allConstants.EMAIL_PREFERENCES)}
          {...props.inputDefaultProps}
          field={COMPANY_FIELDS.EMAIL_PREFERENCES}
          defaultValue={getDefaultValue(COMPANY_FIELDS.EMAIL_PREFERENCES.api_name)}
          preventCapitalize
          fullWidth/>
      </Box>
    </CardContent>
  </Card>;

  const adminCardSelfRegister =  <Card className={classes.card_block}>
    <CardContent height={'100%'} className={classes.half_width}>
      {AdminInfoBlock('Administrator Information', true)}

      <CustomFormBlock
        {...props.inputDefaultProps}
        field={COMPANY_FIELDS.EMAIL}
        fullWidth={true}
        isEditable={true}
        defaultValue={getDefaultValue(COMPANY_FIELDS.EMAIL.api_name)}
      />

      <CustomFormBlock
        {...props.inputDefaultProps}
        field={allConstants.USER_FIELDS.PASSWORD}
        fullWidth={true}
        isEditable={true}
        inputType={inputTypes.password}
        confirmIsRequired={true}
        twoColumns={true}
        hideHelper={true}
      />
      <CustomFormBlock
        {...props.inputDefaultProps}
        field={COMPANY_FIELDS.ADMIN_PHONE}
        fullWidth={true}
        nested={true}
        parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
        defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_PHONE.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
        isEditable={true}
        inputType={inputTypes.phone}
      />
    </CardContent>
  </Card>;

  const adminCardCompleteOnboarding = <Card className={classes.card_block}>
    <CardContent height={'100%'} className={classes.half_width}>
      {AdminInfoBlock('Administrator Information', true)}
      <CustomFormBlock
        {...props.inputDefaultProps}
        field={COMPANY_FIELDS.EMAIL}
        fullWidth={true}
        isEditable={false}
        nested={true}
        parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
        defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_EMAIL.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
      />
      <CustomFormBlock
        {...props.inputDefaultProps}
        field={COMPANY_FIELDS.ADMIN_PHONE}
        fullWidth={true}
        nested={true}
        parent={COMPANY_FIELDS.ADMIN_INFO.api_name}
        defaultValue={getDefaultValue(COMPANY_FIELDS.ADMIN_PHONE.api_name, COMPANY_FIELDS.ADMIN_INFO.api_name)}
        isEditable={true}
        inputType={inputTypes.phone}
      />
    </CardContent>
  </Card>;

  const locationCard =   <Card className={classes.card_block} sx={{
    height: '100%'}}>
    <CardContent className={classes.half_width}>
      {props.tabProps.companyViewMode === companyViewModes.selfRegistering && <>
        <CustomFormBlock
          {...props.inputDefaultProps}
          field={COMPANY_FIELDS.NAME}
          isEditable={true}
          fullWidth={true}
          defaultValue={getDefaultValue(COMPANY_FIELDS.NAME.api_name)}
        />

      </>}
      {props.tabProps.companyViewMode !== companyViewModes.selfRegistering &&
      props.tabProps.companyViewMode !== companyViewModes.completeOnboarding &&
      !isIpermitInvite && ipermiViewAdminBlock()}


      {!isIpermitInvite && <CustomFormBlock
        inputType={inputTypes.singleSelect}
        options={Utils.getOptions(allConstants.PAYMENT_METHODS)}
        {...props.inputDefaultProps}
        field={COMPANY_FIELDS.PAYMENT_METHOD}
        defaultValue={getDefaultValue(COMPANY_FIELDS.PAYMENT_METHOD.api_name)}
        isEditable={true}
        preventCapitalize={true}
        fullWidth={true}/>
      }

      <Typography className={classes.card_section_header} >Location Information</Typography>

      <CustomFormBlock
        {...props.inputDefaultProps}
        inputType={inputTypes.address}
        field={COMPANY_FIELDS.ADDRESS}
        fullWidth
        renderLabel={false}
        defaultValue={getDefaultValue(COMPANY_FIELDS.ADDRESS.api_name)}

      />
      <CustomFormBlock  {...props.inputDefaultProps}
        inputType={inputTypes.phone}
        field={COMPANY_FIELDS.PHONE_NUMBER}
        fullWidth
        defaultValue={getDefaultValue(COMPANY_FIELDS.PHONE_NUMBER.api_name)}
        isEditable={true}
      />

    </CardContent>
  </Card>;

  const firstColHasOneCard = !props.tabProps.isSelfRegistering && !props.tabProps.isCompleteonboarding;
  if(firstColHasOneCard) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <Grid container height={'100%'} flex={1} spacing={0}>
              <Grid item xs={12}>
                {locationCard}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {stateLicenseCard}
              </Grid>
              <Grid item xs={12}>
                {authLetterCard}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} >
          <Grid container flexDirection={'row'} spacing={3}>
            <Grid item xs={12} md={6}>
              {props.tabProps.isSelfRegistering && adminCardSelfRegister}
              {props.tabProps.isCompleteonboarding && adminCardCompleteOnboarding}
            </Grid>
            <Grid item xs={12} md={6} flex={1}>
              {stateLicenseCard}
            </Grid>

          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container flexDirection={'row'} spacing={3}>
            <Grid item xs={12} md={6}>
              {locationCard}
            </Grid>
            <Grid item xs={12} md={6} flex={1}>
              {authLetterCard}
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </>

  );
};

CompanyInfo.propTypes = companyPropTypes;
CompanyInfo.defaultProps = companyDefaultProps;
