import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {urlSearchParams} from '../../../baseUrls';
import * as allConstants from '../../../constants/allConstants';
import Utils from '../../../utils/utils';
import AllInspectionsView from './listViews/inspections/allInspectionsView';
import {ThemeProvider} from '@mui/material';
import {useGridViewState} from '../../../hooks/useGridViewState';
import NavBar from '../../../pieces/navbar/navbar';
import {PermitListViews} from './listViews/permits/permitListViews';
import myTheme from '../../../styles/myStyles';


export const ModuleView = (props) => {
  const [module, setModule] = React.useState(Utils.getUrlParameter(urlSearchParams.moduleName));
  const location= useLocation();
  const viewController = useGridViewState();

  useEffect(() => {
    setModule(Utils.getUrlParameter(urlSearchParams.moduleName));
  }, [location]);

  const getPage = () => {
    if(module === allConstants.IPERMIT_MODULES.INSPECTION) {

      return <AllInspectionsView/>;
    }
    return <PermitListViews/>;
  };

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          {getPage()}
        </ThemeProvider>



      </>)
  );
};
