import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import {Provider} from 'react-redux';
import App from './App';
import {Toaster} from 'react-hot-toast';



ReactDOM.render(
  <Provider store={store}>
    <Toaster toastOptions={{
      className: '',
      style: {
        border: '1px solid #713200',
        padding: '16px',
        color: '#713200',
      },
    }}/>
    <App />

  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
