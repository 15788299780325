import PropTypes from 'prop-types';
import {unwrapResult} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const unwrapRequestResult = (props)=>{
  try{
    const promiseResult = unwrapResult(props.result);
    if(props.showSuccess) {

      toast.success(props.successMessage ? props.successMessage : 'Success', {
        toastId: `success_${props.id}`
      });
    }
    return promiseResult;

  } catch (e) {
    console.log(`Error on ${props.id}:::: `);
    console.log('e', e);
    toast.error(props.errorMessage ? props.errorMessage : e.message, {
      toastId: `error_${props.id}`
    });
    return e;
  }
};
unwrapRequestResult.propTypes = {
  props: PropTypes.shape({
    showSuccess: PropTypes.bool,
    id: PropTypes.string.isRequired,
    successMessage: PropTypes.string,

  })
};

export {unwrapRequestResult};

