import React from 'react';
import PropTypes from 'prop-types';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import styled from 'styled-components';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';

export const AlpineContainer = (props) => {
  // const isXS = (theme) => theme.breakpoints.is('xs');
  // const queries = {isXS: isXS(myTheme)};
  // console.log('QQQ', queries);
  return (
    <StyledGridContainer
      // queries={queries}
      id="ipermit-grid-container"
      className={`${props.hideDefaultGrid ? '' : 'ag-theme-alpine'}${props.className ? ` ${props.className}` : ''}`}
      //@ts-ignore
      hidedefaultgrid={props?.hideDefaultGrid}
    >
      {props.children}
    </StyledGridContainer>
  );
};


const StyledGridContainer = styled.div`
  //height: calc(100vh - 100px);
  border-bottom: 2px solid #f4f7fd;
  width: 100%;
  height: calc(100vh - ${(props)=>STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false})} - 50px - 20px - ${(props)=>STYLE_CONSTANTS.HEIGHT.PAGINATOR(props.queries)}); // window height - [navbar] - [header] -[padding] - [paginator]
 
`;

AlpineContainer.propTypes = {
  hideDefaultGrid: PropTypes.bool,
  className: PropTypes.string,
};
AlpineContainer.defaultProps = {
  hideDefaultGrid: false,
  className: undefined,
};
