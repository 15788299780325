import React, {useState} from 'react';
import {
  Box,
  Typography,
  Button
} from '@mui/material';
import {globalUseClasses} from '../../styles/globalClasses';
import {useStylesLogin} from './loginStyles';
import Utils from '../../utils/utils';
import {SingInPageWrapper} from './singInPageWrapper';

import {useDispatch} from 'react-redux';
import actions from '../../redux/actions';
import {useForm} from 'react-hook-form';
import * as companySlice from '../../redux/companies/companySlice';
import {toast} from 'react-toastify';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {ShowPassword} from './pieces/showPasswordIcon';
import CustomIcon from '../../static/icons/Icon';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import {AuthFormFooter} from './pieces/authFormFooter';
import {CustomInput} from '../../pieces/inputs/customInputComponent';


const LoginPage = () => {
  // console.log('RENDER LOGIN');
  const views = {
    login: 'login',
    forget: 'forget',
  };
  const [show, setShow] = useState(views.login);
  //const [resetSent, setResetSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = {...useStylesLogin(), ...globalUseClasses()};
  const dispatch = useDispatch();
  const {register, handleSubmit} = useForm();
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSetForget = ()=> {
    setShow(views.forget);
    dispatch(actions.clearState());
  };

  const handleBackToLogin = ()=> {
    setShow(views.login);
    dispatch(actions.clearState());
  };

  const handleReset = async (email)=> {
    // eslint-disable-next-line no-unused-vars
    const signInStatus = await dispatch(actions.forgotUserPassword({email}));
    try{
      toast.success('Password reset link sent to your given email address', {
        autoClose: true,
        hideProgressBar: true,
        toastId: 'reset-password-success'
        //onClose: ()=>setShow(lo)
      });
    } catch (e) {
      toast.error(e.message, {
        toastId: 'reset-password-error'
      });
    }
  };

  const handleSignIn = async (email, password)=> {

    const signInStatus = await dispatch(actions.signInUser({email, password}));
    const unwrapped = unwrapRequestResult({
      result: signInStatus,
      id: 'user_login'
    });
    if(unwrapped) {
      if (Utils.objectHasProperty(unwrapped, 'company') && unwrapped.company) {
        dispatch(companySlice.updateCompanyState(unwrapped.company));
      }

    }
    //const stateLicenseNumber = Utils.getUrlParameter(urlSearchParams.stateLicenseNumber);
  };

  return (

    <SingInPageWrapper>
      {show !== views.reset && <>
        <Typography variant={'h2'} className={classes.form_header}>{show===views.forget ? 'Forgot Password' : 'Sign In'}</Typography>
        <Box
          component="form"
          noValidate
          // autoComplete="off"
        >
          <Box>
            <Box display={'flex'} flexDirection={'column'} className={classes.form_group}>
              <span className={classes.input_label}>Email</span>
              <CustomInput
                id="login_email"
                placeholder={'john@example.com'}
                variant={'outlined'}
                size="small"
                inputProps={{'data-testid': 'login_email'}}
                {...register('email')}
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'} className={classes.form_group}>
              {show === views.login && <span className={classes.input_label}>Password</span>}
              {show=== views.login && <CustomInput
                variant="outlined"
                size="small"
                type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                {...register('password')}
                endAdornment={<ShowPassword show={showPassword} handleShowPassword={handleShowPassword}/>}
              />}
            </Box>
          </Box>

          <Box mt={1}>
            {show === views.login && <Button
              variant="contained"
              type="submit"
              color={'primary'}
              fullWidth
              onClick={handleSubmit((data) => {
                handleSignIn(data.email, data.password);
              })}
            >
          Sign in
            </Button>}
            {show === views.login && <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} pt={2}>
              <Button disableRipple
                className={classes.button_simple}
                style={{paddingLeft: 0, color: STYLE_CONSTANTS.COLORS.linkColor}}
                onClick={handleSetForget}
                onMouseDown={handleSetForget}>
                Forgot Password?
              </Button>
            </Box>}
            {show === views.forget &&
              <>
                <Box>
                  <Button variant="contained"
                    color={'primary'}
                    fullWidth
                    onClick={handleSubmit((data) => {
                      handleReset(data.email);
                    })}>
                  Reset Password
                  </Button>
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} pt={2} alignItems={'center'}>
                  <CustomIcon
                    icon={`${'arrow_left'}`}
                    color={STYLE_CONSTANTS.COLORS.globalBlue}
                    fill={'none'}
                    style={{
                      marginRight: '8px'
                    }}

                  />
                  <Button disableRipple
                    className={classes.button_simple}
                    style={{paddingLeft: 0}}
                    onClick={handleSetForget}
                    onMouseDown={handleBackToLogin}>
                    Back to login
                  </Button>
                </Box>
              </>
            }

          </Box>
          {show !== views.forget && <AuthFormFooter isSignUpPage={false}/>}

        </Box>
      </>}

    </SingInPageWrapper>


  );
};
export default LoginPage;
