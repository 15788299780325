import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, CssBaseline, Drawer, Grid, List, ListItem, ListItemButton, ListItemText, ListItemAvatar} from '@mui/material';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import ErrorIcon from '../../static/images/alert-circle.svg';
import Utils from '../../utils/utils';
import {makeStyles} from '@mui/styles';
import {useSearchParams, useNavigate} from 'react-router-dom';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import GoBackFormButton from '../../pieces/goBackFormButton';
import * as allConstants from '../../constants/allConstants';
import {useFormContext} from 'react-hook-form';
import {CustomMediaQuery} from '../../hooks/customMediaQuery';
import Loader from '../misc/loader';
const useStyles = makeStyles(theme => {

  return {
    error_tab: {
      borderColor: STYLE_CONSTANTS.COLORS.globalRed
    }
  };
});

export const drawerWidth = (query)=> query.isLG ? 240 : 200;

export const RecordBodyWrapper = (props)=> {

  const tabs = props.tabs;
  const urlParam = 'open';
  const [searchParams, setSearchParams] = useSearchParams();


  const getData = (panel)=> {
    return Object.values((tabs)).sort((a, b) => a.order-b.order).map((tab)=> {
      if(panel) {
        return tab.panel;
      }
      return {
        text: tab.name,
        fieldsToValidate: tab.fieldsToValidate,
        api_name: tab?.api_name
      };
    });
  };

  const formContext = useFormContext();

  const {errors} = formContext ? formContext.formState : {};


  // const {formState: {errors}} = formContext;

  const tabHasErrors = (tab)=> {
    const tabErrors = tab.fieldsToValidate.filter((field)=> Object.keys(errors).includes(field));
    return tabErrors.length > 0;
  };

  const currentTabs = getData();
  const currentPanels = getData(true);

  const getSelectedTab = ()=> {
    const selectedTab = Utils.getUrlParameter(urlParam);
    if(!selectedTab || !currentTabs || !Array.isArray(currentTabs)) return 0;
    const tab = currentTabs.find((tab)=> tab.api_name === selectedTab);

    return tab ? currentTabs.indexOf(tab) : 0;
  };

  const [openTab, setOpenTabs] = useState(getSelectedTab());

  const handleTabClickOpen = (index)=> {
    if(!props.preventSwitchTabsOnEdit) {
      setOpenTabs(index);
    } else if(props.setSwitchTabsWarning) {
      props.setSwitchTabsWarning(true);
    }
  };

  const navigate = useNavigate();
  const classes = {...useStyles()};

  useEffect(()=> {
    //handle tabs navigation
    searchParams.delete(urlParam);

    searchParams.append(urlParam, currentTabs[openTab]?.api_name);
    setSearchParams(searchParams);
    return ()=> {
      searchParams.delete(urlParam);
    };
  }, [openTab]);

  useEffect(()=> {
    //on browser go back if no tab open go to list view
    const currTab = searchParams.get(urlParam);
    if(!currTab) {
      searchParams.delete(urlParam);
      navigate(props.goBack?.show ? props.goBack?.path : allConstants.PATH.HOME);
    }
  }, [searchParams]);


  const drawer = (
    <>
      {props.goBack?.show && <Box display={'flex'} sx={{
        width:'100%',
        height: props.headerHeight,
        padding: '8px 16px',
        alignItems: 'center'
      }}>

        <GoBackFormButton
          path={props.goBack?.path}
          view={props.goBack.view}/>

      </Box>}
      <List>
        {currentTabs.map((tab, index) => {
          const isErrors = tabHasErrors(tab);
          return (
            <ListItem key={tab.text+index}>
              <ListItemButton onClick={()=> handleTabClickOpen(index)} selected={openTab === index}
                className={isErrors ? classes.error_tab : ''}
                sx={{
                  border: '1px solid white',
                  '&:hover': {
                    backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain
                  },
                  '& .MuiTypography-root': {
                    fontSize: '13px'
                  },
                  '&.Mui-selected': {
                    borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
                    backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
                    '& .MuiTypography-root': {
                      color: '#fff'
                    },
                    '&:hover': {
                      backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
                    }
                  },
                  '& .MuiListItemAvatar-root': {
                    minWidth: '10px'
                  },
                  borderRadius: '12px',
                }}>
                <ListItemText primary={tab.text} />
                {isErrors && <ListItemAvatar>
                  <span><img src={ErrorIcon} alt=""/></span>
                </ListItemAvatar>}
              </ListItemButton>
            </ListItem>
          );
        }

        )}
      </List>
    </>
  );
  const mainHeight = props.hideNavbar ? `calc(100vh - ${props.headerHeight})` : `calc(100vh - ${props.headerHeight} - ${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false})})`;
  const query = CustomMediaQuery();
  return (
    <Box sx={{display: 'flex', width: '100%'}}>
      <CssBaseline />
      <Box
        // component="nav"
        sx={{flexShrink: {sm: 0},
          '& .MuiDrawer-paper': {
            top: `${STYLE_CONSTANTS.HEIGHT.NAVBAR({isXS: false, number: true})+5}px`,
          }
        }}
        aria-label="page sections"
      >
        <Drawer
          variant="permanent"
          sx={{

            display: {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth(query)},
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3, py: 2,
          mt: props.hideNavbar? 'initial' : STYLE_CONSTANTS.HEIGHT.NAVBAR(),
          width: {sm: `calc(100% - ${drawerWidth(query)}px)`},
          backgroundColor: '#fff',
          height: mainHeight,
          overflowY: 'scroll'
        }}
      >
        {props.showLoader && <Box sx={{
          position: 'absolute',
          top:0,
          left:0,
          right:0,
          bottom:0,
          zIndex: 100,
          padding: 0,
          backgroundColor: STYLE_CONSTANTS.COLORS.white
        }}>
          <Loader useOwnWrapper/>
        </Box>
        }

        <Grid container height={'100%'}>
          {props.showTimeline && props.timeline && <Grid item xs={12} sx={{
            textTransform: 'none'
          }}>
            {props.timeline}
          </Grid>}
          {!props.showTimeline && <Grid item xs={12}>
            {currentPanels[openTab]}
          </Grid>}
          <Grid item xs={12}>
            <Box display={'flex'}
              justifyContent={'center'}
              alignItems={'flex-end'}
              height={'100%'}
              minHeight={'32px'}
            >
              {props.submitBlockOptions?.show &&<FormSubmitButtons
                cancelText={props.submitBlockOptions.cancelText}
                submitText={props.submitBlockOptions.submitText}
                cancelAction={props.submitBlockOptions.cancelAction}
                extraButton={props.submitBlockOptions.extraButton.show ? props.submitBlockOptions.extraButton : null}
              />}


            </Box>
          </Grid>
        </Grid>

      </Box>
    </Box>
  );
};

RecordBodyWrapper.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  goBack: PropTypes.shape({
    show: PropTypes.bool,
    path: PropTypes.string.isRequired,
    view: PropTypes.string,
  }),
  showLoader: PropTypes.bool,
  hideNavbar: PropTypes.bool,
  headerHeight: PropTypes.string,
  submitBlockOptions: PropTypes.shape({
    show: PropTypes.bool,
    cancelText: PropTypes.string,
    submitText: PropTypes.string,
    cancelAction: PropTypes.func,
    extraButton: PropTypes.shape({
      show: PropTypes.bool
    }),

  }),
  preventSwitchTabsOnEdit: PropTypes.bool,
  setSwitchTabsWarning: PropTypes.func,
  showTimeline: PropTypes.bool,
  timeline: PropTypes.element,
};

RecordBodyWrapper.defaultProps = {
  goBack: {
    show: false,
    view: null
  },
  showLoader: false,
  hideNavbar: false,
  headerHeight: null,
  submitBlockOptions: {
    show: false,
    cancelText: null,
    submitText: null,
    cancelAction: () => {
    },
    extraButton: {
      show: false
    }

  },
  preventSwitchTabsOnEdit: false,
  setSwitchTabsWarning: null,
  showTimeline: false,
  timeline: null
};
