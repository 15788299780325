/* eslint  no-unused-vars: 0 */
import React, {useEffect, useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import {AlpineContainer} from './gridContainer';
import axios from 'axios';
import ApiUtils from '../../utils/apiUtils';
import Utils from '../../utils/utils';
import {useSearchParams} from 'react-router-dom';
import * as ReactDOMServer from 'react-dom/server';
import {Button} from '@mui/material';
import {Class} from '@mui/icons-material';
const _ = require('lodash');


require('./grid-styles.scss');


const MyPaginatedGrid = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dragStart, setDragStart] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [currentPage, setCurrentPage] = useState(props.pagination.currentPage);
  const [itemsPerPage, setItemsPerPage] = useState(props.pagination.itemsPerPage);
  const currUrlSearchParams = useRef(props.defFilterParams?.[0]);
  const additionalFilters = useRef(props.additionalFilters);

  const defaultColDef = useMemo( ()=> ({
    flex: 1,
    filter: true,
    resizable: true,
    sortable: true,
    floatingFilter: true,
    suppressMenu: true
  }), []);

  const formatFilterModel = (model) => {
    for(const value of Object.values(model)) {
      if(value.dateFrom) value.dateFrom = Utils.formatLocalTimeToIsoString(value.dateFrom, true);
      if(value.dateTo) value.dateTo = Utils.formatLocalTimeToIsoString(value.dateTo, true);
    }

    return model;
  };

  const onColumnMoved =useMemo(()=>(params)=> {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem(props.ls_name, columnState);

  },[]);

  const onCellDoubleClicked = useMemo(()=>(params)=> {
    if(props.onCellDoubleClicked) {
      props.onCellDoubleClicked(params);
    }
  },[]);

  const onGridReady = useMemo(()=>(params)=> {
    setGridApi(params.api);
    if(props.defFilterParams && Array.isArray(props.defFilterParams) && props.defFilterParams.length === 2){
      try{
        setTimeout(()=> {
          var filterComponent = params.api.getFilterInstance(props.defFilterParams[0]);
          filterComponent.setModel({
            filterType: 'set',
            values:[props.defFilterParams[1]]
          });
          params.api.eventService.addEventListener('filterModified', (e) => {
            removeQueryParams(currUrlSearchParams.current);
            currUrlSearchParams.current = null;
          });
          filterComponent.onFilterChanged();
        },150);

      }catch (e){}
    }

    props.registerGrid(params);
    let columnState = JSON.parse(localStorage.getItem(props.ls_name));

    if (columnState) {
      params.columnApi.applyColumnState({state:columnState, applyOrder: true});
    }
  },[]);



  useEffect(()=>{
    if(!gridApi) return;
    additionalFilters.current = props.additionalFilters;
    gridApi.onFilterChanged();
  },[props.additionalFilters]);

  const portalFiltersToAgGridModel = ()=> {
    const agGridFilters = {};
    if(!additionalFilters.current) return agGridFilters;

    for (const [field, filter] of Object.entries(additionalFilters.current)) {
      if(filter.param === 'empty' || filter.param === 'notEmpty') {
        agGridFilters[field] = {
          type: filter.param,
          filterType: filter.type
        };
      } else if(filter.value && filter.value.length !== 0){
        agGridFilters[field] = {
          type: filter.param,
          filterType: filter.type,
          filter: filter.value
        };
      }

    }
    return agGridFilters;

  };

  const getServerSideDataSource = useMemo(()=>(fetchParams, companyId, limit = 10) => {
    const api = fetchParams.url;
    const options = fetchParams.options;

    return {
      getRows: async (params) => {
        let filterModel;
        if(currUrlSearchParams.current){
          const instance = params.api.getFilterInstance(currUrlSearchParams.current);
          filterModel = {[currUrlSearchParams.current]: instance.appliedModel};
        } else {
          filterModel = params.request?.filterModel || {};
        }
        const fetch = async ()=> {
          if(gridApi) gridApi.showLoadingOverlay();

          const page = Math.ceil(params.request.endRow / limit);
          filterModel = {...formatFilterModel(filterModel),...portalFiltersToAgGridModel()};

          let requestParams = {limit: limit, page: page, filterModel: filterModel};
          if(options?.status) {
            requestParams = {...requestParams, status: options.status};
          }

          if(companyId) {
            requestParams = {...requestParams, companyId: companyId};
          }
          let url = `${api}`;

          const authToken = await ApiUtils.getAccessToken();
          const userList = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
          return userList.data.data;
        };
        props.pagination.setCurrentPage(undefined);
        props.pagination.setTotalPageCount(undefined);
        props.pagination.setTotalItemsCount(undefined);

        fetch()
          .then((data)=> {

            if(gridApi) gridApi.hideOverlay();
            const modifiedList = data[props.fetchParams.respKey].map((el) => {
              el = props.modifyDataElement ? props.modifyDataElement(el) : el;
              return el;
            });

            if(gridApi && (!modifiedList || modifiedList.length === 0)) {
              gridApi.showNoRowsOverlay();
            }

            props.pagination.setCurrentPage(data.currentPage);
            props.pagination.setTotalPageCount(data.pagesCount);
            props.pagination.setTotalItemsCount(data.totalRecords);
            params.success({rowData: modifiedList, rowCount: data.totalRecords});
          })
          .catch((e)=> {
            if(gridApi) {
              gridApi.showNoRowsOverlay();
            }
            console.log('FAILED TO FETCH');
            console.log('error', e);
            params.fail();
            props.pagination.setCurrentPage(0);
            props.pagination.setTotalPageCount(0);
            props.pagination.setTotalItemsCount(0);
            props.onFetchFail(e);
          });

      }
    };
  },[props.additionalFilters]);

  useEffect(() => {
    if(gridApi) {
      const dataSource = getServerSideDataSource(props.fetchParams, props.companyId, itemsPerPage);
      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi, itemsPerPage, props.fetchParams.url, props.companyId]);

  useEffect(() => {
    setCurrentPage(props.pagination.currentPage);
    setItemsPerPage(props.pagination.itemsPerPage);
  }, [props.pagination.currentPage, props.pagination.itemsPerPage]);

  useEffect(() => {
    if(gridApi) {
      //page index start from 0 in ag-grid
      gridApi.paginationGoToPage(currentPage-1);
    }
  }, [currentPage, gridApi]);


  const updateColumnOrder = useMemo(()=>()=> {
    if(!props.ls_name) return props.columnDefs;
    const columnSavedState = JSON.parse(localStorage.getItem(props.ls_name));
    try{
      if(columnSavedState) {
        let newColumnDef = new Array(props.columnDefs.length);
        props.columnDefs.forEach((el, i)=> {
          //console.log('el:::', el);
          const index = columnSavedState.findIndex((e)=> e.colId === el.field);
          newColumnDef[index] = el;
        });
        return newColumnDef;
      } else {
        return props.columnDefs;
      }
    } catch(e) {
      return props.columnDefs;
    }
  },[]);


  const getGridDefaultOptions =useMemo(()=>()=> {
    return {
      reactUi: 'true',
      className: 'ag-theme-alpine',
      animateRows: 'true',
      // suppressRowClickSelection: 'true',
      //modules={modules}
      onColumnMoved: onColumnMoved,
      defaultColDef: defaultColDef,
      enableCellTextSelection: true,
      rowSelection: 'multiple',
      suppressHorizontalScroll: false,
      overlayLoadingTemplate:
        '<Typography> Please wait...</Typography>',
      overlayNoRowsTemplate: '<Typography> No records found</Typography>',
      headerHeight: 30,
      rowHeight: 20

    };
  },[]);
  const removeQueryParams = (p) => {
    const param = searchParams.get(p);

    if (param) {
      // 👇️ delete each query param
      searchParams.delete(p);

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };

  function onFirstDataRendered(params) {
    params.api.forEachNode(function (node) {
      node.setExpanded(node.id === '1');
    });
  }

  return (
    <AlpineContainer>

      <AgGridReact
        {...getGridDefaultOptions()}
        pagination={true}
        rowModelType={'serverSide'}
        paginationPageSize={itemsPerPage}
        cacheBlockSize = {itemsPerPage}
        serverSideStoreType={'partial'}
        getContextMenuItems={props.contextMenu}
        columnDefs={updateColumnOrder()}
        rowData={props?.rowData ||[]}
        frameworkComponents={props.components}
        onGridReady={onGridReady}
        suppressPaginationPanel={true}
        onCellDoubleClicked={onCellDoubleClicked}
        masterDetail={!!(props.masterDetail && !!props?.detailsCellRenderer)}
        detailCellRenderer={props.detailsCellRenderer}
        onFirstDataRendered={props.masterDetail ? onFirstDataRendered : null}
      />
    </AlpineContainer>
  );
};

MyPaginatedGrid.propTypes = {
  ls_name: PropTypes.string.isRequired,
  columnDefs: PropTypes.instanceOf(Object).isRequired,
  contextMenu: PropTypes.instanceOf(Array).isRequired,
  fetchParams: PropTypes.shape({
    url: PropTypes.string.isRequired,
    respKey:  PropTypes.instanceOf(String).isRequired,
  }),
  modifyDataElement: PropTypes.instanceOf(Function),
  onFetchFail: PropTypes.instanceOf(Function),

  rowData: PropTypes.instanceOf(Array).isRequired,
  components: PropTypes.instanceOf(Object),
  registerGrid: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    setTotalPageCount: PropTypes.func.isRequired,
    setTotalItemsCount: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  companyId: PropTypes.string,
  onCellDoubleClicked: PropTypes.func,
  defFilterParams: PropTypes.instanceOf(Array).isRequired,
  additionalFilters: PropTypes.instanceOf(Object),
  masterDetail: PropTypes.bool,
  detailsCellRenderer: PropTypes.instanceOf(Function),
};

MyPaginatedGrid.defaultProps = {
  components: {},
  onFetchFail: ()=>{console.log('Fail to fetch data...');},
  modifyDataElement: undefined,
  companyId: undefined,
  onCellDoubleClicked: undefined,
  additionalFilters: {},
  masterDetail: false,
  detailsCellRenderer: undefined,
};
export default MyPaginatedGrid;
