import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box} from '@mui/material';
import * as allConstants from '../../../../../constants/allConstants';
import AllInspectionsGrid from './allInspectionsGrid';
import DisplayListsPageWrapper from '../../../../wrappers/displayListsPageWrapper';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import ListPageHeader from '../../../../../pieces/listPageHeader';
import usePagination from '../../../../../hooks/usePagination';
import mergeProps from 'merge-props';
import {useGridViewState} from '../../../../../hooks/useGridViewState';
import clsx from 'clsx';
import {jobFilters} from '../../../allJobs/jobExtendedFilters';
import {useSelector} from 'react-redux';
import {GridWithFilterWrapper, useFilter} from '../../../../../hooks/useFilter';
import useViewMenu, {MenuOption} from '../../../../../hooks/useViewMenu';
import {inspectionApiTypes} from './inspectionsGridOptions';
import {ListViewHeader} from '../../../components/listViewHeader';

const AllInspectionsView = () => {
  const viewController = useGridViewState();
  const navigate = useNavigate();
  const classes = {...globalUseClasses()};
  const pagination = usePagination();
  const user = useSelector(state=> state.user);

  const filterFields = jobFilters.getFilters(user, {module: allConstants.IPERMIT_MODULES.INSPECTION});

  const getMenuItems = ()=> {
    const items = [
      new MenuOption(inspectionApiTypes.all, 'All Inspections'),
      new MenuOption(inspectionApiTypes.previous, 'Follow up'),
      new MenuOption(inspectionApiTypes.pending, 'Pending Inspections'),
      new MenuOption(inspectionApiTypes.manual, 'Manually Ordered Inspections'),
      new MenuOption(inspectionApiTypes.hold, 'Hold'),
      new MenuOption(inspectionApiTypes.completed, 'Completed'),
      new MenuOption(inspectionApiTypes.failed, 'Failed'),
    ];

    return items;
  };
  const InspectionsGridEnum = getMenuItems();

  const menuController = useViewMenu({items: InspectionsGridEnum});

  const extendedFilters = useFilter({fields: filterFields, width: 230, collection: 'jobs'});

  const viewJobHandler = (id)=> {
    navigate(`${id}${location.search}`);
  };

  const gridProps = {
    viewJobHandler: viewJobHandler,
    pagination: pagination,
    viewController: viewController
  };

  const handleClick = (event) => {
    menuController.setAnchorEl(event.currentTarget);
  };

  const getHeaderComponent = ()=> {
    let title = menuController.title;
    if(pagination?.totalItemsCount || pagination.totalItemsCount === 0) {
      title = `${title} (${pagination.totalItemsCount})`;
    }
    return (
      <ListViewHeader title={title}
        gridEnum={InspectionsGridEnum}
        pagination={pagination}
        handleClick={handleClick}
        menuController={menuController}
        extendedFilters={extendedFilters}
        viewController={viewController}
      />
    );
  };

  const headerComponent = <ListPageHeader headComponent = {getHeaderComponent()}
    totalItems={pagination.totalItemsCount}
    addHandler={()=>{navigate(allConstants.PATH.CREATE_JOB);}}
    showAddButton={false}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  const currGridProps = mergeProps(gridProps, {gridType: InspectionsGridEnum[menuController.viewGrid].value});
  return (
    <>
      <Box className={clsx(classes.page)}>
        <DisplayListsPageWrapper
          headerComponent={headerComponent}>

          <GridWithFilterWrapper show={extendedFilters.show} filterPanel={extendedFilters.filterPanel}>
            {
              <AllInspectionsGrid
                {...currGridProps}
                key={InspectionsGridEnum[menuController.viewGrid].value}
                additionalFilters={extendedFilters.applyFilters}
              />
            }
          </GridWithFilterWrapper>
          {pagination.PageController}
        </DisplayListsPageWrapper>
      </Box>

    </>


  );
};

export default AllInspectionsView;
