import {SCOPES, IPERMIT_ROLES} from '../../constants/allConstants';
const ELEMENTS = {
  viewPage: 'viewPage',
  addCityButton: 'addCityButton',
  deleteCity: 'deleteCity',
  editCity: 'editCity',
  forceAddToCRM: 'forceAddToCRM',
};


const citiesPageRules = Object.freeze({
  [ELEMENTS.viewPage]: {
    [SCOPES.canView]: [...Object.values(IPERMIT_ROLES)],
  },

  [ELEMENTS.addCityButton]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.editCity]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.deleteCity]: {
    [SCOPES.canView]: [IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.forceAddToCRM]: {
    [SCOPES.canView]: [IPERMIT_ROLES.SYSTEM_ADMIN]
  },
});

export {citiesPageRules , ELEMENTS};
