import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box, CssBaseline, Drawer, Grid, List, ListItem, ListItemButton, ListItemText, ListItemIcon} from '@mui/material';
import Utils from '../utils/utils';
import {useSearchParams} from 'react-router-dom';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {drawerWidth} from '../pages/wrappers/recordBodyWrapper';
import {urlSearchParams} from '../baseUrls';
import Spacer from './spacer';
import {CustomMediaQuery} from '../hooks/customMediaQuery';

export const DrawerWrapper = (props)=> {
  const tabs = props.tabs;
  const urlParam = urlSearchParams.openTab;
  const query = CustomMediaQuery();

  const getData = (panel)=> {
    return Object.values((tabs)).sort((a, b) => a.order-b.order).map((tab)=> {
      if(panel) {
        return tab.panel;
      }
      return {
        text: tab.name,
        icon: tab?.icon,
      };
    });
  };

  const currentTabs = getData();
  const currentPanels = getData(true);

  const getSelectedTab = ()=> {
    const selectedTab = Utils.getUrlParameter(urlParam);
    if(!currentTabs || !Array.isArray(currentTabs)) return 0;
    const currTabNames = currentTabs.map((t)=> t.text);

    return currTabNames.indexOf(selectedTab) !== -1 ? currTabNames.indexOf(selectedTab) : 0;
  };

  const [openTab, setOpenTabs] = useState(getSelectedTab());

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=> {
    //handle tabs navigation
    searchParams.delete(urlParam);
    searchParams.append(urlParam, currentTabs[openTab].text);
    setSearchParams(searchParams);
    return ()=> {
      searchParams.delete(urlParam);
    };
  }, [openTab]);

  const drawer = useMemo(()=>{
    return(
      <>
        <List>
          {currentTabs.map((tab, index) => {

            return (
              <ListItem key={tab.text+index} sx={{paddingLeft: '0'}}>
                <ListItemButton onClick={()=> setOpenTabs(index)} selected={openTab === index}
                  sx={{
                    border: '1px solid white',
                    paddingY: {xs: '4px', xl: '8px'},
                    '&:hover': {
                      backgroundColor: STYLE_CONSTANTS.COLORS.backgroundMain
                    },
                    '& .MuiTypography-root': {
                      fontSize: '13px'
                    },
                    '& .MuiListItemIcon-root': {
                      minWidth: '30px',
                      color: 'inherit'
                    },
                    '& img': {
                      filter: STYLE_CONSTANTS.FILTERS.GRAY_NOT_ACTIVE
                    },
                    '&.Mui-selected': {
                      borderColor: STYLE_CONSTANTS.COLORS.globalBlue,
                      backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
                      '& img': {
                        filter: 'none'
                      },
                      '& .MuiTypography-root': {
                        color: '#fff'
                      },
                      '& .MuiListItemIcon-root': {
                        color: '#fff'
                      },
                      '&:hover': {
                        backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
                      }
                    },
                    '& .MuiListItemAvatar-root': {
                      minWidth: '10px'
                    },
                    borderRadius: '12px',
                  }}>
                  {tab.icon && <ListItemIcon>

                    <img src={tab.icon} alt=""/>
                  </ListItemIcon>

                  }
                  <ListItemText primary={tab.text} sx={{textTransform: 'uppercase'}}/>
                </ListItemButton>
              </ListItem>
            );
          }
          )}
        </List>
      </>
    );}, [openTab]);

  return (
    <Box sx={{display: 'flex', width: '100%'}}>
      <CssBaseline />
      <Box
        sx={{flexShrink: {sm: 0}, position: 'relative'}}
        aria-label="page sections"
      >
        <Drawer
          variant="persistent"
          sx={{
            height: '100%',
            zIndex: 1,
            position: 'relative', marginLeft: 'auto',
            display: {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box', width: drawerWidth(query)
              ,
              position: 'relative'
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          paddingLeft: 3,
          width: {sm: `calc(100% - ${drawerWidth(query)}px)`},
          backgroundColor: '#fff',
          position: 'relative'
        }}
      >
        <Grid container height={'100%'}>
          <Grid item xs={12}>
            {currentPanels[openTab]}
            <Spacer y={8}/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};


DrawerWrapper.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired
};
