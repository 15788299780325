import React, {useState} from 'react';
import {Tabs, Tab} from '@mui/material';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import PropTypes from 'prop-types';

export const useTabs = (props)=> {
  const {tabs} = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const stepper = <PillTabs tabs={tabs} activeIndex={tabIndex} disabled={disabled} onChange={(index)=>{
    if(disabled) return;
    setTabIndex(index);
  }} color={props?.color}/>;


  return {
    Stepper: stepper,
    activeIndex: tabIndex,
    setActiveIndex: setTabIndex,
    disable: ()=> setDisabled(true),
    enable: ()=> setDisabled(false),
  };
};


const PillTabs = (props) => {
  const {tabs, activeIndex, onChange, color, disabled} = props;
  const getLabel = (tab)=> {
    if(tab?.count === undefined) {
      return tab.label;
    }
    return `${tab.label} (${tab.count})`;
  };

  const tabHeight= '32px';
  return (
    <Tabs
      value={activeIndex}
      onChange={(e, index) => {
        if (disabled) return;
        console.log('index', index);
        onChange(index);
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      sx={{
        minHeight: tabHeight,
        height: tabHeight,
        '& .MuiTabs-flexContainer': {
          columnGap: {xs: '4px', md: '8px', xl: '16px'},
        },
        '& .MuiTabs-scroller': {
          minHeight: tabHeight,
          height: tabHeight,

        },
        '& .MuiTabs-indicator': {
          // border: '1px solid red',
          borderRadius: '50px',
          height: '100%',
          backgroundColor: color || 'none',
        },
        '& .MuiTab-root': {
          // border: '1px solid red',
          // borderRadius: '50px',
        },
        '& .MuiButtonBase-root': {
          borderRadius: '50px',
          paddingY: 0,
          minHeight: tabHeight,
          height: tabHeight,
          fontSize: {xs: '12px', xl: '14px'},
          backgroundColor: 'none',
          color: disabled ? STYLE_CONSTANTS.COLORS.fontPale : 'initial',
          '&:hover': {
            cursor: disabled ? 'not-allowed' : 'pointer',
          },
        },
        '& .Mui-selected.MuiButtonBase-root': {
          zIndex: 1,
          color: `${STYLE_CONSTANTS.COLORS.white} !important`,
          backgroundColor: color || STYLE_CONSTANTS.COLORS.globalBlue,
        }
      }}
    >
      {tabs && tabs.map((tab, index) => {
        return <Tab  key={index+tab.label} label={getLabel(tab)} disableRipple={true}/>;
      })}
    </Tabs>
  );
};

useTabs.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string
};

useTabs.defaultProps = {
  color: null,
};

PillTabs.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  activeIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool
};

PillTabs.defaultProps = {
  color: null,
  disabled: false,
};
