import {SCOPES, IPERMIT_ROLES} from '../../constants/allConstants';
const ELEMENTS = {
  viewPage: 'viewPage',
  addRaterButton: 'addRaterButton',
  deleteRater: 'deleteRater',
  editRater: 'editRater'
};


const ratersPageRules = Object.freeze({
  [ELEMENTS.viewPage]: {
    [SCOPES.canView]: [...Object.values(IPERMIT_ROLES)],
  },

  [ELEMENTS.addRaterButton]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.editRater]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.deleteRater]: {
    [SCOPES.canView]: [IPERMIT_ROLES.SYSTEM_ADMIN],
  }
});

export {ratersPageRules, ELEMENTS};
