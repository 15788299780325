import ColumnDefinition from '../../../../../pieces/grids/columnGenerator';
import * as allConstants from '../../../../../constants/allConstants';
import {INSPECTION_REQUIREMENTS} from '../../../../../constants/options/filterOptions/columnFilterOptions';
import Utils from '../../../../../utils/utils';
import {getStatusColumns} from '../../../helpers/jobGridOptions';

const inspectionApiTypes = {
  all: 'all',
  previous: 'previous',
  pending: 'pending',
  manual: 'manual',
  completed: 'completed',
  hold: 'hold',
  failed: 'failed',
};

const getInspectionColumns = (handleDisplayJobDetails, cellRendererName, jobOptions) => {
  const JOB_FIELDS = allConstants.JOB_FIELDS;
  const INSP_FIELDS = allConstants.INSPECTION_FIELDS;
  const SCHEDULING_FIELDS = allConstants.SCHEDULING_FIELDS;
  return {
    id: new ColumnDefinition({field: JOB_FIELDS.ID, hide: true}),
    company: new ColumnDefinition({
      field: allConstants.COMPANY_FIELDS.ID,
      parent: 'company',
      hide: true,
    }),
    companyName: new ColumnDefinition({
      field: allConstants.COMPANY_FIELDS.NAME,
      parent: 'company',
      hide: false
    }),
    jobNumber: new ColumnDefinition({
      field: JOB_FIELDS.JOB_NUMBER,
      lockPinned: true,
      filter: 'agNumberColumnFilter',
      pinned: 'left',
      checkboxSelection: true,
      cellRenderer: cellRendererName,
      cellRendererParams: (params) => {
        return {
          handleExpand: handleDisplayJobDetails,
          showInspectionResults: true,
        };
      },
    }),
    jobNumberWithOutResults: new ColumnDefinition({
      field: JOB_FIELDS.JOB_NUMBER,
      lockPinned: true,
      filter: 'agNumberColumnFilter',
      pinned: 'left',
      checkboxSelection: true,
      cellRenderer: cellRendererName,
      cellRendererParams: (params) => {
        return {
          handleExpand: handleDisplayJobDetails,
          showInspectionResults: false,
        };
      },
    }),
    jobType: new ColumnDefinition({
      field: JOB_FIELDS.JOB_TYPE,
      filter: 'agSetColumnFilter',
      filterValues: Object.values(allConstants.JOB_TYPES).map((s) => s.value).filter((s) => s !== allConstants.JOB_TYPES.COMMERCIAL.value),
    }),

    assignedCity: new ColumnDefinition({
      parent: allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name,
      field: allConstants.CITY_FIELDS.NAME,
      headerName: 'assigned city'
    }),

    cityType: new ColumnDefinition({
      parent: allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name,
      field: allConstants.CITY_FIELDS.CITY_TYPE,
      headerName: 'city type',
      wrapText: true,
      cellStyle: {
        lineHeight: '14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      },
      removeDefaultFilter: true,
    }),


    inspectionStatus: new ColumnDefinition({
      field: allConstants.INSPECTION_FIELDS.INSPECTION_STATUS,
      parent: INSP_FIELDS.INSPECTION.api_name,
      headerName: 'inspection status',
      cellRenderer: 'statusRenderer',
      filter: 'agSetColumnFilter',
      filterValues: Object.values(allConstants.INSPECTION_STATUSES).map((s) => s.value),
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return inspectionData?.[INSP_FIELDS.INSPECTION_STATUS.api_name];
        }
        return allConstants.PERMIT_STATUSES.NOT_REQUIRED.value;
      }),
    }),
    inspectionScheduledDate: new ColumnDefinition({
      field: allConstants.JOB_FIELDS.HERS_SCHEDULED_DATE,
      parent: INSP_FIELDS.INSPECTION.api_name,
      headerName: 'scheduled date',
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return Utils.formatIsoDateString(inspectionData?.[SCHEDULING_FIELDS.START_DATE.api_name], {date: true});
        }
        return null;

      })
    }),
    inspectionResult: new ColumnDefinition({
      field: INSP_FIELDS.RESULT,
      parent: `${INSP_FIELDS.INSPECTION.api_name}.${INSP_FIELDS.INSPECTION_RESULTS.api_name}`,
      headerName: 'result',
      cellRenderer: 'statusRenderer',
      filter: 'agSetColumnFilter',
      width: 120,
      filterValues: Object.values(allConstants.RESULT_OPTIONS).map((s) => s.value),
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          const tests = inspectionData?.[INSP_FIELDS.INSPECTION_RESULTS.api_name];
          if (!tests || tests.length === 0) {
            return null;
          }
          const lastTest = tests[tests.length - 1];
          return lastTest[INSP_FIELDS.RESULT.api_name];
        }
        return null;

      })
    }),
    callsCount: new ColumnDefinition({
      field: allConstants.CALL_LOGS_FIELDS.NUMBER_OF_CALLS,
      parent: INSP_FIELDS.INSPECTION.api_name,
      filter: 'agNumberColumnFilter',
      valueGetter: (({data}) => {
        const callsData = data?.[INSP_FIELDS.INSPECTION.api_name]?.[allConstants.CALL_LOGS_FIELDS.CALL_LOG.api_name];
        if(callsData && callsData.length > 0) {
          //filter calls to home owner
          const callsToHomeOwner = callsData.filter(call => call[allConstants.CALL_LOGS_FIELDS.TYPE.api_name] === allConstants.CALL_TO_OPTIONS.HOME_OWNER.value);
          return callsToHomeOwner.length ?? '';
        }
        return '';
      }
      ),
    }),
    requirements: new ColumnDefinition({
      field: INSP_FIELDS.REQUIREMENTS,
      parent: INSP_FIELDS.INSPECTION.api_name,
      filter: 'agSetColumnFilter',
      filterValues: Object.values(INSPECTION_REQUIREMENTS).map((s) => s.value),
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return {
            [INSP_FIELDS.LADDER.api_name]: inspectionData[INSP_FIELDS.LADDER.api_name],
            [INSP_FIELDS.REQUIREMENTS.api_name]: inspectionData?.[INSP_FIELDS.REQUIREMENTS.api_name],
          };
        }
        return null;
      }),

      cellRenderer: 'InspectionRequirementsRenderer',

    }),
    createdTime: new ColumnDefinition({
      field: JOB_FIELDS.CREATE_TIME,
      parent: INSP_FIELDS.INSPECTION.api_name,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => Utils.formatIsoDateString(data?.[INSP_FIELDS.INSPECTION.api_name]?.[JOB_FIELDS.CREATE_TIME.api_name],{date: true}))
    }),
    ...getStatusColumns(jobOptions)

  };
};


export {getInspectionColumns, inspectionApiTypes};
