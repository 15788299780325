import React from 'react';
import PropTypes from 'prop-types';
import {CustomInput} from './customInputComponent';
import NumberFormat from 'react-number-format';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import Utils from '../../utils/utils';

const PhoneInput = (props)=> {
  return(
    <NumberFormat key={props.defaultValue}
      size="small"
      label="price"
      color="secondary"
      variant="outlined"
      onValueChange={(v) => {
        props.setValueToForm(props.field_name, v.value, {shouldDirty: true,  trigger: props.field_name});
      }}
      error={props.isError}
      allowEmptyFormatting={false}
      format="(###) ###-####" mask="_"
      {...props.field}
      {...props.register(props.field_name, {required: props.field.required})}
      customInput={CustomInput}
      defaultValue={Utils.reformatPhoneNumber(props.defaultValue)}
      disabled={!props.isEditable || props.viewMode}
      endAdornment={props.lockIcon}
    />
  );
};

PhoneInput.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.bool.isRequired,
    lockIcon: PropTypes.element.isRequired
  }
};
PhoneInput.defaultProps = formBlockDefaultProps;
export default PhoneInput;
