const COLORS = {
  globalRed: '#CA1F41',
  warningRed: '#DE0000',
  globalBlue:'#153359',
  globalGreen: '#84C341',
  globalGreenHover: '#B5DB8D',
  globalRedPale: 'rgba(193,54,54,0.15)',
  globalBluePale:'rgba(49,82,163,0.15)',
  globalBlue50:'rgba(49,82,163,0.5)',
  globalBlueHover: '#1D467C',
  globalRedHover: '#981731',
  backgroundMain: '#EAECEE',
  // background:'#F4F7FE',
  statusColor:'#38456F',
  //fontPale: '#A3AED0',
  chartPink: '#FFEEF1',
  chartBlue: '#E9EDF7',
  chartGreen: '#E9F7F0',
  fontDarkBlueSecondary: '#1B2559',
  fontDarkBlue: '#2B3674',
  fontMain: '#3F4254',
  fontSecondary: '#38456F',
  fontPale: '#84849A',
  violetBold: '#7F38AD',
  violet: '#885EFB',
  lightViolet:'#ECE3FD',
  lightVioletHover:  '#DED7ED',
  lightGray: '#F2F2F2',
  lightGrayHover: '#C7C7C7',
  white: '#ffffff',
  hoverColor: '#F5F5F9',
  boxShadow: '#daddea',
  extraDarkBlue: '#38456F',
  lightGreen: '#EBF8E5',
  grayBase: '#707174',
  brightBlue: '#0094FF',
  linkColor: '#3152A3',
  teal: '#36CEC5',
  black: '#000000',
  lightBlue: '#B3DCEE',
  lightBlueHover: '#C8E6F3',
  lightBluePale: '#647A9C',
  darkBlue: '#22759A',
  activeTransitionButton: '#84C341',
  lightBlueBackground: '#f4f8fc'
};

const TAG_COLORS = {
  orange: '#e89d25'
};

const HEIGHT = {
  NAVBAR: (props)=> {
    const height =  props?.isXS ? 50 : 50;
    return props?.number ? height : `${height}px`;
  },
  PAGINATOR: (props)=> {
    return '50px';
  },
  JOB_HEADER_HEIGHT: (props)=>{
    return props?.isXL ? '220px' : '210px';
  }
};

const STYLES = {
  CUSTOM_BUTTON: {borderRadius: '10px', paddingY: '4px', fontSize: {xs: '12px', xl: '14px'}},
  SHADOW_BOX: {border: `1px solid ${COLORS.globalGreen}`,
    borderRadius: '8px',}
};

const DEFAULT = {
  BOX_SHADOW: '0px 0px 18px rgba(0, 0, 0, 0.12)',
  ANALYTICS_CARD_HEIGHT: '340px',
  MAX_WIDTH_JOB_VIEW: '1500px'
};

const FILTERS = {
  GRAY_NOT_ACTIVE: 'invert(58%) sepia(0%) saturate(7485%) hue-rotate(181deg) brightness(100%) contrast(92%)',
};

export {
  COLORS,
  HEIGHT,
  DEFAULT,
  FILTERS,
  STYLES,
  TAG_COLORS
};
