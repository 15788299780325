import {styled} from '@mui/material/styles';
import {InputBase, InputLabel} from '@mui/material';
import {COLORS} from '../../styles/styleConstants';
import React from 'react';
import PropTypes from 'prop-types';

const FloatingLabel = (props)=> {

  const padding = '0 7px 0 3px';
  const fontSize = {xs: '10px', md: '12px'};
  const fontWeight = '900';

  return (<>
    <InputLabel shrink htmlFor={props.apiName}
      required={props.required}
      sx={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        padding: padding,
        textTransform: 'capitalize !important',
        transform: {xs: 'translate(10px, -2px)', md: 'translate(10px, -4px)'},
        color: COLORS.globalBlue,
        zIndex: 1
      }}
    >
      {props.label}
      <InputLabel shrink htmlFor={props.apiName}
        sx={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          padding: padding,
          textTransform: 'capitalize !important',
          transform: {xs: 'translate(-4px, 6px)', md: 'translate(-4px, 8px)'},
          height: '2px',
          backgroundColor: '#fff',
          zIndex: '-1'
        }}
        required={props.required}
      >
        {props.label}
      </InputLabel>
    </InputLabel>
  </>);
};

FloatingLabel.propTypes = {
  apiName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired
};

FloatingLabel.defaultProps = {
  required: false,
};

const CustomInput = styled(InputBase)(({theme}) => {
  return (
    {
      'input': {
        fontSize: {xs: '12px', xl: '14px'},
        '&::placeholder': {
          textTransform: 'none'
        }
      },
      'input[type="time"]':  {
        [theme.breakpoints.down('md')]: { //fix height for time input on ipad
          height: '2.3rem',
          lineHeight: '2.3rem',
          '&:focus': {
            height: '2.3rem',
            lineHeight: '2.3rem',
          },

        },
        padding: '10px 4px 10px 8px',
        //minHeight: '3rem',
      },
      '&.MuiInputBase-multiline': {
        padding: 0,
        '& textarea': {
          maxHeight: '300px',
          padding: '7px 12px'
        }

      },
      '& .MuiInputAdornment-positionEnd': {
        position: 'absolute',
        right: '6px',
        //bottom: '35px',
        '& svg': {
          width: '16px',
        }
      },

      '& .MuiSelect-select': {
        minWidth: '100px !important',
        lineHeight: '2ex',
        height: '1.125em !important',
        minHeight:  '1.125em !important',
        //paddingRight: '58px !important',
      },
      '& .MuiInputBase-inputMultiline': {
        width: '300px',
        resize: 'auto',
        [theme.breakpoints.only('xs')]: {
          width: '200px',
        },
        // '&::-webkit-scrollbar':{
        //   display: 'none',
        // }

      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      '& .MuiSelect-multiple': {
        minWidth: '200px',
        //width: '300px',
        height: 'auto !important',
        minHeight: '1.95em !important',
        paddingTop: '6px !important',
        paddingBottom: '6px !important',

      },
      '&.Mui-disabled .MuiInputBase-input': {
        backgroundColor: '#F8F8F8',
        WebkitTextFillColor: 'rgba(63, 66, 84, 1)',
      },
      '&.Mui-focused .MuiInputBase-input': {
        backgroundColor: '#fff',
      },
      '&.Mui-error .MuiInputBase-input': {
        boxShadow: '0px 0.125em 0.5em rgba(202, 31, 65, 0.21)',
        borderColor: theme.palette.error.main
      },
      'label + &': {
        //marginTop: theme.spacing(0.3),
        // marginBottom:  isXS ? theme.spacing(3) :theme.spacing(4),
      },
      '&:focus': {
        '& .MuiInputBase-input': {
          // height: '1.25em' //2px[focused border] smaller than not focused. Prevent jumping when focused with wider border.
        },
      },
      '& .MuiInputBase-input': {
        height: '1.125em',
        //minHeight: '1em',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        borderRadius: '4px !important',
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        fontSize: '12px',
        [theme.breakpoints.only('xl')]: {
          fontSize: '14px',
        },
        //fontSize: isXS ? '12px': '14px',
        padding: '10px 12px 6px 12px',
        [theme.breakpoints.only('xl')]: {
          paddingBottom: '12px'
        },
        textTransform:'uppercase',
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          //'box-shadow',
        ]),
        boxShadow: '0px 2px 8px rgba(202, 31, 65, 0)',
        '&:focus': {
          height: '1.125em',
          boxShadow: '0px 2px 8px rgba(49, 82, 163, 0.21)',
          borderColor: theme.palette.primary.main,
        },

      },
    }
  );});

export {CustomInput, FloatingLabel};
