import React, {createContext, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../constants/allConstants';
import {timelineTypes, useTimeline} from '../hooks/useTimeline';
import Utils from '../utils/utils';
import {urlSearchParams} from '../baseUrls';

export const JobContext = createContext(undefined);

const getTimelineType = (module)=> {
  return timelineTypes.inspection;
};

export const JobContextProvider = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [job, setJob] = useState(props.job);

  const {JOB_FIELDS} = allConstants;
  const jobType = job?.[JOB_FIELDS.JOB_TYPE.api_name];

  const module = Utils.getUrlParameter(urlSearchParams.moduleName);
  const subModule = Utils.getUrlParameter(urlSearchParams.submodule);
  const timelineIds = {
    [allConstants.IPERMIT_MODULES.ALL_JOBS]: job[allConstants.JOB_FIELDS.ID.api_name],
    [allConstants.IPERMIT_MODULES.INSPECTION]: job[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]?.id,
  };

  const timeline = useTimeline({
    recordId: job[allConstants.JOB_FIELDS.ID.api_name],
    moduleName: getTimelineType(subModule || module),
    timezone: job[allConstants.JOB_FIELDS.TIMEZONE.api_name],
    timelineIds,
  });

  useEffect(()=> {
    setJob(job);
  }, [props.job]);

  if (process.env.NODE_ENV === 'development') {
    window.jobContext = job;
  }

  const isPermitOnlyJob = ()=> {
    return jobType !== allConstants.JOB_TYPES.TESTING_ONLY.value && jobType !== allConstants.JOB_TYPES.HVAC_RES.value;
  };

  const isTestingOnlyJob = ()=> {
    return jobType === allConstants.JOB_TYPES.TESTING_ONLY.value;
  };

  const isBusinessLicense = ()=> {
    return jobType === allConstants.JOB_TYPES.BUSINESS_LIC.value;
  };

  const isHVACJob = ()=> {
    return jobType === allConstants.JOB_TYPES.HVAC_RES.value || jobType === allConstants.JOB_TYPES.TESTING_ONLY.value;
  };

  const hersIsRequired = ()=>{
    return job?.[JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name];
  };


  const jobHasInspection = ()=> {
    const inspection = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name];
    return inspection !== undefined;
  };

  const inspectionWithOwnRater = ()=> {
    const inspection = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name];
    if(!inspection) return false;
    return !!inspection?.rater?.id;
  };

  const inspectionScheduled = ()=> {
    const status = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]?.[allConstants.INSPECTION_FIELDS.INSPECTION_STATUS.api_name];
    return status === allConstants.INSPECTION_STATUSES.SCHEDULED.value;
  };

  const context = {
    job: job,
    setJob: setJob,
    isPermitOnly: isPermitOnlyJob,
    isTestingOnly: isTestingOnlyJob,
    isBusinessLicense: isBusinessLicense,
    jobHasInspection: jobHasInspection,
    isHVACJob: isHVACJob,
    hersIsRequired: hersIsRequired,
    inspectionWithOwnRater: inspectionWithOwnRater,
    inspectionScheduled: inspectionScheduled,
    timeline: timeline,
  };

  if (process.env.NODE_ENV === 'development') {
    window.jobContext = context;
  }
  return (
    <JobContext.Provider value={context} >
      {props.children}
    </JobContext.Provider>
  );
};

JobContextProvider.propTypes = {
  job: PropTypes.instanceOf(Object).isRequired,

};

export const useJob = () => useContext(JobContext);
