import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import base_url from '../../../../../baseUrls';
import StatusRenderer, {StatusRenderWithAdditionalData} from '../../../../../pieces/grids/statusRenderer';
import MyPaginatedGrid from '../../../../../pieces/grids/paginatedGridWrapper';
import {MaximizableCellRenderer} from '../../../../../pieces/grids/MaximizableCellRenderer';
import {getInspectionColumns, inspectionApiTypes} from './inspectionsGridOptions';
import * as allConstants from '../../../../../constants/allConstants';
import {useAutocompleteOptions} from '../../../../../hooks/useAutompleteOptions';


const AllInspectionsGrid = (props) => {
  const viewController = props.viewController;
  const [components] = useState({
    'statusRenderer': StatusRenderer,
    'statusRenderWithDate': StatusRenderWithAdditionalData,
    'MaximizableCellRenderer': MaximizableCellRenderer,
  });

  const jobStatusOptions = useAutocompleteOptions({field: 'jobStatuses'}).options;

  const handleDisplayJobDetails = (params) => {
    props.viewJobHandler(params?.node?.data.id);
  };

  const columnDefs = useMemo(()=> {
    const columns = getInspectionColumns(handleDisplayJobDetails, 'MaximizableCellRenderer', jobStatusOptions);

    if(props.gridType === inspectionApiTypes.pending) {
      return [
        columns.id,
        columns.jobNumber,
        columns.company,
        columns.companyName,
        columns.jobType,
        columns.assignedCity,
        columns.cityType,
        columns.permitStatus,
        columns.hersStatus,
        columns.mailingStatus,
        columns.mailingStatusChangeDate,
        columns.callsCount,
        columns.inspectionScheduledDate,
        columns.inspectionResult
      ];
    }
    if(props.gridType === inspectionApiTypes.hold || props.gridType === inspectionApiTypes.completed) {
      return [
        columns.id,
        columns.jobNumber,
        columns.company,
        columns.companyName,
        columns.jobType,
        columns.assignedCity,
        columns.permitStatus,
        columns.hersStatus,
        columns.mailingStatus,
        columns.inspectionScheduledDate,
        columns.inspectionResult
      ];
    }

    if(props.gridType === inspectionApiTypes.failed) {
      return [
        columns.id,
        columns.jobNumberWithOutResults,
        columns.company,
        columns.companyName,
        columns.jobType,
        columns.assignedCity,
        columns.permitStatus,
        columns.hersStatus,
        columns.mailingStatus,
        columns.inspectionStatus,
        columns.inspectionScheduledDate,
      ];
    }

    const head = [
      columns.id,
      columns.jobNumber,
      columns.company,
      columns.companyName,
      columns.jobType,
      columns.assignedCity,
      columns.permitStatus,
      columns.hersStatus,
      columns.mailingStatus,
      columns.inspectionStatus,
      columns.callsCount,
      columns.inspectionScheduledDate,
      columns.inspectionResult
    ];

    if(props.gridType === inspectionApiTypes.manual) {
      //insert the manual inspection column at 3 position
      head.splice(3, 0, columns.createdTime);
    }

    return head;


  }, [jobStatusOptions]);


  const getFetchParams = (gridType)=> {
    const baseJobsUrl = `${base_url.api}jobs`;
    const ipermitBaseJobsUrl = `${base_url.api}ipermit/jobs`;
    //const baseJobsUrl = `${base_url.api}jobs/`;
    let url = baseJobsUrl;
    let params = {};
    if(gridType === inspectionApiTypes.previous) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-previous-inspections`;
    } else if(gridType === inspectionApiTypes.pending) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-inspections-with-status`;
      console.log('pending', url);
      params.status = allConstants.INSPECTION_STATUSES.PENDING.value;
    } else if(gridType === inspectionApiTypes.hold) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-inspections-with-status`;
      params.status = allConstants.INSPECTION_STATUSES.HOLD.value;
    }
    else if(gridType === inspectionApiTypes.completed) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-inspections-with-status`;
      params.status = allConstants.INSPECTION_STATUSES.COMPLETED.value;
    }
    else if(gridType === inspectionApiTypes.manual) {
      url = `${ipermitBaseJobsUrl}/city-inspection/get-manual-inspections`;
    }
    else if(gridType === inspectionApiTypes.failed) {
      url = `${url}/get-failed-inspections`;
    }
    else {
      url = `${url}/get-inspection-jobs`;
    }

    return {url: url, respKey:'inspections', options: params};
  };

  const getContextMenuItems = (params)=> {

    let standard = [
      {
        name:  'Show Job Details',
        cssClasses: ['blueFont', 'bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {handleDisplayJobDetails(params);},
      },

      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];

    let listOfActions = [...standard];
    return listOfActions;

  };

  const fetchParams = getFetchParams(props.gridType);
  if(!jobStatusOptions) return null;
  return (
    <>
      <MyPaginatedGrid columnDefs={columnDefs}
        components={components}
        contextMenu={getContextMenuItems}
        onCellDoubleClicked={handleDisplayJobDetails}
        registerGrid={viewController.setGridToParent}
        fetchParams={fetchParams}
        pagination={props.pagination}
        onFetchFail={viewController.onFetchFail}
        defFilterParams={props.defFilterParams}
        additionalFilters={props.additionalFilters}
      />
    </>
  );
};
AllInspectionsGrid.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  gridType: PropTypes.oneOf(['hers', 'permits', 'all']),
  viewController: PropTypes.instanceOf(Object).isRequired,
  defFilterParams: PropTypes.instanceOf(Array),
  viewJobHandler: PropTypes.func.isRequired,
  additionalFilters: PropTypes.instanceOf(Object),
};

AllInspectionsGrid.defaultProps = {
  gridType: 'all',
  defFilterParams: [],
  additionalFilters: {},
};
export default AllInspectionsGrid;
