import React from 'react';
import * as allConstants from '../../constants/allConstants';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import usePagination from '../../hooks/usePagination';
import ListPageHeader from '../../pieces/listPageHeader';
import NavBar from '../../pieces/navbar/navbar';
import {Box, ThemeProvider} from '@mui/material';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import AllCitiesGrid from './allCitiesGrid';
import {useNavigate} from 'react-router-dom';
import PermissionGate from '../../permissions/permissionGate';
import {cityRules} from './cityAccessControl';
import myTheme from '../../styles/myStyles';

export const AllCitiesView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();
  const navigate = useNavigate();


  const viewCityHandler = (id)=> {
    navigate(`${id}${location.search}`);
  };

  const headerComponent = <ListPageHeader header={'All Cities'}
    totalItems={pagination.totalItemsCount}
    addHandler={()=>navigate(allConstants.PATH.ADD_CITY)}
    showAddButton={PermissionGate({
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
      modulePermissions: cityRules.createCity
    })}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  // if(viewController.blocked) return <BlockedPage/>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllCitiesGrid
                user={viewController.user}
                viewController={viewController}
                pagination={pagination}
                viewCityHandler={viewCityHandler}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};
