import React from 'react';
// import PropTypes from 'prop-types';
// import {Box} from '@mui/material';
// import Spacer from '../../../pieces/spacer';
// import {Text} from '../../../pieces/text';

export const IpermitPermissionsPanel = (props) => {
  return (
    <>

      <div>User Permissions Panel</div>
    </>
  );
};

