import {ROLES, SCOPES, IPERMIT_ROLES, CONTRACTOR_ROLES} from './constants/allConstants';
const ELEMENTS = {
  addUserButton: 'addUserButton',
  allCompaniesLink: 'allCompaniesLink',
  companyLink: 'companyLink',
  usersLink: 'usersLink',
  adminPanelButton: 'adminPanelButton',
  depersonatButton: 'depersonateButton',
  adminPanel: 'adminPanel',
  analytics: 'analytics',
  userCompanyColumn: 'userCompanyColumn',
  impersonate: 'impersonate',
  ipermitHomeButtons: 'ipermitHomeButtons',
  contractorUserHomeButtons: 'contractorUserHomeButtons',
  allRoles: 'allRoles',
  otherRolesHomeButtons: 'otherRolesHomeButtons',
  products: 'products',
  cities: 'cities',
  contractorAdminHomeButtons: 'contractorAdminHomeButtons',
  forceAddToCRM: 'forceAddToCRM',
  inspectionsModule: 'inspectionsModule',
  permitsModule: 'permitsModule',
  jobsViewForContractor: 'jobs',
  raters: 'raters',
};


const generalRules = Object.freeze({
  [ELEMENTS.depersonatButton]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideFromSystemAdmin: true,
    hideFromIpermit: [SCOPES.canView]
  },
  [ELEMENTS.impersonate]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideFromSystemAdmin: true,
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.addUserButton]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.COMPANY_ADMIN],
    hideFromSystemAdmin: true,
  },
  [ELEMENTS.companyLink]: {
    [SCOPES.canView]: [...[ROLES.CONTRACTOR, ROLES.COMPANY_ADMIN], ...Object.values(IPERMIT_ROLES)],
    hideFromSystemAdmin: true,
    hideFromIpermit: [SCOPES.canView]
  },
  [ELEMENTS.allCompaniesLink]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.userCompanyColumn]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.adminPanelButton]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN],
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.adminPanel]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN],
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.analytics]: {
    [SCOPES.canView]: [ROLES.CONTRACTOR, ROLES.COMPANY_ADMIN, ROLES.SYSTEM_ADMIN, ...Object.values(IPERMIT_ROLES)],
  },
  [ELEMENTS.ipermitHomeButtons]: {
    [SCOPES.canView]: [...Object.values(IPERMIT_ROLES)],
    hideWhenImpersonating: [SCOPES.canView]

  },
  [ELEMENTS.contractorUserHomeButtons]: {
    [SCOPES.canView]: [ROLES.CONTRACTOR],
    hideWhenImpersonating: [SCOPES.canView],
    hideFromIpermit: [SCOPES.canView],
  },
  [ELEMENTS.contractorAdminHomeButtons]: {
    [SCOPES.canView]: [...Object.values(IPERMIT_ROLES), ...[ROLES.COMPANY_ADMIN, ROLES.SYSTEM_ADMIN]],
    hideFromSystemAdmin: true,
    hideFromIpermit: [SCOPES.canView]
  },
  [ELEMENTS.usersLink]: {
    [SCOPES.canView]: [...Object.values(IPERMIT_ROLES), ...[ROLES.COMPANY_ADMIN]],
  },
  [ELEMENTS.forceAddToCRM]: {
    [SCOPES.actionAllowed]: [ROLES.SYSTEM_ADMIN]
  },

  [ELEMENTS.products]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.inspectionsModule]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.permitsModule]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideWhenImpersonating: [SCOPES.canView],
  },
  [ELEMENTS.jobsViewForContractor]: {
    [SCOPES.canView]: [...Object.values(CONTRACTOR_ROLES), ...Object.values(IPERMIT_ROLES)],
    // hideFromIpermit: [SCOPES.canView],
  },

  [ELEMENTS.cities]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.raters]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideWhenImpersonating: [SCOPES.canView]
  },



});

export {generalRules, ELEMENTS};
