import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, ButtonBase, Typography} from '@mui/material';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';


export const ListViewHeader = (props) => {
  const {extendedFilters, handleClick, gridEnum, menuController, title, viewController} = props;
  return (
    <>
      <Box display={'flex'} alignItems={'center'} gap={1}>
        {extendedFilters && extendedFilters.controller}
        <ButtonBase disableRipple={false} onClick={handleClick}>
          <Typography variant={'h5'} sx={{marginRight: '16px'}}>{title}</Typography>
          {gridEnum && gridEnum.length !== 1 && menuController.controller}
        </ButtonBase>
        {menuController && menuController.menuItems}
        <Typography>Export:</Typography>
        {viewController && <>
          <Button sx={{minWidth: 'unset', padding: '4px', color: STYLE_CONSTANTS.COLORS.linkColor}}
            onClick={viewController.exportAll}>Page</Button>
          <Typography>or</Typography>
          <Button sx={{minWidth: 'unset', padding: '4px', color: STYLE_CONSTANTS.COLORS.linkColor}}
            onClick={viewController.exportChecked}>Checked</Button>
        </>}

      </Box>
    </>
  );
};

ListViewHeader.propTypes = {
  extendedFilters: PropTypes.instanceOf(Object),
  handleClick: PropTypes.func,
  gridEnum: PropTypes.arrayOf(PropTypes.string),
  menuController: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  viewController: PropTypes.instanceOf(Object),
};

ListViewHeader.defaultProps = {
  extendedFilters: null,
  handleClick: null,
  gridEnum: null,
  menuController: null,
  title: '',
  viewController: null,
};
