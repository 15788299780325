import React, {useEffect, useState} from 'react';
import base_url, {urlSearchParams} from '../../../baseUrls';
import ApiUtils from '../../../utils/apiUtils';
import axios from 'axios';
import Loader from '../../misc/loader';
import {useParams} from 'react-router-dom';
import NavBar from '../../../pieces/navbar/navbar';
import {JobContextProvider} from '../../../contextProviders/jobContext';
import * as allConstants from '../../../constants/allConstants';
import InspectionRecord from './recordViews/inspection/inspectionRecord';
import {ThemeProvider} from '@mui/material';
import Utils from '../../../utils/utils';
import CF1RRecord from './recordViews/cf1r/cf1rRecord';
import AllJobsRecord from './recordViews/permit/allJobsRecord';
import myTheme from '../../../styles/myStyles';

export const IpermitJobView = (props)=> {

  const [job, setJob] = useState();
  const [fetching, setFetching] = useState(false);
  const route = useParams();
  const module = Utils.getUrlParameter(urlSearchParams.moduleName);
  const subModule = Utils.getUrlParameter(urlSearchParams.submodule);

  useEffect(() => {
    const fetch = async () => {
      let url = `${base_url.api}jobs/${route.id}`;
      const authToken = await ApiUtils.getAccessToken();
      const requestParams = {populate: ['inspection']};
      const jobResponse = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
      return jobResponse;
    };

    setFetching(true);
    fetch()
      .then((data) => {
        console.log('got job', data.data.data.job);
        setJob(data.data.data.job);
        // setNotes(data.data.data.job.notes);
        setFetching(false)
        ;
      })
      .catch((error) => {
        // props.parentFetching(false);
        setFetching(false);
        console.log('ERR:::', error);
      });
  }, []);

  if(fetching) return <Loader/>;
  if(!job) return null;

  return (
    <>
      <NavBar/>

      <ThemeProvider theme={myTheme}>
        <JobContextProvider job={job}>
          {module === allConstants.IPERMIT_MODULES.INSPECTION && <InspectionRecord/>}
          {subModule === allConstants.IPERMIT_MODULES.CF1R && <CF1RRecord/>}
          {subModule === allConstants.IPERMIT_MODULES.ALL_JOBS && <AllJobsRecord/>}

        </JobContextProvider>
      </ThemeProvider>
    </>
  );
};
