import {SCOPES, IPERMIT_ROLES} from '../../constants/allConstants';
const ELEMENTS = {
  addNote: 'addNote',
  addAttachment: 'addAttachment',
  editCity: 'editCity',
  createCity: 'createCity',
};

const cityRules = Object.freeze({
  [ELEMENTS.addNote]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.createCity]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
});

export default ELEMENTS;
export {cityRules};
