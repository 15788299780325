import {ROLES, SCOPES, IPERMIT_ROLES} from '../../constants/allConstants';
const ELEMENTS = {
  viewPage: 'viewPage',
  addUserButton: 'addUserButton',
  changeCompanyAdmin: 'changeCompanyAdmin',
  // userCompanyColumn: 'userCompanyColumn',
  impersonate: 'impersonate',
  deleteUser: 'deleteUser',
  resetPassword: 'resetPassword',

};


const userPageRules = Object.freeze({
  [ELEMENTS.viewPage]: {
    [SCOPES.canView]: [...Object.values(IPERMIT_ROLES), ...[ROLES.COMPANY_ADMIN]],
  },
  [ELEMENTS.resetPassword]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
    hideWhenImpersonating: [SCOPES.canView],
  },

  [ELEMENTS.impersonate]: {
    [SCOPES.canView]: Object.values(IPERMIT_ROLES),
    hideFromSystemAdmin: true,
  },
  [ELEMENTS.addUserButton]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.COMPANY_ADMIN]
  },
  [ELEMENTS.changeCompanyAdmin]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.COMPANY_ADMIN]
  },
  [ELEMENTS.deleteUser]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.COMPANY_ADMIN]
  }
});

export {userPageRules, ELEMENTS};
