import React from 'react';
import * as allConstants from '../../../constants/allConstants';
import Plumbing from './plumbing/plumbing';
import Electrical from './electrical/electrical';
import Mechanical from './mechanical/mechanical';
import SolarFormBlock from './solar/solarFormBlock';
import TempPowerFormBlock from './tempPower/tempPower';
import Reroof from './reroof/reroof';

export const EquipmentStep = (props)=> {
  const {jobType} = props;
  let details;
  switch (jobType) {
  case allConstants.JOB_TYPES.PLUMBING.value: {
    details = <Plumbing {...props}/>;
    break;
  }
  case allConstants.JOB_TYPES.ELECTRICAL.value: {
    details = <Electrical {...props}/>;
    break;
  }
  case allConstants.JOB_TYPES.HVAC_RES.value:
  case allConstants.JOB_TYPES.TESTING_ONLY.value: {
    details = <Mechanical {...props}/>;
    break;
  }
  case allConstants.JOB_TYPES.SOLAR.value: {
    details = <SolarFormBlock {...props}/>;
    break;
  }
  case allConstants.JOB_TYPES.TEMPORARY_POWER.value: {
    details = <TempPowerFormBlock {...props}/>;
    break;
  }
  case allConstants.JOB_TYPES.REROOF.value: {
    details = <Reroof {...props}/>;
    break;
  }

  default: {
    details = <p>form not added yet</p>;
  }
  }

  return details;
};