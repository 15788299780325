import React, {useState, useCallback} from 'react';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as allConstants from '../../constants/allConstants';
import PermissionGate from '../../permissions/permissionGate';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import ListPageHeader from '../../pieces/listPageHeader';
import usePagination from '../../hooks/usePagination';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import {productsPageRules} from './productsPageAccessControl';
import AllProductsGrid from './allProductsGrid';
import ProductForm from './productForm';
import ProductModal from '../../pieces/modals/productModal';
import ConfirmDelete from '../../pieces/modals/confirmDelete';
import base_url from '../../baseUrls';

const AllProductsView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();

  const [showAddProductPopup, setShowAddProductPopup] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const handleConfirmDeletePopupClose = () => setShowConfirmDeletePopup(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleAddProductOpen = useCallback(() => setShowAddProductPopup(true), []);
  const handleAddProductClose = () => setShowAddProductPopup(false);


  const [showEditProductPopup, setShowEditProductPopup] = useState(false);
  const handleEditProductClose = () => setShowEditProductPopup(false);


  const [selectedProduct, setSelectedProduct] = useState(undefined);


  const editProductHandler = (data)=> {
    setSelectedProduct(data);
    setShowEditProductPopup(true);
  };

  const editProductCancel = ()=> {
    setSelectedProduct(undefined);
    setShowEditProductPopup(false);
  };

  const handleSuccess = ()=> {
    if(showAddProductPopup || showEditProductPopup || showConfirmDeletePopup) {
      window.location.reload();
    }
  };

  const deleteProductHandler = (data)=> {
    setSelectedProduct(data);
    setShowConfirmDeletePopup(true);
  };

  const getDeleteURL = ()=> {
    if(!selectedProduct) return null;
    return `${base_url.api}products/delete`;
  };

  const deleteCancelHandler = ()=> {
    console.log('Cancel Called');
    setSelectedProduct(undefined);
    setShowConfirmDeletePopup(false);
  };

  const headerComponent = <ListPageHeader header={'All Products'}
    addHandler={handleAddProductOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {PermissionGate ({
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
      modulePermissions:productsPageRules.addProductButton})}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  // if(viewController.blocked) return <BlockedPage/>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>

            <ProductModal open={showEditProductPopup}
              handleClose={handleEditProductClose}
              header={'Edit product'}
              preventScroll={false}
            >
              <ProductForm closeModal={handleEditProductClose}
                editMode={true}
                successAction={handleSuccess}
                productData={selectedProduct}/>
            </ProductModal>

            <ProductModal open={showAddProductPopup}
              handleClose={handleAddProductClose}
              header={'Create product'}
              preventScroll={false}
            >
              <ProductForm closeModal={handleAddProductClose}
                successAction={handleSuccess}
                editMode={false}/>
            </ProductModal >

            <ConfirmDelete deleteUrl={getDeleteURL()}
              open={showConfirmDeletePopup}
              submitDeleteAction={handleConfirmDeletePopupClose}
              handleCancel={deleteCancelHandler}
              successAction={handleSuccess}
              idToDelete={selectedProduct?.id}
              entityName={'product'}
              recordName={selectedProduct ? selectedProduct.name : ''}
            />

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllProductsGrid
                // user={viewController.user}
                editProductHandler={editProductHandler}
                editProductCancel={editProductCancel}
                viewController={viewController}
                deleteProductHandler={deleteProductHandler}
                deleteProductCancel={deleteCancelHandler}

                // setBlocked={viewController.setBlocked}
                pagination={pagination}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};
export default AllProductsView;
