import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../../styles/styleConstants';
import {Table, TableBody, Typography, Grid, Paper, Button, Box, IconButton, TableHead} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {globalUseClasses} from '../../../../styles/globalClasses';
import {useJobDetailViewStyles} from './jobDetailViewStyles';
import base_url, {urlSearchParams} from '../../../../baseUrls';
import ApiUtils from '../../../../utils/apiUtils';
import axios from 'axios';
import Utils from '../../../../utils/utils';
import Notes from '../tabs/noteTab';
import Attachments from '../tabs/attachmentsTab';
import PermitInfo from '../tabs/permitInfoTab';
import CallLogs from '../tabs/callLogsTab';
import JobDetails from '../tabs/jobDetailsTab';
import GeneralInformation from '../tabs/generalInformationTab';
import MapTab from '../tabs/mapTab';
import Inspections from '../tabs/inspectionsTab';
import TabsBlock from '../../../../pieces/tabsBlock';
import Loader from '../../../misc/loader';
import {FormLine} from '../../../../pieces/displayJob/formLine';
import {StatusRow} from '../../../../pieces/statusRow';
import {useNavigate} from 'react-router-dom';
import GlobalStyles from '@mui/material/GlobalStyles';
import PermissionGate from '../../../../permissions/permissionGate';
import JOB_ELEMENTS, {jobRules} from '../../jobsAccessControl';
import {companyAndUserUtils} from '../../../../utils/companyAndUserUtils';
import {useSelector} from 'react-redux';
import {SCROLLBAR_STYLES} from '../../../../styles/scrollBar';
import {CreatedByAndWhen, DuplicateOf, JobTypeAndNumber} from '../../components/headerComponents';
import {PrintButton} from '../../../../pieces/printButton';
import {JobContextProvider} from '../../../../contextProviders/jobContext';
import {ScheduledDatesWrapper} from '../../../wrappers/scheduledDatesWrapper';
import {orderInspection, jobHasInspection, canDoInspection} from '../../helpers/requestCityInspection';

function JobDetailView(props) {
  const navigate = useNavigate();
  const user = useSelector(state=> state.user);

  const classes = {...globalUseClasses(), ...useJobDetailViewStyles()};
  const [job, setJob] = useState();
  const [fetching, setFetching] = useState(false);
  const [notes, setNotes] = useState([]);
  // const [tabValue, setTabValue] = useState(0);
  const jobId = props.jobId;
  const {JOB_FIELDS, JOB_TYPES} = allConstants;
  const isHers = job && (job[allConstants.JOB_FIELDS.JOB_TYPE.api_name] === allConstants.JOB_TYPES.TESTING_ONLY.value ||
    (job[allConstants.JOB_FIELDS.JOB_TYPE.api_name] === allConstants.JOB_TYPES.HVAC_RES.value &&
      (job?.[allConstants.JOB_FIELDS.HERS_STATUS] || job[allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name])));
  const isBusinessLicense = job && job[allConstants.JOB_FIELDS.JOB_TYPE.api_name] === allConstants.JOB_TYPES.BUSINESS_LIC.value;
  const isTestOnly = job && job[allConstants.JOB_FIELDS.JOB_TYPE.api_name] === allConstants.JOB_TYPES.TESTING_ONLY.value;
  const jobInspection = job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name];

  const isHERSScheduled = Utils.valueIsSpecified(job?.[JOB_FIELDS.HERS_SCHEDULED_DATE.api_name]);
  const isInspectionScheduled = Utils.valueIsSpecified(job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]?.[allConstants.SCHEDULING_FIELDS.START_DATE.api_name]);

  const addNote = (noteObj) => {
    const notesArray = notes;
    notesArray.push(noteObj);
    setNotes(notesArray);
  };

  const [currErrors, setCurrentErrors] = useState({});

  const removeError = (errorKey) => {
    const newErrors = {...currErrors};
    if(Utils.objectHasProperty(newErrors, errorKey)){
      delete newErrors[errorKey];
      setCurrentErrors(newErrors);
    }
  };

  const jobTabs = [
    {...allConstants.DISPLAY_JOB_TABS.NOTES, ...{element: Notes}},
    {...allConstants.DISPLAY_JOB_TABS.ATTACHMENTS, ...{element: Attachments}},
    {...allConstants.DISPLAY_JOB_TABS.PERMIT_INFO, ...{element: PermitInfo,  shouldShow: (jobType)=> jobType !== allConstants.JOB_TYPES.TESTING_ONLY.value}},
    {...allConstants.DISPLAY_JOB_TABS.CALL_LOGS, ...{element: CallLogs, shouldShow: ()=> isHers}},
    {...allConstants.DISPLAY_JOB_TABS.JOB_DETAILS, ...{element: JobDetails, shouldShow: ()=> !isBusinessLicense}},
    {...allConstants.DISPLAY_JOB_TABS.GENERAL_INFO, ...{element: GeneralInformation}},
    {...allConstants.DISPLAY_JOB_TABS.MAP, ...{element: MapTab, shouldShow: ()=>Utils.jobShouldHaveMap(job)}},
    {...allConstants.DISPLAY_JOB_TABS.INSPECTIONS, ...{element: Inspections, shouldShow: ()=> jobInspection !== undefined}},

  ];
  const content = jobTabs.filter(t=> !t.shouldShow || t.shouldShow()).map((t, idx)=> {

    const tab = {
      name: t.label,
      api_name: t.name,
      label: t.label
    };

    if(t.name === allConstants.DISPLAY_JOB_TABS.MAP.name) {
      tab.errors = currErrors.isMissingMap;
    }

    if (t.label === 'Notes') {
      tab.panel = ()=>t.element({...job, jobNotes: notes, addNote: addNote});
    } else {
      tab.panel =  ()=>t.element({...job, ...{isMissingMap: currErrors.isMissingMap, removeErrorAction: ()=>removeError('isMissingMap')}});
    }
    return tab;

  });

  const formatNumericDateString = (date) => {
    return Utils.formatIsoDateStringWithOptions(date, {year: 'numeric', month: '2-digit', day: '2-digit'});
  };

  useEffect(() => {
    const fetch = async () => {
      let url = `${base_url.api}jobs/${jobId}`;
      const authToken = await ApiUtils.getAccessToken();
      const requestParams = {populate: ['inspection']};
      const jobResponse = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
      return jobResponse;
    };

    setFetching(true);
    fetch()
      .then((data) => {
        console.log('got job', data.data.data.job);
        setJob(data.data.data.job);
        setNotes(data.data.data.job.notes);
        setFetching(false)
        ;
      })
      .catch((error) => {
        // props.parentFetching(false);
        setFetching(false);
        console.log(error);
      });
  }, []);

  useEffect(()=> {
    if(job) {
      setCurrentErrors({...currErrors, ...{
        isMissingMap: Utils.isServiceTitanJobWithMissingMap(job)
      }
      });
    }
  }, [job]);

  if(fetching) return <Loader/>;
  if(!job) return null;

  let bLicCity;
  if(isBusinessLicense) {
    const json = job?.[JOB_FIELDS.GENERAL.JOB_JSON.api_name];
    if(json) {
      const details = JSON.parse(json);
      const city = details?.[JOB_TYPES.BUSINESS_LIC.api_name]?.[JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name];
      if(Utils.valueIsSpecified(city)) {
        bLicCity = {label: JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.display_label, value: city};
      }
    }
  }

  const getAddressString = ()=> {
    const address = job?.[allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name];
    return Utils.getAddressString(address);
  };

  const dupicateJob = ()=> {
    const id = props.jobId;
    navigate(`${allConstants.PATH.CREATE_JOB}?${urlSearchParams.duplicateOfId}=${id}`);
  };


  const shouldShowDuplicate = PermissionGate ({
    scope: allConstants.SCOPES.canView,
    role: user.role,
    modulePermissions:jobRules[JOB_ELEMENTS.addJobButton],
    companyId:user.companyId}) && companyAndUserUtils.canAddNewEntityToCompany(job.company);

  const canRequestInspection = PermissionGate ({
    scope: allConstants.SCOPES.canView,
    role: user.role,
    modulePermissions:jobRules[JOB_ELEMENTS.requestInspection],
    companyId:user.companyId});

  const statusIsNotNew = (status)=>{
    return status && !Utils.equalsIgnoreCase(status, allConstants.PERMIT_STATUSES.NEW.value);
  };

  const updateJobWithInspectionData = (inspectionData)=> {
    if(!inspectionData) {
      return;
    }
    const updatedJob = {...job, ...{
      [allConstants.INSPECTION_FIELDS.INSPECTION.api_name]: inspectionData
    }};

    setJob(updatedJob);
  };


  return (
    <>
      <JobContextProvider job={job}>
        <GlobalStyles
          styles={SCROLLBAR_STYLES}
        />
        <Grid container xs={12} sm={12} md={12} lg={12} sx={{textTransform: 'uppercase'}}>
          {/* <Box display={'flex'} flexDirection={'row'}> */}
          <Grid container xs={12} sm={12} md={12} lg={12} mb={{xs: '8px', md: '16px'}}>
            <Grid item xs={12} sm={12} md={3}>
              <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} gap={1} height={'100%'}>
                <Box>
                  <Typography sx={{fontSize: '14px', fontWeight: 'bold', textAlign: {xs: 'center', sm: 'center', md: 'inherit'}}}>{job?.company.name}</Typography>
                  <DuplicateOf job={job} link={`${location.origin}/jobs?job=${job?.[JOB_FIELDS.DUPLICATE_OF.api_name]?.jobId}`}/>
                </Box>
                {job?.[allConstants.JOB_FIELDS.SERVICE_TITAN_ID.api_name] && <Box>
                  <Typography sx={{fontWeight: '900', fontSize: {xs: '14px', md: '16px'}, color: STYLE_CONSTANTS.COLORS.globalBlue}}>Service Titan</Typography>
                </Box>}

              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={0.5}>
                <JobTypeAndNumber job={job}/>
                {/*<Typography variant='h1' sx={{fontSize: {xs: '18px', md: '20px'}, fontWeight: 'bold'}}>{modifyJobTypeToDisplay(job?.jobType)} : #{job?.jobNumber}</Typography>*/}
                <CreatedByAndWhen job={job}/>
                {!isTestOnly && <Box display={'flex'} justifyItems={'center'} gap={2}
                  sx={{fontWeight: '600',
                    '& .MuiTypography-root': {
                      fontSize: {xs: '14px', md: '16px'}
                    },
                  }}>
                  <Typography variant='h5'>{allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.display_label}:</Typography>
                  {job?.[allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name] && <Typography variant='h5' sx={{color: STYLE_CONSTANTS.COLORS.globalBlue}}>
                    {job?.[allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name]?.[allConstants.CITY_FIELDS.NAME.api_name]}, {job?.address?.state}
                  </Typography>}
                </Box>}
              </Box>

            </Grid>
            <Grid item xs={12} sm={12} md={4} display={'flex'} justifyContent={'flex-end'}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'} gap={1} justifyContent={'flex-end'}>
                {canRequestInspection && <Button variant="outlined"
                  color={'secondary'}
                  sx={STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON}
                  disabled={jobHasInspection(job) || !canDoInspection(job)}
                  onClick={()=> orderInspection(job?.[allConstants.JOB_FIELDS.ID.api_name], updateJobWithInspectionData)}
                >REQUEST CITY INSPECTION</Button>}
                {shouldShowDuplicate && <Button variant="outlined"
                  color={'primary'}
                  sx={STYLE_CONSTANTS.STYLES.CUSTOM_BUTTON}
                  onClick={dupicateJob}
                >DUPLICATE JOB</Button>}
                <PrintButton job={job}/>

              </Box>
              <Box ml={{xs: '16px', sm: '24px'}}>
                <IconButton
                  sx={{
                    backgroundColor:STYLE_CONSTANTS.COLORS.backgroundMain,
                    width: '34px',
                    height: '34px'
                  }}
                  onClick={props.closeModal}>
                  <CloseIcon/>
                </IconButton>
              </Box>

            </Grid>
          </Grid >
          <Grid container spacing={2}  sx={{height: 'fit-content'}}>
            <Grid item xs={12} sm={6} md={6} pt={0}>
              <Paper className={classes.paper_style}>
                <Box display={'flex'} flexDirection={'column'} gap={1} p={2} >
                  <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                    {bLicCity && <>
                      <FormLine label={bLicCity.label} value={bLicCity.value}/>
                    </>}
                    <Typography sx={{color: STYLE_CONSTANTS.COLORS.globalBlue, fontSize:'16px'}}>{job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.firstName} {job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.lastName}</Typography>
                  </Box>


                  <Box display={'flex'} flexDirection={'column'} gap={0.5}>
                    {job?.address &&  <Typography>{getAddressString()}</Typography>}
                    {job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name] && <>
                      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Typography>{Utils.formatPhoneNumber(job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name].phoneNumber)}</Typography>
                        {job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name].altPhoneNumber && <Typography sx={{textTransform: 'uppercase'}}>ALT : {Utils.formatPhoneNumber(job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name].altPhoneNumber)}</Typography>}

                      </Box>
                      <Box display={'flex'} flexDirection={'row'}>
                        <Typography sx={{textTransform: 'uppercase', wordBreak: 'break-all'}}>{job?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.email}</Typography>
                      </Box>
                    </>}

                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}  pt={0}>
              <Paper className={classes.paper_style} sx={{height: '100%'}}>
                <Box display={'flex'} p={2} sx={{paddingTop: '8px !important'}}>
                  <Table  size="small" aria-label="status table">
                    <TableHead sx={{
                      '& .MuiTableCell-root': {
                        textTransform: 'none',
                        color: STYLE_CONSTANTS.COLORS.globalBlue,
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }
                    }}>
                      <StatusRow label={'Department'} status={'Status'} date={'Change Date'} header={true}/>
                    </TableHead>
                    <TableBody>
                      {!isTestOnly && <StatusRow label={allConstants.JOB_FIELDS.GENERAL.PERMIT.label}
                        status={job[allConstants.JOB_FIELDS.PERMIT_STATUS.api_name]}
                        date={job?.[allConstants.JOB_FIELDS.PERMIT_STATUS_CHANGE_DATE.api_name] ? formatNumericDateString(job[allConstants.JOB_FIELDS.PERMIT_STATUS_CHANGE_DATE.api_name]) : ''}/>}
                      {isHers && <StatusRow label={allConstants.JOB_FIELDS.GENERAL.HERS.label}
                        status={job[allConstants.JOB_FIELDS.HERS_STATUS.api_name]}
                        date={statusIsNotNew(job?.[allConstants.JOB_FIELDS.HERS_STATUS.api_name]) && job?.[allConstants.JOB_FIELDS.HERS_STATUS_CHANGE_DATE.api_name] ? formatNumericDateString(job[allConstants.JOB_FIELDS.HERS_STATUS_CHANGE_DATE.api_name] ) : ''}/>
                      }
                      <StatusRow label={allConstants.JOB_FIELDS.GENERAL.MAILING.label}
                        status={job[allConstants.JOB_FIELDS.MAILING_STATUS.api_name]}
                        date={statusIsNotNew(job?.[allConstants.JOB_FIELDS.MAILING_STATUS.api_name]) && job?.[allConstants.JOB_FIELDS.MAILING_STATUS_CHANGE_DATE.api_name] ? formatNumericDateString(job[allConstants.JOB_FIELDS.MAILING_STATUS_CHANGE_DATE.api_name] ) : ''}/>
                      {jobInspection && <StatusRow label={allConstants.INSPECTION_FIELDS.INSPECTION.label}
                        status={jobInspection[allConstants.INSPECTION_FIELDS.INSPECTION_STATUS.api_name]}
                        date={jobInspection?.[allConstants.INSPECTION_FIELDS.INSPECTION_STATUS_CHANGE_DATE.api_name] ? formatNumericDateString(jobInspection[allConstants.INSPECTION_FIELDS.INSPECTION_STATUS_CHANGE_DATE.api_name]) : ''}/>
                      }
                    </TableBody>
                  </Table>
                </Box>
              </Paper>
            </Grid>

          </Grid>
          {/*scheduled dates block*/}
          {(isInspectionScheduled || isHERSScheduled) &&
          <Grid item xs={12} mt={2}>
            <ScheduledDatesWrapper HERSDate={job?.[JOB_FIELDS.HERS_SCHEDULED_DATE.api_name]}
              inspectionData={job?.[allConstants.INSPECTION_FIELDS.INSPECTION.api_name]}
              timezone={job?.[JOB_FIELDS.TIMEZONE.api_name]}
            />
          </Grid>
          }
          <Grid item xs={12} sm={12} md={12} lg={12} mt={2} sx={{textTransform: 'uppercase !important', height: 'fit-content'}}>
            <TabsBlock content={content}
              fullWidth
            // setModalScrollable={props.setModalScrollable}
            />
          </Grid>
        </Grid>
      </JobContextProvider>
    </>
  );
}

JobDetailView.propTypes = {
  jobId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

export default JobDetailView;
