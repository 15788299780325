import React, {useEffect, useState} from 'react';
import {Box, LinearProgress, Typography} from '@mui/material';
import axios from 'axios';
import clsx from 'clsx';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import base_url from '../../../../../baseUrls';
import * as allConstants from '../../../../../constants/allConstants';
import {
  JOB_FIELDS,
  PERMIT_MODULE_FIELDS
} from '../../../../../constants/allConstants';
import {useJob} from '../../../../../contextProviders/jobContext';
import {Card} from '../../../../../pieces/card';
import Spacer from '../../../../../pieces/spacer';
import {globalUseClasses} from '../../../../../styles/globalClasses';
import * as STYLE_CONSTANTS from '../../../../../styles/styleConstants';
import ApiUtils from '../../../../../utils/apiUtils';
import Utils from '../../../../../utils/utils';
import Loader from '../../../../misc/loader';
import {IpermitJobWrapper, showChildrenPosition} from '../../../components/ipermitJobWrapper';
import {Text, TextWithIconWrapper} from '../../../../../pieces/text';
import {useForm, FormProvider} from 'react-hook-form';
import CustomFormBlock from '../../../../../pieces/inputs/formBlock';
import {useEditRecord} from '../../../../../hooks/useEditRecord';
import {JobActionButtonsContained, JobActionButtonsOutlined} from '../../../../../pieces/jobActionButtons';
import {ReactComponent as Cf1rIcon} from '../../../../../static/images/cf1rIcon.svg';
import {ReactComponent as InfoIconSvg} from '../../../../../static/images/infoIcon.svg';
import {Cf1rPopupWindow} from './cf1rPopupWindow';
import FormSubmitButtons from '../../../../../pieces/formSubmitButtons';
import {inputTypes} from '../../../../../pieces/inputs/formInput';
import {AddCf1rPopupWindow} from './addCf1rFile';

const CF1RRecord = ()=>{
  const classes = {...globalUseClasses()};
  const [fetching, setFetching] = useState(false);
  const [updating, setUpdating] = useState(false);
  const {job, setJob, ...jobContext} = useJob();
  const route = useParams();
  const [zones, setZones] = useState([]);

  useEffect(async () => {
    const zoneOptionsFetch = await ApiUtils.makeApiCall('GET', 'autocomplete/get-zones', undefined);
    if(zoneOptionsFetch?.status === 'success' && zoneOptionsFetch?.data?.zones) {
      setZones(zoneOptionsFetch?.data?.zones);
    }
  },[]);


  const [creatingCf1r, setCreatingCf1r] = useState(false);
  const [createCf1rError, setCreateCf1rError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);


  useEffect(() => {
    const fetch = async () => {
      let url = `${base_url.api}jobs/${route.id}`;
      const authToken = await ApiUtils.getAccessToken();
      const requestParams = {populate: ['inspection']};
      const jobResponse = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
      return jobResponse;
    };

    setFetching(true);
    fetch()
      .then((data) => {
        console.log('got job', data.data.data.job);
        setJob(data.data.data.job);
        // setNotes(data.data.data.job.notes);
        setFetching(false)
        ;
      })
      .catch((error) => {
        // props.parentFetching(false);
        setFetching(false);
        setCreateCf1rError(true);
        console.log('ERR:::', error);
      });
  }, []);



  const getRow = (field, options)=> {
    const {label} = field;
    if(options?.value) {
      return {label:label, value: options.value};
    }
    const value = options?.parent ? job?.[options.parent]?.[field.api_name] : job?.[field.api_name];
    const displayValue = typeof value === 'undefined' ? '-' : Utils.reformatValue(value);
    return{
      label: label, value: displayValue};
  };

  const cf1rForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
  });

  const {edit, EditButton, cancelEdit} = useEditRecord();

  const cf1rFormCommonProps = {
    fullWidth: true,
    maxWidth: '100%',
    isEditable: true,
    renderLabel: true,
    viewMode: !edit
  };

  const generalFields = (()=> {
    const NaValue = 'N/A';
    const col1 = [
      getRow(JOB_FIELDS.GENERAL.PROPERTY_TYPE),
      getRow(JOB_FIELDS.GENERAL.SQFT),
      getRow(JOB_FIELDS.GENERAL.JOB_VALUE, {value: job?.[JOB_FIELDS.GENERAL.JOB_VALUE.api_name] ? `$${job?.[JOB_FIELDS.GENERAL.JOB_VALUE.api_name]}` : '-'}),
      {label: 'Contractor\'s email', value: job[JOB_FIELDS.ORDERED_BY.api_name]?.[allConstants.USER_FIELDS.EMAIL.api_name], longLabel: true}
    ];

    const col2 = [
      getRow(JOB_FIELDS.GENERAL.ASBESTOS, {value: jobContext.isHVACJob() ? undefined : NaValue}),
      getRow(JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED, {value: jobContext.isPermitOnly() ? NaValue : undefined}),
      getRow(JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED, {value: jobContext.isPermitOnly() ? NaValue : undefined}),

    ];
    return [col1, col2];

  })();
  const jobHasMoreThanOneSystem = ()=> {
    return job?.[JOB_FIELDS.MECHANICAL.SYSTEM_COUNT.api_name] > 1;
  };

  const jobHasCheersProject = ()=> {
    const cheersProject = job?.[JOB_FIELDS.CHEERS.api_name];
    if(!cheersProject) return false;
    return cheersProject?.[JOB_FIELDS.CHEERS_CF1R_DOCUMENT_ID.api_name];
  };

  const createCf1r = async (jobId)=> {
    if(!jobId) return;
    setCreatingCf1r(true);
    setShowModal(true);
    const response = await ApiUtils.makeApiCall('POST',
      'ipermit/jobs/cf1r/create',
      JSON.stringify({jobId: jobId}));
    if(response.status === 'error') {
      toast.error(response.message);
      setCreatingCf1r(false);
    } else {
      const cheersData = response.data;
      //documentId, documentUrl, projectUrl
      const updatedJob = {...job};
      updatedJob[JOB_FIELDS.CHEERS.api_name] = updatedJob[JOB_FIELDS.CHEERS.api_name] || {};
      updatedJob[JOB_FIELDS.CHEERS.api_name][JOB_FIELDS.CHEERS_CF1R_DOCUMENT_ID.api_name] = cheersData.documentId;
      updatedJob[JOB_FIELDS.CHEERS.api_name][JOB_FIELDS.CHEERS_CF1R_DOCUMENT_URL.api_name] = cheersData.documentUrl;
      updatedJob[JOB_FIELDS.CHEERS.api_name][JOB_FIELDS.CHEERS_CF1R_PROJECT_URL.api_name] = cheersData.projectUrl;

      setJob(updatedJob);
      setCreatingCf1r(false);

      toast.success('CF1R created successfully');
    }
  };

  const cf1rButtonAction = async ()=> {
    if(!jobHasCheersProject()) {
      await createCf1r(job[JOB_FIELDS.ID.api_name]);
    } else {
      if(job[JOB_FIELDS.CHEERS.api_name]?.[JOB_FIELDS.CHEERS_CF1R_PROJECT_URL.api_name]) {
        Utils.openInNewTab(job[JOB_FIELDS.CHEERS.api_name]?.[JOB_FIELDS.CHEERS_CF1R_PROJECT_URL.api_name]);
      } else {
        toast.error('CF1R document is not available');
      }
    }
  };

  const editCf1r = async (data)=> {

    setUpdating(true);
    const payload = {
      jobId: job[JOB_FIELDS.ID.api_name],
      zone: data[PERMIT_MODULE_FIELDS.ZONE.api_name],
      mech01: data[PERMIT_MODULE_FIELDS.MECH01.api_name],
    };

    const response = await ApiUtils.makeApiCall('PATCH', 'ipermit/jobs/cf1r/edit-cf1r-fields', JSON.stringify(payload));
    if(response.status === 'error') {
      toast.error(response.message);
      setUpdating(false);
    } else {
      setUpdating(false);
      toast.success('Job updated successfully');
      setJob(response.data.job);
      cancelEdit();

    }
  };

  const getExistingCf1RDocument = ()=> {
    const attachments = [...job?.attachments];
    if(!attachments || attachments.length === 0) return null;
    const cf1rAttachment = attachments.find(attachment => attachment.type === allConstants.ATTACHMENT_TYPES.CF1R.value);
    if(!cf1rAttachment) return null;
    return cf1rAttachment;
  };

  if(fetching) return <Loader timeout={10}/>;
  if(!job) return null;
  const buttonWidth= '130px';
  return (
    <>
      <Box className={clsx(classes.page)}>
        <IpermitJobWrapper module={allConstants.IPERMIT_MODULES.CF1R}
          backPath={`${allConstants.PATH.MODULE}${location.search}`}
          generalInfo={generalFields}
          showChildrenPosition={showChildrenPosition.afterGeneral}
        >
          <>
            {showModal && <Cf1rPopupWindow isLoading={creatingCf1r}
              cheersData={job?.[JOB_FIELDS.CHEERS.api_name]}
              isError={createCf1rError}
              tryAgain={async ()=>{await createCf1r(job[JOB_FIELDS.ID.api_name]);}}
              close={()=>{
                setShowModal(false);
                setCreateCf1rError(false);
              }}/>}
            {showFileUploadModal && <AddCf1rPopupWindow
              close={()=>{
                setShowFileUploadModal(false);
              }}
              jobId={job[JOB_FIELDS.ID.api_name]}
              existingCf1rDocument={getExistingCf1RDocument()}
            />}
            <Spacer y={3}/>
            <Card
              padding={{xs: '8px', md: '16px'}}
              styles={{
                height: 'unset !important',
                minHeight: '200px !important',
              }}
              header={
                <Box display={'flex'} flexDirection={'row'} >
                  <TextWithIconWrapper icon={<Cf1rIcon/>} iconColor={STYLE_CONSTANTS.COLORS.globalBlue}>
                    <Text text={'CF1R'} weight={'bold'}/>
                  </TextWithIconWrapper>
                </Box>
              }>
              <Box position={'relative'}>
                <Box position={'absolute'} right={0} zIndex={100} display={'flex'} flexDirection={'column'} rowGap={'20px'}>
                  {EditButton}
                  <JobActionButtonsContained action={cf1rButtonAction}
                    text={jobHasCheersProject() ? 'View CF1R' :'Create CF1R'}
                    width={buttonWidth}
                  />
                  <JobActionButtonsOutlined action={()=>{setShowFileUploadModal(true);}}
                    text={'Upload CF1R'}
                    width={buttonWidth}
                  />
                </Box>
                <Box>
                  <Spacer y={2}/>
                  {jobHasMoreThanOneSystem() && <>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: STYLE_CONSTANTS.COLORS.chartPink,
                      padding: '6px 12px',
                      width: 'max-content',
                      maxWidth: '100%',
                      textTransform: 'none',
                      color: STYLE_CONSTANTS.COLORS.globalRed
                    }}>
                      <InfoIconSvg/>
                      <Spacer x={2}/>
                      <Typography sx={{color: STYLE_CONSTANTS.COLORS.globalRed}}>
                  For multiple systems job, please generate the CF1R and fill out the equipment in Cheers
                      </Typography>
                    </Box>
                    <Spacer y={2}/>
                  </>}

                  <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 160px',
                    columnGap: '20px',
                  }}>
                    {updating && <LinearProgress/>}
                    {!updating && <Box>
                      <FormProvider {...cf1rForm}>
                        <form onSubmit={cf1rForm.handleSubmit(editCf1r)} method={'POST'} key={edit}>
                          <Box display={'grid'} gridTemplateColumns={'repeat(3, 200px)'} gap={'20px'}>
                            <CustomFormBlock {...cf1rFormCommonProps}
                              field={PERMIT_MODULE_FIELDS.ZONE}
                              defaultValue={job?.[PERMIT_MODULE_FIELDS.ZONE.api_name]}
                              inputType={inputTypes.singleSelect}
                              options={Utils.getOptions(zones)}
                            />
                            <CustomFormBlock {...cf1rFormCommonProps}
                              field={PERMIT_MODULE_FIELDS.MECH01}
                              inputType={inputTypes.checkbox}
                              renderLabel={false}
                              defaultValue={job?.[PERMIT_MODULE_FIELDS.MECH01.api_name]}
                              labelLeft={true}

                            />
                          </Box>
                          {edit && <FormSubmitButtons cancelAction={cancelEdit} submitText={'Update'} />}

                        </form>
                      </FormProvider>
                    </Box>}
                  </Box>

                </Box>

              </Box>


            </Card>

          </>
        </IpermitJobWrapper >

      </Box>
    </>
  );
};

export default CF1RRecord;
