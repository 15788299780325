import {createAsyncThunk} from '@reduxjs/toolkit';
import base_url from '../../../baseUrls';
import handleErrorMessage from '../../handleErrorMessage';

export const forgotUserPassword = createAsyncThunk(
  'users/request-password-recover-email',
  async ({email}, thunkAPI) => {
    try {
      const url = new URL(`${base_url.api}users/request-password-recover-email`);
      url.searchParams.append('email', email);

      const response = await fetch(
        url,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
          }),
        }
      );
      console.log('forgotUserPassword Response: ', response);
      let data = await response.json();

      if (response.status === 'success') {
        return {...data};
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const forgotPasswordReducer = {
  [forgotUserPassword.fulfilled]: (state, {payload}) => {
    state.resetPasswordEmailSent = payload.isValid;
  },
  [forgotUserPassword.pending]: (state) => {
    state.isFetching = true;
  },
  [forgotUserPassword.rejected]: (state, {payload}) => {
    state.isFetching = false;
    if (payload.status === 'success') {
      state.resetPasswordEmailSent = true;
    } else {
      state.isError = true;
      state.errorMessage = handleErrorMessage(payload);
    }
  },
};
