import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import base_url, {urlSearchParams} from '../../baseUrls';
import Utils from '../../utils/utils';
import {roleTranslator} from '../../utils/translator';
import PermissionGate from '../../permissions/permissionGate';
import {generalRules} from '../../generalAccessControl';
// eslint-disable-next-line no-unused-vars
import {userPageRules} from './usersPageAccessControl';
import * as allConstants from '../../constants/allConstants';
import {useDispatch, useSelector} from 'react-redux';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import actions from '../../redux/actions';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import {USER_FIELDS} from '../../constants/allConstants';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {companyAndUserUtils} from '../../utils/companyAndUserUtils';
import {useNavigate} from 'react-router-dom';
import {MaximizableCellRenderer} from '../../pieces/grids/MaximizableCellRenderer';
import {canBeAdmin} from './setCompanyAdmin';


const AllUsersGrid = (props) => {
  const viewController = props.viewController;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const company = useSelector(state=> state.company);
  const [components] = useState({
    'statusRenderer': StatusRenderer,
    'MaximizableCellRenderer': MaximizableCellRenderer,
  });

  let columnDefs = useMemo(()=>{
    return [
      new ColumnDefinition({field: allConstants.USER_FIELDS.ID, hide: true}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.COMPANY_ID, hide: true}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.NAME,
        lockPinned: true,
        pinned: 'left',
        valueGetter: ({data})=> {return `${data.firstName} ${data.lastName}`;},
        checkboxSelection: true,
        cellRenderer: 'MaximizableCellRenderer',
        cellRendererParams: (params) => {
          return {
            handleExpand: ()=>editUserHandler(params.data),
          };
        },
      }),
      new ColumnDefinition({field: allConstants.USER_FIELDS.PHONE_NUMBER,
        valueGetter: (({data})=> {
          return Utils.formatPhoneNumber(data[allConstants.USER_FIELDS.PHONE_NUMBER.api_name]);

        })
      }),
      new ColumnDefinition({field: allConstants.USER_FIELDS.EMAIL}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.COMPANY_NAME, width: 300, wrapText: true}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.TITLE}),
      new ColumnDefinition({field: allConstants.USER_FIELDS.ROLE,
        valueGetter: (({data})=>  roleTranslator.translate(data.role)),
        filter:'agSetColumnFilter',
        filterValues: Object.values(allConstants.CONTRACTOR_ROLES).map((r)=> roleTranslator.translate(r)),
      }),

      new ColumnDefinition({field: allConstants.USER_FIELDS.STATUS, cellRenderer: 'statusRenderer',
        filter:'agSetColumnFilter',
        filterValues: Object.values(allConstants.USER_STATUSES).filter((status)=> status.value!==allConstants.USER_STATUSES.NOT_ACTIVE.value).map((s)=> s.value.toUpperCase())
      })
    ];
  }, []);


  if(!PermissionGate({modulePermissions:
    generalRules.userCompanyColumn, scope: allConstants.SCOPES.canView,
  role: viewController.userRole,
  companyId: viewController.companyId})) {
    columnDefs = columnDefs.filter((el)=> el.field !== 'companyId');
  }

  const impersonate = (companyId)=> {
    dispatch(actions.impersonate(companyId));
  };

  const changeAdminHandler = (newAdmin)=> {
    props.changeAdminHandler(newAdmin);
  };

  const editUserHandler = (userData)=> {
    navigate(`${allConstants.PATH.USER}${location.search}&${urlSearchParams.userId}=${userData.id}`);
  };

  const resetPasswordUserHandler = (userData)=> {
    props.resetPasswordUserHandler(userData);
  };

  // eslint-disable-next-line no-unused-vars
  const deleteUser = (userId)=> {
    //** todo: open modal and confirm action from there
  };

  /**
   * @param {object} userData
   * @return {Promise<void>}
   */
  const deactivateUser = async (userData)=> {
    props.deactivateUserHandler(userData);
  };

  const activateUser = async (userId, userData) => {
    const activateStatus = await dispatch(actions.activateUser({userId: userId}));
    unwrapRequestResult({
      result: activateStatus,
      successMessage: `Activated: ${userData?.[USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[USER_FIELDS.LAST_NAME.api_name]}.`,
      errorMessage: 'Error on activation',
      showSuccess: true,
    } );
  };

  const reinvite = async (userId, userData) => {
    const reinviteStatus = await dispatch(actions.reinviteUser({userId: userId}));
    unwrapRequestResult({
      result: reinviteStatus,
      successMessage: `New invite link sent to user ${userData?.[USER_FIELDS.FIRST_NAME.api_name]} ${userData?.[USER_FIELDS.LAST_NAME.api_name]}.`,
      //errorMessage: 'Error on re-invite',
      showSuccess: true,
    } );
  };

  const getContextMenuItems = (params)=> {
    // console.log('params', params?.node?.data, user?.id);
    if(!params.node) return [];
    const userStatus = params?.node?.data[allConstants.USER_FIELDS.STATUS.api_name];
    const userRole = params?.node?.data[allConstants.USER_FIELDS.ROLE.api_name];
    let clickOnSelf = params?.node?.data && params?.node?.data.id === props.viewController.user.id;
    const userSignedUp = Utils.notEqualsIgnoreCase(userStatus, allConstants.USER_STATUSES.INVITED.value);

    const actions = {
      impersonate:  {
        name: 'Impersonate',
        disabled: !userSignedUp || viewController.selectedMultipleRows(),
        action: ()=> {
          impersonate(params?.node?.data.companyId);
        },
        cssClasses: ['redFont', 'bold'],
      },
      reInvite: {
        name: 'Re-send Invite',
        disabled: viewController.selectedMultipleRows() || userSignedUp,
        action: ()=> {
          reinvite(params?.node?.data[USER_FIELDS.ID.api_name], params?.node?.data);
        },
        cssClasses: ['blueFont', 'bold'],
      },
      resetPassword: {
        name: 'Reset Password',
        disabled: viewController.selectedMultipleRows() || !userSignedUp,
        action: () => {
          resetPasswordUserHandler(params?.node?.data);
        },
      },
      editUser: {
        name: 'Edit',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> {
          editUserHandler(params?.node?.data);
        },
      },
      disableUser: {
        name: 'Disable User',
        disabled: clickOnSelf || viewController.selectedMultipleRows(),
        action: ()=> {
          deactivateUser(params?.node?.data);
        }
      },
      activateUser: {
        name: 'Activate User',
        disabled: (clickOnSelf || viewController.selectedMultipleRows() || (!companyAndUserUtils.canAddNewEntityToCompany(company)
          && Object.keys(allConstants.IPERMIT_ROLES).includes(userRole))),
        action: ()=> {
          activateUser(params?.node?.data[USER_FIELDS.ID.api_name], params?.node?.data);
        }
      },
      setCompanyAdmin: {
        name: 'Set Company Admin',
        disabled: clickOnSelf || !canBeAdmin(userStatus, userRole) || viewController.selectedMultipleRows(),
        action: ()=> {
          changeAdminHandler(params?.node?.data);
        }
      },

    };

    const ipermitOptions = [
      userSignedUp ? actions.impersonate : actions.reInvite,
    ];



    const userActions = [actions.editUser];
    if(userStatus === allConstants.USER_STATUSES.DISABLED.value.toUpperCase()) {
      // console.log('will add activation');
      userActions.push(actions.activateUser);
    } else {
      userActions.push(actions.disableUser);
    }
    userActions.push(actions.setCompanyAdmin);

    if(PermissionGate({
      modulePermissions: userPageRules.resetPassword,
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
      companyId: viewController.companyId})) {
      userActions.push(actions.resetPassword);
    }


    const standard = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];
    const separator=[
      'separator'
    ];

    const dangerous = [];

    // if(PermissionGate({
    //   modulePermissions: userPageRules.deleteUser, scope: allConstants.SCOPES.canView, role: viewController.userRole})){
    //   dangerous.push('separator');
    //   dangerous.push({
    //     name: 'Delete User',
    //     action: ()=> deleteUser(params?.node?.data)
    //   });
    // }
    if(PermissionGate({
      modulePermissions: generalRules.impersonate, scope: allConstants.SCOPES.canView,
      role: viewController.userRole, companyId: viewController.companyId})) {
      return [...ipermitOptions,...separator, ...userActions, ...separator, ...standard, ...dangerous];
    }
    return [...userActions, ...separator, ...standard, ...dangerous];
  };

  const modifyElement = (el) => {

    if(el[allConstants.USER_FIELDS.IS_BLOCKED.api_name]) {
      el[allConstants.USER_FIELDS.STATUS.api_name] = allConstants.USER_STATUSES.BLOCKED.value;
    }
    return el;

  };

  const ls_name = viewController.user.companyId ? 'company_users_grid': 'all_users_grid';

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      onCellDoubleClicked={(params)=> editUserHandler(params.data)}
      components={components}
      contextMenu={getContextMenuItems}
      ls_name={ls_name}
      registerGrid={viewController.setGridToParent}
      fetchParams={{url: `${base_url.api}users/get-contractor-users`, respKey:'users'}}
      companyId={viewController.companyId}
      modifyDataElement={modifyElement}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
    />
  );
};

AllUsersGrid.propTypes = {
  resetPasswordUserHandler: PropTypes.func.isRequired,
  changeAdminHandler: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,
  deactivateUserHandler: PropTypes.func.isRequired,

};
export default AllUsersGrid;
