class ColumnDefinition {
  constructor(props) {
    const getFilterOptionsForFilter = (filter) => {
      switch (filter) {
      case 'agDateColumnFilter':
        return ['equals', 'inRange'];
      case 'agNumberColumnFilter':
        return ['equals'];
      default:
        return ['contains'];
      }
    };

    const filter = props.filter ? props.filter : 'agTextColumnFilter';

    const defFilterParams = {
      filter: filter,
      filterParams: {buttons: ['reset'], suppressAndOrCondition: true, filterOptions: getFilterOptionsForFilter(filter)}
    };

    if(props.comparator) {
      defFilterParams.filterParams.comparator = props.comparator;
    }
    if(props.filterValues) {
      defFilterParams.filterParams.values = props.filterValues;
    }

    this.field = {
      flex: 1,
      resizable: true,
      sortable: false,
      autoHeight: true,
      minWidth: props.width ? props.width : 200,
      maxWidth:  props.width ? props.width : null,
      field: props.parent ? `${props.parent}.${props.field.api_name}`:props.field.api_name,
      hide: props.hide,
      wrapText: props.wrapText,
      checkboxSelection: props.checkboxSelection,
      headerName: props.emptyHeader ? '' : props.headerName ? props.headerName?.toUpperCase() : props.field.label?.toUpperCase(),
      valueGetter: props.valueGetter,
      cellRendererParams: props.cellRendererParams ? props.cellRendererParams : {},
      floatingFilter: !props.removeDefaultFilter,
      showWarning: props.showWarning,
      cellStyle: props.cellStyle ? props.cellStyle : {},


    };
    if(props.pinned) {
      this.field.pinned = props.pinned;
      this.field.lockPinned= props.lockPinned;
      this.field.lockPosition = true;
    }
    if(!props.removeDefaultFilter) {
      this.field = {...this.field, ...defFilterParams};
    }
    if(props.cellRenderer) {
      this.field.cellRenderer = props.cellRenderer;
    }
    if(props.comparator) {
      this.field.comparator = props.comparator;
    }

    return this.field;
  }
}
export default ColumnDefinition;
