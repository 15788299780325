import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Typography} from '@mui/material';
import * as allConstants from '../../constants/allConstants';
import {RecordHeader} from '../../pieces/recordHeader';
import {CompanyInfo} from './panels/companyInfo';
import {CompanyFinancial} from './panels/companyFinancial';
import {CompanySettings} from './panels/companySettings';
import {WorkersComp} from './panels/workersComp';
import {OwnerInfo} from './panels/ownerInfo';
import {RecordBodyWrapper} from '../wrappers/recordBodyWrapper';
import {RecordWrapper} from '../wrappers/recordWrapper';
import {RecordController} from '../../hooks/recordController';
import CompanyValidator from './validationSchemas';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, FormProvider} from 'react-hook-form';
import Utils from '../../utils/utils';
import base_url, {urlSearchParams} from '../../baseUrls';
import {COMPANY_FIELDS, ERROR_MSG, SCOPES, USER_FIELDS} from '../../constants/allConstants';
import {ELEMENTS, companyRules} from './companyAccessControl';
import PermissionGate from '../../permissions/permissionGate';
import {useDispatch, useSelector} from 'react-redux';
import {useGetRole} from '../../hooks/useGetRole';
import ApiUtils from '../../utils/apiUtils';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {COLORS} from '../../styles/styleConstants';
import EditFormButton from '../../pieces/editFormButton';
import actions from '../../redux/actions';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import {useNavigate} from 'react-router-dom';
import {generalRules} from '../../generalAccessControl';
import {companyViewModes} from './companyViewModes';
import ConfirmModal from '../../pieces/modals/confirmPopup';
import FormSubmitButtons from '../../pieces/formSubmitButtons';
import {toast} from 'react-toastify';
import axios from 'axios';
import Loader from '../misc/loader';
import {isIpermitUser} from '../../permissions/utils';
import {useAutocompleteOptions} from '../../hooks/useAutompleteOptions';
import {validatePrices} from './companyUtils';


const tabNames = {
  companySettings: 'Company Settings',
  companyInfo: 'Company Information',
  ownerInfo: 'Owner Information',
  workersComp: 'Workers Compensation',
  finance: 'Finance Fields'
};

const emptyAddress = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zipCode: '',
};

const createDefaultCompanyData = (companyData, mode, user, urlStateLicense)=> {

  if(mode === companyViewModes.ipermitInvite) {
    return {
      [COMPANY_FIELDS.CITY_INSPECTION.api_name]: false,
      [COMPANY_FIELDS.EMAIL_PREFERENCES.api_name]: companyData?.[COMPANY_FIELDS.EMAIL_PREFERENCES.api_name] || allConstants.EMAIL_PREFERENCES.EVERYONE.value,
    };
  }

  if(mode === companyViewModes.selfRegistering) {
    return {
      [USER_FIELDS.EMAIL.api_name]: null,
      [USER_FIELDS.PASSWORD.api_name]: null,
      [USER_FIELDS.CONFIRM_PASSWORD.api_name]: null,
      [COMPANY_FIELDS.ADMIN_INFO.api_name]: {
        [COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name]: null,
        [COMPANY_FIELDS.ADMIN_NAME_LAST.api_name]: null,
        [COMPANY_FIELDS.ADMIN_PHONE.api_name]: '',
      },
      [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: null,
      [COMPANY_FIELDS.DRIVER_LICENSE.api_name]: {
        [COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name]: null,
        [COMPANY_FIELDS.DRIVER_LICENSE_EXPIRES_AT.api_name]: null,
      },
      [COMPANY_FIELDS.WORKERS_COMP.api_name]: {
        [COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: allConstants.WORKERS_COMP_TYPES.REQUIRED.value,
        [COMPANY_FIELDS.WORKERS_COMP_CERT.api_name]: null,
        [COMPANY_FIELDS.WORKERS_COMP_CERT_EXP_DATE.api_name]: null,
      },
      [COMPANY_FIELDS.STATE_LICENSE.api_name]: {
        [COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]: urlStateLicense,
        [COMPANY_FIELDS.STATE_LICENSE_EXPIRES_AT.api_name]: null,
      },
      [COMPANY_FIELDS.EMAIL_PREFERENCES.api_name]: companyData?.[COMPANY_FIELDS.EMAIL_PREFERENCES.api_name] || allConstants.EMAIL_PREFERENCES.EVERYONE.value,
    };
  }

  const stateLicense = companyData?.[COMPANY_FIELDS.STATE_LICENSE.api_name]?
    {...companyData[COMPANY_FIELDS.STATE_LICENSE.api_name],
      ...{[COMPANY_FIELDS.STATE_LICENSE_FILE.api_name]:  companyData[COMPANY_FIELDS.STATE_LICENSE.api_name]?.fileId}} :{};


  const driversLicense = companyData?.[COMPANY_FIELDS.DRIVER_LICENSE.api_name]?
    {...companyData[COMPANY_FIELDS.DRIVER_LICENSE.api_name],
      ...{[COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name]:  companyData[COMPANY_FIELDS.DRIVER_LICENSE.api_name]?.fileId}} :{};

  const workerscomp = companyData?.[COMPANY_FIELDS.WORKERS_COMP.api_name]?
    {...companyData[COMPANY_FIELDS.WORKERS_COMP.api_name],
      ...{
        [COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: companyData?.[COMPANY_FIELDS.WORKERS_COMP.api_name]?.[COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name] || allConstants.WORKERS_COMP_TYPES.REQUIRED.value,
        [COMPANY_FIELDS.WORKERS_COMP_CERT.api_name]:  companyData[COMPANY_FIELDS.WORKERS_COMP.api_name]?.fileId}
    } :{[COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: allConstants.WORKERS_COMP_TYPES.REQUIRED.value};

  const paymentAuthForm = companyData?.[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name]?
    {...companyData[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name],
      ...{[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name]:  companyData[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name]?.fileId}} :{};

  let allowedTests = companyData?.[COMPANY_FIELDS.ALLOWED_TESTS.api_name];

  if(mode !== companyViewModes.verifyCompany && (!allowedTests || allowedTests.length === 0)) {
    companyData = {...companyData, [COMPANY_FIELDS.ALLOWED_TESTS.api_name]: [allConstants.TEST_TYPES.NONE.value]};
  }

  const company = {
    [COMPANY_FIELDS.ADMIN_INFO.api_name]: {
      [COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name]: null,
      [COMPANY_FIELDS.ADMIN_NAME_LAST.api_name]: null,
      [COMPANY_FIELDS.ADMIN_PHONE.api_name]:'',
    },

    [COMPANY_FIELDS.STATE_LICENSE.api_name]: stateLicense,
    [COMPANY_FIELDS.DRIVER_LICENSE.api_name]:driversLicense,
    [COMPANY_FIELDS.WORKERS_COMP.api_name]: workerscomp,

    [COMPANY_FIELDS.PAYMENT_METHOD.api_name]: null,
    [COMPANY_FIELDS.ADDRESS.api_name]: companyData?.[COMPANY_FIELDS.ADDRESS.api_name] || emptyAddress,

    [COMPANY_FIELDS.OWNER.api_name]: {
      [COMPANY_FIELDS.ADDRESS.api_name]: companyData?.[COMPANY_FIELDS.OWNER.api_name]?.[COMPANY_FIELDS.ADDRESS.api_name] || emptyAddress,
    },

    [COMPANY_FIELDS.CITY_INSPECTION.api_name]: companyData?.[COMPANY_FIELDS.CITY_INSPECTION.api_name],

  };

  if(PermissionGate({
    modulePermissions: companyRules.financeInfo,
    scope: SCOPES.canView,
    role: user?.[USER_FIELDS.ROLE.api_name],
    companyId: user?.[USER_FIELDS.COMPANY_ID.api_name],
  })) {
    company[COMPANY_FIELDS.PRICE_LIST.api_name] = companyData?.[COMPANY_FIELDS.PRICE_LIST.api_name] || [];
    company[COMPANY_FIELDS.PAYMENT_AUTH_FORM.api_name] = paymentAuthForm;
  }


  if(!companyData) return {};

  return Utils.mergeDeep(company, companyData);


};

const getTabRequiredFields = (mode, isIpermit)=> {
  const validate = {
    [tabNames.companySettings]: [],
    [tabNames.companyInfo]:[
      COMPANY_FIELDS.STATE_LICENSE.api_name,
      COMPANY_FIELDS.PAYMENT_METHOD.api_name,
    ],
    [tabNames.workersComp]:[
      COMPANY_FIELDS.WORKERS_COMP.api_name,
    ],
    [tabNames.ownerInfo]: [
      `${COMPANY_FIELDS.DRIVER_LICENSE.api_name}`,
    ],
    [tabNames.finance]:[COMPANY_FIELDS.PRICE_LIST.api_name, COMPANY_FIELDS.QB_EMAIL.api_name],
  };

  const companySettingsValidation = [
    COMPANY_FIELDS.NAME.api_name, COMPANY_FIELDS.EMAIL.api_name,
    COMPANY_FIELDS.LOCATION.api_name, COMPANY_FIELDS.ALLOWED_TESTS.api_name,
    COMPANY_FIELDS.ALLOWED_PERMITS.api_name,
    COMPANY_FIELDS.STATE_LICENSE.api_name
  ];

  switch (mode) {
  case companyViewModes.selfRegistering: {
    validate[tabNames.companyInfo] = [
      `${COMPANY_FIELDS.NAME.api_name}`,
      `${COMPANY_FIELDS.ADMIN_INFO.api_name}`,
      COMPANY_FIELDS.STATE_LICENSE.api_name,
      // `${COMPANY_FIELDS.ADMIN_NAME.api_name}.${COMPANY_FIELDS.ADMIN_NAME_LAST.api_name}`,
      `${COMPANY_FIELDS.PAYMENT_METHOD.api_name}`,
      `${COMPANY_FIELDS.PHONE_NUMBER.api_name}`,
      `${COMPANY_FIELDS.EMAIL.api_name}`,
      USER_FIELDS.PASSWORD.api_name,
      USER_FIELDS.CONFIRM_PASSWORD.api_name
    ];
    break;
  }
  case companyViewModes.ipermitInvite: {
    validate[tabNames.companySettings] = [
      COMPANY_FIELDS.NAME.api_name, COMPANY_FIELDS.EMAIL.api_name,
      COMPANY_FIELDS.LOCATION.api_name, COMPANY_FIELDS.ALLOWED_TESTS.api_name,
      COMPANY_FIELDS.ALLOWED_PERMITS.api_name,
      COMPANY_FIELDS.STATE_LICENSE.api_name
    ];
    validate[tabNames.companyInfo] = [];
    validate[tabNames.ownerInfo] = [];
    validate[tabNames.workersComp] = [];

    break;
  }
  case companyViewModes.verifyCompany: {
    validate[tabNames.companySettings] = [COMPANY_FIELDS.NAME.api_name, COMPANY_FIELDS.EMAIL.api_name,
      COMPANY_FIELDS.LOCATION.api_name, COMPANY_FIELDS.ALLOWED_TESTS.api_name,
      COMPANY_FIELDS.ALLOWED_PERMITS.api_name
    ];
    validate[tabNames.companyInfo] = [...validate[tabNames.companyInfo], ...[
      `${COMPANY_FIELDS.NAME.api_name}`,
      `${COMPANY_FIELDS.PAYMENT_METHOD.api_name}`,
    ]];
    break;
  }
  case companyViewModes.completeOnboarding: {
    validate[tabNames.companyInfo].push(`${COMPANY_FIELDS.ADMIN_INFO.api_name}`);
    validate[tabNames.companyInfo].push(`${COMPANY_FIELDS.PAYMENT_METHOD.api_name}`);
    break;
  }
  default: {
    if (isIpermit) {
      validate[tabNames.companySettings] = companySettingsValidation;
    }
    break;
  }
  }
  return validate;
};

export const CompanyView = (props)=> {
  const fileUpoadFieldKeys = [
    `${COMPANY_FIELDS.STATE_LICENSE.api_name}.${COMPANY_FIELDS.STATE_LICENSE_FILE.api_name}`,
    `${COMPANY_FIELDS.WORKERS_COMP.api_name}.${COMPANY_FIELDS.WORKERS_COMP_CERT.api_name}`,
    `${COMPANY_FIELDS.DRIVER_LICENSE.api_name}.${COMPANY_FIELDS.DRIVER_LICENSE_FILE.api_name}`,
    `${COMPANY_FIELDS.AUTH_LETTER.api_name}.${COMPANY_FIELDS.AUTH_LETTER_FILE.api_name}`,
  ];
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const isRegistering = React.useRef(props.isRegistering);
  const stateLicenseNumber = Utils.getUrlParameter(urlSearchParams.stateLicenseNumber);
  const options = {
    markets: useAutocompleteOptions({field: COMPANY_FIELDS.LOCATION.api_name}).options
  };

  const isSelfRegistering = ()=> {
    return isRegistering.current && Utils.valueIsSpecified(stateLicenseNumber);
  };
  const getInitialState = ()=> {
    const defaultCompanyData = {
      [COMPANY_FIELDS.WORKERS_COMP.api_name]: {
        [COMPANY_FIELDS.WORKERS_COMP_TYPE.api_name]: allConstants.WORKERS_COMP_TYPES.REQUIRED.value
      }
    };
    if(isSelfRegistering()) {
      return{...defaultCompanyData, ...{
        [COMPANY_FIELDS.STATE_LICENSE.api_name]: {
          [COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]: stateLicenseNumber,
        }
      }};
    }
    return defaultCompanyData;
  };

  const user = useSelector(state=> state.user);
  const stateCompany = useSelector(state=> state.company);
  const role = useGetRole();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [company, setCompany] = useState(getInitialState());

  const isCompleteonboarding = React.useRef(Utils.textToBool(Utils.getUrlParameter(urlSearchParams.completeOnboarding)));

  const isIpermitInvite = ()=> {
    if(Utils.getUrlParameter(urlSearchParams.invite)) return true;
    return isRegistering.current && !stateLicenseNumber;
  };

  const companyId = Utils.getUrlParameter(urlSearchParams.companyId);

  const [showRejectPopup, setShowRejectPopup] = useState(false);

  const verificationProcess =React.useRef(false);

  const getCurrentMode = ()=> {
    if(isSelfRegistering()) return companyViewModes.selfRegistering;
    if(isIpermitInvite()) return companyViewModes.ipermitInvite;
    if(isCompleteonboarding.current) return companyViewModes.completeOnboarding;
    if(verificationProcess.current || company?.[COMPANY_FIELDS.STATUS.api_name] === allConstants.COMPANY_STATUSES.PENDING.value) return companyViewModes.verifyCompany;
    return companyViewModes.general;
  };

  const initialViewMode = (currentMode)=> {

    if(currentMode === companyViewModes.selfRegistering ||
      currentMode === companyViewModes.ipermitInvite ||
      currentMode === companyViewModes.completeOnboarding) return false;
    return true;
  };
  const currentMode = getCurrentMode();
  // const [hasErrors, setHasErrors] = useState(currentMode === companyViewModes.selfRegistering ? [0,1,2]:[]);
  const [viewMode, toggleViewMode] = useState(initialViewMode(currentMode));

  const companyValidator = React.useRef(new CompanyValidator({
    isSelfRegistering: isSelfRegistering(),
    isIpermitInvite: isIpermitInvite(),
    id: companyId,
    uniqueEmail: currentMode !== companyViewModes.completeOnboarding && currentMode!== companyViewModes.verifyCompany

  }));



  const validationSchema = companyValidator.current.getValidationSchema(getTabRequiredFields(currentMode, isIpermitUser(user?.role) && !user.companyId), currentMode === isIpermitInvite());
  const formOptions = {resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    criteriaMode:'all',
    defaultValues: createDefaultCompanyData(company,
      isSelfRegistering()? companyViewModes.selfRegistering : isIpermitInvite() ? companyViewModes.ipermitInvite : companyViewModes.existingCompany,
      user, stateLicenseNumber
    )
  };

  const formMethods = React.useRef(useForm(formOptions));
  /**
   * Set company data as default values to react-hook-form
   */
  useEffect(()=> {

    const companyStatus  = company?.[COMPANY_FIELDS.STATUS.api_name];
    if(company && companyStatus && companyStatus === allConstants.COMPANY_STATUSES.PENDING.value){
      verificationProcess.current = true;
    }
    if(company && companyStatus && companyStatus === allConstants.COMPANY_STATUSES.NOT_SIGNED.value && !Object.values(allConstants.IPERMIT_ROLES).includes(user[allConstants.USER_FIELDS.ROLE.api_name]) &&
      user.companyId){

      isCompleteonboarding.current = true;
      if(viewMode) {
        toggleViewMode(false);
      }

    }

  }, [company]);

  useEffect(()=> {
    if(formMethods.current) {

      formMethods.current.reset(createDefaultCompanyData(company, currentMode, user, stateLicenseNumber));
    }
  }, [viewMode, company]);

  useEffect(()=> {
    formMethods.current.clearErrors();
  }, [viewMode]);

  useEffect(()=> {
    const currentId = company ? company?.id : undefined;
    if(!currentId && stateCompany.id) {
      setCompany(stateCompany);
    }
  }, [stateCompany]);

  useEffect(()=> {
    sessionStorage.clear();
    return ()=> {
      sessionStorage.clear();
      formMethods.current.reset();
    };
  }, []);

  if(Utils.isDevEnv()) {
    window.companyForm = formMethods.current;
  }

  const inputDefaultProps = {
    viewMode: viewMode,
    isEditable: true,
    renderLabel: true,
    nested: false,
    parent: null,
  };

  const tabProps = {
    isSelfRegistering: isSelfRegistering(),
    isIpermitInvite: isIpermitInvite(),
    isCompleteonboarding: isCompleteonboarding.current,
    companyData: company,
    user: user,
    companyViewMode: getCurrentMode(),
    dontUploadImmediately: getCurrentMode() === companyViewModes.selfRegistering,
    options: options,
  };

  const onCancel = async (e)=> {
    e.preventDefault();
    const initial = createDefaultCompanyData(company, isSelfRegistering()? companyViewModes.selfRegistering : isIpermitInvite() ? companyViewModes.ipermitInvite : companyViewModes.existingCompany);
    await formMethods.current.reset(initial);

    if(isSelfRegistering()) {
      navigate(allConstants.PATH.SIGN_UP);
      return;
    }
    if(isIpermitInvite() || verificationProcess.current) {
      navigate(allConstants.PATH.ALL_COMPANIES);
      return;
    }
    toggleViewMode(true);
  };

  /**
   * Switch to edit mode
   */
  const toggleEditMode = ()=> {
    toggleViewMode(false);
  };

  const getTabs = ()=> {
    const tabRequiredFields = getTabRequiredFields(getCurrentMode(), isIpermitUser(user?.role) && !user.companyId);
    return {
      companySettings: {name: tabNames.companySettings,
        panel: <CompanySettings inputDefaultProps={inputDefaultProps} tabProps={tabProps}/>,
        order: 0, access:
        ELEMENTS.companySettings,
        fieldsToValidate: tabRequiredFields[tabNames.companySettings]
      },
      companyInfo: {name: tabNames.companyInfo,
        panel: <CompanyInfo inputDefaultProps={inputDefaultProps} tabProps={tabProps}/>,
        order: 1,
        access: ELEMENTS.companyInfo,
        fieldsToValidate: tabRequiredFields[tabNames.companyInfo]
      },
      ownerInfo: {name: tabNames.ownerInfo,
        panel: <OwnerInfo inputDefaultProps={inputDefaultProps} tabProps={tabProps}/>,
        order: 2,
        access: ELEMENTS.ownerInfo,
        fieldsToValidate: tabRequiredFields[tabNames.ownerInfo]
      },
      workersComp: {name: tabNames.workersComp,
        panel: <WorkersComp inputDefaultProps={inputDefaultProps} tabProps={tabProps}/>,
        order: 3,
        access: ELEMENTS.workersInfo,
        fieldsToValidate: tabRequiredFields[tabNames.workersComp]
      },
      finance: {name: tabNames.finance,
        panel: <CompanyFinancial inputDefaultProps={inputDefaultProps} tabProps={tabProps}/>,
        order: 4,
        access: ELEMENTS.financeInfo,
        fieldsToValidate: tabRequiredFields[tabNames.finance]
      },
    };
  };



  const getCurrentTabs = ()=> {
    const tabs = getTabs();
    const currTabs = {...tabs};
    if(isIpermitInvite()) {
      currTabs.companyInfo.fieldsToValidate = currTabs.companyInfo.fieldsToValidate.filter((f)=> {
        return f !== COMPANY_FIELDS.NAME.api_name && f !== COMPANY_FIELDS.EMAIL.api_name;
      });
      return currTabs;
    } else if(isSelfRegistering()) {

      delete currTabs.companySettings;
      delete currTabs.finance;
      return currTabs;
    }

    const currentTabs = {};
    for (const [key, value] of Object.entries(tabs)) {
      if(PermissionGate({
        modulePermissions: companyRules[value.access],
        scope: SCOPES.canView,
        role: role,
        companyId: user?.companyId,
      })) {
        currentTabs[key] = value;
      }
    }
    return currentTabs;
  };

  const headerHeight = isIpermitInvite() || isSelfRegistering() ? '50px' : '75px';

  const getValue = (field)=> {
    return company?.[field.api_name]||'';
  };

  const userCanEditCompany = ()=> {

    const editableStatuses = [
      allConstants.COMPANY_STATUSES.ACTIVE.value,
      allConstants.COMPANY_STATUSES.PENDING.value,
      allConstants.COMPANY_STATUSES.INVITED.value,
      allConstants.COMPANY_STATUSES.NOT_SIGNED.value
    ];

    if(company?.[allConstants.COMPANY_FIELDS.IS_BLOCKED.api_name]) return  false;
    if(editableStatuses.includes(company?.[allConstants.COMPANY_FIELDS.STATUS.api_name])) {
      return PermissionGate({
        modulePermissions:companyRules.editButton,
        role: role,
        scope: allConstants.SCOPES.canView,
        blockForDeactivatedCompany: true,
        companyStatus: company[allConstants.COMPANY_FIELDS.STATUS.api_name],
        companyId: user.companyId
      });
    } else {
      return false;
    }
  };

  // const validatePrices = (priceList)=>{
  //   if(!priceList || Object.keys(priceList).length === 0) return [];
  //
  //   let invalidIndexes = [];
  //   priceList.forEach((row, index)=> {
  //     if(typeof row.price !== 'number' && isNaN(row.price)) {
  //       invalidIndexes.push(index);
  //
  //     }
  //   });
  //   return  invalidIndexes;
  // };

  const onReject = async ()=> {
    const responseStatus = await dispatch(actions.rejectCompanyRegistration({companyId: company[COMPANY_FIELDS.ID.api_name]}));
    unwrapRequestResult({
      showSuccess: true,
      successMessage: 'Rejected company was deleted.',
      result: responseStatus,
      id: 'reject_company',
    });
    if(responseStatus?.meta?.requestStatus === 'fulfilled') {
      handleSuccess(true);
    }
  };

  const handleSuccess = (returnToAllCompanies)=> {
    if(isSelfRegistering()) {
      dispatch(actions.getCurrentUserData());
      setSubmitInProgress(false);
      navigate(allConstants.PATH.COMPANY_PENDING, {replace: true});
    }
    if(isIpermitInvite() || returnToAllCompanies) {
      navigate(allConstants.PATH.ALL_COMPANIES, {replace: true});
    }
    toggleViewMode(true);
    // setHasErrors([]);

  };

  const formHasFiles= ()=> {
    let hasFiles = false;
    fileUpoadFieldKeys.forEach((key)=> {
      const itemInSessionStorage = sessionStorage.getItem(key);
      if(itemInSessionStorage) {
        hasFiles = true;
      }
    });
    return hasFiles;
  };

  const fileUpload = async (file)=> {
    const fileData = new FormData();
    fileData.append('file', file);
    let url = `${base_url.api}files/upload`;
    const authToken = await ApiUtils.getAccessToken();
    return axios.post(url, fileData, {
      headers: {
        Authorization: authToken,
      }});
  };

  const uploadFilesOfPendingCompany = async (companyId, unwrappedCompany)=> {
    const fields = [];
    if(!companyId)  {
      handleSuccess();
      return;
    }
    if(formHasFiles()) {
      let promises = [];
      for (const key of fileUpoadFieldKeys) {
        const itemInSessionStorage = sessionStorage.getItem(key);
        if(itemInSessionStorage) {
          const fileName = sessionStorage.getItem(`${key}_name`);
          const blob = Utils.dataURItoBlob(itemInSessionStorage);
          const file = new File([blob], fileName || key.split('.')[0], {type: blob.type});
          fields.push(key);
          promises.push(fileUpload(file));
          sessionStorage.removeItem(`${key}_name`);
          sessionStorage.removeItem(key);
        }
      }
      const result = await Promise.allSettled(promises);
      const failed = [];
      const editCompanyData = {};
      result.forEach((r, i)=> {
        if(r.status === 'rejected') {
          failed.push(fields[i]);
        }
        if(r.status === 'fulfilled') {

          const keys = fields[i].split('.');
          editCompanyData[keys[0]] = keys.length === 1 ? r.value.data.data.id : {
            [keys[1]]: r.value.data.data.id
          };
        }
      });
      if(Object.keys(editCompanyData).length > 0) {
        const payload = {
          ...editCompanyData, ...{
            companyId: companyId,
          }
        };

        const editResult = await dispatch(actions.editCompany(payload));

        if(editResult?.meta?.requestStatus === 'rejected') {
          const warningMessage = 'Company was successfully registered, but files were not uploaded.';
          toast(warningMessage, {type: 'warning', toastId: 'company_registration'});
        }
        else if(failed.length > 0) {
          const failedFields = failed.map((f)=> f.split('.')[0]);
          const warningMessage = `Company was successfully registered, but some files were not uploaded: ${failedFields.join(', ')}`;
          toast(warningMessage, {type: 'warning', toastId: 'company_registration'});
        } else {
          toast('Company was successfully registered', {type: 'success', toastId: 'company_registration'});
        }
        handleSuccess();
      }
      console.log(result.map(promise => promise));
    }
    else {
      console.log('No files to upload');
      handleSuccess();
    }
  };

  const onSubmit = async (data) => {

    if(ApiUtils.isMockMode()) {
      return;
    }

    const sanitizedData = Utils.sanitizeFileUploadFields(data);

    let invalidPrices = validatePrices(data[COMPANY_FIELDS.PRICE_LIST.api_name]);
    if(invalidPrices.length !== 0) {
      invalidPrices.forEach((el, index)=> {
        formMethods.current.setError(`${COMPANY_FIELDS.PRICE_LIST.api_name}.[${el}].price`, {type: 'custom', message: ERROR_MSG.PRICE_SHOULD_BE_A_NUMBER});
      });
      return;
    }

    let responseStatus;
    let successMessage;

    if (isCompleteonboarding.current) {
      successMessage = 'Sign up completed. Now you can create jobs.';
      responseStatus = await dispatch(actions.completeOnboarding({company: sanitizedData}));
      dispatch(actions.getCurrentUserData());


    } else if(isIpermitInvite()){
      successMessage = 'Company is invited.';
      responseStatus = await dispatch((actions.inviteCompany(sanitizedData)));


    } else if(isSelfRegistering()){
      setSubmitInProgress(true);
      responseStatus = await dispatch(actions.selfRegisterCompany({...sanitizedData, ...{
        user: {
          [allConstants.USER_FIELDS.FIRST_NAME.api_name]: sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.ADMIN_NAME_FIRST.api_name],
          [allConstants.USER_FIELDS.LAST_NAME.api_name]: sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.ADMIN_NAME_LAST.api_name],
          [allConstants.USER_FIELDS.EMAIL.api_name]: sanitizedData[allConstants.USER_FIELDS.EMAIL.api_name],
          [allConstants.USER_FIELDS.PASSWORD.api_name]: sanitizedData[allConstants.USER_FIELDS.PASSWORD.api_name],
          [allConstants.USER_FIELDS.PHONE_NUMBER.api_name]:sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.PHONE_NUMBER.api_name],
          [allConstants.USER_FIELDS.PHONE_NUMBER.api_name]:sanitizedData[allConstants.COMPANY_FIELDS.ADMIN_INFO.api_name][allConstants.COMPANY_FIELDS.PHONE_NUMBER.api_name],

        }
      }}));

      if(responseStatus.meta.requestStatus === 'fulfilled') {
        const companyId = responseStatus.payload.company.id;
        await uploadFilesOfPendingCompany(companyId);

      } else if(responseStatus.meta.requestStatus === 'rejected') {
        setSubmitInProgress(false);
        toast.error(responseStatus.payload.message, {
          toastId: 'error_onregister'
        });
      }
      return;

    } else if(verificationProcess.current){
      responseStatus = await dispatch(actions.acceptCompanyRegistration({...sanitizedData, ...{
        companyId: company[allConstants.COMPANY_FIELDS.ID.api_name],
      }
      }));

    } else {
      const payload = {
        ...sanitizedData, ...{
          companyId: company[allConstants.COMPANY_FIELDS.ID.api_name]}
      };
      successMessage = 'Company successfully updated.';
      responseStatus = await dispatch(actions.editCompany(payload));
    }

    if(responseStatus) {
      const unwrapped = unwrapRequestResult({
        showSuccess: true,
        result: responseStatus,
        successMessage: successMessage,
        id: 'company_update',
      });
      if(unwrapped && Utils.objectHasProperty(unwrapped, 'company') && unwrapped.company) {
        //merge with current company object to make sure we display company admin name - it is not returned in edit company response.
        setCompany({...company, ...unwrapped.company});
      }
      if(unwrapped?.status !== 'error') {
        toggleViewMode(true);
      }
      if(responseStatus?.meta?.requestStatus === 'fulfilled') {
        handleSuccess();
      }
    }
  };
  //if dev env bind company to window object
  if(process.env.NODE_ENV === 'development') {
    window.compantState = company;
  }


  const hideNavbar = isSelfRegistering();


  if(submitInProgress) {
    return <Loader timeout={50}/>;
  }

  return (
    <RecordController
      recordId={companyId || null}
      module={'companies'}
      responseTarget={'company'}
      saveRecord={(data)=>setCompany(data)}
      viewMode={viewMode}
    >
      <RecordWrapper
        hideNavbar={hideNavbar}
        headerHeight={headerHeight}

        header={
          <Box display={'flex'} flexDirection={'row'} id={'headercontainer'} justifyContent={'center'} sx={{
            width: '100%'

          }}>
            <Box display={'flex'} flexDirection={'row'} width={'100%'} height={'75px'}>
              {company && <ConfirmModal
                open={showRejectPopup}
                // header={'Reject company registration'}
                handleClose={()=> setShowRejectPopup(false)}>
                <Box sx={{
                  '& .MuiTypography-root': {
                    fontSize: '1rem'
                  }}}>
                  <Typography variant={'body1'} color={COLORS.globalRed} mb={2}>Rejected company will be deleted from the portal!</Typography>
                  <Typography variant={'body1'} mb={3}>{'Reject '}
                    <Typography component={'span'} fontWeight={600} color={COLORS.globalBlue}>{company?.[COMPANY_FIELDS.NAME.api_name]}</Typography>?
                  </Typography>

                  <FormSubmitButtons
                    cancelText={'No'}
                    submitText={'Yes'}
                    cancelAction={()=> setShowRejectPopup(false)}
                    submitAction={onReject}
                  />


                </Box>

              </ConfirmModal>}
              <Box display={'flex'}
                width={'calc(100% - 110px)'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={0.5}
              >
                <RecordHeader
                  text={isSelfRegistering() ? 'Register' : isIpermitInvite() ? 'Invite Company' : getValue(COMPANY_FIELDS.NAME)}
                  headerHeight={headerHeight}
                />
                {getValue(COMPANY_FIELDS.EMAIL) &&
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <EmailOutlinedIcon sx={{color: COLORS.globalBlue, marginRight: '8px'}}/>
                <Typography component={'span'} sx={{
                  fontStyle: 'italic'
                }}>{getValue(COMPANY_FIELDS.EMAIL)}</Typography>
              </Box>}

              </Box>
              {ApiUtils.isDevelopment() && <Box sx={{position: 'absolute',
                left: '20px',
                top: '20px'}}>
                <Button variant="contained"
                  color={'secondary'}
                  onClick={async ()=> {
                    await formMethods.current.trigger();
                  }}

                >Show errors</Button>
              </Box>}

              {userCanEditCompany() && <Box display={'flex'} sx={{width:'110px'}}>
                <EditFormButton onClick={viewMode ? toggleEditMode : onCancel} onCancel={onCancel} edit={viewMode}/>
              </Box>}
            </Box>
          </Box>
        }
      >
        <FormProvider {...formMethods.current}>
          <form onSubmit={formMethods.current.handleSubmit(onSubmit)}>
            {/*render main content*/}
            <RecordBodyWrapper
              tabs={getCurrentTabs()}
              headerHeight={headerHeight}
              hideNavbar={hideNavbar}
              submitBlockOptions={{
                show: isIpermitInvite() || isSelfRegistering() || !viewMode,
                cancelText: 'Cancel',
                submitText: isSelfRegistering() ? 'Register' : isIpermitInvite() ? 'Invite' : verificationProcess.current ? 'Verify':'Update',
                cancelAction: onCancel,
                extraButton: {
                  show: verificationProcess.current,
                  text: 'Reject this company',
                  type: 'button',
                  action: (()=> setShowRejectPopup(true)),
                  variant: 'outlined'
                }
              }}
              goBack={{
                show: PermissionGate({
                  modulePermissions: generalRules.allCompaniesLink,
                  role: user.role,
                  companyId: user.companyId,
                  scope: SCOPES.canView
                }),
                path: allConstants.PATH.ALL_COMPANIES,
                view: 'companies'
              }}
            >

              {/*render form submit buttons*/}


            </RecordBodyWrapper>
          </form>
        </FormProvider>
      </RecordWrapper>
    </RecordController>

  );
};

CompanyView.propTypes = {
  isRegistering: PropTypes.bool
};

CompanyView.defaultProps = {
  isRegistering: false
};
