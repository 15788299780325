import {SCOPES, IPERMIT_ROLES} from '../../constants/allConstants';
const ELEMENTS = {
  viewPage: 'viewPage',
  addProductButton: 'addProductButton',
  deleteProduct: 'deleteProduct',
  editProduct: 'editProduct'
};


const productsPageRules = Object.freeze({
  [ELEMENTS.viewPage]: {
    [SCOPES.canView]: [...Object.values(IPERMIT_ROLES)],
  },

  [ELEMENTS.addProductButton]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.editProduct]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
  },
  [ELEMENTS.deleteProduct]: {
    [SCOPES.canView]: [IPERMIT_ROLES.SYSTEM_ADMIN],
  }
});

export {productsPageRules, ELEMENTS};
