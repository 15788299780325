import {Box, TableCell, TableRow, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import * as allConstants from '../../../constants/allConstants';
import {RecordHeader} from '../../../pieces/recordHeader';
import Spacer from '../../../pieces/spacer';
import {Text, TextWithIconWrapper} from '../../../pieces/text';
import {ReactComponent as CopyIconSvg} from '../../../static/images/duplicate.svg';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import {getStatusColor} from '../../../styles/styleUtils';
import Utils from '../../../utils/utils';
import {useLocation} from 'react-router-dom';

const {JOB_FIELDS, JOB_TYPES} = allConstants;

const modifyJobTypeToDisplay =(type)=> {
  if(type === JOB_TYPES.TESTING_ONLY.value) return JOB_TYPES.TESTING_ONLY.display;
  return type;
};

export const JobTypeAndNumber = (props)=> {
  const {job} = props;
  const location = useLocation();
  const getRef = ()=> {
    return `${allConstants.PATH.MODULE}/${job?.[JOB_FIELDS.DUPLICATE_OF.api_name].jobId}/${location.search}`;
  };

  const showDuplicateInfo = ()=> {
    if(!props.ipermitView) return null;
    const duplicateInfo = job?.[JOB_FIELDS.DUPLICATE_OF.api_name];

    if(!duplicateInfo) return null;


    return (
      <Box sx={{
        display: 'flex',
        '& .MuiTypography-root': {
          color: '#EDFF00 !important',
          fontSize: '12px',
          fontWeight: 'bold',
        },
        '& a': {
          color: '#EDFF00 !important',
          fontSize: '12px',
          fontWeight: 'bold',
        }
      }}>

        <Text text={'('}/>
        <TextWithIconWrapper icon={<CopyIconSvg/>} iconColor={'#EDFF00'} spacing={'none'}>
          <Text text={'of: '}/>
        </TextWithIconWrapper>
        <Spacer x={1}/>
        <a href={getRef()}
          target="_blank" rel="noreferrer"
        >
          #{job?.[JOB_FIELDS.DUPLICATE_OF.api_name].jobNumber}
        </a>
        <Spacer x={1}/>
        <Text text={')'}/>
      </Box>
    );
  };

  return(
    <Box display={'flex'} alignItems={'flex-end'}>
      <RecordHeader text={`${modifyJobTypeToDisplay(job?.jobType)} : #${job?.jobNumber} `}/>
      <Spacer x={2}/>
      {showDuplicateInfo()}
    </Box>

  );
};
JobTypeAndNumber.propTypes = {
  job: PropTypes.shape({
    jobType: PropTypes.string.isRequired,
    jobNumber: PropTypes.string.isRequired
  }).isRequired,
  ipermitView: PropTypes.bool,
};

JobTypeAndNumber.defaultProps = {
  ipermitView: false,
};

export const DuplicateOf = (props)=> {
  const {job, link} = props;
  return(
    <>
      {job?.[JOB_FIELDS.DUPLICATE_OF.api_name] &&
    <Box display={'flex'} gap={1}>
      <Typography sx={{color:STYLE_CONSTANTS.COLORS.fontPale}}>{allConstants.JOB_FIELDS.DUPLICATE_OF.display_label}:</Typography>
      <Typography sx={{fontWeight: '600', color: STYLE_CONSTANTS.COLORS.globalBlue}}>
        <a href={link}
          target="_blank" rel="noreferrer"
        >
          #{job?.[JOB_FIELDS.DUPLICATE_OF.api_name].jobNumber}
        </a></Typography>
    </Box>}
    </>
  );
};

DuplicateOf.propTypes = {
  job: PropTypes.instanceOf(Object).isRequired,
  link: PropTypes.string.isRequired
};

export const CreatedByAndWhen = (props)=> {
  // eslint-disable-next-line react/prop-types
  const {job: {createdAt, orderedByUser}} = props;
  return (
    <Text pale size={'normal'} text={
      `Created: ${Utils.formatIsoDateStringWithOptions(createdAt, {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
        // eslint-disable-next-line react/prop-types
      })} BY ${orderedByUser.firstName} ${orderedByUser.lastName}`
    }/>
  );
};
CreatedByAndWhen.propsTypes = {
  job: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    orderedByUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired
};

const formatNumericDateString = (date) => {
  return Utils.formatIsoDateStringWithOptions(date, {year: 'numeric', month: '2-digit', day: '2-digit'});
};

export const StatusCard = (props)=> {
  const {active, header, statuses} = props;

  const raduis = '10px';
  const backgroundColor = active ? STYLE_CONSTANTS.COLORS.globalBlue : STYLE_CONSTANTS.COLORS.lightBluePale;

  const styles = {
    border: `1px solid ${backgroundColor}`,
    borderRadius: raduis,
    height: {xs: '90px', xl: '100px'},
    width: '214px',
    display: 'flex',
    flexDirection: 'column'
  };

  const headerStyles = {
    backgroundColor: backgroundColor,
    color: STYLE_CONSTANTS.COLORS.white,
    border: `1px solid ${backgroundColor}`,
    borderTopLeftRadius: raduis,
    borderTopRightRadius: raduis,
    fontSize: '14px',
    fontWeight: '600',
    transform: 'translate(0, -1px)',
    padding: theme=> theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  };

  return(
    <Box sx={styles}>
      <Box sx={headerStyles}>
        <Text text={header} color={STYLE_CONSTANTS.COLORS.white}/>

        {props?.subName && <Text text={props.subName} color={'lime'}/>}
      </Box>
      <Box sx={{height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingY: {xs: '4px', xl: '12px'},
        paddingX: '8px',
        rowGap: {xs: '4px', xl: '6px'}
      }}>

        {statuses.map((s, index)=> {
          //if(!s.status) return  null
          const color = getStatusColor(s.status);
          return(
            <TableRow key={index+s.status} sx={{fontSize: '12px'}}>
              <TableCell size={'small'} sx={{
                color: color,
                width: '125px',
                fontSize: {xs: '12px', xl: '14px'},
                fontWeight: '600',
                padding: 0,
                borderBottom: 'none',
              }}>
                {s.name && <span style={{marginRight: '4px', color: STYLE_CONSTANTS.COLORS.fontMain}}>{`${s.name}:`}</span>}
                {s.status}
              </TableCell>
              {s.date && <TableCell size={'small'} sx={{

                padding: '0 0 0 8px',
                fontSize: {xs: '12px', xl: '14px'},

                borderLeft: `2px solid ${STYLE_CONSTANTS.COLORS.lightGrayHover}`,
                borderBottom: 'none'
              }}>{formatNumericDateString(s.date)}</TableCell>
              }
            </TableRow>
          );
        })}
      </Box>
    </Box>
  );
};

StatusCard.propTypes = {
  active: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string.isRequired,
    name: PropTypes.string,
    date: PropTypes.string.isRequired,
  }).isRequired,
  ).isRequired,
  subName: PropTypes.string
};

StatusCard.defaultProps = {
  subName: null,
};
