import {Button} from '@mui/material';
import React, {useState} from 'react';
import CancelIcon from '../static/images/cancelIcon.svg';
import EditIcon from '../static/images/editIcon.png';
import * as STYLE_CONSTANTS from '../styles/styleConstants';

export const useEditRecord = (props)=> {
  const [edit, setEdit] = useState(props?.edit);
  const [isLoading, setLoading] = useState(false);

  const getButtonText = ()=> {
    if(edit) {
      return props?.cancelText ? props.cancelText :  'Cancel';

    }
    return props?.editText ? props.editText :  'Edit';
  };

  const text = getButtonText();
  const backgroundColor = edit ? STYLE_CONSTANTS.COLORS.chartPink : STYLE_CONSTANTS.COLORS.chartBlue;
  const color = edit ? STYLE_CONSTANTS.COLORS.warningRed : STYLE_CONSTANTS.COLORS.globalBlue;
  const StartEditIcon =  edit ? <img src={CancelIcon} alt={''}/> : <img src={EditIcon} alt={''}/>;


  return {
    EditButton: <Button
      onClick={()=> {
        setEdit(!edit);}}
      startIcon={StartEditIcon}
      sx={{
        backgroundColor: backgroundColor,
        textTransform: 'uppercase',
        borderRadius: '10px',
        paddingX: theme=> theme.spacing(2),
        color: color,
        width: '120px',
        fontSize: {xs: '12px', xl: '14px'},
        border: `1px solid ${backgroundColor}`,
        '&:hover':{
          borderColor: color,
          backgroundColor: STYLE_CONSTANTS.COLORS.white
        }
      }}


    >
      {text} </Button>,
    edit: edit,
    cancelEdit: ()=>{
      if(props?.resetForm) {
        console.log('resetting form');
        props.resetForm();
      }
      setEdit(false);
    },
    isLoading: isLoading,
    setLoading: setLoading
  };
};
