import {Box, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {JobPaperWrapper} from './pieces/jobPaperWrapper';
import * as allConstants from '../../../constants/allConstants';
import {globalUseClasses} from '../../../styles/globalClasses';
import {useCreateJobStyles} from './createJobStyles';
import OtherMechanical from './mechanical/otherMechanical';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import PropTypes from 'prop-types';
import AddAttachmentsJob from './pieces/addAttachmentsJob';
import {useFormContext} from 'react-hook-form';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import axios from 'axios';
import CityRequiredDocuments from './pieces/cityRequiredDocuments';
import JobFormAccordionTab from '../../../pieces/accordion/jobFormAccordionTab';
import Commercial from './mechanical/commercialSection';

const OtherStep = (props)=> {
  //console.log('OTHER STEP', props);
  const classes = {...globalUseClasses(), ...useCreateJobStyles()};

  const showOtherMechanical = props.jobType === allConstants.JOB_TYPES.HVAC_RES.value || props.jobType === allConstants.JOB_TYPES.TESTING_ONLY.value;
  const showMisc = props.jobType === allConstants.JOB_TYPES.MISC.value;

  const formMethods = useFormContext();
  const watchJobType = formMethods.watch(allConstants.JOB_FIELDS.JOB_TYPE.api_name);
  const watchAddress = formMethods.watch(allConstants.JOB_FIELDS.GENERAL.ADDRESS.api_name);
  const [cityDocs, setCityDocs] = useState([]);

  useEffect(()=> {
    // console.log('Need to fetch city documents for:::', watchJobType, watchAddress.city);

    const fetch = async ()=> {
      let url = `${base_url.api}cities/get-documents-by-name-and-job-type`;
      const authToken = await ApiUtils.getAccessToken();
      const requestParams = {name: watchAddress.city, jobType: watchJobType};
      const response = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
      return response;
    };
    fetch()
      .then((data)=> {
        // console.log('GOT DATA:::', data.data.data);
        if(data?.data?.data?.documents) {
          setCityDocs(data.data.data.documents);
        }

      })
      .catch((error)=> {
        console.log('ERROR Getting City Documents::: ', error);
      });
  }, [watchJobType, watchAddress]);

  let shouldShowCommercial = props.propertyType === allConstants.PROPERTY_TYPES.COMMERCIAL.value;
  if(props.jobType === allConstants.JOB_TYPES.HVAC_RES.value) {
    shouldShowCommercial = false;
  }

  return (
    <>
      {showOtherMechanical && OtherMechanical(props)}
      {showMisc && <>
        <Grid container mb={2}>
          <Grid item xs={12} display={'flex'} flexDirection={{xs: 'column'}}
            sx={{
              '& .MuiInputBase-root textarea': {
                width: '100%',
                maxWidth: '500px'
              }
            }}
          >
            <Box sx={{
              // width:{sx: '100%', sm: '150px'},
              marginRight: theme=>theme.spacing(2),
              paddingTop: '5px',
            }}>
              <Typography variant='h5' className={classes.block_header}>{allConstants.JOB_FIELDS.GENERAL.SCOPE.label}</Typography>
            </Box>
            <JobPaperWrapper styles={{padding: '24px !important'}}>
              <Box>
                <CustomFormBlock
                  {...props}
                  renderLabel={false}
                  multiline={true}
                  field={allConstants.JOB_FIELDS.GENERAL.SCOPE}
                  nested={true}
                  parent={allConstants.JOB_TYPES.MISC.api_name}
                  //defaultValue={values?.[`${blockParent}`]?.[allConstants.JOB_FIELDS.GENERAL.SCOPE.api_name]}
                  fullWidth/>
              </Box>

            </JobPaperWrapper>
          </Grid>
        </Grid>

      </>}
      {shouldShowCommercial &&
      <JobFormAccordionTab classes={classes}
        show={true}
        // idx+tab.name} key={
        header={'Commercial Information'}
        id={'com-info-header'}
        aria_controls={'com-info-content'}
        alwaysOpen={true}>
        <Commercial {...props} nested={true} parent={allConstants.MAP_JOB_TYPE_VALUE_TO_API[props.jobType]}/>

      </JobFormAccordionTab>
      }

      <Box display={'flex'} gap={2} flexDirection={'column'}>
        <Typography variant='h5' className={classes.block_header}>Other Information</Typography>
        <Grid container spacing={5}>
          {
            cityDocs !== undefined && cityDocs.length > 0 && <CityRequiredDocuments cityDocs={cityDocs}/>
          }
          <Grid item xs={12} sm={12} md={6} sx={{
            height: '100%',
            '& .MuiInputBase-root textarea': {
              width: '100%',
              maxWidth: '500px'
            }}}>
            <Typography variant={'h5'} sx={{fontSize: '16px'}}>Note</Typography>
            <JobPaperWrapper styles={{padding: '24px !important'}}>
              {/*<Grid item xs={12}>*/}
              <CustomFormBlock
                {...props}
                multiline={true}
                rowCount={6}
                renderLabel={false}
                field={{...allConstants.JOB_FIELDS.NOTE.TEXT, ...{required: false}}}
                fullWidth/>
              {/*</Grid>*/}
            </JobPaperWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{
            height: '100%',
          }}>
            <AddAttachmentsJob {...props} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export {OtherStep};

OtherStep.propTypes = {
  jobType: PropTypes.string.isRequired,
  setEquipmentForm: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired,
};
