import React, {useMemo} from 'react';
import {FormControl, FormHelperText, ThemeProvider} from '@mui/material';
import Utils from '../../utils/utils';
import {FormInput} from './formInput';
import {FloatingLabel} from './customInputComponent';
import myTheme, {useHelperTextStyles} from '../../styles/myStyles';
import {formBlockTypes, formBlockDefaultProps} from './formBlockProps';
import {JOB_FIELDS} from '../../constants/allConstants';
import {useFormContext} from 'react-hook-form';


const getFieldName = (props)=> {
  let field_name = props?.field?.api_name;
  if(props.nested && props.parent && props.parent.length !== 0) {
    field_name = `${props.parent}.${field_name}`;
  }
  return field_name;
};

const CustomFormBlock = (props)=>{
  const field_name = getFieldName(props);

  const helperTextStyles = useHelperTextStyles();
  const control = useFormContext();

  const error = () => {
    return Utils.formstateContainErrorFor(control?.formState, field_name);
  };
  const inputFormMethods = useMemo(()=> {
    return {
      control: control?.control,
      register: control?.register,
      setValueToForm: control?.setValue,
      formState: control?.formState
    };
  }, []);

  const isError = error();
  if(!control) return null;

  if(props.hide) {
    inputFormMethods.register(field_name);
    return null;
  }

  return (
    <ThemeProvider theme={myTheme}>
      <FormControl variant="standard" fullWidth={props.fullWidth} sx={{maxWidth: props.maxWidth ? props.maxWidth : '500px'}}>
        {props.renderLabel &&
        <FloatingLabel apiName={props.field.api_name} required={props.field.required} label={props.field.label}/>
        }
        <FormInput {...props} {...inputFormMethods}
          field_name={field_name}
          isError={isError}
          // helperText={error ? props.formState?.errors?.[field.api_name]?.message : 'error'}/>
        />

        {field_name !== JOB_FIELDS.GENERAL.JOBSITE_ADDRESS.api_name && !props.hideHelper && <FormHelperText classes={{
          root:helperTextStyles.root
        }}>{isError?.message}</FormHelperText>}
      </FormControl>
    </ThemeProvider>
  );
};

CustomFormBlock.propTypes = formBlockTypes;

CustomFormBlock.defualtProps = formBlockDefaultProps;

export default CustomFormBlock;
