import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Navigate, useNavigate} from 'react-router-dom';
import {Grid, Button, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as allConstants from '../../../constants/allConstants';
import * as STYLE_CONSTANTS from '../../../styles/styleConstants';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, FormProvider} from 'react-hook-form';
import {GeneralStep} from './generalStep';
import {EquipmentStep} from './equipmentStep';
import {OtherStep} from './otherStep';
import JobStepper from './pieces/jobStepper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useSelector} from 'react-redux';
import Utils from '../../../utils/utils';
import {useCreateJobStyles} from './createJobStyles';
import Loader from '../../misc/loader';
import JobSubmitted from './jobSubmitted';
import base_url from '../../../baseUrls';
import ApiUtils from '../../../utils/apiUtils';
import actions from '../../../redux/actions';
import validator from '../../../utils/validators/validator';
import {MapController} from '../../../utils/mapUtils';
import Spacer from '../../../pieces/spacer';

const stepNames = {
  general: 'general',
  equipment: 'equipment',
  other: 'other',
};

const StyledButton = styled(Button)(({theme}) => ({
  fontSize: '20px',
  width: '200px',
  margin: '0 20px',
}));

const getDefaultValues = ({allowedTests, initialData, isDraft, inspectionIsAllowed})=>{
  const bl_data = {};
  if(initialData && initialData?.jobType === allConstants.JOB_TYPES.BUSINESS_LIC.value) {
    try{
      const jsonData = JSON.parse(initialData?.[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
      bl_data[allConstants.JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name] = jsonData?.[allConstants.JOB_TYPES.BUSINESS_LIC.api_name]?.[allConstants.JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name];
    } catch(e){}

  }
  return {...{
    [allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name]: isDraft ? '' : allConstants.PROPERTY_TYPES.SINGLE_FAMILY.value,
    [allConstants.JOB_FIELDS.GENERAL.NEW_CONSTRUCTION.api_name]: false,
    [allConstants.JOB_FIELDS.GENERAL.ASBESTOS.api_name]: false,
    [allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name]: isDraft ? initialData?.[allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name]
      : allowedTests && allowedTests.includes(allConstants.TEST_TYPES.HERS.value),
    [allConstants.JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.api_name]: isDraft ? initialData?.[allConstants.JOB_FIELDS.GENERAL.EUC_TEST_REQUIRED.api_name]:false,
    [allConstants.JOB_FIELDS.MECHANICAL.COOLING_SYSTEM.api_name]: [],
    [allConstants.JOB_FIELDS.MECHANICAL.HPU_SYSTEM.api_name]: [],
    [allConstants.JOB_FIELDS.MECHANICAL.DUCTS.api_name]: [],
    [allConstants.JOB_FIELDS.MECHANICAL.OTHER_EQ_OPTIONS.api_name]: [],
    [allConstants.JOB_FIELDS.DUPLICATE_OF.api_name]: null,
    [allConstants.JOB_FIELDS.GENERAL.POST_JOB_CARD.api_name]: allConstants.POST_JOB_CARD.SHIP.value
  }, ...initialData, ...bl_data};
};

const CreateJobForm = (props) => {
  const dispatch = useDispatch();
  const classes = useCreateJobStyles();
  const navigate = useNavigate();
  const steps = [stepNames.general, stepNames.equipment, stepNames.other];
  const [activeStep, setActiveStep] = useState(0);
  const company = useSelector(state => state.company);
  const allowedTests = company?.allowedPermits ? company?.allowedTests ? Object.values(allConstants.TEST_TYPES).filter((type) =>
    company.allowedTests.includes(type.value)) : undefined : [];
  const [jobType, setJobType] = useState();
  const [propertyType, setPropertyType] = useState();
  const [equipmentForm, setEquipmentForm] = useState({});
  const GENERAL = allConstants.JOB_FIELDS.GENERAL;
  const [fetching, setFetching] = useState(false);
  const [submitted, setSubmitted] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const timerId = React.createRef();
  const mapController = MapController();
  const initialData = React.createRef();

  const isDuplicate = ()=> {
    return Utils.objectHasProperty(props, 'duplicateOf') && props.duplicateOf;
  };

  if(Utils.objectHasProperty(props, 'duplicateOf') && props.duplicateOf) {
    initialData.current = {...props.duplicateOf, ...{
      [allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]: {...props?.duplicateOf?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name], ...{
        [allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name]: Utils.reformatPhoneNumber(props?.duplicateOf?.[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name]),
        [allConstants.JOB_FIELDS.GENERAL.ALT_HO_PHONE.api_name]: Utils.reformatPhoneNumber(props?.duplicateOf[allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[allConstants.JOB_FIELDS.GENERAL.ALT_HO_PHONE.api_name])
      }}

    }};
  }

  if(props.draftData) {
    const jobJson =  JSON.parse(props.draftData?.[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]);
    initialData.current = {...props.draftData, ...jobJson};
  }

  let nextButton = React.useRef(null);
  const [systemErrors, setSystemErrors] = useState(undefined);

  const saveEquipmentForm = async (name, form) => {
    // const newEq = {...equipmentForm, ...{[name]: form}}
    equipmentForm[name] = form;
    setEquipmentForm(equipmentForm);
    await validateSubforms();
  };

  const resetSystemForm = (system_name)=> {
    delete equipmentForm[system_name];
    dispatch(actions.resetSubform({jobType: allConstants.MAP_JOB_TYPE_VALUE_TO_API[jobType], subformName: system_name}));
  };

  const refMapToError = {
    general: {
      [allConstants.JOB_FIELDS.JOB_TYPE.api_name]: React.useRef(null),
      [GENERAL.HO_NAME.api_name]: React.useRef(null),
      [GENERAL.JOBSITE_ADDRESS.api_name]: React.useRef(null),
      [GENERAL.HO_PHONE.api_name]: React.useRef(null),
      [GENERAL.JOB_VALUE.api_name]: React.useRef(null),
    },
  };

  const validationSchema = Yup.object().shape({
    [allConstants.JOB_FIELDS.JOB_TYPE.api_name]: Yup.string()
      .required(allConstants.ERROR_MSG.SELECT_JOB_TYPE),

    [allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name]: Yup.object().when(
      allConstants.JOB_FIELDS.JOB_TYPE.api_name,{
        is:(jobType) => {
          return jobType && jobType !== allConstants.JOB_TYPES.BUSINESS_LIC.value;
        },
        then: Yup.object().shape({
          [allConstants.JOB_FIELDS.GENERAL.HO_NAME.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
          [allConstants.JOB_FIELDS.GENERAL.HO_PHONE.api_name]: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD)
            .test({
              message: allConstants.ERROR_MSG.INVALID_PHONE,
              test: value => validator.isValidPhoneNumber(value)
            }),
          [allConstants.JOB_FIELDS.GENERAL.ALT_HO_PHONE.api_name]: Yup.string()
            .test({
              message: allConstants.ERROR_MSG.INVALID_PHONE,
              test: value => validator.isValidPhoneNumber(value)
            }),
          [allConstants.JOB_FIELDS.GENERAL.EMAIL.api_name]: Yup.string()
            .test({
              message: allConstants.ERROR_MSG.EMAIL_INVALID,
              test: value => validator.isValidEmail(value, true)
            }),
        })
      }
    ),


    [allConstants.JOB_FIELDS.GENERAL.JOB_VALUE.api_name]: Yup.number()
      .when(allConstants.JOB_FIELDS.JOB_TYPE.api_name, {
        is: (type) => {
          return type && type !== allConstants.JOB_TYPES.TESTING_ONLY.value && type !== allConstants.JOB_TYPES.BUSINESS_LIC.value;},
        then: Yup.number()
          .transform(value => (isNaN(value) ? undefined : value))
          .required(allConstants.ERROR_MSG.REQUIRED_FIELD)
      }),
    [allConstants.JOB_FIELDS.BUSINESS_LIC.CITY_TO_RENEW_BL.api_name]: Yup.string()
      .when(allConstants.JOB_FIELDS.JOB_TYPE.api_name, {
        is: (type) => {
          return type && type === allConstants.JOB_TYPES.BUSINESS_LIC.value;},
        then: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD)
      }),
    [allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name]: Yup.string()
      .when(allConstants.JOB_FIELDS.JOB_TYPE.api_name, {
        is: (type) => type !== allConstants.JOB_TYPES.BUSINESS_LIC.value,
        then: Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD)
      }),
    [allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS.api_name]: Yup.object()
      .when(allConstants.JOB_FIELDS.JOB_TYPE.api_name, {
        is: (type) => type !== allConstants.JOB_TYPES.BUSINESS_LIC.value,
        then: Yup.object().shape({
          [allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_LINE_1.api_name]:  Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
          [allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_CITY.api_name]:  Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
          [allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_STATE.api_name]:  Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD),
          [allConstants.JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_ZIPCODE.api_name]:  Yup.string().required(allConstants.ERROR_MSG.REQUIRED_FIELD).typeError(allConstants.ERROR_MSG.REQUIRED_FIELD),
        })
      }),
    [allConstants.JOB_FIELDS.GENERAL.HERS_PO_NUMBER.api_name]: Yup.string().max(31), //QB field limitation
    [allConstants.JOB_FIELDS.GENERAL.CUSTOMER_JOB_NUMBER.api_name]: Yup.string().max(31), //QB field limitation

  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValues: getDefaultValues({allowedTests: allowedTests, initialData: initialData.current, isDraft: props.draftData})
  };
  const formMethods = useForm(formOptions);
  const watchJobType = formMethods.watch(allConstants.JOB_FIELDS.JOB_TYPE.api_name);

  const watchPropertyType = formMethods.watch(allConstants.JOB_FIELDS.GENERAL.PROPERTY_TYPE.api_name);

  const handleNext = async (event) => {
    event.preventDefault();
    let canGoNext = false;
    if(activeStep === 0) {

      canGoNext = await formMethods.trigger();
      nextButton.current.focus();

      if(!canGoNext){
        // canGoNext = false;
        const errors = formMethods.formState.errors;
        if(Object.keys(errors).length !== 0) {
          // canGoNext = false;
          if(Utils.objectHasProperty(errors, allConstants.JOB_FIELDS.JOB_TYPE.api_name)) {
            refMapToError.general[allConstants.JOB_FIELDS.JOB_TYPE.api_name].current.scrollIntoView();
          } else {
            const el = refMapToError.general?.[Object.keys(errors)[0]];
            if(el && el.current) {
              el.current.scrollIntoView();
            }
          }
        }
      }
    }

    if(activeStep === 1) {
      const errors = await validateSubforms();
      window.noValidate = false;
      canGoNext = !errors || Object.keys(errors).length === 0;
    }
    if(canGoNext || window.noValidate) {
      setActiveStep(activeStep + 1);
    }

  };
  const handlePrev = () => {
    setActiveStep(activeStep - 1);
  };


  useEffect(()=> {
    dispatch(actions.resetAllSubforms());
    if(jobType) {
      formMethods.unregister(allConstants.MAP_JOB_TYPE_VALUE_TO_API[jobType]);
    }
    if(watchJobType === allConstants.JOB_TYPES.HVAC_RES.value || watchJobType === allConstants.JOB_TYPES.TESTING_ONLY.value) {
      dispatch(actions.addSubform({jobType: allConstants.MAP_JOB_TYPE_VALUE_TO_API[watchJobType]}));
    }

    setJobType(watchJobType);
  },[watchJobType]);

  useEffect(()=> {
    setPropertyType(watchPropertyType);
    if(props.draftData) return;
    if(watchPropertyType === allConstants.PROPERTY_TYPES.MOBILE_HOME.value &&
      (jobType === allConstants.JOB_TYPES.HVAC_RES.value || jobType === allConstants.JOB_TYPES.TESTING_ONLY.value)) {
      formMethods.resetField(allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name, {defaultValue: false});
    } else if(jobType === allConstants.JOB_TYPES.HVAC_RES.value || jobType === allConstants.JOB_TYPES.TESTING_ONLY.value) {
      let defaultValue = true;
      if(props.duplicateOf) {
        defaultValue = Object.keys(props.duplicateOf).length === 0;
      }
      formMethods.resetField(allConstants.JOB_FIELDS.GENERAL.HERS_TEST_REQUIRED.api_name, {
        defaultValue: defaultValue
      });
    }
  },[watchPropertyType, jobType]);

  useEffect(()=> {
    return ()=> {
      if(timerId && timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  });

  useEffect(async () => {
    if(activeStep === 2) {
      nextButton?.current?.blur();
    }
  }, [activeStep]);

  const addSystemErrors = (system, errors)=> {
    const newErrors = {...systemErrors};
    if(!newErrors?.[system]) {
      newErrors[system] = errors;
      setSystemErrors(newErrors);
    }
  };

  const onSubmit = async (submit=true)=> {

    const mapFileId = await mapController.saveMapImage(()=>setFetching(submit));
    const equipment = getEquipmentFromSavedSubforms();
    const equipmentStr = Utils.getEqupmentUsedString(equipment);

    const attachments = getAttachmentFromSavedSubforms();
    const data = Utils.createJobObject({
      main: formMethods.getValues(),
      equipment: equipment,
      attachments: attachments,
      allowedTests: allowedTests,
    });

    if(props.duplicateOf?.id) {
      data.duplicateOfJobId = props.duplicateOf?.id;
    }
    if(equipmentStr && equipmentStr.length !== 0) {
      data.equipmentUsed = equipmentStr;
    }

    console.log('JSON', JSON.parse(data[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name]));
    const usedEquipment = Utils.getEquipmentThatRequiresMap(equipmentForm);
    if(usedEquipment && usedEquipment.length !== 0) {
      data.map = {
        marks: Utils.getMapDistances(data?.[allConstants.JOB_FIELDS.GENERAL.JOB_JSON.api_name], allConstants.MAP_JOB_TYPE_VALUE_TO_API[jobType])
      };
      // const mapFileId = await mapUtils.saveMapImage();
      if(mapFileId) {
        data.map.fileId = mapFileId;
      }
    }

    console.log('data to submit', data);

    if(!submit) return;
    const authToken = await ApiUtils.getAccessToken();
    if(!authToken) {
      return;
    }
    let url = props.draftData ? `${base_url.api}jobs/update-draft`: `${base_url.api}jobs/create`;
    const response = await fetch(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(
          data,
        ),
      }
    );

    let submitted = await response.json();

    // eslint-disable-next-line no-unused-vars
    timerId.current = setTimeout(()=> {
      if(submitted.status === 'success') {
        setSubmitted(submitted.data.job[allConstants.JOB_FIELDS.JOB_NUMBER.api_name] || '_');
      } else {
        setSubmitError(`Error::: ${submitted.message}`);
        // setSubmitError('Error happened on job submit');
      }
      setFetching(false);

    }, 2000);
  };

  const inputDefaultProps = {
    viewMode: false,
    renderLabel: true,
    nested: activeStep !== 0,
    parent: null,
    isEditable: true,
    labelSecondary: true
  };

  const stepProps = {
    jobType: jobType,
    draftData: props.draftData,
    propertyType: propertyType,
    allowedPermits: company?.allowedPermits ? Object.values(allConstants.JOB_TYPES).filter((type)=>
      company.allowedPermits.includes(type.value)) : [],

    allowedTests: allowedTests,
    addSystemErrors: addSystemErrors
    // stepName: steps[activeStep],
  };

  window.formMethods = formMethods;
  const shouldSkipEquipment = ()=> {
    return jobType === allConstants.JOB_TYPES.BUSINESS_LIC.value || jobType === allConstants.JOB_TYPES.MISC.value;
  };

  const isLastStep = ()=>{
    const currSteps = [...steps];
    if(shouldSkipEquipment()) {
      currSteps.splice(1, 1);
    }
    return activeStep === currSteps.length-1;
  };

  const getAttachmentFromSavedSubforms = ()=> {
    let attachments = [];
    const values = equipmentForm['attachments']?.getValues()?.files;
    if(!values) return attachments;

    for (const value of values) {
      if(value?.file) {
        let fileName = '';

        if(value?.name && value.name.length !== 0) {
          fileName = value.name;
        } else if(value?.file && typeof value.file === 'object' &&  value?.file?.name){
          fileName = value.file.name;
        } else {
          fileName = value.fileName;
        }

        const attachment = {
          [allConstants.JOB_FIELDS.ATTACHMENT.FILE_UPLOAD_ID.api_name]: value.file?.id || value.file,
          [allConstants.JOB_FIELDS.ATTACHMENT.FILE_NAME.api_name]: fileName,
          [allConstants.JOB_FIELDS.ATTACHMENT.TYPE.api_name]: jobType === allConstants.JOB_TYPES.TESTING_ONLY.value ? allConstants.NOTE_TYPES.HERS.value : allConstants.NOTE_TYPES.PERMIT.value,
        };
        attachments.push(attachment);
      }
    }



    return attachments;
  };
  const validateSubforms = async ()=> {
    try{
      const errors = {};
      if(equipmentForm&& Object.keys(equipmentForm).length !== 0) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, form] of Object.entries(equipmentForm)) {

          // Skip attachments
          if (key === 'attachments') {
            continue;
          }
          await form.trigger();
          const systemErrors = form.formState.errors;
          if(Object.keys(systemErrors).length !== 0){
            errors[key] = systemErrors;
          }
        }
      }

      if(Object.keys(errors).length !== 0) {
        setSystemErrors(errors);
      } else {
        setSystemErrors(undefined);
      }
      return errors;
    } catch (e){
      return {};
    }

  };

  const getEquipmentFromSavedSubforms = ()=> {
    let equipment = [];

    if(equipmentForm && Object.keys(equipmentForm).length !== 0) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(equipmentForm)) {

        // Skip attachments
        if (key === 'attachments') {
          continue;
        }

        equipment = [...equipment, ...Utils.removeEmptyEquipmentRows(value.getValues())];
      }
    }
    return equipment;
  };

  const successDone = ()=> {
    navigate(allConstants.PATH.JOBS);
  };

  const createNewAction = ()=> {
    setSubmitError(null);
    setFetching(false);
    setSubmitted(false);
    setActiveStep(0);
    formMethods.reset();
    dispatch(actions.resetAllSubforms());
    setEquipmentForm([]);
  };

  if(fetching) {
    return <Loader timeout={50}/>;
  }

  if(submitted) {
    return <JobSubmitted jobNumber={submitted}
      doneAction={successDone}
      newAction={createNewAction}
      isDraft={props.draftData}/>;
  }
  if(submitError) {
    return <JobSubmitted jobNumber={submitError}
      isError={true}
      doneAction={successDone}
      newAction={createNewAction}
      isDraft={props.draftData}/>;
  }

  const getStepContent = () => {
    switch (activeStep) {
    case 0:
      return  <GeneralStep {...inputDefaultProps} {...stepProps} refs={refMapToError.general}
        isDuplicate={isDuplicate()}/>;
    case 1:
      if(shouldSkipEquipment()) {
        return  <OtherStep {...inputDefaultProps} {...stepProps} setEquipmentForm={saveEquipmentForm}/>;
      } else {
        return <EquipmentStep {...inputDefaultProps}
          {...stepProps}
          setEquipmentForm={saveEquipmentForm}
          resetSystemForm={resetSystemForm}
          systemErrors={systemErrors}
        />;
      }
    case 2:
      return  <OtherStep {...inputDefaultProps} {...stepProps} savedForms={equipmentForm} mapController={mapController} setEquipmentForm={saveEquipmentForm}/>;
    default:
      return 'Unknown step';
    }
  };
  if(!company?.id) {
    return <Navigate to={allConstants.PATH.JOBS}/>;
  }

  return (
    <>
      <FormProvider {...formMethods} >
        <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST" >
          <Grid container className={classes.job_form} justifyContent={'center'}>
            <Spacer y={3}/>
            <Grid item xs={12} display={'flex'} justifyContent={'center'} >
              <JobStepper currentActive={activeStep+1} shouldSkipEquipment={shouldSkipEquipment()}/>
            </Grid>
            <Spacer y={3}/>
            <Grid item xs={12} sx={{maxWidth: '1550px !important'}}>
              {getStepContent()}
            </Grid>
            <Spacer y={2}/>
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              {ApiUtils.isDevelopment() &&<Button onClick={()=>onSubmit(false)} variant={'outlined'} color={'info'}>Log Fields</Button>}
              {ApiUtils.isDevelopment() && <Button onClick={()=>{window.noValidate=true;}}  variant={'outlined'} color={'info'}>Turn off validation</Button>}
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Box sx={{width: {md: '450px', xs: '100%', paddingBottom: '24px'}}} display={'flex'}>
                <StyledButton
                  disabled={activeStep === 0}
                  startIcon={<ArrowBackIcon />}
                  onClick={handlePrev}
                  variant="outlined"
                  sx={{
                    visibility: `${activeStep !== 0 ? 'visible' : 'hidden'}`,
                    '& .MuiButton-startIcon': {
                      position: 'absolute',
                      left: '16px',
                    },
                    '&:hover': {
                      color: STYLE_CONSTANTS.COLORS.white,
                      backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue
                    }
                  }}
                >
                Previous
                </StyledButton>
                <StyledButton
                  variant="contained"
                  type={isLastStep() ? 'button' : 'submit'}
                  endIcon={isLastStep() ? null : <ArrowForwardIcon />}
                  onClick={isLastStep() ? onSubmit : handleNext}
                  ref={nextButton}
                  sx={{
                    '& .MuiButton-endIcon': {
                      position: 'absolute',
                      right: '16px',
                    }
                  }}
                >
                  {isLastStep() ? 'Submit' : 'Next'}
                </StyledButton>
              </Box>

            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
CreateJobForm.propTypes = {
  duplicateOf: PropTypes.instanceOf(Object),
  draftData: PropTypes.string,
};
CreateJobForm.defaultProps = {
  duplicateOf: null,
  draftData: null
};
export default CreateJobForm;
