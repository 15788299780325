import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import base_url, {urlSearchParams} from '../../baseUrls';
import Utils from '../../utils/utils';
import PermissionGate from '../../permissions/permissionGate';
import * as allConstants from '../../constants/allConstants';
import {useDispatch} from 'react-redux';
import actions from '../../redux/actions';
import StatusRenderer from '../../pieces/grids/statusRenderer';
import ColumnDefinition from '../../pieces/grids/columnGenerator';
import {allCompaniesPageRules} from './allCompaniesPageAccesssControl';
import {COMPANY_FIELDS} from '../../constants/allConstants';
import {unwrapRequestResult} from '../../utils/unwrapRequestResult';
import MyPaginatedGrid from '../../pieces/grids/paginatedGridWrapper';
import {useNavigate} from 'react-router-dom';


const {COMPANY_FIELDS: {
  ADDRESS,
  EMAIL,
  ID,
  MAIN_CONTACT,
  NAME,
  PHONE_NUMBER,
  STATUS
}} = allConstants;


const AllCompaniesGrid = (props) => {
  const viewController = props.viewController;
  const dispatch = useDispatch();
  const [components] = useState({
    'statusRenderer': StatusRenderer,
  });
  const navigate = useNavigate();
  const companyColumns = {
    id:  new ColumnDefinition({field: ID, hide: true}),
    name: new ColumnDefinition({field: NAME, wrapText: true, width: 300,lockPinned: true, pinned: 'left',
      cellRendererParams: (params)=> {
        return {
          handleExpand: (params)=>handleDisplayCompanyDetails(params),
        };},
    }),
    status: new ColumnDefinition({field: STATUS,
      cellRenderer: 'statusRenderer', width: 200,
      filter:'agSetColumnFilter',
      filterValues: Object.values(allConstants.COMPANY_STATUSES).map((s)=> s.value),
      valueGetter: ({data})=> {
        if(data?.[COMPANY_FIELDS.IS_BLOCKED.api_name]) {
          return allConstants.COMPANY_STATUSES.BLOCKED.value;
        }
        return data?.[COMPANY_FIELDS.STATUS.api_name];
      }
    }),
    email: new ColumnDefinition({field: EMAIL}),
    mainContact: new ColumnDefinition({field: MAIN_CONTACT}),
    phoneNumber: new ColumnDefinition({field: PHONE_NUMBER,
      width: 170,
      valueGetter: ({data})=>Utils.formatPhoneNumber(data[PHONE_NUMBER.api_name])}),
    address: new ColumnDefinition({field: ADDRESS,
      headerName: 'Address',
      valueGetter: ({data})=> {
        if(!Utils.objectHasProperty(data, ADDRESS.api_name)) return '';
        return Utils.getAddressString(data[ADDRESS.api_name]);}
    }),
    licenseNumber: new ColumnDefinition({
      field: {...COMPANY_FIELDS.STATE_LICENSE_NUMBER, ...{label: 'state license #'}},
      width: 170,
      valueGetter: ({data})=> data?.[COMPANY_FIELDS.STATE_LICENSE.api_name]?.[COMPANY_FIELDS.STATE_LICENSE_NUMBER.api_name]
    }),
    qBID: new ColumnDefinition({field: COMPANY_FIELDS.QB_ID})
  };

  const getColumns = ()=> {
    return [
      companyColumns.id,
      companyColumns.name,
      companyColumns.licenseNumber,
      companyColumns.mainContact,
      companyColumns.phoneNumber,
      companyColumns.address,
      companyColumns.status,
      companyColumns.email,
      companyColumns.qBID
    ];
  };

  let columnDefs = useMemo(()=> {
    return getColumns();
  }, []);


  const impersonate = (companyId)=> {
    dispatch(actions.impersonate(companyId));
  };

  const deactivate = async (companyId, companyName)=> {
    //console.log('will deactivate', companyId);
    const deactivateStatus = await dispatch(actions.deactivateCompany(companyId));
    unwrapRequestResult({
      result: deactivateStatus,
      successMessage: `${companyName} was DEACTIVATED. Please refresh page to see changes.`,
      errorMessage: 'Error on deactivation',
      showSuccess: true,
    } );

  };

  const activate = async (companyId, companyName)=> {
    const activateStatus = await dispatch(actions.activateCompany(companyId));
    unwrapRequestResult({
      result: activateStatus,
      successMessage: `${companyName} was ACTIVATED.`,
      errorMessage: 'Error on activation',
      showSuccess: true,
    } );
  };

  const block = async (companyId, companyName)=> {
    const activateStatus = await dispatch(actions.blockCompany(companyId));
    unwrapRequestResult({
      result: activateStatus,
      successMessage: `${companyName} was blocked.`,
      errorMessage: 'Error on blocking',
      showSuccess: true,
    } );
  };
  const unblock = async (companyId, companyName)=> {
    const activateStatus = await dispatch(actions.unblockCompany(companyId));
    unwrapRequestResult({
      result: activateStatus,
      successMessage: `${companyName} was unblocked.`,
      errorMessage: 'Error on unblocking',
      showSuccess: true,
    } );
  };

  const handleDisplayCompanyDetails = (params) => {
    navigate(`${allConstants.PATH.COMPANY}?${urlSearchParams.companyId}=${params?.node?.data.id}`);
  };

  const handleEditFinancial = (companyData) => {
    if(props.editFinancial) {
      props.editFinancial(companyData);
    }
  };

  const getContextMenuItems = (params)=> {
    // console.log('params', params?.node?.data.id, viewController.userRole);
    if(!params.node) return [];
    const companyStatus = params?.node?.data[allConstants.COMPANY_FIELDS.STATUS.api_name];
    const isBlocked = params?.node?.data[allConstants.COMPANY_FIELDS.IS_BLOCKED.api_name];
    // const isSignedUp = Utils.notEqualsIgnoreCase(companyStatus, allConstants.COMPANY_STATUSES.NOT_SIGNED.value);
    const actions = {
      impersonate: {
        name: 'Impersonate',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> impersonate(params?.node?.data.id),
        cssClasses: ['redFont', 'bold'],
      },
      viewComapny: {
        name:  params?.node?.data?.[COMPANY_FIELDS.STATUS.api_name] === allConstants.COMPANY_STATUSES.PENDING.value ? 'Verify' : 'View',
        cssClasses: ['bold'],
        disabled: viewController.selectedMultipleRows(),
        action: () => {handleDisplayCompanyDetails(params);},
      },
      deactivate: {
        name: 'Deactivate',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> deactivate(params?.node?.data.id, params?.node?.data[COMPANY_FIELDS.NAME.api_name]),
      },
      activate: {
        name: 'Activate',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> activate(params?.node?.data.id, params?.node?.data[COMPANY_FIELDS.NAME.api_name]),
      },
      block: {
        name: 'Block company',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> block(params?.node?.data.id, params?.node?.data[COMPANY_FIELDS.NAME.api_name]),
      },
      unblock: {
        name: 'Unblock company',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> unblock(params?.node?.data.id, params?.node?.data[COMPANY_FIELDS.NAME.api_name]),
      },
      editFinancialInfo: {
        name: 'Edit Financial Info',
        disabled: viewController.selectedMultipleRows(),
        action: ()=> handleEditFinancial(params?.node?.data),
      }
    };

    const standard = [
      {
        name:  'Export Selected (.xlsx)',
        action: () => params.api.exportDataAsExcel(
          {onlySelected: true}
        )
      },
      'copy',
    ];
    const separator=[
      'separator'
    ];
    const companyActions = [actions.viewComapny];

    if(PermissionGate({
      modulePermissions: allCompaniesPageRules.editFinancialInfo,
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
    })) {
      companyActions.push(actions.editFinancialInfo);
    }

    companyActions.push('separator');

    if(PermissionGate({
      modulePermissions: allCompaniesPageRules.impersonate,
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
    })) {
      companyActions.push(actions.impersonate);
      companyActions.push('separator');
    }

    if(PermissionGate({
      modulePermissions: allCompaniesPageRules.activate,
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
    })) {
      if(companyStatus === allConstants.COMPANY_STATUSES.ACTIVE.value) {
        companyActions.push(actions.deactivate);
      } else if(companyStatus === allConstants.COMPANY_STATUSES.NOT_ACTIVE.value){
        companyActions.push(actions.activate);
      }
    }
    if(PermissionGate({
      modulePermissions: allCompaniesPageRules.block,
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
    })) {
      if(!isBlocked) {
        companyActions.push(actions.block);
      } else {
        companyActions.push(actions.unblock);
      }
    }

    return [...companyActions, ...separator, ...standard];
  };

  return (
    <MyPaginatedGrid columnDefs={columnDefs}
      components={components}
      ls_name={'all_companies_grid'}
      onCellDoubleClicked={(params)=> handleDisplayCompanyDetails(params)}
      registerGrid={viewController.setGridToParent}
      contextMenu={getContextMenuItems}
      fetchParams={{url:`${base_url.api}companies`, respKey:'companies'}}
      onFetchFail={viewController.onFetchFail}
      pagination={props.pagination}
    />
  );
};

AllCompaniesGrid.propTypes = {
  pagination: PropTypes.instanceOf(Object).isRequired,
  viewController: PropTypes.instanceOf(Object).isRequired,
  editFinancial: PropTypes.func.isRequired
};
export default AllCompaniesGrid;
