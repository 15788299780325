import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as STYLE_CONSTANTS from '../styles/styleConstants';
import {Switch} from '@mui/material';

const AntSwitch = styled(Switch)(({theme}) => ({
  width: 44,
  height: 22,
  padding: 0,
  display: 'flex',
  marginRight: theme.spacing(2),
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 18,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(21px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: STYLE_CONSTANTS.COLORS.globalBlue,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    padding: '8px',
    width: 18,
    height: 18,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    width: 44,
    height: 22,
    borderRadius: '11px',
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function SimpleSwitch(props) {
  return <AntSwitch onChange={props.onChange} defaultChecked={props.defaultChecked} checked={props.defaultChecked}/>;
}

SimpleSwitch.propTypes = {
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool
};

SimpleSwitch.defaultProps = {
  defaultChecked: false,
};
