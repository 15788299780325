import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box, InputAdornment} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import * as STYLE_CONSTANTS from '../../styles/styleConstants';
import AddressFrom from './address';
import {CustomInput} from './customInputComponent';
import FileUploadField from './fileUploadField';
import DateInput from './dateInput';
import CustomSelect from './select';
import PhoneInput from './phoneInput';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import CustomRadioGroup from './radioGroup';
import CurrencyInput from './currencyInput';
import MultipleSelect from './multiSelect';
import CustomCheckBox from './checkBox';
import PasswordBlock from './password';
import CustomAutocomplete from './autocomplete';
import fieldFormatter from '../../utils/fieldsToNormalize';
import {useFormContext} from 'react-hook-form';
import Utils from '../../utils/utils';
import MultipleSelectWithSearch from './multiSelectWithSearch';

const inputTypes = {
  // test: 'text',
  file: 'file',
  singleSelect: 'singleSelect',
  number: 'number',
  multiSelect: 'multiSelect',
  address: 'address',
  date: 'date',
  phone: 'phone',
  radio: 'radio',
  currency: 'currency',
  checkbox: 'checkbox',
  password: 'password',
  autoComplete: 'autocomplete',
  time: 'time',
  multiSelectWithSearch: 'multiSelectWithSearch',
};

const FormInput = (props)=> {
  const staticProps = useMemo(()=> props, ['defaultValue']);
  const forMethods = useFormContext();

  useEffect(()=>{
    if(staticProps.defaultValue){
      let value = staticProps.defaultValue;
      if(props.inputType === inputTypes.file) {
        value = staticProps.defaultValue.id;
      }
      if(props.inputType === inputTypes.phone) {
        value = Utils.reformatPhoneNumber(staticProps.defaultValue);
      }
      forMethods.setValue(staticProps.field_name, value, {setDirty: false, setTouched: false});
    }
  },[staticProps.defaultValue]);

  // console.log('[FORM INPUT]:::', props.field_name, props.viewMode);

  let isNumber = staticProps.inputType === inputTypes.number;
  if(isNumber && staticProps.defaultValue) {
    try{
      if(isNaN(Number(staticProps.defaultValue))) {
        isNumber = false;
      }

    } catch(e){

    }
  }
  const lockIcon = props.isEditable ? null : props.viewMode ? null :
    <InputAdornment position="end">{<LockIcon style={{color: STYLE_CONSTANTS.COLORS.fontPale}}/>}</InputAdornment>;

  const CustomInputComponent = <CustomInput id={`${staticProps.field.api_name}-input`}

    name={staticProps.field.api_name}
    inputProps={{
      defaultValue: staticProps.defaultValue ? JSON.stringify(staticProps.defaultValue): null,
      title: staticProps.inputType === inputTypes.number ? 'Number' : null,
      min: '0',
      autoComplete: 'off',
      step: isNumber ? 'any' : undefined
    }}
    // autoComplete={'new-password'}
    autoComplete={'off'}
    readOnly={!props.isEditable || props.viewMode}
    disabled={!props.isEditable || props.viewMode}
    {...props.register(staticProps.field_name, {required: staticProps.field.required})}
    endAdornment={lockIcon}
    error={props.isError}
    multiline={staticProps.multiline}
    rows={staticProps.multiline ? staticProps.rowCount : undefined}
    placeholder={staticProps.placeholder ? staticProps.placeholder : isNumber && !staticProps.hidePlaceholder ? 'number' : null}

    onChange={(v)=> {
      staticProps.setValueToForm(staticProps.field_name, staticProps.preventCapitalize ? v.target.value : fieldFormatter.getValueForDB(staticProps.field.api_name, v.target.value),
        {shouldDirty: true, shouldValidate:true});
    }}
    type={props.inputType === inputTypes.time ? 'time' : isNumber ? 'number' : 'text'}
  />;


  let inputComponent;

  switch (props.inputType) {
  case inputTypes.file: {
    inputComponent =  <FileUploadField {...props}/>;
    break;
  }
  case inputTypes.radio: {
    inputComponent = <CustomRadioGroup  {...props} />;
    break;
  }
  case inputTypes.phone: {
    inputComponent = <PhoneInput {...props} lockIcon={lockIcon} />;
    break;
  }
  case inputTypes.singleSelect: {
    inputComponent = props.showAsStatic ? CustomInputComponent : <CustomSelect {...props}/>;
    break;
  }
  case inputTypes.multiSelect: {
    inputComponent = <MultipleSelect {...props} multiSelect/>;
    break;
  }
  case inputTypes.currency: {
    inputComponent = <CurrencyInput  {...props}/>;
    break;
  }
  case inputTypes.checkbox: {
    inputComponent = <CustomCheckBox {...props}/>;
    break;
  }
  case inputTypes.date: {
    inputComponent = <>
      <DateInput
        {...props}
      />

    </>;
    break;
  }
  // case inputTypes.time: {
  //   inputComponent = <>
  //     <TimeInput
  //       {...props}
  //     />
  //
  //   </>;
  //   break;
  // }
  case inputTypes.address: {
    inputComponent = <>
      <AddressFrom
        field_name={props.field_name}
        defaultValue={props.defaultValue}
        field={props.field}
        isError={props.isError}
        viewMode={props.viewMode}
        readOnly={!props.isEditable || props.viewMode}
        maxWidth={props?.maxWidth}
      />
    </>;
    break;
  }
  case inputTypes.password: {
    inputComponent = <PasswordBlock {...props}/>;
    break;
  }
  case inputTypes.autoComplete: {
    inputComponent = <CustomAutocomplete {...props}/>;
    break;
  }

  case inputTypes.multiSelectWithSearch: {
    inputComponent = <MultipleSelectWithSearch {...props}/>;
    break;
  }
  default: {
    inputComponent = CustomInputComponent;
  }
  }

  return (
    <Box sx={{
      position: 'relative',
      '& .MuiInputBase-root': {
        width: '100%',
        minHeight: '20px'
      },
      '& .Mui-disabled .MuiInputBase-input': {
        backgroundColor: `${props.showAsStatic ? 'initial !important' : ''}`
      },
      '& .MuiInputAdornment-positionEnd': {
        display: props.showAsStatic ? 'none' : 'flex'
      }
    }}>
      {inputComponent}
    </Box>

  );

};

FormInput.propTypes = {
  ...formBlockTypes,
  ...{error: PropTypes.instanceOf(Object),
    helperText: PropTypes.string
  }
};

FormInput.defaultProps = {
  ...formBlockDefaultProps,
  ...{error: false,
    helperText: ''
  }
};

export {FormInput, inputTypes};
