import React  from 'react';
import PropTypes from 'prop-types';
import ModalWrapper, {ModalContentWrapper} from '../../../../pieces/modals/modalWrapper';
import {useForm, FormProvider} from 'react-hook-form';
import FormSubmitButtons from '../../../../pieces/formSubmitButtons';
import * as allConstants from '../../../../constants/allConstants';
import CustomFormBlock from '../../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../../pieces/inputs/formInput';
import {Box, LinearProgress} from '@mui/material';
import {Text} from '../../../../pieces/text';
import {COLORS} from '../../../../styles/styleConstants';
import Spacer from '../../../../pieces/spacer';

export const EditFile = (props)=> {

  const getFileNameToDisplay = (fileName) => {

    if(!fileName) return '';
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    return fileNameWithoutExtension;
  };
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode:'all',
    defaultValues:{
      id: props.attachment?.id,
      [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: {id: props.attachment?.fileId, fileName: props.attachment?.fileId},
      [allConstants.JOB_FIELDS.FILE.NAME.api_name]: getFileNameToDisplay(props.attachment?.fileName),
    }
  });

  const onSubmit = (data) => {

    props.submitAction({
      id: props.attachment.id,
      fileId: data.file,
      fileName: data.name,
    });
  };

  const commonFieldProps = {
    fullWidth: true,
    maxWidth: '100%',
    isEditable: true,
    renderLabel: true,
    nested: true,
  };

  return (
    <ModalWrapper handleClose={props.onCancel}
      open={true}
      modalName={'delete-attachment'}
      preventScroll={true}
      width={{sx: '98%', sm: '600px'}}
      padding={6}
      customStyle={{paddingTop: 2}}
    >
      <ModalContentWrapper handleClose={props.onCancel} header={'Upload new file'}>
        {props.updateInProgress &&
        <Box sx={{
          minWidth: '70%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Box width={'100%'}>
            <LinearProgress/>
          </Box>

          <Text text={'Updating attachment...'} sizePx={16}/>
        </Box>}

        {!props.updateInProgress && props.attachment && props.attachment?.[allConstants.JOB_FIELDS.ATTACHMENT.FILE_ID.api_name] && <FormProvider {...formMethods}>
          <Text text={'Please delete attachment and create a new one if you need only file name change'}
            color={COLORS.warningRed}
            sizePx={14}/>
          <Spacer y={1}/>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
            {/*<CustomFormBlock {...commonFieldProps}*/}

            {/*  field={{...allConstants.JOB_FIELDS.FILE.NAME, ...{required: true}}}*/}
            {/*  // defaultValue={props.attachment.fileName}*/}
            {/*/>*/}
            <CustomFormBlock {...commonFieldProps}

              field={{...allConstants.JOB_FIELDS.FILE.UPLOAD, ...{required: true}}}
              inputType={inputTypes.file}
              defaultValue={{
                id: props.attachment.fileId,
                name: props.attachment.fileName
              }}

              // defaultValue={getFileValue(allConstants.JOB_FIELDS.FILE.UPLOAD.api_name, index)}
            />
            <FormSubmitButtons cancelAction={props.onCancel} submitText={'Submit'}/>
          </form>
        </FormProvider>}
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

EditFile.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fileId: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func,
  submitAction: PropTypes.func,
  updateInProgress: PropTypes.bool

};

EditFile.defaultProps = {
  onCancel: ()=>{},
  submitAction: ()=>{},
  updateInProgress: false
};
