import {ROLES, SCOPES, IPERMIT_ROLES, CONTRACTOR_ROLES} from '../../constants/allConstants';
const ELEMENTS = {
  addJobButton: 'addJobButton',
  addNote: 'addNote',
  addAttachment: 'addAttachment',
  editMap: 'editMap',
  addInspectionNote: 'addInspectionNote',
  requestInspection: 'requestInspection',
  setCf1rPending: 'setCf1rPending',

};

const jobRules = Object.freeze({
  [ELEMENTS.addJobButton]: {
    [SCOPES.canView]: [ ROLES.CONTRACTOR, ROLES.COMPANY_ADMIN, ...Object.values(IPERMIT_ROLES)],
    hideFromSystemAdmin: true,
    hideFromIpermit: [SCOPES.canView]
  },
  [ELEMENTS.addNote]: {
    [SCOPES.canView]: Object.values(CONTRACTOR_ROLES),
  },
  [ELEMENTS.addAttachment]: {
    [SCOPES.canView]: Object.values(CONTRACTOR_ROLES),
  },
  [ELEMENTS.editMap]: {
    [SCOPES.canView]: [ ...Object.values(IPERMIT_ROLES), ...[ROLES.COMPANY_ADMIN, ROLES.CONTRACTOR]]
  },
  [ELEMENTS.addInspectionNote]: {
    [SCOPES.canView]: [ ...Object.values(IPERMIT_ROLES)],
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.requestInspection]: {
    [SCOPES.canView]: [IPERMIT_ROLES.IPERMIT_ADMIN, IPERMIT_ROLES.SYSTEM_ADMIN],
    hideWhenImpersonating: [SCOPES.canView]
  },
  [ELEMENTS.setCf1rPending]: {
    [SCOPES.canView]: [ROLES.IPERMIT_ADMIN, ROLES.SYSTEM_ADMIN],
    hideWhenImpersonating: [SCOPES.canView]
  }

});
export default ELEMENTS;
export {jobRules};
