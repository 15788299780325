import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useGetRole} from './useGetRole';
import {useGetMyCompanyId} from './useGetMyCompanyId';
import usePagination from './usePagination';
import * as allConstants from '../constants/allConstants';
import BlockedPage from '../pages/misc/blockedCompany';
import AccessDenied from '../pages/misc/accessDenied';

const useGridViewState = ()=> {
  const user = useSelector((state=> state.user));
  const role = useGetRole();
  const companyId = useGetMyCompanyId();
  const [fetching, setFetching] = useState(true);
  const [grid, setGrid] = useState();
  const [blocked, setBlocked] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const pagination = usePagination();
  const onFetchFail = (error) => {
    setFetching(false);
    if(error?.response?.data?.errorCode === allConstants.ERROR_CODES.COMPANY_BLOCKED) {
      setBlocked(true);
    }
  };
  const setGridToParent = (data)=> {
    setGrid(data);
  };
  /**
   *
   * @param url
   * @param respKey
   * @return {{respKey, url}}
   */
  const createFetchParams = (url, respKey)=> {
    return{
      url: url,
      respKey: respKey,
    };
  };

  const selectedMultiple = ()=> {
    if(!grid) return false;
    const selectedNodes = grid.api.getSelectedNodes();
    return selectedNodes && selectedNodes.length >= 2;
  };

  const getSelected = ()=> {
    if(!grid) return null;
    return grid.api.getSelectedNodes();
  };

  let RenderElement = (children)=><>{children}</>;
  if(blocked) {
    // eslint-disable-next-line react/display-name
    RenderElement = ()=><BlockedPage/>;
  } else if(accessDenied) {
    // eslint-disable-next-line react/display-name
    RenderElement = ()=><AccessDenied/>;
  }

  const exportAllAction = ()=> {

    grid.api.exportDataAsExcel('filteredAndSorted');
  };

  const exportChecked = () => {
    grid.api.exportDataAsExcel({
      onlySelected: true,
    });
  };

  return {
    user: user,
    userRole: role,
    companyId: companyId,
    grid: grid,
    blocked: blocked,
    fetching: fetching,
    pagination: pagination,
    onFetchFail: onFetchFail,
    createFetchParams: createFetchParams,
    setGridToParent: setGridToParent,
    selectedMultipleRows: selectedMultiple,
    setFetching: (s)=>setFetching(s),
    RenderElement: RenderElement,
    setAccessDenied: setAccessDenied,
    getSelected: getSelected,
    exportAll: exportAllAction,
    exportChecked: exportChecked,

  };

};

export {useGridViewState};
