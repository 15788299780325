import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import * as allConstants from '../../../constants/allConstants';
import * as Yup from 'yup';
import {useSubform} from '../../../hooks/useSubform';
import {FormProvider} from 'react-hook-form';
import {Box, List, ListItem} from '@mui/material';
import CustomFormBlock from '../../../pieces/inputs/formBlock';
import {inputTypes} from '../../../pieces/inputs/formInput';
import {AddRowButton, variants} from '../../../pieces/addDeleteEquipmentRowButtons';
import FormSubmitButtons from '../../../pieces/formSubmitButtons';
import ApiUtils from '../../../utils/apiUtils';
import base_url from '../../../baseUrls';
import Utils from '../../../utils/utils';
import {useJob} from '../../../contextProviders/jobContext';
import Loader from '../../misc/loader';


export const IpermitAddAttachemnt = (props) => {
  const {onCancel, defaultType} = props;
  const [fetching, setFetching] = useState(false);
  const {job, setJob} = useJob();

  const collectionName = allConstants.JOB_FIELDS.ATTACHMENT.TEXT.api_name;

  const validationSchema = Yup.object().shape({
    [collectionName]: Yup.array().of(Yup.object().shape({

      [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: Yup.mixed().required('Please upload file or remove this row'),
    }))
  });

  const collectionRow = useMemo(() => {
    return {
      [allConstants.JOB_FIELDS.FILE.NAME.api_name]: null,
      [allConstants.JOB_FIELDS.FILE.UPLOAD.api_name]: null,
    };
  },[]);

  const {commonFieldProps, fields, formMethods, addRowAction, getDeleteRowButton} = useSubform({
    collectionName: collectionName,
    validationSchema: validationSchema,
    subformRow: collectionRow,
    currentValues: [collectionRow],
    addRowText: 'Add Attachment',
  });

  const values = formMethods.getValues();

  const onSubmit = async (data) => {
    const filesArray = data[collectionName];
    if(!filesArray || filesArray.length === 0) return;

    setFetching(true);

    const filesToSubmit = filesArray.map((f)=> {
      const fileObj = {
        fileUploadId: f?.file?.id || f?.file,
        type: f?.type || allConstants.ATTACHMENT_TYPES.PERMIT.value,
      };

      let fileName;
      if(f?.name && f.name.length !== 0) {
        fileName = f.name;
      } else if(f?.file && typeof f.file === 'object' &&  f?.file?.name){
        fileName = f.file.name;
      } else {
        fileName = f.fileName;
      }

      fileObj.fileName = fileName;

      return fileObj;
    });

    const authToken = await ApiUtils.getAccessToken();
    const body = {
      jobId: job?.[allConstants.JOB_FIELDS.ID.api_name],
      attachments: filesToSubmit
    };

    const response = await fetch(`${base_url.api}jobs/add-attachments`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(
          body
        ),
      }).catch((error) => {
      setFetching(false);
    });

    const responseJson = await response.json();

    Utils.handleToastMessage(responseJson, 'Attachment added', 'Please try adding attachment again', 'add_attachments');

    setFetching(false);

    if (responseJson.status === 'success') {
      setJob({...job, ...responseJson.data.job});
      onCancel();
    }
  };

  const gridTemplateColumns = '1fr 1fr 0.5fr 40px';
  const gap = '16px';
  if (fetching) return (<Loader/>);

  const getFileValue = (fieldName, index)=> {
    const v = values?.[collectionName];
    if(!v || !Array.isArray(v) || !v[0]) return null;
    const blockValues = v?.[index];
    if(!blockValues) return null;
    if(blockValues?.file && typeof blockValues?.file === 'string') {
      return {
        id: blockValues.file,
        name:blockValues?.fileName || '',
      };
    }
    return blockValues.file;
  };

  const getFileName = (fieldName, index)=> {
    const existingName = values?.['files']?.[index]?.[allConstants.JOB_FIELDS.FILE.NAME.api_name];
    if(existingName) return existingName;
    return undefined;
  };

  return (
    <>
      <FormProvider {...formMethods}>

        <form onSubmit={formMethods.handleSubmit(onSubmit)}  method="POST">
          <List sx={{
            maxWidth: '800px',
          }}>
            {fields.map((item, index) => (
              <ListItem key={item.id+index} sx={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns,
                gap: gap,
                alignItems: 'flex-start',
                '& .MuiInputBase-inputMultiline': {
                  width: '100% !important',
                },
                paddingBottom: 0,
                paddingTop: 0,
              }}>
                <CustomFormBlock
                  {...commonFieldProps(index)}
                  field={allConstants.JOB_FIELDS.FILE.NAME}
                  defaultValue={getFileName(allConstants.JOB_FIELDS.FILE.UPLOAD.api_name, index)?.name}
                  isEditable={true}
                  renderLabel={true}
                  fullWidth
                />
                <CustomFormBlock
                  {...commonFieldProps(index)}
                  inputType={inputTypes.file}
                  field={allConstants.JOB_FIELDS.FILE.UPLOAD}
                  placeholder={allConstants.JOB_FIELDS.FILE.UPLOAD.display_label}
                  defaultValue={getFileValue(allConstants.JOB_FIELDS.FILE.UPLOAD.api_name, index)}
                  isEditable={true}
                  renderLabel={true}
                  fullWidth
                />
                <CustomFormBlock
                  {...commonFieldProps(index)}
                  isEditable={true}
                  field={allConstants.JOB_FIELDS.FILE.TYPE}
                  inputType={inputTypes.singleSelect}
                  options={Utils.getOptions(allConstants.ATTACHMENT_TYPES)}
                  defaultValue={defaultType.value}
                  renderLabel={true}
                  fullWidth
                />

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  ' .MuiButtonBase-root': {
                    marginTop: '-7px'
                  }
                }}>
                  {getDeleteRowButton(index)}
                </Box>

              </ListItem>

            ))}
            <ListItem>
              <AddRowButton eqName={collectionName} variant={variants.textWithIcon}
                addAction={()=>  addRowAction()}/>
            </ListItem>
          </List>


          <FormSubmitButtons cancelAction={onCancel} submitText={'Submit'}
            disableSubmit={!values?.[collectionName] || values[collectionName].length === 0}/>

        </form>
      </FormProvider>
    </>

  );
};

IpermitAddAttachemnt.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  defaultType: PropTypes.shape({
    value: PropTypes.string,
    display: PropTypes.string,
  }),
};

IpermitAddAttachemnt.defaultProps = {
  open: false,
  onCancel: () => {},
  defaultType: allConstants.NOTE_TYPES.PERMIT,
};
