import React, {forwardRef, useEffect} from 'react';
import PropTypes from 'prop-types';
// import MenuItem from '@mui/material/MenuItem';
import {Controller} from 'react-hook-form';
import {formBlockDefaultProps, formBlockTypes} from './formBlockProps';
import {Radio, RadioGroup} from '@mui/material';

export const CustomRadioGroup= forwardRef((props, ref)=> {
  //console.log('CustomRadioGroup', props);

  // const [currentSelected, setCurrentSelected] = useState(props.defaultValue ? props.defaultValue : '');
  // const blankOption = [<MenuItem aria-label="None" value="" key={'0none'} style={{padding: '0'}}/>];
  const triggerMouseDown = props.items.props.onMouseDown;

  let items;
  if(props.items) {
    items = props.items;
  } else {
    items = [];
    props.options.forEach((e, idx)=> {
      items.push(<Radio value={e.value} key={`${e.value}${idx}`} checked={false}>{e.name}</Radio>);
    });
  }

  const handleSelect = (e)=> {
    if(props.disabled) return;
    // setCurrentSelected(e.target.value);
    props.setValueToForm(props.field_name, e.target.value ? e.target.value : undefined, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
  };
  const {register, field_name} = props;
  useEffect(() => {
    register(field_name );
  }, [register, field_name]);

  // console.oog
  return (
    <>
      <Controller
        name={props.field_name}
        control={props.control}
        defaultValue={props.defaultValue}
        {...props.register(props.field_name, {required: props.field.required})}
        render={() => (
          <RadioGroup row
            aria-labelledby="select-job-type-label"
            // defaultValue="female"
            name="select-job-type"
            onChange={triggerMouseDown ? null : handleSelect}
            onMouseDown={triggerMouseDown ? handleSelect : null}
          >
            {items}
          </RadioGroup>
        )}/>
    </>
  );
});
CustomRadioGroup.displayName = 'CustomRadioGroup';
CustomRadioGroup.propTypes = {
  ...formBlockTypes,
  ...{
    field_name: PropTypes.string.isRequired
  }
};
CustomRadioGroup.defaultProps = formBlockDefaultProps;
export default CustomRadioGroup;
