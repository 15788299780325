import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import * as allConstants from '../../constants/allConstants';
import {Text} from '../text';
import LadderIcon from '../../static/images/ladder.png';

export const InspectionRequirementsRenderer = ({value})=> {
  if(!value) return <></>;

  const needLadder = value?.[allConstants.INSPECTION_FIELDS.LADDER.api_name];
  const requirements = value?.[allConstants.INSPECTION_FIELDS.REQUIREMENTS.api_name];

  return (
    <Box display={'flex'} sx={{
      alignItems: 'center',
      '& img': {
        height: '26px',
        width: '20px',
      }
    }}>
      {needLadder && <>
        <img src={LadderIcon} alt={'Need ladder'}/>
      </>}
      {requirements && <Text text={requirements} size={'small'}/>}
    </Box>
  );
};

InspectionRequirementsRenderer.propTypes = {
  value: PropTypes.instanceOf(Object)
};

InspectionRequirementsRenderer.defaultProps = {
  value: null
};

export default InspectionRequirementsRenderer;
