import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ThemeProvider, Box} from '@mui/material';
import NavBar from '../../pieces/navbar/navbar';
import * as allConstants from '../../constants/allConstants';
import PermissionGate from '../../permissions/permissionGate';
import myTheme from '../../styles/myStyles';
import DisplayListsPageWrapper from '../wrappers/displayListsPageWrapper';
import ListPageHeader from '../../pieces/listPageHeader';
import usePagination from '../../hooks/usePagination';
import {useGridViewState} from '../../hooks/useGridViewState';
import {globalUseClasses} from '../../styles/globalClasses';
import {ratersPageRules} from './raterPageAccessControl';
import AllRatersGrid from './allRatersGrid';

const AllRatersView = () => {
  const viewController = useGridViewState();
  const classes = globalUseClasses();
  const pagination = usePagination();
  const navigate = useNavigate();

  const handleAddRaterOpen = (id)=>{
    navigate(`${allConstants.PATH.RATER}${location.search}`);
  };

  const headerComponent = <ListPageHeader header={'All Raters'}
    addHandler={handleAddRaterOpen}
    totalItems={pagination.totalItemsCount}
    showAddButton = {PermissionGate ({
      scope: allConstants.SCOPES.canView,
      role: viewController.userRole,
      modulePermissions:ratersPageRules.addRaterButton})}
  >
    {pagination.ItemsController}
  </ListPageHeader>;

  return (
    viewController.RenderElement(
      <>
        <NavBar/>
        <ThemeProvider theme={myTheme}>
          <Box className={classes.page}>

            <DisplayListsPageWrapper
              headerComponent={headerComponent}>
              <AllRatersGrid
                viewController={viewController}
                pagination={pagination}
              />
              {pagination.PageController}

            </DisplayListsPageWrapper>
          </Box>
        </ThemeProvider>
      </>
    )
  );
};
export default AllRatersView;
